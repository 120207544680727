import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Mark } from '../../mark/mark';
import { MarkService } from '../../mark/mark.service';

@Component({
  selector: 'app-add-marque-modal',
  templateUrl: './add-marque-modal.component.html',
  styleUrls: ['./add-marque-modal.component.css']
})
export class AddMarqueModalComponent implements OnInit {
  showAlert: boolean;
  creerNouveau: boolean = false;
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<any>();
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false
  constructor(private markService: MarkService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getMarks({ where: { "entrepriseId": this.id_entreprise } })
  }

  getMarks(filter: FilterDto<Mark>) {
    this.markService.getMarks(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  CancelAction() {
    this.closed.emit(true);
  }

  submit(mark: Mark, form: NgForm) {
    mark.real_id = this.real_id
    mark.entrepriseId = this.id_entreprise
    mark.byAdmin = this.isAdmin
    this.markService.addMark(mark)
      .subscribe(
        data => {
          this.CancelAction();
          this.saved.emit(data);
        })
  }
}
