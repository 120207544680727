import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { LoginComponent } from './login/login.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AddUserComponent } from './user-management/add-user/add-user.component';
import { DeleteUserComponent } from './user-management/delete-user/delete-user.component';
import { DetailUserComponent } from './user-management/detail-user/detail-user.component';
import { EditUserComponent } from './user-management/edit-user/edit-user.component';
import { UserRoutingModule } from './user-routing.module';
import { SharedModule } from '../shared/shared.module';
import '@cds/core/file/register.js';
import { CdsModule } from '@cds/angular';
import { SignInComponent } from './sign-in/sign-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { TranslateModule } from '@ngx-translate/core';
import { NavComponent } from './nav/nav.component';
import { AddCaisseModalComponent } from './user-management/add-caisse-modal/add-caisse-modal.component';
import { AddSalesPointModalComponent } from './user-management/add-sales-point-modal/add-sales-point-modal.component';
import { AddModalStoreComponent } from './user-management/add-modal-store/add-modal-store.component';
import { AddModalVehiculeComponent } from './user-management/add-modal-vehicule/add-modal-vehicule.component';
import { ActivationAccountComponent } from './activation-account/activation-account.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserDemoComponent } from './user-demo/user-demo.component';
import { AutorisationComponent } from './autorisation/autorisation.component';
import { AddAutorisationComponent } from './autorisation/add-autorisation/add-autorisation.component';
import { AutorisationDeleteComponent } from './autorisation/autorisation-delete/autorisation-delete.component';
import { AutorisationDetailComponent } from './autorisation/autorisation-detail/autorisation-detail.component';



@NgModule({
  declarations: [LoginComponent, UserManagementComponent,
    AddUserComponent, DeleteUserComponent, DetailUserComponent,
    EditUserComponent, SignInComponent, ResetPasswordComponent,
    NavComponent, AddCaisseModalComponent, AddSalesPointModalComponent,
    AddModalStoreComponent, AddModalVehiculeComponent, ActivationAccountComponent, UserDemoComponent,
     AutorisationComponent,  AddAutorisationComponent, AutorisationDeleteComponent, AutorisationDetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    UserRoutingModule,
    SharedModule,
    CdsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule
  ],
  exports: [LoginComponent, SignInComponent]
})
export class UserModule { }
