import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-email-list-filter',
  templateUrl: './email-list-filter.component.html',
  styleUrls: ['./email-list-filter.component.css']
})
export class EmailListFilterComponent implements ClrDatagridFilterInterface<{ key: string, value: string }> {

  @Input() openUserFilter: boolean
  public changes = new Subject<any>();
  mailArray = []
  loading: boolean;
  saisie: string;
  filter: FilterDto = new FilterDto();
  id_entreprise: number

  constructor(private quotationService: QuotationService) { }


  onSearchChange(args?: string) {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      if (this.openUserFilter) {
        this.filter.select = ["id", "email"];
        this.filter.where = { email: { type: "like", value: this.saisie }, active: true }
        this.getUsersMail(this.filter)
      }
    }
    else {
      this.filter.where = { active: true }
      this.filter.take = 15;
      this.getUsersMail(this.filter)

    }
  }

   


  getUsersMail(filter: FilterDto<any>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.quotationService.getUser(filter).subscribe(
      data => {
        this.mailArray = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )
  }

  accepts(item): boolean {
    return true;
  }

  isActive(): boolean {
    return this.mailArray != null && this.mailArray.length > 0;
  }


}




