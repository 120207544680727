<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> {{ 'commun.jt' | translate }} {{ 'commun.type' | translate }}</h3>
        <<svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CancelAction()" width="20"
          height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
      </div>
      <div class="modal-body">
        <form clrForm (ngSubmit)="submit(typeForm.value)" #typeForm="ngForm">
          <clr-input-container>
            <label> {{ 'login.VotreNom' | translate }}*</label>
            <input clrInput type="text" name="name" ngModel required />
            <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-select-container>
            <label>Type *</label>
            <select clrSelect name="type" ngModel required>
              <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
              <option value="Numero"> {{ 'commun.num' | translate }}</option>
              <option value="Texte">{{ 'commun.tx' | translate }}</option>
              <option value="Couleur">{{ 'commun.clr' | translate }}</option>
            </select>
          </clr-select-container>
          <clr-textarea-container>
            <label> {{ 'Points.desc' | translate }}</label>
            <textarea clrTextarea name="description" ngModel></textarea>
          </clr-textarea-container>
          <br />
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="CancelAction()"> {{ 'commun.nn' | translate }}</button>
        <button class="btn btn-success" [disabled]="typeForm.invalid" type="button" (click)="submit(typeForm.value)"> {{
          'commun.jt' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
