import { Component, Input } from '@angular/core';
import { ClrDatagridFilterInterface, ClrSignpostModule } from '@clr/angular';
import { Subject } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { Companys, Customer } from 'src/app/parts/parts';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { Carrier } from 'src/app/transport/carrier/carrier';
import { CarrierService } from 'src/app/transport/carrier/carrier.service';
import { User } from 'src/app/user/user';
import { SettingService } from '../../setting/setting.service';
import { CostsType, OperationType, PriceType, TransactionType } from '../../setting/type/type';
import { PartsService } from '../../parts/parts.service';
import { Bank, Store } from '../../buying/buying';
import { Providers } from '../../parts/parts';
import { Truck } from 'src/app/transport/truck/truck';
import { TruckService } from 'src/app/transport/truck/truck.service';
import { Banniere } from 'src/app/shop/shop';
import { ShopService } from '../../shop/shop.service';
import { StockService } from 'src/app/stock/stock.service';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-combobox-list-filter',
  templateUrl: './combobox-list-filter.component.html',
  styleUrls: ['./combobox-list-filter.component.css']
})
export class ComboboxListFilterComponent implements ClrDatagridFilterInterface<[]> {

  @Input() openCustomerFilter: boolean
  @Input() openCusVilleFilter: boolean
  @Input() openBkFilter: boolean
  @Input() nameUser: boolean
  @Input() cmdUserFilter: boolean
  @Input() carrierFilter: boolean
  @Input() accountFilter: boolean
  @Input() providerFilter: boolean
  @Input() companyFilter: boolean
  @Input() markFilter: boolean
  @Input() modelFilter: boolean



  @Input() entityName: string

  changes = new Subject<any>();
  customerFilter: Customer[] = [];

  priceTypes: PriceType[] = []
  costTypes: CostsType[] = []
  familletype = []
  userArray = []
  cmdUserArray = []
  bankArray = []
  accountArray = []
  providerArray = []
  companyArray = []
  customerFilterVille = []
  filter: FilterDto<any> = new FilterDto<any>()
  saisie: string;
  loading: boolean;
  carriers: Carrier[] = []
  trucks: Truck[] = [];

  where: any = {};


  opTypeValue: number
  createdBy: number
  selectedItem: string
  generalTab: any[] = []
  customerNames: Customer[];

  id_entreprise: number

  constructor(private shopService: ShopService, private quotationService: QuotationService, private settingService: SettingService, private partsService: PartsService,
    private carrierService: CarrierService, private truckService: TruckService, private stockService: StockService) {
  }
  onSearchUser(args?: any) {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    if (args == null) {
      args = undefined
      this.createdBy = undefined
    }
    if (args) {
      var item = args.split('_')
      if (item.length == 2) {
        var name: string = item[1]
        var id: number = item[0]
        this.createdBy = +id
        this.selectedItem = name
        this.filter.where = { id: id, active: true, "entrepriseId": this.id_entreprise }
        this.getCmdUser(this.filter)
      }
    }
    else {
      this.filter.where = { active: true, "entrepriseId": this.id_entreprise }
      this.filter.take = 15;
      this.getCmdUser(this.filter)
    }
  }
  getCmdUser(filter: FilterDto<User>) {
    this.quotationService.getUser(filter).subscribe(
      data => {
        this.cmdUserArray = data[0]
        this.changes.next(true);
      }
    )
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  onSearchChange(args?: string) {
    let hasData: boolean = false
    this.loading = true
    if (args) {
      hasData = true;
      this.saisie = "*" + args + "*"
      this.filter.select = ["id", "name"];
      this.filter.where = { name: { type: "like", value: this.saisie }, active: true, "entrepriseId": this.id_entreprise }

      if (this.nameUser) {
        this.filter.select = ["id", "name"];
        this.filter.where = { name: { type: "like", value: this.saisie }, active: true, "entrepriseId": this.id_entreprise }
        this.getUsers(this.filter)
      }
      if (this.accountFilter) {
        this.filter.select = ["id", "account"];
        this.filter.where = { account: { type: "like", value: this.saisie }, active: true, "entrepriseId": this.id_entreprise }
        this.getAccount(this.filter)
      }
      if (this.openCusVilleFilter) {
        this.filter.select = ["id", "ville"];
        this.filter.where = { ville: { type: "like", value: this.saisie }, active: true, "entrepriseId": this.id_entreprise }
        this.getcustomerVille(this.filter, true)
      }

      if (this.entityName == "identifiantFilter") {
        this.filter.select = ["id", "identifier"];
        this.filter.where = { identifier: { type: "like", value: this.saisie }, active: true, "entrepriseId": this.id_entreprise }
        this.getIdentifierTypes(this.filter)
      }


      if (this.entityName == "banieroffer") {
        this.filter.select = ["pathUrl"];
        this.filter.where = { pathUrl: { type: "like", value: this.saisie }, active: true, isBigOffer: true, position: { type: "equal", value: 5 }, "entrepriseId": this.id_entreprise }
        this.getBanniere(this.filter)
        this.where = this.filter.where
      }
      if (this.entityName == "banierpromo") {
        this.filter.select = ["pathUrl"];
        this.filter.where = { "entrepriseId": this.id_entreprise, pathUrl: { type: "like", value: this.saisie }, active: true, isBigOffer: false, position: { type: "in", value: [1, 2, 3, 4] } }
        this.getBanniere(this.filter)
        this.where = this.filter.where
      }

      if (this.entityName == "baniereBigTitle") {
        this.filter.select = ["bigTitle"];
        this.filter.where = { "entrepriseId": this.id_entreprise, bigTitle: { type: "like", value: this.saisie } }
        this.getBanniere(this.filter)
      }
      if (this.entityName == "baniereSmallTitle") {
        this.filter.select = ["smallTitle"];
        this.filter.where = { "entrepriseId": this.id_entreprise, smallTitle: { type: "like", value: this.saisie } }
        this.getBanniere(this.filter)
      }

      if (this.entityName == "OpenCosFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise, name: { type: "like", value: this.saisie }, active: true }
        this.getCostTypes(this.filter)
      }
      if (this.entityName == "depotName") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getStore(this.filter)
      }

      if (this.entityName == "OpenCos") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCostTypes2(this.filter, hasData)
      }



      if (this.entityName == "openOptype") {
        this.getOperationTypesSelect()
      }

      if (this.entityName == "TrFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getTransactionTypes(this.filter)
      }

      if (this.entityName == "openOpFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getOperationTypes(this.filter)
      }
      if (this.entityName == "OpenPrFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getPriceTypes(this.filter)
      }
      if (this.providerFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getProviders(this.filter)
      }
      if (this.companyFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCompanys(this.filter)
      }
      if (this.openBkFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getBanks(this.filter)
      }
      if (this.openCustomerFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCustomers(this.filter, hasData)
      }
      if (this.openCusVilleFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getcustomerVille(this.filter, hasData)
      }

      if (this.carrierFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.filter.select = ["id", "name"];
        this.filter.where = { name: { type: "like", value: this.saisie }, active: true }
        this.getCarriers(this.filter)
      }
      if (this.markFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.filter.select = ["id", "mark"];
        this.filter.where = { mark: { type: "like", value: this.saisie }, active: true }
        this.getTrucks(this.filter)
      }
      if (this.modelFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.filter.select = ["id", "model"];
        this.filter.where = { model: { type: "like", value: this.saisie }, active: true }
        this.getTrucks(this.filter)
      }
      if (this.entityName == "personelNamesFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCustomersIds(this.filter, hasData)
      }


    }
    else {
      this.filter.where = { active: true }
      hasData = false
      this.filter.take = 15;
      if (this.entityName == "personelNamesFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCustomersIds(this.filter, hasData)
      }

      if (this.entityName == "identifiantFilter") {
        this.filter.select = ["id", "identifier"];
        this.filter.where = { active: true }
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getIdentifierTypes(this.filter)
      }

      if (this.entityName == "baniereBigTitle") {
        this.filter.select = ["id", "bigTitle"];
        this.filter.where = { "entrepriseId": this.id_entreprise, active: true, isBigOffer: false, position: { type: "equal", value: 6 } }
        this.getBanniere(this.filter)
      }
      if (this.entityName == "baniereSmallTitle") {
        this.filter.select = ["id", "bigTitle", "smallTitle"];
        this.filter.where = { "entrepriseId": this.id_entreprise, active: true, isBigOffer: false, position: { type: "equal", value: 6 } }
        this.getBanniere(this.filter)
      }

      if (this.entityName == "banieroffer") {
        this.filter.where = { "entrepriseId": this.id_entreprise, active: true, isBigOffer: true, position: { type: "equal", value: 5 } }
        this.getBanniere(this.filter)
      }
      if (this.entityName == "banierpromo") {
        this.filter.where = { "entrepriseId": this.id_entreprise, active: true, isBigOffer: false, position: { type: "in", value: [1, 2, 3, 4] } }
        this.getBanniere(this.filter)
      }
      if (this.entityName == "OpenCosFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCostTypes(this.filter)
      }

      if (this.entityName == "OpenCos") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCostTypes2(this.filter, hasData)
      }

      if (this.entityName == "openOptype") {
        this.getOperationTypesSelect()
      }
      if (this.entityName == "depotName") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getStore(this.filter)
      }

      if (this.providerFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getProviders(this.filter)
      }
      if (this.companyFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCompanys(this.filter)
      }
      if (this.openBkFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getBanks(this.filter)
      }

      if (this.entityName == "openOpFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getOperationTypes(this.filter)
      }
      if (this.entityName == "TrFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getTransactionTypes(this.filter)
      }
      if (this.openCustomerFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCustomers(this.filter, hasData)
      }
      if (this.openCusVilleFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getcustomerVille(this.filter, false)
      }
      if (this.entityName == "OpenPrFilter") {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getPriceTypes(this.filter)
      }
      if (this.nameUser) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getUsers(this.filter)
      }
      if (this.carrierFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getCarriers(this.filter)
      }
      if (this.accountFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getAccount(this.filter)
      }
      if (this.markFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getTrucks(this.filter)
      }
      if (this.modelFilter) {
        this.filter.where = { "entrepriseId": this.id_entreprise }
        this.getTrucks(this.filter)
      }
    }

  }

  getCustomersIds(filter: FilterDto<Customer>, valuergs: boolean) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.quotationService.getCustomers(filter).subscribe(
      customers => {
        this.customerNames = customers[0];
        if (valuergs == true) {
          customers[0].forEach(element => {
            this.generalTab.push(element.id)
            this.filter.where = { customerId: { type: "in", value: this.generalTab } }
          });
        } else {
          this.generalTab = []
        }
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  getIdentifierTypes(filter: FilterDto<TransactionType>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.where = filter.where
    this.settingService.getTransactionTypes(filter).subscribe(
      data => {
        this.generalTab = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )
  }


  getBanks(filter: FilterDto<Bank>) {
    this.partsService.getBanks(filter).subscribe(
      data => {
        this.bankArray = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )

  }
  getProviders(filter: FilterDto<Providers>) {
    this.partsService.getProviders(filter).subscribe(
      data => {
        this.providerArray = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )

  }
  getCompanys(filter: FilterDto<Companys>) {
    this.partsService.getCompanys(filter).subscribe(
      data => {
        this.companyArray = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )

  }
  getAccount(filter: FilterDto<Bank>) {
    this.partsService.getBanks(filter).subscribe(
      data => {
        this.accountArray = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )

  }

  getUsers(filter: FilterDto<User>) {
    this.quotationService.getUser(filter).subscribe(
      data => {
        this.userArray = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )

  }
  getCustomers(filter: FilterDto<Customer>, valuergs: boolean) {
    this.quotationService.getCustomers(filter).subscribe(
      customers => {
        this.customerFilter = customers[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  getcustomerVille(filter: FilterDto<Customer>, valuergs: boolean) {
    this.quotationService.getCustomers(filter).subscribe(
      customers => {
        customers[0].forEach(element => {
          if (element.ville) {
            this.customerFilterVille.push(element)
          }
        });
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  getOperationTypes(filter: FilterDto<OperationType>) {
    this.settingService.getOperationTypes(filter).subscribe(
      data => {
        this.generalTab = data[0]
        this.where = this.filter.where
        this.changes.next(true);
        this.loading = false
      }
    )
  }


  change() {
    this.opTypeValue = +this.opTypeValue
    this.getOperationTypesSelect()
  }
  getOperationTypesSelect() {

    if (this.opTypeValue == 1) {
      this.filter.where = { "entrepriseId": this.id_entreprise, transactionTypeId: { type: "in", value: [2, 4] } }
      this.filter.where = Object.assign(this.filter.where, { isCredit: false })
    } if (this.opTypeValue == 2) {
      this.filter.where = { "entrepriseId": this.id_entreprise, transactionTypeId: { type: "in", value: [1, 3] } }
      this.filter.where = Object.assign(this.filter.where, { isCredit: false })
    } if (this.opTypeValue == 3) {
      this.filter.where = { "entrepriseId": this.id_entreprise, transactionTypeId: { type: "moreThan", value: 4 } }
    } if (this.opTypeValue == 0) { this.filter.where = { "entrepriseId": this.id_entreprise, active: true } }
    this.generalTab.push(this.filter.where)
    this.changes.next(true);
    this.where = this.filter.where
  }

  getTransactionTypes(filter: FilterDto<TransactionType>) {
    this.where = filter.where
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getTransactionTypes(filter).subscribe(
      data => {
        this.generalTab = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )
  }

  getPriceTypes(filter: FilterDto<TransactionType>) {
    this.where = filter.where
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getPricesTypes(filter).subscribe(
      data => {
        this.generalTab = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )
  }


  getCarriers(filter: FilterDto<Carrier>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.carrierService.getCarriers(filter).subscribe(
      data => {
        this.carriers = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  getTrucks(filter: FilterDto<Truck>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.truckService.getTrucks(filter).subscribe(
      data => {
        this.trucks = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }


  getBanniere(filter: FilterDto<Banniere>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.where = filter.where
    this.shopService.getBannieres(filter).subscribe(
      data => {
        this.generalTab = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )
  }


  getCostTypes(filter: FilterDto<CostsType>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getCostTypes(filter).subscribe(
      data => {
        this.generalTab = data[0]
        this.where = filter.where
        this.changes.next(true);
        this.loading = false
      }
    )
  }
  getCostTypes2(filter: FilterDto<CostsType>, hasData: boolean) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getCostTypes(filter).subscribe(
      data => {
        this.costTypes = data[0]
        if (hasData == true) {
          for (let i = 0; i < this.costTypes.length; i++) {
            this.generalTab[i] = this.costTypes[i].id
          }
          filter.where = { costTypeId: { type: "in", value: this.generalTab } }
        }
        else {
          this.generalTab = []
        }
        this.where = filter.where
        this.changes.next(true)
        this.loading = false
      }
    )
  }



  getStore(filter: FilterDto<Store>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.where = filter.where
    this.stockService.getStore(filter).subscribe(
      data => {
        this.generalTab = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )
  }



  accepts(): boolean {
    return true;
  }

  isActive(): boolean {
    if (this.generalTab && this.generalTab.length > 0) {
      return this.generalTab != null && this.generalTab.length > 0
    }

    if (this.openCustomerFilter) {
      return this.customerFilter != null && this.customerFilter.length > 0;
    }
    if (this.openCusVilleFilter) {
      return this.customerFilterVille != null && this.customerFilterVille.length > 0;
    }

    if (this.nameUser) {
      return this.userArray != null && this.userArray.length > 0;
    }
    if (this.carrierFilter) {
      return this.carriers != null && this.carriers.length > 0;
    }
    if (this.openBkFilter) {
      return this.bankArray != null && this.bankArray.length > 0;
    }
    if (this.accountFilter) {
      return this.accountArray != null && this.accountArray.length > 0;
    }
    if (this.providerFilter) {
      return this.providerArray != null && this.providerArray.length > 0;
    }
    if (this.companyFilter) {
      return this.companyArray != null && this.companyArray.length > 0;
    }

    if (this.markFilter) {
      return this.trucks != null && this.trucks.length > 0;
    }
    if (this.modelFilter) {
      return this.trucks != null && this.trucks.length > 0;
    }
    if (this.cmdUserFilter) {
      return this.cmdUserArray != null && this.cmdUserArray.length > 0;
    }

  }
}
