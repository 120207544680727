<div class="modal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="modal-header">
                <h3> {{ 'commun.jt' | translate }} {{ 'commun.dép' | translate }}</h3>
                <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CloseAction()" width="20"
                    height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
            </div>
            <div class="modal-body">
                <form clrForm (ngSubmit)="submit(depotForm.value)" #depotForm="ngForm">
                    <clr-input-container>
                        <label> {{ 'login.VotreNom' | translate }}*</label>
                        <input clrInput type="text" name="name" ngModel required />
                        <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label> {{ 'commun.Tel' | translate }}*</label>
                        <input clrInput type="text" name="phone" ngModel required />
                        <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label> {{ 'commun.ad' | translate }}*</label>
                        <input clrInput type="text" name="address" ngModel required />
                        <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
                    </clr-input-container>
                    <br>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline" (click)="CloseAction()"> {{ 'commun.nn' | translate }} </button>
                <button class="btn btn-success" [disabled]="depotForm.invalid" (click)="submit(depotForm.value)"> {{
                    'commun.jt'
                    | translate }}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>