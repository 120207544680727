import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AbnGuard } from '../auth/abn.guard';
import { AuthGuard } from '../auth/auth.guard';
import { BankAddComponent } from './bank/bank-add/bank-add.component';
import { BankDetailComponent } from './bank/bank-detail/bank-detail.component';
import { BankEditComponent } from './bank/bank-edit/bank-edit.component';
import { BankComponent } from './bank/bank.component';
import { CompanyAddComponent } from './company/company-add/company-add.component';
import { CompanyDetailComponent } from './company/company-detail/company-detail.component';
import { CompanyEditComponent } from './company/company-edit/company-edit.component';
import { CompanyComponent } from './company/company.component';
import { CustomerAddComponent } from './customer/customer-add/customer-add.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { CustomerEditComponent } from './customer/customer-edit/customer-edit.component';
import { CustomerComponent } from './customer/customer.component';
import { PrintListComponent } from './customer/print-list/print-list.component';
import { PrintListProviderComponent } from './provider/print-list-provider/print-list-provider.component';
import { ProviderAddComponent } from './provider/provider-add/provider-add.component';
import { ProviderDetailComponent } from './provider/provider-detail/provider-detail.component';
import { ProviderEditComponent } from './provider/provider-edit/provider-edit.component';
import { ProviderComponent } from './provider/provider.component';
import { HttpClientModule } from '@angular/common/http';
import { AccessGuard } from '../auth/access.guard';


const routes: Routes = [
  { path: 'customer', component: CustomerComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","carte_fidelite", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'customer/add', component: CustomerAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","carte_fidelite", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'customer/detail/:id', component: CustomerDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","carte_fidelite", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'customer/edit/:id', component: CustomerEditComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","carte_fidelite", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'provider', component: ProviderComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'provider/add', component: ProviderAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'provider/detail/:id', component: ProviderDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'provider/edit/:id', component: ProviderEditComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'bank', component: BankComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'bank/add', component: BankAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'bank/detail/:id', component: BankDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'bank/edit/:id', component: BankEditComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'company', component: CompanyComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'company/add', component: CompanyAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'company/detail/:id', component: CompanyDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'company/edit/:id', component: CompanyEditComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  // { path: 'caissier', component: CashierComponent, canActivate: [AuthGuard] },
  // { path: 'caissier/add', component: AddCashierComponent, canActivate: [AuthGuard] },
  // { path: 'caissier/detail/:id', component: DetailCashierComponent, canActivate: [AuthGuard] },
  // { path: 'caissier/edit/:id', component: EditCashierComponent, canActivate: [AuthGuard] },
  { path: 'customer/printList', component: PrintListComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente","carte_fidelite", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'provider/printList', component: PrintListProviderComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    HttpClientModule
  ],
  exports: [RouterModule]
})
export class PartsRoutingModule { }
