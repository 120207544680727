import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { User } from 'src/app/user/user';
import { getValueFromToken } from 'src/app/user/user.service';
import { pack } from '../pack';
import { PackService } from '../pack.service';

@Component({
  selector: 'app-detail-pack',
  templateUrl: './detail-pack.component.html',
  styleUrls: ['./detail-pack.component.css']
})
export class DetailPackComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>()
  @Input() modelpack: pack
  // modelpack: pack;
  userAdd: any
  userEdit: any
  id_entreprise: number
  constructor(private packService: PackService, private settingService: SettingService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getInfo()
  }
  getInfo() {
    if (this.modelpack.createdBy) {
      if (!this.modelpack.byAdmin) {
        this.settingService.getUser(this.modelpack.createdBy).subscribe(
          data => {
            this.userAdd = data
          }
        )
      } else {
        let filter = new FilterDto()
        filter.where = { "id": this.id_entreprise }
        this.settingService.getAllEntreprise(filter).subscribe(
          data => {
            this.userAdd = data[0][0]
          }
        )
      }
    }
    if (this.modelpack.updatedBy) {
      if (!this.modelpack.updatebyAdmin) {
        this.settingService.getUser(this.modelpack.updatedBy).subscribe(
          data => {
            this.userEdit = data
          }
        )
      } else {
        let filter = new FilterDto()
        filter.where = { "id": this.id_entreprise }
        this.settingService.getAllEntreprise(filter).subscribe(
          data => {
            this.userEdit = data[0][0]
          }
        )
      }
    }
  }
  close() {
    this.closed.emit(true);
  }
}
