import { Component, OnInit } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken } from 'src/app/user/user.service';
import { Customer } from '../../parts';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-print-list',
  templateUrl: './print-list.component.html',
  styleUrls: ['./print-list.component.css']
})
export class PrintListComponent implements OnInit {
  filter: FilterDto<Customer> = new FilterDto<Customer>();
  customers: Customer[]
  count: number
  id_entreprise: number

  constructor(private partService: PartsService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.customers = [];
    this.getAllCustomers();
  }
   

  getAllCustomers() {
    this.filter.take = Infinity;
    this.filter.order = { "id": "DESC" }
    this.filter.relations = ["contacts"]
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.partService.getCustomers(this.filter).subscribe(
      data => {
        this.customers = data[0];
        this.count = data[1];
      }
    )
  }
  PrintList() {
    window.print();
  }
}
