import { Component, HostListener, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { PostBank } from '../../parts';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-bank-add',
  templateUrl: './bank-add.component.html',
  styleUrls: ['./bank-add.component.css']
})
export class BankAddComponent implements OnInit {
  showAlert: boolean;
  bankObject: PostBank = new PostBank()
  addNew: boolean;
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private partsService: PartsService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.partsService.getBanks({
      where: {
        "entrepriseId": this.id_entreprise
      }
    }).subscribe(data => {
      this.real_id = data[1] + 1;
    })
  }
   
  addBank() {
    this.bankObject.entrepriseId = this.id_entreprise
    this.bankObject.real_id = this.real_id
    this.bankObject.byAdmin = this.isAdmin
    this.partsService.addBank(this.bankObject).subscribe(
      data => {
        this.showAlert = true;
        if (this.addNew) {
          this.bankObject = new PostBank();
          this.addNew = false
        } else {
          this.addNew = true
        }
      }
    )
  }
  resetForm() {
    this.addNew = false;
    this.bankObject = new PostBank();
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
