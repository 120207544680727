import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-provider-delete',
  templateUrl: './provider-delete.component.html',
  styleUrls: ['./provider-delete.component.css']
})
export class ProviderDeleteComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() providerId: number

  deleteAlert: boolean;
  alertError: boolean;
  real_id: number

  constructor(private partsService: PartsService, private router: Router) { }
  ngOnInit(): void {
    this.partsService.getProvider(this.providerId).subscribe(
      data => {
        this.real_id = data.real_id;
      }
    )
  }



  deleteProvider() {
    if (!this.deleteAlert) {
      this.partsService.deleteProvider(this.providerId).subscribe(
        data => {
          this.saved.emit(true)
          this.closed.emit(true);
          this.router.navigate(['/parts/provider'])
        },
        err => {
          console.error('Observer got an error: ' + err)
          this.alertError = true
        }
      )
    }
  }
  closeAction() {
    this.closed.emit(true);
  }

}