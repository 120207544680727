import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartsRoutingModule } from './parts-routing.module';
import { CustomerComponent } from './customer/customer.component';
import { ProviderComponent } from './provider/provider.component';
import { BankComponent } from './bank/bank.component';
import { CustomerAddComponent } from './customer/customer-add/customer-add.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { CustomerEditComponent } from './customer/customer-edit/customer-edit.component';
import { CustomerDeleteComponent } from './customer/customer-delete/customer-delete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { ProviderAddComponent } from './provider/provider-add/provider-add.component';
import { ProviderDetailComponent } from './provider/provider-detail/provider-detail.component';
import { ProviderEditComponent } from './provider/provider-edit/provider-edit.component';
import { ProviderDeleteComponent } from './provider/provider-delete/provider-delete.component';
import { BankAddComponent } from './bank/bank-add/bank-add.component';
import { BankDetailComponent } from './bank/bank-detail/bank-detail.component';
import { BankEditComponent } from './bank/bank-edit/bank-edit.component';
import { BankDeleteComponent } from './bank/bank-delete/bank-delete.component';
import { SharedModule } from '../shared/shared.module';
import { CustomerStatestiqueComponent } from './customer/shared-customer/customer-statestique/customer-statestique.component';
import { CustomerHistoriqueComponent } from './customer/shared-customer/customer-historique/customer-historique.component';
import { CustomerCommandesComponent } from './customer/shared-customer/customer-commandes/customer-commandes.component';
import { CustomerInfosComponent } from './customer/shared-customer/customer-infos/customer-infos.component';
import { CompanyComponent } from './company/company.component';
import { CompanyAddComponent } from './company/company-add/company-add.component';
import { CompanyEditComponent } from './company/company-edit/company-edit.component';
import { CompanyDetailComponent } from './company/company-detail/company-detail.component';
import { CompanyDeleteComponent } from './company/company-delete/company-delete.component';
import { CashierComponent } from './cashier/cashier.component';
import { AddCashierComponent } from './cashier/add-cashier/add-cashier.component';
import { DetailCashierComponent } from './cashier/detail-cashier/detail-cashier.component';
import { EditCashierComponent } from './cashier/edit-cashier/edit-cashier.component';
import { DeleteCashierComponent } from './cashier/delete-cashier/delete-cashier.component';
import { PrintListComponent } from './customer/print-list/print-list.component';
import { PrintListProviderComponent } from './provider/print-list-provider/print-list-provider.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PartsDemoComponent } from './parts-demo/parts-demo.component';



@NgModule({
  declarations: [CustomerComponent, ProviderComponent, BankComponent, CustomerAddComponent,
    CustomerDetailComponent, CustomerEditComponent, CustomerDeleteComponent,
    ProviderAddComponent, ProviderDetailComponent, ProviderEditComponent, ProviderDeleteComponent,
    BankAddComponent, BankDetailComponent, BankEditComponent, BankDeleteComponent, CustomerStatestiqueComponent, CustomerHistoriqueComponent, CustomerCommandesComponent, CustomerInfosComponent, CompanyComponent, CompanyAddComponent, CompanyEditComponent, CompanyDetailComponent, CompanyDeleteComponent, CashierComponent, AddCashierComponent, DetailCashierComponent, EditCashierComponent, DeleteCashierComponent,
    PrintListComponent, PrintListProviderComponent, PartsDemoComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    PartsRoutingModule,
    SharedModule,
    TranslateModule,
    NgbModule
  ],
  exports: [
    CustomerComponent,
    ProviderComponent,
    CustomerStatestiqueComponent,
    CustomerHistoriqueComponent,
    CustomerCommandesComponent,
    CustomerInfosComponent
  ]
})
export class PartsModule { }
