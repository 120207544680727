import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PartsService } from '../../parts.service';
import { User } from 'src/app/user/user';
import { Bank } from '../../../buying/buying';
import { SettingService } from '../../../setting/setting.service';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.css']
})
export class BankDetailComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() bankId: Bank;
  showAlert: boolean;
  userEdit: any
  userAdd: any
  bankObject: Bank
  stopLoading: boolean
  id_entreprise: number
  constructor(private partsService: PartsService, private settingService: SettingService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    if (this.bankId) {
      this.partsService.getBank(this.bankId[0].id).subscribe(
        data => {
          this.bankObject = data
          this.stopLoading = true
          if (this.bankObject.createdBy) {
            if (!this.bankObject.byAdmin) {
              this.settingService.getUser(this.bankObject.createdBy).subscribe(
                data => {
                  this.userAdd = data
                }
              )
            } else {
              let filter = new FilterDto()
              filter.where = { "id": this.id_entreprise }
              this.settingService.getAllEntreprise(filter).subscribe(
                data => {
                  this.userAdd = data[0][0]
                }
              )
            }
          }
          if (this.bankObject.updatedBy) {
            if (!this.bankObject.updatebyAdmin) {
              this.settingService.getUser(this.bankObject.updatedBy).subscribe(
                data => {
                  this.userEdit = data
                }
              )
            } else {
              let filter = new FilterDto()
              filter.where = { "id": this.id_entreprise }
              this.settingService.getAllEntreprise(filter).subscribe(
                data => {
                  this.userEdit = data[0][0]
                }
              )
            }
          }
        },
        err => {
          console.error('Observer got an error: ' + err)
        },
      )
    }
  }
  closeAction() {
    this.closed.emit(true);
  }
}