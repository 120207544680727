import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { Operation } from 'src/app/stock/stock';
import { environment } from 'src/environments/environment';
import { Order, OrderDetail } from './order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getOrders(filter: FilterDto<Order>): Observable<[Order[], number]> {
    return this.http.get(environment.api + '/orders?filter=' + JSON.stringify(filter)) as Observable<[Order[], number]>;
  }

  getOrdersDetails(filter: FilterDto): Observable<[OrderDetail[], number]> {
    return this.http.get(environment.api + '/orderdetail?filter=' + JSON.stringify(filter)) as Observable<[OrderDetail[], number]>
  }
  postOrder(order: Order): Observable<Order> {
    return this.http.post<Order>(environment.api + '/order', order)
  }
  patchOrder(ID: number, order: Order): Observable<Order> {
    return this.http.patch<Order>(environment.api + '/order/' + JSON.stringify(ID), order);
  }
  deleteOrder(ID: number) {
    return this.http.delete(environment.api + '/order/' + JSON.stringify(ID));
  }


  deleteOrderDetail(ID: number) {
    return this.http.delete(environment.api + '/orderdetail/' + JSON.stringify(ID));
  }
  patchOrderDetail(ID: number, orderDetail: OrderDetail): Observable<OrderDetail> {
    return this.http.patch<OrderDetail>(environment.api + '/orderdetail/' + JSON.stringify(ID), orderDetail);
  }
  postOrderDetail(orderDetail: OrderDetail): Observable<OrderDetail> {
    return this.http.post<OrderDetail>(environment.api + '/orderdetail', orderDetail)
  }


  patchOperation(ID: number, operation: Operation): Observable<Operation> {
    return this.http.patch<Operation>(environment.api + '/operation/' + JSON.stringify(ID), operation);
  }
  postOperation(operation: Operation): Observable<Operation> {
    return this.http.post<Operation>(environment.api + '/operation', operation)
  }
  deleteOperation(ID: number) {
    return this.http.delete(environment.api + '/operation/' + JSON.stringify(ID));
  }
  deleteOperationDetail(ID: number) {
    return this.http.delete(environment.api + '/operation-detail/' + JSON.stringify(ID));
  }

  // sendMail(mail){
  //   return this.http.post("https://iotech-mailer.ioc.tn/api/mails", mail)
  // }

  sendMail(mail) {
    return this.http.post(environment.api + '/send', mail)
  }
}