import { Component, HostListener, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { categoryService } from '../../category-service/categoryService';
import { categoryserviceService } from '../../category-service/categoryService.service';
import { Tax } from '../../tax/tax';
import { TaxService } from '../../tax/tax.service';
import { Service } from '../service';
import { serviceService } from '../service.service';

@Component({
  selector: 'app-service-add',
  templateUrl: './service-add.component.html',
  styleUrls: ['./service-add.component.css']
})
export class ServiceAddComponent implements OnInit {

  filter: FilterDto = new FilterDto()
  model: Service = new Service();
  categories: categoryService[];
  taxs: Tax[];
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  real_id: number
  saisie: string;
  showAlert: boolean;
  showAddCategory: boolean;
  selectedTax: string
  creerNouveau: boolean = false;
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  tailleInvalid: boolean
  loading: boolean = false;
  constructor(private categoryserviceService: categoryserviceService, private taxService: TaxService,
     private serviceService: serviceService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getServices(this.filter)
  }
  ngAfterViewInit(){
    this.showtab()
  }
  showtab(){
    var elements = Array.from(document.getElementsByClassName('clr-col-md-10'))
    elements.forEach(el=>{ if (el && el instanceof HTMLElement && el.style) {
      (el as HTMLElement).style.maxWidth='65%';
      (el as HTMLElement).style.flex='0 0 65%';
    }})
  }

  onSearchChange(args: string, table: string) {
    this.filter = new FilterDto()
    this.loading = true
    this.filter.where = {}
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      if (table == "tax") {
        this.filter.where = { percentage: { type: "equal", value: +args }, "entrepriseId": this.id_entreprise, active: true }
      }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true }
    }
    if (table == "category") {
      this.getCategoriesService(this.filter)
    } else {
      if (table == "tax") {
        this.getTaxs(this.filter)
      }
    }
  }

  getCategoriesService(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.categoryserviceService.getCategoriesService(filter).subscribe(
      data => {
        this.categories = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getTaxs(filter: FilterDto) {
    filter.where = { "active": true }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  itemChanged(item: any, tableItem: string) {
    if (tableItem == "category") {
      this.model.categoryserviceId = item
    } else {
      if (tableItem == "tax") {
        this.model.taxId = item
      }
    }
  }

  getServices(filter: FilterDto<Service>) {
    this.serviceService.getServices(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  submit() {
    this.model.entrepriseId = this.id_entreprise
    this.model.real_id = this.real_id
    this.model.byAdmin = this.isAdmin
    this.serviceService.addService(this.model)
      .subscribe(
        service => {
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.model = new Service();
            this.creerNouveau = false;
          } else {
            this.creerNouveau = true;
          }
        })
  }

  AddCategoriePopUp() {
    this.showAddCategory = true;
  }
  AddCategory(event: any) {
    this.getCategoriesService({});
    this.model.categoryserviceId = event
  }
  CloseAction() {
    this.showAddCategory = false;
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
