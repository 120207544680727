import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetailService } from 'src/app/catalog/detail/detail.service';
import { Category } from 'src/app/catalog/category/category';
import { CategoryService } from 'src/app/catalog/category/category.service';
import { Detail } from 'src/app/catalog/detail/detail';
import { Mark } from 'src/app/catalog/mark/mark';
import { MarkService } from 'src/app/catalog/mark/mark.service';
import { Detailproduct, Product, ProductPicture, Productprice } from 'src/app/catalog/product/product';
import { ProductService } from 'src/app/catalog/product/product.service';
import { Tax } from 'src/app/catalog/tax/tax';
import { TaxService } from 'src/app/catalog/tax/tax.service';
import { Unity } from 'src/app/catalog/unity/unity';
import { UnityService } from 'src/app/catalog/unity/unity.service';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { PriceType } from 'src/app/setting/type/type';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-modal-add-product',
  templateUrl: './modal-add-product.component.html',
  styleUrls: ['./modal-add-product.component.css']
})
export class ModalAddProductComponent implements OnInit {

  @Output() saved = new EventEmitter<any>()
  @Output() closed = new EventEmitter<boolean>()
  @Input() isAdmin: boolean

  showAlert: boolean;
  model: Product = new Product();
  modelProductprice: Productprice = new Productprice();
  modelDetailProduct: Detailproduct = new Detailproduct()

  filter: FilterDto = new FilterDto()
  categories: Category[];
  marks: Mark[];
  unities: Unity[];
  taxs: Tax[];
  priceTypes: PriceType[];
  details: Detail[];

  pictureArray: ProductPicture[] = [];
  allpicture: ProductPicture = new ProductPicture();
  pictureString: ProductPicture = new ProductPicture();
  filename: string;
  pictureIndex: number = -1;
  desableDefCheck: boolean;
  isPictureChecked: boolean;
  pictureDetails: boolean;
  untouched: boolean = true;
  alert: {}
  filesize: number;
  loading: boolean = false;
  saisie: string;
  editCateory: boolean = false;
  tailleInvalid: boolean

  selectedUnity: string
  selectedMark: string
  selectedTax: string
  selectedType: string
  selectedDetail: string

  initialStep: string = "step1"
  creerNouveau: boolean = false;
  showAddCategory: boolean;
  showAddMarque: boolean;
  showAddUnite: boolean;
  showAddTaxe: boolean
  id_entreprise: number
  real_id: number
  constructor(private settingService: SettingService, private categoryService: CategoryService, private markService: MarkService, private unityService: UnityService,
    private taxService: TaxService, private detailService: DetailService, private productService: ProductService) {
    this.model.productPrices = [];
    this.model.productDetails = [];
  }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.showAddCategory = false;
    this.showAddMarque = false;
    this.showAddTaxe = false;
    this.showAddUnite = false;
    this.taxService.getTaxs({ where: { "active": true } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }
   
  CloseAction() {
    this.closed.emit(true);
  }

  AddMarque(event: any) {
    this.selectedMark = event.name
    this.getMarks({});
  }
  AddUnity(event: any) {
    this.selectedUnity = event.name
    this.getUnities(this.filter);
  }
  AddTax() {
    this.getTaxs(this.filter);
  }
  AddMarquePopUp() {
    this.showAddMarque = true;
  }
  AddUnityPopUp() {
    this.showAddUnite = true;
  }
  AddTaxePopUp() {
    this.showAddTaxe = true;
  }
  onSearchChange(args: string, table: string) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      if (table == "tax") {
        this.filter.where = { percentage: { type: "equal", value: +args }, active: true }
      } else {
        if (table == "detail") {
          this.filter.where = { type: { type: "like", value: this.saisie }, active: true }
        } else {
          this.filter.where = { name: { type: "like", value: this.saisie }, active: true }
        }
      }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true }
    }
    if (table == "category") {
      this.getCategories(this.filter)
    } else {
      if (table == "mark") {
        this.getMarks(this.filter)
      } else {
        if (table == "unity") {
          this.getUnities(this.filter)
        } else {
          if (table == "tax") {
            this.getTaxs(this.filter)
          } else {
            if (table == "priceType") {
              this.getPriceTypes(this.filter)

            } else {
              if (table == "detail") {
                this.getDetails(this.filter)
              }
            }
          }
        }
      }
    }
  }
  itemChanged(item: any, tableItem: string) {
    if (tableItem == "category") {
      this.model.categoryId = item
    } else {
      if (tableItem == "mark") {
        this.model.markId = item
      } else {
        if (tableItem == "unity") {
          this.model.unityId = item
        } else {
          if (tableItem == "tax") {
            this.model.taxId = item
            this.selectedTax = item.percentage.toString()
          } else {
            if (tableItem == "priceType") {
              this.modelProductprice.priceTypeId = item
            } else {
              if (tableItem == "detail") {
                this.modelDetailProduct.detailId = item
              }
            }
          }
        }
      }
    }
  }

  getCategories(filter: FilterDto) {
    filter.relations = ["details"];
    filter.where = { "entrepriseId": this.id_entreprise }
    this.categoryService.getCategories(filter).subscribe(
      data => {
        this.categories = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getMarks(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.markService.getMarks(filter).subscribe(
      data => {
        this.marks = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getUnities(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.unityService.getUnities(filter).subscribe(
      data => {
        this.unities = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getTaxs(filter: FilterDto) {
    filter.where = { "active": true }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getPriceTypes(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getPricesTypes(filter).subscribe(
      data => {
        this.priceTypes = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getDetails(filter: FilterDto) {
    let idDetails = []
    this.model.categoryId?.details.forEach(element => {
      element.entrepriseId = this.id_entreprise
      idDetails.push(element.id)
    })
    filter.where = Object.assign(filter.where, { id: { type: "in", value: idDetails } })
    filter.where = { "entrepriseId": this.id_entreprise }
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }
  ajoutProductprice() {
    this.modelProductprice.entrepriseId = this.id_entreprise
    this.model.productPrices.push(this.modelProductprice)
    this.modelProductprice = new Productprice()
  }

  deleteProductPrice(index: number) {
    this.model.productPrices.splice(index, 1);
  }

  ajoutDetailProduct() {
    this.modelDetailProduct.entrepriseId = this.id_entreprise
    this.model.productDetails.push(this.modelDetailProduct)
    this.modelDetailProduct = new Detailproduct()
  }

  deleteProductDetails(index: number) {
    this.model.productDetails.splice(index, 1);
  }

  picked(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.filename = file.name;
      this.filesize = file.size;
      if (this.filesize > 100000) {
        this.tailleInvalid = true;
        return
      }
      this.tailleInvalid = false;
      this.handleInputChange(file);
    }
    else {
      alert("No file selected");
    }
  }

  handleInputChange(files) {
    var file = files;
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.alert = { type: 'danger', message: "format d'image invalide " };
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    let allpicture: ProductPicture = new ProductPicture()
    allpicture.url = await e.target.result;
    allpicture.defaults = await false
    await this.pictureArray.push(allpicture)
    const found = await this.pictureArray.filter(item => item.defaults === true);
    if (found.length == 0) {
      this.pictureArray[0].defaults = await true
    }
    this.desableDefCheck = false
  }

  async deletePicAction(item) {
    var pos = await this.pictureArray.indexOf(item);
    await this.pictureArray.splice(pos, 1);
    if (item.defaults == true) {
      this.pictureArray[0].defaults = await true
    }
  }

  pictureDetailAction(item: ProductPicture, index: number) {
    if (this.untouched) {
      this.pictureString = item
      this.pictureDetails = true
      this.pictureIndex = index
      if (this.pictureArray.length == 1) {
        this.desableDefCheck = true
      }
      if (this.pictureString.defaults == true) {
        this.isPictureChecked = true
      } else {
        this.isPictureChecked = false
      }
    }
  }

  submitPictureDetails() {
    this.pictureDetails = false
    if (this.pictureArray[this.pictureIndex].defaults == true && this.pictureIndex != 0) {
      this.pictureArray[0].defaults = false
      let item = this.pictureArray[this.pictureIndex]
      this.pictureArray.splice(this.pictureIndex, 1)
      this.pictureArray.unshift(item)
    }
  }
  AddCategoriePopUp() {
    this.showAddCategory = true;
  }
  CloseActionPopUp() {
    this.showAddCategory = false;
    this.showAddMarque = false;
    this.showAddTaxe = false;
    this.showAddUnite = false;
  }
  AddCategory(event: any) {
    this.model.categoryId = event
    this.getCategories({});
  }

  submit() {
    this.model.entrepriseId = this.id_entreprise
    this.model.real_id = this.real_id;
    this.model.byAdmin = this.isAdmin
    this.productService.addProduct(this.model)
      .subscribe(
        product => {
          this.pictureArray.forEach(async picture => {
            picture.productId = product.id
            picture.entrepriseId = this.id_entreprise
            await this.productService.addPicture(picture)
              .subscribe(
                picture => {
                  this.showAlert = true;
                })
          })
          this.saved.emit(product);
          this.CloseAction();
          this.showAlert = true;
        })
  }

  annuler() {
    this.model = new Product();
    this.modelProductprice = new Productprice();
    this.modelDetailProduct = new Detailproduct()
    this.pictureArray = []
    this.initialStep = "step1"
    this.creerNouveau = false
    this.selectedMark = null
    this.selectedUnity = null
    this.selectedTax = null
    this.selectedType = null
    this.selectedDetail = null
    this.showAlert = false
  }
  clrInitialStep(initialStep: string) {
    this.initialStep = initialStep
  }

  editCategory() {
    if (this.model.productDetails.length > 0 || this.pictureArray.length > 0) {
      this.editCateory = true;
    } else {
      this.editCateory = false;
      this.model.categoryId = null;
    }
  }
  cancelEditCategory() {
    this.editCateory = false;
  }
  activeEditCategory() {
    this.model.categoryId = null;
    this.pictureArray = []
    this.model.productDetails = []
    this.editCateory = false
  }


}
