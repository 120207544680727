<clr-combobox-container>
    <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)" (clrOpenChange)="$event ? onSearchChange() : null">
        <clr-options *ngIf="categories&& table=='categories'">
            <div *ngIf="properties=='name'">
                <clr-option *ngFor="let item of categories;" clrValue={{item.name}}>
                    {{item?.name}}
                </clr-option>
            </div>
            <div *ngIf="properties=='description'">
                <clr-option *ngFor="let item of categories;" clrValue={{item.description}}>
                    {{item?.description}}
                </clr-option>
            </div>
        </clr-options>

        <clr-options *ngIf="products&& table=='product'">
            <div *ngIf="properties=='reference'">
                <clr-option *ngFor="let item of products;" clrValue={{item.product.reference}}>
                    {{item?.product?.reference}}
                </clr-option>
            </div>
            <div *ngIf="properties=='name'">
                <clr-option *ngFor="let item of products;" clrValue={{item.product.name}}>
                    {{item?.product?.name}}
                </clr-option>
            </div>
        </clr-options>

        <clr-options *ngIf="marks&& table=='mark'">
            <div *ngIf="properties=='name'">
                <clr-option *ngFor="let item of marks;" clrValue={{item.name}}>
                    {{item?.name}}
                </clr-option>
            </div>
        </clr-options>

        <clr-options *ngIf="unities&& table=='unity'">
            <div *ngIf="properties=='name'">
                <clr-option *ngFor="let item of unities;" clrValue={{item.name}}>
                    {{item?.name}}
                </clr-option>
            </div>
        </clr-options>

        <clr-options *ngIf="taxs&& table=='tax'">
            <div *ngIf="properties=='name'">
                <clr-option *ngFor="let item of taxs;" clrValue={{item.name}}>
                    {{item?.name}}
                </clr-option>
            </div>
        </clr-options>
        <clr-options *ngIf="details&& table=='detail'">
            <div *ngIf="properties=='name'">
                <clr-option *ngFor="let item of details;" clrValue={{item.name}}>
                    {{item?.name}}
                </clr-option>
            </div>
            <div *ngIf="properties=='type'">
                <clr-option *ngFor="let item of details;" clrValue={{item.type}}>
                    {{item?.type}}
                </clr-option>
            </div>
        </clr-options>

        <clr-options *ngIf="points&& table=='point'">
            <div *ngIf="properties=='name'">
                <clr-option *ngFor="let item of points;" clrValue={{item.name}}>
                    {{item?.name}}
                </clr-option>
            </div>
            <div *ngIf="properties=='description'">
                <clr-option *ngFor="let item of points;" clrValue={{item.description}}>
                    {{item?.description}}
                </clr-option>
            </div>
        </clr-options>

    </clr-combobox>
</clr-combobox-container>