import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeedBack } from './feedBack';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { CoreService } from '../core.service';
import { FilterDto } from 'src/app/filter.dto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  showContact:boolean = false
  showStatic:boolean = false
  showIcons:boolean = true
  showPulsation:boolean = false
  toggleShow:any
  showMailContact:boolean = false
  showCallback:boolean = false
  showPhone:boolean = true 
  loadingPhone:boolean = false
  callbackMessage:boolean = false
  arabicLanguage: boolean = false
  constructor(public translate: TranslateService,private coreService : CoreService) { }
  feedbackModel : FeedBack = new FeedBack();
  isAdmin : boolean = false 
  userRole : string
  id_entreprise : number
  mail: { from: any; to: string; subject: string; html: string };
  infoLogin : any 
  userName : string


  ngOnInit(): void {
  //   this.toggleShow = setInterval(() => {
  //   this.inverseStaticIcons(); 
  // }, 4000);
  this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.infoLogin = jwtDecode(tokenGetter())["username"]
    this.userName = this.infoLogin["name"]
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getEntrepriseInfo()
    }
  setTimeout(() => {
    this.showPulsation = true
  }, 1000);
  this.EventLang()
  }
  ngOnDestroy() {
    if (this.toggleShow) {
      clearInterval(this.toggleShow);
    }
  }

  inverseStaticIcons(){
    this.showStatic = !this.showStatic
    this.showIcons = !this.showIcons
  }
  showContactBlock(){
    if (this.showCallback || this.showMailContact){
      this.showContact = false
    } else {
      this.showContact = !this.showContact
    }
  }
  hideContactBlock(){
    this.showContact = false
  }
  setMailContact() {
    this.showMailContact = true;
    this.showContact = false
  }
  closeContact() {
    this.showMailContact = false;
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    const contactBlock = document.getElementById('contactuspace');
    const clickedElement = event.target as HTMLElement;
    let found = contactBlock.contains(clickedElement)
    if (!found) {
      this.showContact=false
    }
  }
  showCallbackBloc(){
    this.showCallback = true
    this.callbackMessage = false
    this.showPhone = true
  }
  hideCallbackBlock(){
    this.showCallback = false
  }
  showCallbackPhone(){
    this.showPhone = true
  }
  hideCallbackPhone(){
    this.showPhone = false
  }
  getEntrepriseInfo(){
    let filter = new FilterDto()
    filter.where={"id":this.id_entreprise}
    this.coreService.getEntrepriseData(filter).subscribe(
      data => {
        this.userName +=  ` (utilisateur de ${data[0][0]?.name} )`
      }
    )
  }
  showLoadingPhone(){
    this.loadingPhone = true
    this.feedbackModel.byAdmin = this.isAdmin 
    this.feedbackModel.entrepriseId = this.id_entreprise 
    this.feedbackModel.createdAt = new Date()
    
    this.coreService.addFeedback(this.feedbackModel).subscribe(
      data => {
        let content = `
        <!DOCTYPE html>
        <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: #f6f6f6;
              margin: 0;
              padding: 0;
            }
        
            .container {
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
              background-color: #fff;
            }
        
            h1 {
              color: #333;
            }
        
            p {
              margin-bottom: 20px;
            }
        
            .button {
              display: inline-block;
              padding: 10px 20px;
              background-color: #32a852;
              color: #fff;
              text-decoration: none;
              border-radius: 3px;
            }
            .ii a[href] {
              color: #fff;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>Nouvelle demande de communication</h1>
            <p>Cher administrateur,</p>
            <p>Une nouvelle demande de communication de la part de ${this.userName} a été ajouté sur votre plateforme.</p>
            <p>Veuillez vous connecter à votre compte administrateur pour consulter la demande et y répondre si nécessaire.</p>
            <p>Merci,</p>
            <p>L'équipe de votre plateforme</p>
            <br>
            <p><a class="button" href="${environment.frontAdmin}">Se connecter</a></p>
          </div>
        </body>
        </html>
        `
        this.mail={
          from:environment.emailSociete,
          to : environment.adminMail,
          subject:"Message de feedback recu",
          html:content
        }
        this.coreService.sendMail(this.mail).subscribe(
          data => {
            setTimeout(() => {
              this.hideLoadingPhone()
              this.showPhone = false
            }, 3000);
          }
        )
      }
    )
  }
  hideLoadingPhone(){
    this.loadingPhone = false
    this.callbackMessage = true
  }
  EventLang() {
    let lang = localStorage.getItem('currentLang')
    if (lang == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  changeEventLang(event: any) {
    this.translate.use(event.target.value);
    if (event.target.value == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
}
