import { Address } from "../parts/parts";
import { Abonnement } from "../setting/setting";
import { Contact, Devise } from "./user";

export class Entreprise {
    constructor(
        public id?: number,
        public email?: string,
        public name?: string,
        public password?: string,
        public repassword?: string,
        public resToken?: string,
        public role?: string,
        public logo?: string,
        public active?: boolean,
        public contactId?: Contact,
        public adressId?: Address,
        public createdAt?: string,
        public updatedAt?: string,
        public saltRounds?: string,
        public abonnementId?: Abonnement | null,
        public deviseId?: Devise,
        public activationId?: string,
        public confirmedEmail?: boolean,

    ) {
        //test
    }
}