import { Product } from "../buying/buying";
import { Service } from "../catalog/service/service";
import { Customer } from "../parts/parts";
import { Points } from "../sales-point/points";

export class Commande {
    constructor(
        public id?: number,
        public real_id?: number,
        public byAdmin?: boolean,
        public isApproved?: boolean,
        public dateLimite?: Date,
        public customerId?: number & Customer,
        public entrepriseId?: number,
        public commandeDetails?: CommandeDetail[],
        public pointId?: Points | number,
        public etatCommandeId?:  number & EtatCommande,
        public createdAt?: string,
        public createdBy?: number,
        public updatedAt?: string,
        public updatedBy?: number,
    ) { }
}

export class CommandeDetail {
    constructor(
        public id?: number,
        public byAdmin?: boolean,
        public active?: boolean,
        public quantity?: number,
        public commandeId?: number,
        public productId?: number & Product,
        public entrepriseId?: number,
        public serviceId?: number & Service,
    ) { }
}

export class CommandeProduct {
    constructor(
        public id?: number,
        public productId?: number & Product,
        public serviceId?: number & Service,
        public name?: string,
        public quantity?: number,
        public entrepriseId?: number,
    ) { }
}

export class EtatCommande {
    constructor(
        public id? : number,
        public name ?: string,
        public coefficient ?: number,
        public description ?: string,
        public createdAt?: Date,
        public updatedAt?: Date,
    ) { }
}