<clr-combobox-container *ngIf="entityName=='personelNamesFilter'">
      <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
        <clr-options>
          <clr-option *ngFor="let item of customerNames;" clrValue={{item.name}}>
            <clr-icon shape="user"></clr-icon>{{item?.name}}
          </clr-option>
        </clr-options>
      </clr-combobox>
</clr-combobox-container>

<clr-combobox-container *ngIf="openCustomerFilter">
  <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of customerFilter;" clrValue={{item.name}}>
        <clr-icon shape="user"></clr-icon>&nbsp;&nbsp;{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="openCusVilleFilter">
  <clr-combobox name="ville" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of customerFilterVille;" clrValue={{item.ville}}>
        <cds-icon shape="bank"></cds-icon>&nbsp;&nbsp;{{item?.ville}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>

<clr-combobox-container *ngIf="entityName=='openOpFilter'">
  <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of generalTab;" clrValue={{item.name}}>
        <clr-icon shape="two-way-arrows"></clr-icon>&nbsp;&nbsp;{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>



<clr-combobox-container *ngIf="nameUser">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of userArray;" clrValue={{item.name}}>
        <clr-icon shape="user"></clr-icon>{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>


<clr-combobox-container *ngIf="cmdUserFilter">
  <clr-combobox name="name" [clrLoading]="loading" [ngModel]="selectedItem" (clrInputChange)="onSearchUser($event)">
    <clr-options>
      <clr-option *ngFor="let item of cmdUserArray;" clrValue={{item.id}}_{{item.name}}>
        <clr-icon shape="user"></clr-icon>{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>


<clr-combobox-container *ngIf="carrierFilter">
  <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of carriers;" clrValue={{item.name}}>
        <clr-icon shape="user"></clr-icon>{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>


<clr-combobox-container *ngIf="openBkFilter">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of bankArray;" clrValue={{item.name}}>
        <clr-icon shape="bank" badge="info"></clr-icon>&nbsp;&nbsp;{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="accountFilter">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of accountArray;" clrValue={{item.account}}>
        <clr-icon shape="wallet"></clr-icon>&nbsp;&nbsp;{{item?.account}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="markFilter">
  <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of trucks;" clrValue={{item.mark}}>
        <clr-icon shape="user"></clr-icon>{{item?.mark}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="modelFilter">
  <clr-combobox name="model" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of trucks;" clrValue={{item.model}}>
        <clr-icon shape="user"></clr-icon>{{item?.model}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>


<clr-combobox-container *ngIf="providerFilter">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of providerArray;" clrValue={{item.name}}>
        <clr-icon shape="truck"></clr-icon>&nbsp;&nbsp;{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="companyFilter">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of companyArray;" clrValue={{item.name}}>
        <clr-icon shape="truck"></clr-icon>&nbsp;&nbsp;{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>




<clr-combobox-container *ngIf="entityName=='banierpromo'">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of generalTab;" clrValue={{item.pathUrl}}>
        <clr-icon shape="link"></clr-icon>&nbsp;&nbsp;{{item?.pathUrl}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>

<clr-combobox-container *ngIf="entityName=='banieroffer'">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of generalTab;" clrValue={{item.pathUrl}}>
        <clr-icon shape="link"></clr-icon>&nbsp;&nbsp;{{item?.pathUrl}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>

<clr-combobox-container *ngIf="entityName=='baniereBigTitle'">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of generalTab;" clrValue={{item.bigTitle}}>
        <clr-icon shape="link"></clr-icon>&nbsp;&nbsp;{{item?.bigTitle}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>

<clr-combobox-container *ngIf="entityName=='baniereSmallTitle'">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of generalTab;" clrValue={{item.smallTitle}}>
        <clr-icon shape="link"></clr-icon>&nbsp;&nbsp;{{item?.smallTitle}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>

<clr-combobox-container *ngIf="entityName=='depotName'">
  <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of generalTab;" clrValue={{item.name}}>
        <clr-icon shape="link"></clr-icon>&nbsp;&nbsp;{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="entityName=='identifiantFilter'">
  <clr-combobox name="name" required  [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
        <clr-option *ngFor="let item of generalTab;" clrValue={{item.identifier}}>
          <clr-icon shape="user"></clr-icon>{{item?.identifier}}
        </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>


  
  <clr-combobox-container  *ngIf="entityName=='TrFilter'">
    <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
      <clr-options>
        <clr-option *ngFor="let item of generalTab;" clrValue={{item.name}}>
          <clr-icon shape="dollar"></clr-icon>{{item?.name}}
        </clr-option>
      </clr-options>
    </clr-combobox>
  </clr-combobox-container>
  
  <clr-combobox-container *ngIf="entityName=='OpenPrFilter'">
    <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
      <clr-options>
        <clr-option *ngFor="let item of generalTab;" clrValue={{item.name}}>
          <clr-icon shape="dollar"></clr-icon>{{item?.name}}
        </clr-option>
      </clr-options>
    </clr-combobox>
  </clr-combobox-container>


  <clr-combobox-container *ngIf="entityName=='OpenCosFilter'">
    <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
      <clr-options>
        <clr-option *ngFor="let item of generalTab;" clrValue={{item.name}}>
          <clr-icon shape="dollar"></clr-icon>{{item?.name}}
        </clr-option>
      </clr-options>
    </clr-combobox>
  </clr-combobox-container>

  <clr-combobox-container *ngIf="entityName=='OpenCos'">
    <clr-combobox name="name" [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
      <clr-options>
        <clr-option *ngFor="let item of costTypes;" clrValue={{item.name}}>
          <clr-icon shape="dollar"></clr-icon>{{item?.name}}
        </clr-option>
      </clr-options>
    </clr-combobox>
  </clr-combobox-container>
  
  <clr-select-container *ngIf="entityName=='openOptype'">
    <select clrSelect name="options" [(ngModel)]="opTypeValue"  (change)="change()">
      <option value="0">  Tous les types  </option>
      <option value="1">  Achat  </option>
      <option value="2">  Vente  </option>
      <option value="3">  Autres  </option>
    </select>
  <clr-select-container>








