<div class="modal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h3 class="modal-title">Félicitations</h3> -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
            fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg> -->
        </div>
        <div class="modal-body">
          <!-- <div class="alert alert-danger" role="alert">
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-icon-wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                </div>
                <span class="alert-text">
                  {{msgAlert}}
                </span>
              </div>
            </div>
          </div> -->
          <div class="felicitation">
            <h1>Félicitations! {{customerName}}</h1>
            <p>Vous avez gagner : <br></p>
            <div *ngFor="let msg of messages">
              <h4>{{msg}}</h4> <br>
            </div>
            <!-- <img src="your-image.jpg" alt="Felicitation Image"> -->
        </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" (click)="confirmAction()">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop" aria-hidden="true"></div>
  