<div class="header" id="head">
    <h3><b>  {{ 'commun.lstc' | translate }} </b></h3>
</div>
<b><p id="head_list">  {{ 'commun.lstc' | translate }} </p></b>
<br/>
<div class="list_customer mt-2 mb-2">
    <table class="table">
        <thead>
            <th>   {{ 'login.np' | translate }}</th>
            <th>   {{ 'commun.nuc' | translate }}</th>
            <th>   {{ 'commun.nmc' | translate }}</th>
            <th>   {{ 'commun.ad' | translate }}</th>
        </thead>
        <tbody>
            <tr class="bordered" *ngFor="let item of customers">
                <td>{{item.name}}</td>
                <td *ngIf="item.contacts[0]?.tel">{{item.contacts[0]?.tel}}</td>
                <td *ngIf="!item.contacts[0]?.tel" style="color:red">Vide</td>
                <td *ngIf="item.contacts[0]?.firstName">{{item.contacts[0]?.firstName}}</td>
                <td *ngIf="!item.contacts[0]?.firstName" style="color:red ;"> {{ 'commun.vide' | translate }}</td>
                <td *ngIf="item.ville">{{item.ville}}</td>
                <td *ngIf="!item.ville" style="color: red;">   {{ 'commun.vide' | translate }}</td>
            </tr>
        </tbody>
    </table>
</div>
<br/>
<button type="button" (click)="PrintList()" class="btn btn-primary dontPrint" id="printBTN">
       {{ 'commun.imp' | translate }}
</button>