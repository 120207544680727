import jwt_decode from 'jwt-decode';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { CoreService } from 'src/app/core/core.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';
import { getValueFromToken, tokenGetter, UserService } from 'src/app/user/user.service';
import { Service } from './service';
import { serviceService } from './service.service';
import { FilterDto } from 'src/app/filter.dto';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  loading: boolean;
  id_entreprise: number
  services: Service[] = [];
  tmpList: Service[] = [];
  showService: Service[] = [];
  isAdmin: boolean = false
  userRole: string
  filter: FilterDto<Service> = new FilterDto<Service>()
  userAutorisation : Autorisation = new Autorisation()
  devise: string
  currentLang: string;
  currentPage: number = 1
  pageSize: number = 5
  total: number
  nbr_page: number
  insertIndex: number = 0
  userId : number
  selectedService: Service;
  showDetail: boolean;
  videoDemo: boolean = false
  showAlert: boolean;
  modalVideoDemo: boolean = false
  selectedIdService: number;

  constructor(private translate: TranslateService, private serviceService: serviceService,
    private serviceCore: CoreService, private userService : UserService) {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.translate.use(this.currentLang);
  }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userId = parseInt(jwt_decode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getUserAutorisation();
    }
    this.filter = new FilterDto()
    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.filter.take = this.pageSize
    this.getServices(this.filter);
    this.serviceCore.getEntrepriseData({
      where: { "id": this.id_entreprise },
      relations: ["deviseId"]
    }).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code
      }
    )
  }

  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  }

  getServices(filter: FilterDto<Service>) {
    if (!this.services[this.insertIndex]){
      this.serviceService.getServices(filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.services[this.insertIndex + i] = data[0][i];
          }
          this.tmpList = this.services
          this.showService = this.services.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.total = data[1];
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        },
        err => console.error('Observer got an error: ' + err),
        () => this.loading = false
      );
    } else {
      this.showService = this.services.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }

  getPaginatedData (currentPage) {
    this.filter.skip =  (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getServices(this.filter)
  }

  detail(service: Service) {
    this.selectedService = service
    this.showDetail = true;
  }

  detailAction(idSelected: number) {
    this.selectedIdService = idSelected
    this.showDetail = true;
  }

  deleteAction(service: Service) {
    this.selectedService = service
    this.showAlert = true;
  }

  closeAction() {
    this.filter = new FilterDto()
    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.filter.take = this.pageSize
    this.getServices(this.filter);
    this.showAlert = false;
    this.showDetail = false;
  }

  saved() {
    this.filter = new FilterDto()
    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.filter.take = this.pageSize
    this.getServices(this.filter)
    this.showAlert = false;
  }

  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }

  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
      
    }
  }
}
