import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Tax } from './tax';
import { TaxService } from './tax.service';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.css']
})
export class TaxComponent implements OnInit {

  taxs: Tax[];
  selectedTax: Tax
  count: number;
  showAlert: boolean;
  loading: boolean;
  filter: FilterDto;
  ascSort: ClrDatagridSortOrder;
  whereFindOperator: WhereFindOperator<Tax> = new WhereFindOperator<Tax>()
  showDetail: boolean;
  currentLang: string;
  lg = "rtl"
  x = false;
  y: string;
  id_entreprise: number
  userRole: string

  constructor(
    public translate: TranslateService, private taxService: TaxService, private filterService: FilterService) {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.y = localStorage.getItem('y');
    this.translate.use(this.currentLang);
  }

  ngOnInit() {
    this.ascSort = ClrDatagridSortOrder.ASC;
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  reloadData() {
    window.location.reload();
  }


  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;
    this.filter = this.filterService.bind(state);
    this.whereFindOperator.key = this.filterService.dateSearch(state)
    if (this.whereFindOperator.key) {
      this.filter.where = Object.assign(this.filter.where, this.whereFindOperator.key)
    }
    this.filter.where = Object.assign(this.filter.where.valueOf(), { active: true })
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.getTaxs(this.filter);
  }

  getTaxs(filter: FilterDto<Tax>) {
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.count = data[1];
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }

  delete(tax: Tax) {
    this.selectedTax = tax
    this.showAlert = true;
  }
  detail(tax: Tax) {
    this.selectedTax = tax
    this.showDetail = true;
  }
  close() {
    this.getTaxs(this.filter);
    this.showAlert = false;
    this.showDetail = false;
  }
}