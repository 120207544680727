<div class="header" id="head">
    <h3><b>Liste des fournisseurs</b></h3>
</div>
<b>
    <p id="head_list">Liste des fourrnisseurs </p>
</b>
<br />
<div class="list_vendors mt-2 mb-2">
    <table class="table">
        <thead>
            <th>Nom & prenom</th>
            <th>Num contact</th>
            <th>Nom contacts</th>
            <th>Adresse</th>
        </thead>
        <tbody>
            <tr class="bordered" *ngFor="let item of providers">
                <td>{{item.name}}</td>
                <td *ngIf="item.contacts[0]?.tel">{{item.contacts[0]?.tel}}</td>
                <td *ngIf="!item.contacts[0]?.tel" style="color: red;">Non disponible</td>
                <td *ngIf="item.contacts[0]?.firstName">{{item.contacts[0]?.firstName}}</td>
                <td *ngIf="!item.contacts[0]?.firstName" style="color: red;">Non disponible</td>
                <td *ngIf="item.adress[0]">{{item.adress[0]?.street+', '+item.adress[0]?.state}}</td>
                <td *ngIf="!item.adress[0]" style="color: red;">Non disponible</td>
            </tr>
        </tbody>
    </table>
</div>
<br />
<button type="button" (click)="PrintList()" class="btn btn-primary dontPrint" id="printBTN">
    Imprimer
</button>