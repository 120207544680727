import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { categoryService } from '../categoryService';
import { categoryserviceService } from '../categoryService.service';

@Component({
  selector: 'app-category-service-edit',
  templateUrl: './category-service-edit.component.html',
  styleUrls: ['./category-service-edit.component.css']
})
export class CategoryServiceEditComponent implements OnInit {
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  id: number;
  category: categoryService;
  showAlert: boolean;
  showMsg: boolean;

  constructor(private categoryserviceService: categoryserviceService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.categoryserviceService.getCategoryServiceById(this.id)
        .subscribe(
          data => {
            this.category = data
          })
    });
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  delete() {
    this.showAlert = true;
  }

  close() {
    this.showAlert = false;
  }

  submit(category: categoryService) {
    category.updatebyAdmin = this.isAdmin
    this.categoryserviceService.editCategoryService(this.id, category)
      .subscribe(
        data => {
          this.showMsg = true;
        })
  }
}
