import { Component, OnInit } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken } from 'src/app/user/user.service';
import { Providers } from '../../parts';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-print-list-provider',
  templateUrl: './print-list-provider.component.html',
  styleUrls: ['./print-list-provider.component.css']
})
export class PrintListProviderComponent implements OnInit {

  constructor(private partsservice: PartsService) { }
  providers: Providers[];
  count: number;
  filter: FilterDto<Providers> = new FilterDto<Providers>()
  id_entreprise: number
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.providers = [];
    this.getAllProviders();
  }
   
  getAllProviders() {
    this.filter.order = { "id": "DESC" }
    this.filter.relations = ['contacts', 'adress'];
    this.filter.take = Infinity;
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.partsservice.getProviders(this.filter).subscribe(
      data => {
        this.providers = data[0];
        this.count = data[1];
      })
  }
  PrintList() {
    window.print();
  }
}
