import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { TransactionType } from '../setting/type/type';
import { Transaction, TransactionCredit } from './finance';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(private http: HttpClient) { }

  getTransactions(filter: FilterDto<Transaction>): Observable<Transaction> {
    return this.http.get(environment.api + '/transactions?filter=' + JSON.stringify(filter)) as Observable<Transaction>;
  }

  getAllTransactions(filter: FilterDto): Observable<[Transaction[], number]> {
    return this.http.get(environment.api + '/transactions?filter=' + JSON.stringify(filter)) as Observable<[Transaction[], number]>
  }

  patchTransaction(ID: number, transaction: Transaction): Observable<Transaction> {
    return this.http.patch<Transaction>(environment.api + '/transaction/' + JSON.stringify(ID), transaction);
  }

  postTransaction(transaction: Transaction): Observable<Transaction> {
    return this.http.post<Transaction>(environment.api + '/transaction', transaction)
  }


  deleteTransaction(ID: number) {
    return this.http.delete(environment.api + '/transaction/' + JSON.stringify(ID));
  }

  getTransactionDetail(ID: number): Observable<Transaction> {
    return this.http.get(environment.api + '/transaction/' + JSON.stringify(ID)) as Observable<Transaction>;
  }


  getPersonelList(name, type): Observable<[]> {
    return this.http.get(environment.api + '/contact/' + JSON.stringify(name) + '/' + JSON.stringify(type)) as Observable<[]>
  }

  getTransactionsCredit(filter: FilterDto<Transaction>): Observable<[TransactionCredit]> {
    return this.http.get(environment.api + '/transactions/credit?filter=' + JSON.stringify(filter)) as Observable<[TransactionCredit]>;
  }


  addTransaction(transaction: Transaction): Observable<Transaction> {
    return this.http.post<Transaction>(environment.api + '/transaction', transaction);
  }

  getTransactionTypes(search: FilterDto<TransactionType>): Observable<[TransactionType[], number]> {
    return this.http.get(environment.api + '/transaction-types?filter=' + JSON.stringify(search)) as Observable<[TransactionType[], number]>;
  }
}
