import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent implements OnInit {
  @Input() msg: any
  @Input() showQuestion = false 
  @Output() closed = new EventEmitter<boolean>()
  @Output() searchProd = new EventEmitter<boolean>()
  @Output() searchCarte = new EventEmitter<boolean>()
  msgAlert: string
  title : string = ""
  constructor() { }

  ngOnInit(): void {
    this.title = this.showQuestion == true ? "Question" : "Erreur"
    this.msgAlert = this.msg
  }
  closeAction() {
    this.closed.emit(true)
  }

  searchProductClick(){
    this.searchProd.emit(true)
  }

  searchCarteClick(){
    this.searchCarte.emit(true)
  }

}
