import { Component, Input } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { Operation, Provider } from 'src/app/buying/buying';
import { BuyingService } from 'src/app/buying/buying.service';
import { Product } from 'src/app/catalog/product/product';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from 'src/app/parts/parts';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { Banniere } from 'src/app/shop/shop';
import { StockService } from 'src/app/stock/stock.service';
import { Carrier } from 'src/app/transport/carrier/carrier';
import { CarrierService } from 'src/app/transport/carrier/carrier.service';
import { Truck } from 'src/app/transport/truck/truck';
import { TruckService } from 'src/app/transport/truck/truck.service';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-customer-name-filter',
  templateUrl: './customer-name-filter.component.html',
  styleUrls: ['./customer-name-filter.component.css']
})
export class CustomerNameFilterComponent implements ClrDatagridFilterInterface<[]>{
  @Input() filterClicked: boolean
  @Input() filterProvider: boolean
  @Input() openCustomerIds: boolean
  @Input() openOrderIds: boolean
  @Input() banniereFilter1: boolean;
  @Input() banniereFilter2: boolean;
  @Input() entityName: string

  changes = new Subject<any>();
  clients: Customer[] = [];
  idsCustomers: Number[] = []
  filter: FilterDto<any> = new FilterDto<any>()
  filterBanniere: FilterDto<Banniere> = new FilterDto<Banniere>()
  searchOperation: FilterDto<Operation> = new FilterDto<Operation>()
  saisie: string;
  loading: boolean;
  providers: Provider[];
  idProviders: Number[] = [];
  idProducts: Number[] = [];
  products: Product[] = []
  carriers: Carrier[] = [];
  trucks: Truck[] = [];
  idTrucks: Number[] = [];
  idsOperations: Number[] = [];
  orderId: number
  banniere: Banniere[];
  idBannieres: Number[] = [];
  where: Object

  generalTab: any[] = []
  id_entreprise: number

  constructor(private quotationService: QuotationService, private stockService: StockService, private buyingSerive: BuyingService, private carrierService: CarrierService, private truckService: TruckService) { }


  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  onSearchChange(args?: string) {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    let valuergs: boolean = false
    this.loading = true
    if (args) {
      valuergs = true;
      this.saisie = "*" + args + "*"
      this.filter.select = ["id", "name"];
      this.filter.where = { name: { type: "like", value: this.saisie }, active: true }


      if (this.filterClicked == true) { this.getProduct(this.filter, valuergs) }

      if (this.filterProvider == true) { this.getProvider(this.filter, valuergs) }

      if (this.openCustomerIds) { this.getCustomers(this.filter, valuergs) }

      if (this.entityName == 'carriersFilter') { this.getCarriers(this.filter, valuergs) }

      if (this.entityName == 'marksFilter') {
        this.filter.select = ["id", "mark"];
        this.filter.where = { mark: { type: "like", value: this.saisie }, "entrepriseId": this.id_entreprise }
        this.getTrucks(this.filter, valuergs)
      }

    }
    else {
      valuergs = false
      this.filter.take = 15;
      this.filter.where = { active: true }

      if (this.filterClicked == true) {
        this.entityName = "idsproducts"
        this.getProduct(this.filter, valuergs)
      } else if (this.filterProvider == true) {
        this.getProvider(this.filter, valuergs)
      }
      if (this.entityName == 'carriersFilter') {
        this.getCarriers(this.filter, valuergs)
      }
      if (this.entityName == 'marksFilter') {
        this.getTrucks(this.filter, valuergs)
      }
      if (this.openCustomerIds) {
        this.getCustomers(this.filter, valuergs)
      }

    }

  }
  getCustomers(filter: FilterDto<Customer>, valuergs: boolean) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.quotationService.getCustomers(filter).subscribe(
      customers => {
        this.clients = customers[0];
        this.idsCustomers = []
        if (valuergs == true) {
          this.clients.forEach(element => {
            this.idsCustomers.push(element.id)
          });
        }
        else {
          this.idsCustomers = []
        }
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  getProvider(filter: FilterDto<Customer>, valuergs: boolean) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.buyingSerive.getProviders(filter).subscribe(
      data => {
        this.providers = data[0];
        this.idProviders = []
        if (valuergs == true) {
          this.providers.forEach(element => {
            this.idProviders.push(element.id)
          });
        }
        else {
          this.idProviders = []
        }
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );

  }
  getProduct(filter: FilterDto<Customer>, valuergs: boolean) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.stockService.getProduct(filter).subscribe(
      data => {
        this.products = data[0];
        this.idProducts = []
        if (valuergs == true) {
          this.products.forEach(element => {
            this.idProducts.push(element.id)
            this.where = { productId: { type: "in", value: this.idProducts } }
          });
        }
        else {
          this.idProducts = []
        }
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );

  }
  getCarriers(filter: FilterDto<Carrier>, valuergs: boolean) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.carrierService.getCarriers(filter).subscribe(
      data => {
        this.carriers = data[0];
        this.generalTab = []
        if (valuergs == true) {
          this.carriers.forEach(element => {
            this.generalTab
              .push(element.id)
          });
          filter.where = { carrierId: { type: "in", value: this.generalTab } }
          this.where = filter.where
        }
        else {
          this.generalTab = []
        }
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  getTrucks(filter: FilterDto<Truck>, valuergs: boolean) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.truckService.getTrucks(filter).subscribe(
      data => {
        this.trucks = data[0];
        this.generalTab = []
        if (valuergs == true) {
          this.trucks.forEach(element => {
            this.generalTab
              .push(element.id)
          });
          filter.where = { truckId: { type: "in", value: this.generalTab } }
          this.where = filter.where
        }
        else {
          this.generalTab = []
        }
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }


  accepts(): boolean {
    return true;
  }

  isActive(): boolean {
    if (this.generalTab && this.generalTab.length > 0) {
      return this.generalTab != null && this.generalTab.length > 0
    }


    if (this.filterClicked) {
      return this.idProducts != null && this.idProducts.length > 0;
    }
    if (this.filterProvider) {
      return this.idProviders != null && this.idProviders.length > 0;
    }
    if (this.openCustomerIds) {
      return this.idsCustomers != null && this.idsCustomers.length > 0;
    }
    if (this.openOrderIds) {
      return this.idsOperations != null && this.idsOperations.length > 0;
    }

  }
}