import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from 'src/app/parts/parts';
import { PartsService } from 'src/app/parts/parts.service';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { SettingService } from 'src/app/setting/setting.service';
import { Store } from 'src/app/stock/stock';
import { StockService } from 'src/app/stock/stock.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Commande, CommandeProduct, EtatCommande } from '../Commande';
import { CommandeService } from '../Service/commande-service.service';

@Component({
  selector: 'app-commande',
  templateUrl: './commande.component.html',
  styleUrls: ['./commande.component.css']
})
export class CommandeComponent implements OnInit {

  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  currentUser: number
  id_user:number
  filter: FilterDto<Commande> = new FilterDto<Commande>()
  customers: Customer[] = []
  commandes: Commande[] = []
  showCommande: Commande[] = []
  tmpList: Commande[] = []
  total: number;
  point_id: number
  commandeId: number;
  showAlert: boolean;
  showDetail: boolean;
  types = [
    "client", "date"
  ]
  type: string
  date_search: Date
  customerSelect: Customer = new Customer();
  currentPage = 1;
  pageSize = 5;
  nbr_page: number
  insertIndex: number = 0
  currentLang: string;
  videoDemo : boolean = false
  modalVideoDemo : boolean = false
  link : string = ""
  arabicLanguage : boolean = false
  userAutorisation : Autorisation = new Autorisation()
  etatCommande: EtatCommande = new EtatCommande()
  etatCommandes: EtatCommande[] = []
  commandModel: Commande = new Commande()
  msgAlert: string = ""
  showAlertError: boolean = false
  showAlertSuccess: boolean = false

  constructor(
    private translate: TranslateService, private partsService: PartsService,
    private commandeService : CommandeService, private salespointService: SalesPointService,
    private stockService: StockService, private settingService: SettingService,
    private renderer: Renderer2, private el: ElementRef,
    private _commandeService: CommandeService,
  ) {
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.translate.use(this.currentLang);
    this.userRole = jwtDecode(tokenGetter())["role"]
   }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.isAdmin = this.userRole == "admin"
    this.currentUser = parseInt(getValueFromToken("id"))
    this.getAllCustomers();
    this.filter.where = {}
    if (this.isAdmin) {
      this.verifExitancePointSales();
    } else {
      this.getUserInfo();
    }
  }
  getAllCustomers() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.partsService.getCustomers(filter).subscribe(
      data => {
        this.customers = data[0]
      }
    )
  }
  verifExitancePointSales() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, name: "point de vente principale" }
    filter.relations = ["storeId"]
    this.salespointService.getPoints(filter).subscribe(
      data => {
        if (data[1] == 0) {
          let storeModel = new Store()
          storeModel.real_id = 1
          storeModel.entrepriseId = this.id_entreprise
          storeModel.name = "Depot de la point de vente principale "
          this.stockService.addStore(storeModel).subscribe(
            data => {
              let pointModel = new Points()
              pointModel.real_id = 1
              pointModel.storeId = data.id
              pointModel.name = "point de vente principale"
              pointModel.entrepriseId = this.id_entreprise
              this.salespointService.addPoints(pointModel).subscribe(
                data => {
                  this.point_id = data.id
                  // this.filter = new FilterDto()
                  this.filter.relations = ["customerId", "etatCommandeId", "pointId", "commandeDetails", "commandeDetails.productId", "commandeDetails.serviceId"];
                  // this.filter.where = { "entrepriseId": this.id_entreprise, "active": true, "pointId": this.point_id }
                  this.filter.take = this.pageSize
                  this.getCommandes(this.filter)
                }
              )
            }
          )
        } else {
          this.point_id = data[0][0].id
          // this.filter = new FilterDto()
          this.filter.relations = ["customerId", "etatCommandeId", "pointId", "commandeDetails", "commandeDetails.productId", "commandeDetails.serviceId"];
          // this.filter.where = { "entrepriseId": this.id_entreprise, "active": true, "pointId": this.point_id }
          this.filter.take = this.pageSize
          this.getCommandes(this.filter) 
        }
      }
    )
  }
  getUserInfo() {
    this.id_user = parseInt(getValueFromToken("id"))
    let filter = new FilterDto()
    filter.where = { "id": this.id_user, "active": true, "entrepriseId": this.id_entreprise }
    if (this.userRole == "writer") {
      filter.relations = ["cratesId","autorisationId", "cratesId.pointid", "cratesId.pointid.storeId"]
      this.settingService.getUserInfo(filter).subscribe(
        data => {
          this.userAutorisation = data[0][0].autorisationId
          if (data[0][0] != undefined) {
            this.point_id = data[0][0].cratesId.pointid.id
            // this.filter = new FilterDto()
            this.filter.relations = ["customerId", "etatCommandeId", "pointId", "commandeDetails", "commandeDetails.productId", "commandeDetails.serviceId"];
            // this.filter.where = {
            //   "entrepriseId": this.id_entreprise,
            //   "createdBy": this.id_user, "pointId": this.point_id, "byAdmin": false,
            //   "active": true,
            // }
            this.filter.take = this.pageSize
            this.getCommandes(this.filter)
          }
        }
      )
    } else if (this.userRole == "responsable") {
      filter.relations = ["pointId","autorisationId", "pointId.storeId"]
      this.settingService.getUserInfo(filter).subscribe(
        data => {
          this.userAutorisation = data[0][0].autorisationId
          if (data[1] > 0) {
            this.point_id = data[0][0].pointId.id
            // this.filter = new FilterDto()
            this.filter.relations = ["customerId", "etatCommandeId", "pointId", "commandeDetails", "commandeDetails.productId", "commandeDetails.serviceId"];
            // this.filter.where = { "byAdmin": false, "active": true, "entrepriseId": this.id_entreprise, "pointId": this.point_id }
            this.filter.take = this.pageSize
            this.getCommandes(this.filter)
          } 
        }
      )
    }
  }
  getCommandes(filter: FilterDto<Commande>) {
    this.commandeService.getAllCommande(filter).subscribe(
      commandes => {
        this.commandes = commandes[0];
        this.tmpList = this.commandes
        this.total = commandes[1]
        if (this.total > 0) {
          this.nbr_page = Math.ceil(this.total / this.pageSize)
        } else {
          this.nbr_page = 1
        }
      },
      err => console.error('Observer got an error: ', err)
    );
  }
  typeChanged(item: string) {
    this.type = this.translateItem(item)
    // this.commandes = this.tmpList
    // this.total = this.commandes.length
    if (this.total > 0) {
      this.nbr_page = Math.ceil(this.total / this.pageSize)
    } else {
      this.nbr_page = 1
    }
  }
  searchWithDate(event:Event) {
    let date_search = (event.target as HTMLInputElement).value
    if (date_search !== '' && date_search.split('-')[0].startsWith('2')) {
      let date_debut = new Date(date_search).setHours(0, 0, 1)
      let date_fin = new Date(date_search).setHours(23, 59, 59)
      this.filter.where = {
        createdAt: {
          type: "between",
          value: [new Date(date_debut), new Date(date_fin)],
        },
      };
      this.initialVariables()
      this.getCommandes(this.filter)
    } else if (date_search == '') {
      this.filter.where = {}
      this.initialVariables()
      this.getCommandes(this.filter)
      // this.type = ''
    }
  }
  initialVariables(){
    this.commandes = []
    this.insertIndex = 0
    this.currentPage=1
    this.filter.skip = 0
  }
  customerChanged(item: Customer) {
    this.filter.where = { "customerId":item.id };
    this.initialVariables()
    this.getCommandes(this.filter)
  }
  detailAction(commandeId: number) {
    this.commandeId = commandeId;
    this.showDetail = true;
  }
  deleteAction(commandeId: number) {
    this.commandeId = commandeId;
    this.showAlert = true;
  }
  close() {
    this.showAlert = false;
    this.showDetail = false;
  }
  save() {
    if (this.isAdmin) {
      this.verifExitancePointSales();
    } else {
      this.getUserInfo();
    }
    this.showAlert = false;
  }
  EventLang() {
    let lang = localStorage.getItem('currentLang')
    if (lang == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  changeEventLang(event: any) {
    this.translate.use(event.target.value);
    if (event.target.value == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let selectedElement = event.target as HTMLElement
    if (selectedElement.className === 'changeEtat') {
      const dropdown = selectedElement.nextElementSibling as HTMLElement;
      if (dropdown) {
        const computedStyle = window.getComputedStyle(dropdown)
        const currentDisplay = computedStyle.getPropertyValue('display')
        this.renderer.setStyle(dropdown, 'display', currentDisplay === 'flex' ? 'none' : 'flex');
      }
      const changeEtatButtons = this.el.nativeElement.querySelectorAll('.changeEtat')
      changeEtatButtons.forEach((button : HTMLElement) => {
        if (button !== selectedElement){
          const dropdown = button.nextElementSibling as HTMLElement;
          this.renderer.setStyle(dropdown, 'display', 'none');
        }
      })
    } else if ((selectedElement.parentNode as HTMLElement).className === 'changeEtat'){
      const selectedButton = selectedElement.parentNode as HTMLElement
      const dropdown = selectedButton.nextElementSibling as HTMLElement;
      if (dropdown) {
        const computedStyle = window.getComputedStyle(dropdown)
        const currentDisplay = computedStyle.getPropertyValue('display')
        this.renderer.setStyle(dropdown, 'display', currentDisplay === 'flex' ? 'none' : 'flex');
      }
      const changeEtatButtons = this.el.nativeElement.querySelectorAll('.changeEtat')
      changeEtatButtons.forEach((button : HTMLElement) => {
        if (button !== selectedButton){
          const dropdown = button.nextElementSibling as HTMLElement;
          this.renderer.setStyle(dropdown, 'display', 'none');
        }
      })
    } else {
      const changeEtatButtons = this.el.nativeElement.querySelectorAll('.changeEtat')
      changeEtatButtons.forEach((button : HTMLElement) => {
        const dropdown = button.nextElementSibling as HTMLElement;
        this.renderer.setStyle(dropdown, 'display', 'none');
      })
    }
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getCommandes(this.filter)
  }
  translateItem(item: string): string {
    return this.translate.instant(`sharedSales.${item}`);
  }
}
