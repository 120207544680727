import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { pack } from '../pack';
import { PackService } from '../pack.service';

@Component({
  selector: 'app-delete-pack',
  templateUrl: './delete-pack.component.html',
  styleUrls: ['./delete-pack.component.css']
})
export class DeletePackComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>()
  @Input() pack: pack


  constructor(private packService: PackService) { }

  ngOnInit(): void {
  }

  close() {
    this.closed.emit(true)
  }
  save() {
    this.packService.deletepack(this.pack.id).subscribe(
      () => {
        this.saved.emit(true);
      }
    )
  }

}
