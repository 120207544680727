import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-bank-delete',
  templateUrl: './bank-delete.component.html',
  styleUrls: ['./bank-delete.component.css']
})
export class BankDeleteComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() bankId: number;
  real_id: number

  constructor(private partsService: PartsService, private router: Router) { }
  ngOnInit(): void {
    this.partsService.getBanks({ where: { "id": this.bankId } }).subscribe(
      data => {
        this.real_id = data[0][0].real_id;
      }
    )
  }

  deleteBank() {
    if (this.bankId[0]) {
      this.bankId = this.bankId[0]
    }
    this.partsService.deleteBank(this.bankId).subscribe(
      data => {
        this.saved.emit(true);
        this.closed.emit(true);
        this.router.navigate(['/parts/bank'])
      }
    )
  }

  closeAction() {
    this.closed.emit(true);
  }

}
