<div class="clr-row">
  <div class="clr-col-12 clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
    <div class="card">
      <div class="card-block">
        <div class="clr-col-12">
          <clr-icon shape="shopping-bag" size="36"></clr-icon>
        </div>
        <div class="clr-col-12 center">
          <h3><b>{{ 'sales.Chaffaire' | translate }}</b> </h3>
          <h3 *ngIf="statestique.chiffAffaire"><b>{{statestique.chiffAffaire |
              currency:devise:'symbol':'1.2-3':'fr'}}</b></h3>
          <h3 *ngIf="!statestique.chiffAffaire"><b>{{0 | currency:devise:'symbol':'1.2-3':'fr'}}</b></h3>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-12 clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
    <div class="card">
      <div class="card-block">
        <div class="clr-col-12">
          <clr-icon shape="wallet" size="36"></clr-icon>
        </div>
        <div class="clr-col-12 center">
          <h3><b>{{ 'sales.solde' | translate }}</b></h3>
          <h3 *ngIf="statestique.solde"><b>{{statestique.solde | currency:devise:'symbol':'1.2-3':'fr'}}</b></h3>
          <h3 *ngIf="!statestique.solde"><b>{{0 | currency:devise:'symbol':'1.2-3':'fr'}}</b></h3>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-12 clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
    <div class="card">
      <div class="card-block">
        <div class="clr-col-12">
          <clr-icon shape="clipboard" size="36"></clr-icon>
        </div>
        <div class="clr-col-12 center">
          <h3><b>{{ 'sales.commande' | translate }}</b></h3>
          <h3 *ngIf="statestique.nbrCmd"><b>{{statestique.nbrCmd}}</b></h3>
          <h3 *ngIf="!statestique.nbrCmd"><b>0</b></h3>
          <a class="label label-success">
            {{ 'sales.paye' | translate }}
            <span class="badge" *ngIf="statestique.nbrCmdPaye">{{statestique.nbrCmdPaye}}</span>
            <span class="badge" *ngIf="!statestique.nbrCmdPaye">0</span>
          </a>
          <a class="label label-warning">
            {{ 'sales.nonpaye' | translate }}
            <span class="badge" *ngIf="statestique.nbrCmdNonPaye">{{statestique.nbrCmdNonPaye}}</span>
            <span class="badge" *ngIf="!statestique.nbrCmdNonPaye">0</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-12 clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
    <div class="card">
      <div class="card-block">
        <div class="clr-col-12">
          <clr-icon shape="truck" size="36"></clr-icon>
        </div>
        <div class="clr-col-12 center">
          <h3><b>{{ 'sales.livraison' | translate }}</b></h3>
          <h3 *ngIf="statestique.totalDelivery"><b>{{statestique.totalDelivery}}</b></h3>
          <h3 *ngIf="!statestique.totalDelivery"><b>0</b></h3>
          <a class="label label-success">
            {{ 'sales.livre' | translate }}
            <span class="badge" *ngIf="statestique.livre">{{statestique.livre}}</span>
            <span class="badge" *ngIf="!statestique.livre">0</span>
          </a>
          <a class="label label-warning">
            {{ 'sales.nonLivre' | translate }}
            <span class="badge" *ngIf="statestique.nonLivre">{{statestique.nonLivre}}</span>
            <span class="badge" *ngIf="!statestique.nonLivre">0</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-12  clr-col-sm-12">
    <div class="card">
      <div class="card-block">
        <div class="center">
          <h2>{{ 'sales.TrAnClient' | translate }}</h2>
        </div>
        <div class="clr-offset-5 clr-offset-sm-6">
          <select clrSelect name="year" [(ngModel)]="year" (change)="changeDate()">
            <option *ngFor="let item of dateList" [value]="item.date">{{item.date}}</option>
          </select>
          <br>
        </div>
        <div>
          <canvas #trChart></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="clr-col-12 clr-col-md-6">
    <div class="card">
      <div class="card-block center">
        <h3>{{ 'sales.venParCateg' | translate }}</h3><small>{{ 'sales.prixEn' | translate }} <b>{{ 'commun.Dt' |
            translate }}</b></small>
        <br>
        <canvas id="canvas"></canvas>
      </div>
    </div>
  </div>



  <div class="clr-col-12 clr-col-md-6">
    <div class="card">
      <div class="card-block center">
        <h3>{{ 'sales.typePayment' | translate }}</h3>
        <br>
        <canvas id="canvas2"></canvas>
      </div>
    </div>
  </div>

</div>