import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import jwtDecode from "jwt-decode";
import { Observable } from "rxjs";
import { tokenGetter } from "../user/user.service";

@Injectable({
    providedIn: 'root'
})

export class AbnGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const abonnement = route.data && route.data.abonnements
        const token = tokenGetter()
        const decodedToken = jwtDecode(tokenGetter())
        if (token.length > 0) {
            let userAbonnement = jwtDecode(token)["type_abn"]
            
            if (this.abonnementExist(abonnement,userAbonnement)) {
                return true;
            }
            else {
                this.router.navigate(["/dashboard"])
            }
        }
        else if (token.length == 0) {
            this.router.navigate(["/user/login"])
            return false
        }
    }

    abonnementExist(requiredAbn : string[], userAbn : string[]){
        return userAbn.some(x=> requiredAbn.includes(x))
    }

}