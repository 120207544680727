<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Export data </h5>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <p></p>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-primary">{{ 'commun.impo' | translate }} </button> -->
        <button type="button" (click)="ExportData()" class="btn btn-secondary">{{ 'commun.exp' | translate }}</button>

      </div>
    </div>
  </div>
</div>
