import { Component, HostListener, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FilterDto } from '../filter.dto';
import { FilterService } from '../shared/filter.service';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { pack } from './pack';
import { PackService } from './pack.service';
import jwt_decode from "jwt-decode";
import { Autorisation } from '../user/autorisation/autorisation';
import jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-pack',
  templateUrl: './pack.component.html',
  styleUrls: ['./pack.component.css']
})
export class PackComponent implements OnInit {

  constructor(private packService: PackService, private userService : UserService) {
    this.userRole = jwt_decode(tokenGetter())["role"];
  }
  packs: pack[] = []
  tmpList: pack[] = []
  showPack: pack[] = []
  id_entreprise: number
  filter = new FilterDto<pack>()
  showDetail: boolean = false
  packSelected: pack
  showDelete: boolean = false
  userRole: string
  total: number
  currentPage: number = 1
  nbr_page: number
  pageSize: number = 5
  insertIndex: number = 0
  search_text: string
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""  
  userAutorisation : Autorisation = new Autorisation()
  userId : number
  isAdmin : boolean = false 

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userId = parseInt(jwtDecode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getUserAutorisation();
    }
    this.filter.where = { "entrepriseId": this.id_entreprise, "isDeleted": false }
    this.filter.relations = ["details", "taxId", "details.productid", "details.productid.taxId"]
    this.filter.take = this.pageSize
    this.getPacks(this.filter)
  }
  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  }
  getPacks(filter:FilterDto) {
    // let filter = new FilterDto()
    // filter.where = { "entrepriseId": this.id_entreprise, "isDeleted": false }
    // filter.relations = ["details", "taxId", "details.productid", "details.productid.taxId"]
    if (!this.packs[this.insertIndex]){
      this.packService.getAllPacks(filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.packs[this.insertIndex + i] = data[0][i];
          }
          this.tmpList = this.packs
          this.showPack = this.packs.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.total = data[1]
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        }
      )
    } else {
      this.showPack = this.packs.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getPacks(this.filter)
  }
  search_product(event:Event) {
    let saisie = ((event.target as HTMLInputElement).value)
    if (this.search_text.length == 0) {
      this.packs = []
      this.total = 1
      this.filter.where = { "entrepriseId": this.id_entreprise, "isDeleted": false }
      this.getPaginatedData(1)
    } else {
      this.packs = []
      this.tmpList.filter(x => {
        if (x.nom.toLowerCase().indexOf(this.search_text.toLowerCase().trim()) != -1) {
          this.packs.push(x)
        }
      })
      this.total = this.packs.length
      if (this.total > 0 && saisie[saisie.length - 1] !== ' ') {
        this.nbr_page = Math.ceil(this.total / this.pageSize)
      } else {
        this.nbr_page = 1
      }
      this.showPack = this.packs.slice(this.insertIndex, this.insertIndex + this.pageSize)
      if (this.packs.length == 0) {
        this.filter = new FilterDto()
        this.filter.where = { "entrepriseId": this.id_entreprise, "isDeleted": false }
        this.filter.relations = ["details", "taxId", "details.productid", "details.productid.taxId"]
        this.filter.take = this.pageSize
        this.filter.where = Object.assign(this.filter.where.valueOf(), {
          nom: {
            type: "like",
            value: "*" + this.search_text.trim() + "*"
          }
        })
        this.getPacks(this.filter)
      }
    }
  }
  MakeFavorite(id: any) {
    let packModel = new pack()
    packModel.favorite = true
    this.packService.updatepack(id, packModel).subscribe(
      data => {
        this.filter = new FilterDto
        this.filter.where = { "entrepriseId": this.id_entreprise, "isDeleted": false }
        this.filter.relations = ["details", "taxId", "details.productid", "details.productid.taxId"]
        this.filter.take = this.pageSize
        this.getPacks(this.filter);
      }
    )
  }
  RemoveFromFavorite(id: any) {
    let packModel = new pack()
    packModel.favorite = false
    this.packService.updatepack(id, packModel).subscribe(
      data => {
        this.filter = new FilterDto
        this.filter.where = { "entrepriseId": this.id_entreprise, "isDeleted": false }
        this.filter.relations = ["details", "taxId", "details.productid", "details.productid.taxId"]
        this.filter.take = this.pageSize
        this.getPacks(this.filter);
      }
    )
  }
  deleteAction(item: pack) {
    this.packSelected = item
    this.showDelete = true
  }
  detailAction(id: pack) {
    this.packSelected = id
    this.showDetail = true
  }
  CloseAction() {
    this.showDetail = false
    this.showDelete = false
    this.filter = new FilterDto
    this.filter.where = { "entrepriseId": this.id_entreprise, "isDeleted": false }
    this.filter.relations = ["details", "taxId", "details.productid", "details.productid.taxId"]
    this.filter.take = this.pageSize
    this.getPacks(this.filter);
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
      
    }
  }
}
