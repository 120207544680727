import { Component, HostListener, OnInit } from '@angular/core';
import { Bank } from '../../../buying/buying';
import { PartsService } from '../../parts.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';

@Component({
  selector: 'app-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.css']
})
export class BankEditComponent implements OnInit {
  bankObject: Bank
  stopLoading: boolean
  bankId: number
  showAlert: boolean
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private route: ActivatedRoute, private partsService: PartsService) { }
   
  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.bankId = +params.get('id')
    })
    this.partsService.getBank(this.bankId).subscribe(
      data => {
        this.bankObject = data
        this.stopLoading = true
      },
      err => {
        console.error('Observer got an error: ' + err)
      },
    )
  }

  editBank() {
    this.bankObject.entrepriseId = this.id_entreprise
    this.bankObject.updatebyAdmin = this.isAdmin
    this.partsService.editBank(this.bankId, this.bankObject).subscribe(
      data => {
        this.showAlert = true
      }
    )
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
