import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommandeService } from '../../Service/commande-service.service';

@Component({
  selector: 'app-commande-delete',
  templateUrl: './commande-delete.component.html',
  styleUrls: ['./commande-delete.component.css']
})
export class CommandeDeleteComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() commandeId: number;
  real_id: number

  constructor(private commandeService : CommandeService) { }

  ngOnInit(): void {
    this.commandeService.getAllCommande({ where: { "id": this.commandeId }, select: ["real_id"] }).subscribe(x => {
      this.real_id = x[0][0].real_id;
    })
  }
  save() {
    this.commandeService.deleteCommande(this.commandeId).subscribe(
      res => {
        this.saved.emit(true);
      },
      err => { }
    );
  }
  close() {
    this.closed.emit(true);
  }
}
