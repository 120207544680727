import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { User } from 'src/app/user/user';
import { Tax } from '../tax';
import { TaxService } from '../tax.service';

@Component({
  selector: 'app-tax-detail',
  templateUrl: './tax-detail.component.html',
  styleUrls: ['./tax-detail.component.css']
})

export class TaxDetailComponent implements OnInit {
  @Input() tax: Tax;
  @Output() closed = new EventEmitter<boolean>();
  showAlert: boolean;
  searchUser: FilterDto<User> = new FilterDto<User>()
  user: User = new User();

  constructor(private taxService: TaxService, private quotationService: QuotationService) { }

  ngOnInit() {
    this.taxService.getTaxById(this.tax.id).subscribe(
      data => {
        this.tax = data
        this.searchUser.select = ["id", "name"];
        this.searchUser.where = { "id": this.tax.createdBy };
        this.quotationService.getUser(this.searchUser).subscribe(
          user => {
            this.user = user[0][0];
          },
          err => console.error('Observer got an error: ', err),
        );
      })
  }

  close() {
    this.closed.emit(true);
  }
}