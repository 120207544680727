<div class="sidebar" [ngClass]="{'ShowSidebar' : openSideBar}">
    <div class="sidebar-header">
        <a href="javascript:void(0);" class="nav-retour" (click)="SidebarHide()">
            <i class="fas fa-chevron-right"></i>
        </a>
        <a routerlink="dashboard" class="nav-link nav-logo" ng-reflect-router-link="dashboard" href="#/dashboard">
            <img _ngcontent-kno-c322="" type="image/icon" src="assets\logo_inveep.png" width="150" height="45px"
                alt="clr-icon" class="icon">
        </a>
    </div>
    <div class="sidebar-body">
        <ul class="nav-links">
            <li [ngClass]="{'ShowArticles' : articleButton}" class="articles"
                *ngIf="(autorisationUser?.menuExpiredProd == true || autorisationUser?.menuArticle == true) || autorisationUser==undefined || isAdmin "
                class="pack">
                <div class="but-border" (click)="ArticleButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> {{ 'article.article' | translate }} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="20"
                                fill="currentColor" class="bi bi-folder2-open" viewBox="-1 -2 16 15">
                                <path
                                    d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="articlesMenu">
                    <!-- <li>
                        <a routerLink="/catalog/detail" class="text-color" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'commun.de' | translate }}
                        </a>
                    </li> -->
                    <!-- <li>
                        <a routerLink="/catalog/tax" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'article.taxe.Taxe' | translate }}
                        </a>
                    </li> -->
                    <li *ngIf="autorisationUser?.menuArticle == true || autorisationUser==null || isAdmin">
                        <a routerLink="/catalog/unity" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'article.unites.unite' | translate }}
                        </a>
                    </li>
                    <li *ngIf="autorisationUser?.menuArticle == true || autorisationUser==null || isAdmin">
                        <a routerLink="/catalog/mark" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'article.Marque.Mrq' | translate }}
                        </a>
                    </li>
                    <li *ngIf="autorisationUser?.menuArticle == true || autorisationUser==null || isAdmin">
                        <a routerLink="/catalog/category" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'article.Mcatg.catg' | translate }}
                        </a>
                    </li>
                    <li *ngIf="autorisationUser?.menuArticle == true || autorisationUser==null || isAdmin">
                        <a routerLink="/catalog/product" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'article.Prod.Pro' | translate }}
                        </a>
                    </li>
                    <li *ngIf="autorisationUser?.menuArticle == true || autorisationUser==null || isAdmin">
                        <a clrVerticalNavLink routerLink="/catalog/pack" routerLinkActive="active"
                            (click)="SidebarHide()">
                            {{ 'pack.packname' | translate }}
                        </a>
                    </li>
                    <!-- <li>
                        <a *ngIf="userRole != 'carrier' && userRole != 'writer'" clrVerticalNavLink
                            routerLink="/stock/inventory" routerLinkActive="active">
                            {{ 'Stock.invt.inv' | translate }}
                        </a>
                    </li> -->
                    <li *ngIf="autorisationUser?.menuExpiredProd == true || autorisationUser==null || isAdmin">
                        <a clrVerticalNavLink routerLink="/stock/expired-product" routerLinkActive="active">
                            {{ 'commun.prodreb' | translate }}
                        </a>
                    </li>
                    <!-- <li>
                        <a clrVerticalNavLink routerLink="/stock/mouvement" routerLinkActive="active">
                            {{ 'Stock.Mouvements.Mvm' | translate }}
                        </a>
                    </li> -->
                </ul>
            </li>
            <!-- <li class="but-border pack">
                <a routerLink="catalog/pack" routerLinkActive="active" class="nav-item" (click)="SidebarHide()">
                    <div class="menu-link">
                        <span> {{ 'pack.packname' | translate }} </span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                            fill="currentColor" class="bi bi-box" viewBox="-1 -2 18 18">
                            <path
                                d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                        </svg>
                    </div>
                </a>
            </li> -->
            <li [ngClass]="{'ShowServices' : serviceButton}" class="services"
                *ngIf="(autorisationUser?.menuExpiredProd == true || autorisationUser?.menuArticle == true) || autorisationUser==undefined || isAdmin ">
                <div class="but-border" (click)="ServiceButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> {{ 'article.service' | translate }} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="20"
                                fill="currentColor" class="bi bi-folder2-open" viewBox="-1 -2 16 15">
                                <path
                                    d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="servicesMenu">
                    <li>
                        <a routerLink="/catalog/category-service" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'article.catService' | translate }}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/catalog/service" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'article.services' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="but-border commande" style="padding: 0; height: 36px;"
                *ngIf="autorisationUser?.menuSales == true || autorisationUser == null || isAdmin ">
                <a routerLink="/commande" routerLinkActive="active" class="nav-item" (click)="SidebarHide()"
                    style="height: 100%;">
                    <div class="menu-link">
                        <span> {{ 'sales.commande' | translate }} </span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:4px" width="18" height="18"
                            fill="currentColor" class="bi bi-cart" viewBox="0 0 16 15">
                            <path
                                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                    </div>
                </a>
            </li>
            <li class="but-border ventes" style="padding: 0; height: 36px;"
                *ngIf="autorisationUser?.menuSales == true || autorisationUser == null || isAdmin ">
                <a routerLink="/sales/order" routerLinkActive="active" class="nav-item" (click)="SidebarHide()"
                    style="height: 100%;">
                    <div class="menu-link">
                        <span> {{ 'ventes.ventes' | translate }} </span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:4px" width="18" height="18"
                            fill="currentColor" class="bi bi-cart" viewBox="0 0 16 15">
                            <path
                                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                    </div>
                </a>
            </li>
            <li *ngIf="((autorisationUser?.menuCarteFidelite == true || autorisationUser?.menuCarteFidelite == true) || autorisationUser==undefined || isAdmin) && (gestion_carte_fid || premuim) "
                class="but-border carteFidelite" style="padding: 0; height: 36px;">
                <a routerLink="/carte-fidelite" routerLinkActive="active" class="nav-item" (click)="SidebarHide()"
                    style="height: 100%;">
                    <div class="menu-link">
                        <span> {{ 'sharedSales.cartfidel' | translate }} </span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="16" height="16"
                            fill="currentColor" class="bi bi-card-text" viewBox="0 0 16 16">
                            <path
                                d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                            <path
                                d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </div>
                </a>
            </li>
            <li *ngIf="userRole == 'admin' && (premuim || facturation_point_vente)"
                [ngClass]="{'ShowPtsVentes' : ptsVentesButton}" class="pointsVentes">
                <div class="but-border" (click)="PtsVentesButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> {{ 'Points.pt' | translate }} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-hand-index" viewBox="0 0 16 16">
                                <path
                                    d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435a4.9 4.9 0 0 1 .106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="ptsVentesMenu">
                    <li *ngFor="let item of salespoints">
                        <a routerLink="/sales-point/info/{{item.id}}" routerLinkActive="active" (click)="SidebarHide()">
                            {{item.name}}
                        </a>
                    </li>
                </ul>
            </li>
            <!-- <li class="but-border" *ngIf="">
                <a clrVerticalNavLink routerLink="sales-point" routerLinkActive="active" class="nav-item" (click)="SidebarHide()">
                    <div class="menu-link">
                        <span> {{ 'Points.pt' | translate }} </span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="16" height="16" fill="currentColor"
                        class="bi bi-hand-index" viewBox="0 0 16 16">
                        <path
                        d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435a4.9 4.9 0 0 1 .106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z" />
                        </svg>
                    </div>
                </a>
            </li> -->
            <li class="but-border achats"
                *ngIf="autorisationUser?.menuBuying == true || (autorisationUser ==  null && userRole != 'writer') || isAdmin"
                style="padding: 0; height: 36px;">
                <a routerLink="/buying/" routerLinkActive="active" class="nav-item" (click)="SidebarHide()"
                    style="height: 100%;">
                    <div class="menu-link">
                        <span> {{ 'Achats.acht' | translate }} </span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                            fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
                            <path
                                d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                        </svg>
                    </div>
                </a>
            </li>
            <li *ngIf="(premuim || facturation_point_vente ) && (isAdmin || autorisationUser.menuCrates == true || (autorisationUser == null && userRole != 'carrier'))"
                [ngClass]="{'ShowCaisse' : caisseButton}" class="caisse">
                <div class="but-border" (click)="CaisseButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> {{ 'Caisses.cs1' | translate }} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:4px" width="18" height="18"
                                fill="currentColor" class="bi bi-cart" viewBox="0 0 16 15">
                                <path
                                    d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="caisseMenu">
                    <li
                        *ngIf="(autorisationUser == null && userRole != 'writer' && userRole!='carrier') || isAdmin || autorisationUser.menuCrates == true ">
                        <a routerLink="/crates/" routerLinkActive="active" (click)="SidebarHide()">
                            <span>{{ 'Caisses.cs' | translate }}</span>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:4px" width="18" height="18"
                                fill="currentColor" class="bi bi-cart" viewBox="0 0 16 15">
                                <path
                                    d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg> -->
                        </a>
                    </li>
                    <li
                        *ngIf="((autorisationUser == null && userRole != 'writer' && userRole!='carrier') || isAdmin || autorisationUser.menuCrates == true) && userRole == 'admin'">
                        <a clrVerticalNavLink routerLink="/finance/inventory" routerLinkActive="active">
                            {{ 'Caisses.incs' | translate }}
                        </a>
                    </li>
                    <li *ngIf="userRole == 'writer'">
                        <a routerLink="/caisse/" target="_blank" routerLinkActive="active" (click)="SidebarHide()">
                            <span>{{ 'Caisses.cs1' | translate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:4px" width="16" height="16"
                                fill="currentColor" class="bi bi-cart" viewBox="0 0 16 15">
                                <path
                                    d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="document" [ngClass]="{'ShowDocument' : documentButton}"
                *ngIf="isAdmin || (autorisationUser == null || autorisationUser.menuQuotation == true || autorisationUser.menuInvoice == true || autorisationUser.menuExitVoucher == true || autorisationUser.menuSalesDelivery == true )">
                <div class="but-border" (click)="DocumentButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> {{ 'Document.Doc' | translate }} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                                <path
                                    d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                <path
                                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="documentsMenu">
                    <li *ngIf="isAdmin || autorisationUser == null || autorisationUser.menuQuotation==true">
                        <a routerLink="/sales/quotation" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Document.Devis.Mdv' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'carrier' && userRole != 'writer') || autorisationUser.menuInvoice==true">
                        <a routerLink="/sales/invoice" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Document.Factures.MFact' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="(premuim || livraison ) && ((autorisationUser == null && userRole != 'carrier' && userRole != 'writer') || isAdmin || autorisationUser.menuExitVoucher==true)">
                        <a routerLink="/sales/exit-voucher" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Document.Bs' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="(premuim || livraison ) &&((autorisationUser == null && userRole != 'carrier' && userRole != 'writer') || isAdmin || autorisationUser.menuSalesDelivery==true)">
                        <a routerLink="/stock/sales-delivery" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Stock.Bliv.Bon' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li class="suiviLivraison" [ngClass]="{'showSuiviLivraison' : suiviLivraisonButton}"
                *ngIf="userRole == 'admin' && (premuim || livraison)">
                <div class="but-border" (click)="SuiviLivraisonButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span>{{ 'Livraisons.Livraisons.ZLiv' | translate }} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                <path
                                    d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="suiviLivraisonMenu">
                    <li>
                        <a routerLink="/transport/map" routerLinkActive="active" (click)="SidebarHide()">{{
                            'Livraisons.Livraisons.tr' | translate }}</a>
                    </li>
                    <li *ngFor="let item of salespointCarriers">
                        <a routerLink="/sales-point/info/{{item.id}}" routerLinkActive="active" (click)="SidebarHide()">
                            {{item.name}}
                        </a>
                    </li>
                </ul>
            </li>
            <!-- <li *ngIf="salespointCarriers.length==0" class="suiviLivraison">
                <div *ngIf="userRole == 'admin' && (premuim || livraison)">
                    <div class="but-border">
                        <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> Suivi des livraisons </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="16" height="16" fill="currentColor"
                            class="bi bi-table" viewBox="0 0 16 16">
                            <path
                            d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                            </svg>
                        </div>
                        </button>
                    </div>
                    <ul class="sub-menu">
                        <li (click)="SidebarHide()">
                            <p style="text-align: center;padding-top:0px">Liste vide </p>
                        </li>
                    </ul>
                </div>
            </li> -->
            <li [ngClass]="{'ShowLivraison' : livraisonButton}" class="livraison"
                *ngIf="isAdmin || ( (autorisationUser == null && userRole != 'writer') || autorisationUser.menuLivraison == true || autorisationUser.menuVehicule == true || autorisationUser.menuLivreur == true ) && (premuim || livraison )">
                <div class="but-border" (click)="LivraisonButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span>{{ 'Livraisons.lv' | translate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
                                <path
                                    d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="livraisonMenu">
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuLivraison == true">
                        <a routerLink="/transport/" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Livraisons.lv' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuVehicule == true">
                        <a routerLink="/transport/truck" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Livraisons.Véhicules.Mvehi' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuLivreur == true">
                        <a routerLink="/transport/carrier" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Livraisons.lve' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li [ngClass]="{'ShowStock' : stockButton}" class="livreur"
                *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuDepot == true || autorisationUser.menuMouvement == true || autorisationUser.menuInventaire == true ">
                <div class="but-border" (click)="StockButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span>{{ 'Stock.stock' | translate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-boxes" viewBox="0 0 16 16">
                                <path
                                    d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="stockMenu">
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuDepot == true">
                        <a routerLink="/stock/store" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Stock.Dépots.MDp' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuMouvement == true">
                        <a routerLink="/stock/mouvement" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Stock.Mouvements.Mvm' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuInventaire == true">
                        <a routerLink="/stock/inventory" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Stock.invt.inv' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li [ngClass]="{'ShowTransaction' : transactionButton}" class="transactions" *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuTransaction == true 
                || autorisationUser.menuCredit == true || autorisationUser.menuCaisseToday == true
                || autorisationUser.menuCheque == true || autorisationUser.menuCost == true 
                || autorisationUser.menuPlusValue == true">
                <div class="but-border" (click)="TransactionButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> {{ 'Transactions.Tr' | translate }} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-wallet" viewBox="0 0 16 16">
                                <path
                                    d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="transactionMenu">
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuTransaction == true">
                        <a routerLink="/finance/transaction" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Transactions.Tr' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuCredit == true">
                        <a routerLink="/finance/credit" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Transactions.mcr' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuCaisseToday == true">
                        <a routerLink="/finance/crates" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Transactions.Caisses.Mcais' | translate }}
                        </a>
                    </li>
                    <!-- <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuCheque == true">
                        <a routerLink="/finance/cheque" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Transactions.Chèque.Mch' | translate }}
                        </a>
                    </li> -->
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuCost == true">
                        <a routerLink="/finance/cost" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Transactions.Frais.Mfr' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuPlusValue == true">
                        <a routerLink="/finance/plus-value" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Transactions.plval' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li [ngClass]="{'ShowComptabilite' : comptabiliteButton}" class="comptabilite"
                *ngIf="(isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuRetenueSource == true)  && (premuim || facturation_only || facturation_point_vente)">
                <div class="but-border" (click)="ComptabiliteButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span> {{ 'Comptabilité.Cmtb' | translate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
                                <path
                                    d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="comptabiliteMenu">
                    <li>
                        <a routerLink="/comptabilite/retenuesource" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Comptabilité.Rtn' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <li [ngClass]="{'ShowRelation' : relationButton}" class="relation" *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuCustomer == true || autorisationUser.menuProvider == true 
                || autorisationUser.menuBanque == true || autorisationUser.menuCompany == true">
                <div class="but-border" (click)="RelationButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span>{{ 'Relation.re' | translate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                                <path
                                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="relationsMenu">
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuCustomer == true">
                        <a routerLink="/parts/customer" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Relation.Clients.mclient' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuProvider == true">
                        <a routerLink="/parts/provider" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Relation.Fournisseurs.Nfs' | translate }}
                        </a>
                    </li>
                    <!-- <li>
                        <a routerLink="/parts/caissier" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Relation.Caissières.mcs' | translate }}
                        </a>
                    </li> -->
                    <li
                        *ngIf="isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuBanque == true">
                        <a routerLink="/parts/bank" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Relation.Banques.Banques' | translate }}
                        </a>
                    </li>
                    <li
                        *ngIf="(isAdmin || (autorisationUser == null && userRole != 'writer') || autorisationUser.menuCompany == true) && showabnCompany">
                        <a routerLink="/parts/company" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Relation.sdr.sdl' | translate }}
                        </a>
                    </li>
                    <!-- <li>
                        <a routerLink="/parts/livreur" routerLinkActive="active" (click)="SidebarHide()">
                            Mes Livreurs
                        </a>
                    </li>
                    <li>
                        <a routerLink="/parts/cars" routerLinkActive="active" (click)="SidebarHide()">
                            Mes Véhicules
                        </a>
                    </li>
                    <li>
                        <a routerLink="/parts/marque-cars" routerLinkActive="active" (click)="SidebarHide()">
                            Les marques des véhicules
                        </a>
                    </li> -->
                </ul>
            </li>
            <li class="but-border rapport" style="padding: 0; height: 36px;">
                <a routerLink="/sales-point/report/" routerLinkActive="active" class="nav-item" (click)="SidebarHide()"
                    style="height: 100%;">
                    <div class="menu-link">
                        <span>{{ 'sales.rapport' | translate }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="16" height="16"
                            fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                        </svg>
                    </div>
                </a>
            </li>
            <li *ngIf="userRole=='admin'" [ngClass]="{'ShowParametres' : parametresButton}" class="parametres">
                <div class="but-border" (click)="ParametresButton()">
                    <button class="nav-item">
                        <i class="fas fa-chevron-left"></i>
                        <div class="menu-link">
                            <span>{{ 'Paramtrage.pr' | translate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 4px;" width="18" height="18"
                                fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <ul class="sub-menu" id="parametresMenu">
                    <li>
                        <a routerLink="setting/type" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Paramtrage.Types.Mtp' | translate }}
                        </a>
                    </li>
                    <li>
                        <a routerLink="user/users" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Paramtrage.gs' | translate }}
                        </a>
                    </li>
                    <!-- <li>
                        <a routerLink="users/reset" class="text-color" routerLinkActive="active" (click)="SidebarHide()">
                            reset
                        </a>
                    </li> -->
                    <li>
                        <a routerLink="user/autorisations" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Paramtrage.Configurations.gaut' | translate }}
                        </a>
                    </li>
                    <li>
                        <a routerLink="setting/configuration" routerLinkActive="active" (click)="SidebarHide()">
                            {{ 'Paramtrage.Configurations.conf' | translate }}
                        </a>
                    </li>
                </ul>
            </li>
            <!-- <li *ngIf="userRole == 'admin'" class="export" class="but-border">
                <a (click)="impoexpo()" routerLinkActive="active" class="nav-item" (click)="SidebarHide()">
                    <div class="menu-link">
                        <span> Export </span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:4px" width="18" height="18" fill="currentColor"
                        class="bi bi-database-fill-down" viewBox="0 0 16 16">
                        <path
                        d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Zm.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V10.5a.5.5 0 0 0-1 0v2.793l-.646-.647a.5.5 0 0 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0ZM8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1Z" />
                        <path
                        d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7Zm6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002Zm-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905Z" />
                        </svg>
                    </div>
                </a>
            </li> -->
        </ul>
    </div>
</div>