import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { Product } from 'src/app/catalog/product/product';
import { ProductService } from 'src/app/catalog/product/product.service';
import { Tax } from 'src/app/catalog/tax/tax';
import { TaxService } from 'src/app/catalog/tax/tax.service';
import { FilterDto } from 'src/app/filter.dto';
import { ListeProducts } from 'src/app/sales/shared-sales/shared-sales';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { detailpack, pack } from '../pack';
import { PackService } from '../pack.service';
import { ClrStepper } from '@clr/angular';

@Component({
  selector: 'app-pack-add',
  templateUrl: './pack-add.component.html',
  styleUrls: ['./pack-add.component.css']
})
export class PackAddComponent implements OnInit {

  constructor(private taxService: TaxService, private packService: PackService) { }
  showAlert: boolean = false
  initialStep: string = 'step1'
  modelpack: pack = new pack();
  listeproducts: any[] = [];
  id_entreprise: number
  creerNouveau: boolean = false;
  liste_details: detailpack[] = []
  real_id_pack: number
  pack_id: number
  selectedId: number
  showDetail: boolean = false
  filter: FilterDto = new FilterDto()
  loading: boolean = false
  taxs: Tax[] = []
  selectedTax: string
  showAddTaxe: boolean = false
  taxSelected: Tax
  userRole: string
  isAdmin: boolean = false
  showAddProduct: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  @ViewChild('stepper', { static: false }) stepper: ClrStepper;

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getAllPack();
  }
  clrInitialStep(initialStep: string) {
    this.initialStep = initialStep
  }
  AddTaxePopUp() {
    this.showAddTaxe = true
  }
  CloseAction() {
    this.showAddTaxe = false
  }
  AddTaxe() {
    this.CloseAction();
    this.onSearchChange();
  }
  submit() {
    this.listeproducts.forEach(el => {
      let det_pack = new detailpack()
      det_pack.entrepriseId = this.id_entreprise;
      det_pack.productid = el.productId.id
      det_pack.qte_produit = el.quantity
      det_pack.byAdmin = this.isAdmin
      this.liste_details.push(det_pack)
    })
    this.modelpack.real_id = this.real_id_pack
    this.modelpack.entrepriseId = this.id_entreprise
    this.modelpack.createdAt = new Date();
    this.modelpack.byAdmin = this.isAdmin
    this.modelpack.taxId = this.taxSelected.id
    this.packService.addPack(this.modelpack).subscribe(
      data => {
        this.pack_id = data.id
        this.liste_details.forEach(el => {
          el.packId = data
          this.packService.addDetailPack(el).subscribe(
            x => {
              this.initialStep = "step0"
              this.showAlert = true
              this.creerNouveau = true
            }
          )
        })
      }
    )
  }
  getTaxs(filter: FilterDto) {
    //filter.where = { "entrepriseId": this.id_entreprise }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }
  onSearchChange(search?: string) {
    this.filter = new FilterDto()
    if (search) {
      this.filter.where = { percentage: { type: "equal", value: +search }, active: true }
    } else {
      this.filter.take = 15;
      this.filter.where = { active: true }
    }
    this.getTaxs(this.filter)
  }
  itemChanged(item: any) {
    this.taxSelected = item
  }
  detailAction(id: any) {
    this.selectedId = id
    this.showDetail = true
  }

  resetForm() {
    this.modelpack = new pack();
    this.liste_details = []
    this.listeproducts = []
    this.creerNouveau = false
    this.selectedTax = null
    this.stepper.form.reset()
    this.showAlert = false
    this.clrInitialStep("step1")
  }

  packProductsEvent($event) {
    this.listeproducts = []
    let prods = $event.totalProducts;
    prods.forEach(el => {
      this.listeproducts.push(el)
    })
  }

  getAllPack() {
    let filter = new FilterDto<pack>()
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.packService.getAllPacks(filter).subscribe(
      data => {
        this.real_id_pack = data[1] + 1;
      }
    )
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
