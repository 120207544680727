import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../category';
import { CategoryService } from '../category.service';
import { DetailService } from '../../detail/detail.service';
import { FilterDto } from 'src/app/filter.dto';
import { Detail } from '../../detail/detail';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.css']
})
export class CategoryEditComponent implements OnInit {
  showAlert: boolean;
  showMsg: boolean;
  category: Category;
  id: number;
  loading: boolean = true;
  details: Detail[];
  filter: FilterDto<Detail> = new FilterDto<Detail>()
  saisie: string;
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false

  constructor(private categoryService: CategoryService, private activatedRoute: ActivatedRoute, private detailService: DetailService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.categoryService.getCategoryById(this.id)
        .subscribe(
          data => {
            this.category = data
          })
    });
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  delete() {
    this.showAlert = true;
  }

  close() {
    this.showAlert = false;
  }

  submit(category: Category) {
    category.updatebyAdmin = this.isAdmin
    this.categoryService.editCategory(this.id, category)
      .subscribe(
        data => {
          this.showMsg = true;
        })
  }

  fetchDetails(search?: string) {
    this.loading = true
    if (search) {
      this.saisie = "*" + search + "*"
      this.filter.take = 10;
      this.filter.order = { "id": "ASC" }
      this.filter.where = { name: { type: "like", value: this.saisie } }
    }
    else {
      this.filter.take = 15;
      this.filter.where = undefined
    }
    this.filter.order = { "createdAt": 'DESC' };
    this.filter.select = ["id", "name", "createdAt"];
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.getDetails(this.filter)


  }
  getDetails(filter: FilterDto<Detail>) {
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        this.loading = false

      },
      err => console.error('Observer got an error: ' + err),
    );
  }
}