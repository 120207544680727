import { Transaction } from './../../finance/finance';
import { Chart } from 'chart.js';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, getValueFromToken } from 'src/app/user/user.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { FinanceService } from 'src/app/finance/finance.service';
import { FilterDto } from 'src/app/filter.dto';
import { Store } from 'src/app/stock/stock';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { StockService } from 'src/app/stock/stock.service';
import { SettingService } from 'src/app/setting/setting.service';
import { InvoiceService } from 'src/app/sales/invoice/invoice.service';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { ChiffreAffaire } from 'src/app/finance/finance';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  userRole: string = '';
  triale: boolean;
  currentLang: string;
  lg = "rtl"
  x = false;
  y: string;
  devise: string
  id_entreprise: number;
  current_user: number;
  isAdmin: boolean = false;
  reste_triale: number;
  nbr_devis: number = 0
  nbr_invoice: number = 0
  total_depense: number = 0
  caisse_jour: number = 0
  total_sales: number = 0
  totale_buying: number = 0
  nbr_orders: number = 0
  nbr_buyings: number = 0
  total_buying_credit: number = 0
  total_sales_credit: number = 0
  filter = new FilterDto()
  point_id: number
  chifre_aff: {
    mois: string,
    value: number
  }
  chiffre_affaires: ChiffreAffaire[] = []
  detailsCaisse: boolean = false
  detailsDepenses: boolean = false
  detailsDevis: boolean = false
  detailsFactures: boolean = false
  detailsTtVentes: boolean = false
  detailsTtAchats: boolean = false
  detailsNbrVentes: boolean = false
  detailsNbrAchats: boolean = false

  constructor(public translate: TranslateService, private router: Router, private configService: ConfigService,
    private financeService: FinanceService, private salespointService: SalesPointService,
    private stockService: StockService, private settingService: SettingService,
    private invoiceService: InvoiceService, private quotationService: QuotationService,
    private userService: UserService) {
    this.triale = getValueFromToken("triale")
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.y = localStorage.getItem('y');
    this.translate.use(this.currentLang);
  }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.current_user = parseInt(getValueFromToken("id"))
    this.reste_triale = parseInt(getValueFromToken("number_trial"))
    this.userRole = getValueFromToken("role");
    this.isAdmin = this.userRole == "admin"
    this.getEntrepriseInfo()
    if (this.isAdmin) {
      this.verifExitancePointSales();
    } else {
      this.getUserInfo();
    }
  }

  chartAffaires() {
    new Chart(
      document.getElementById('barCanvas'),
      {
        type: 'bar',
        data: {
          labels: this.chiffre_affaires?.map(row => row.month + 1),
          datasets: [
            {
              label: "Chiffre d'Affaires",
              data: this.chiffre_affaires?.map(row => row.amount),
              backgroundColor: [
                'rgba(255,99,132,0.2)',
                'rgba(54,162,132,0.2)',
                'rgba(255,206,86,0.2)',
                'rgba(75,192,192,0.2)',
                'rgba(153,102,255,0.2)',
                'rgba(255,159,64,0.2)',
                'rgba(205,199,132,0.2)',
              ],
              borderColor: [
                'rgba(255,99,132,1)',
                'rgba(54,162,132,1)',
                'rgba(255,206,86,1)',
                'rgba(75,192,192,1)',
                'rgba(153,102,255,1)',
                'rgba(255,159,64,1)',
                'rgba(205,199,132,1)',
              ],
              color: "#000",
              borderWidth: 1
            },
          ],
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        }
      }
    );
  }
  verifExitancePointSales() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, name: "point de vente principale" }
    filter.relations = ["storeId"]
    this.salespointService.getPoints(filter).subscribe(
      data => {
        if (data[1] == 0) {
          let storeModel = new Store()
          storeModel.real_id = 1
          storeModel.entrepriseId = this.id_entreprise
          storeModel.name = "Depot de la point de vente principale "
          this.stockService.addStore(storeModel).subscribe(
            data => {
              let pointModel = new Points()
              pointModel.real_id = 1
              pointModel.storeId = data.id
              pointModel.name = "point de vente principale"
              pointModel.entrepriseId = this.id_entreprise
              this.salespointService.addPoints(pointModel).subscribe(
                data => {
                  var event = new CustomEvent('RefreshSalesPoints', { 'detail': true });
                  window.dispatchEvent(event);
                  this.point_id = data.id
                  this.filter = new FilterDto()
                  this.filter.relations = ["transactionTypeId", "costTypeId", "orderId", "buyingId"]
                  this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
                  this.getTransactions(this.filter);
                  let filtre = new FilterDto()
                  filtre.where = { "entrepriseId": this.id_entreprise, "active": true }
                  this.getInvoiceNumber()
                  this.getDevisNumber(filtre)
                }
              )
            }
          )
        } else {
          this.point_id = data[0][0].id
          this.filter = new FilterDto()
          this.filter.relations = ["transactionTypeId", "costTypeId", "orderId", "buyingId"]
          this.filter.where = { "entrepriseId": this.id_entreprise }
          this.getTransactions(this.filter);
          let filtre = new FilterDto()
          filtre.where = { "entrepriseId": this.id_entreprise, "active": true }
          this.getInvoiceNumber()
          this.getDevisNumber(filtre)
        }
      }
    )
  }
  getEntrepriseInfo() {
    let filter = new FilterDto()
    filter.where = { "id": this.id_entreprise }
    filter.relations = ["deviseId"]
    this.settingService.getAllEntreprise(filter).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code;
        if (this.devise === "dt") {
          this.devise = "TND"
        }
      }
    )
  }
  getTransactions(filter: FilterDto) {
    this.financeService.getTransactions(filter).subscribe(
      data => {
        let date_start = new Date().setHours(0, 0, 1);
        let date_end = new Date().setHours(23, 59, 59)
        data[0].forEach((el: Transaction) => {
          if (el.costTypeId != null) {
            this.total_depense += el.amount
          }
          if (el.orderId != null && el.orderId.active) {
            this.total_sales += el.amount
            this.nbr_orders++;
          }
          if (el.buyingId != null && el.buyingId.active) {
            this.totale_buying += el.amount
            this.nbr_buyings++;
          }
          if (el.buyingId != null && el.buyingId.active && el.transactionTypeId?.coefficient == 2) {
            this.total_buying_credit += el.amount
          }
          if (el.orderId != null && el.orderId.active && el.transactionTypeId?.coefficient == -2) {
            this.total_sales_credit += el.amount
          }
          let isValid = new Date(el.createdAt).getTime() >= date_start &&
            new Date(el.createdAt).getTime() <= date_end
          if (isValid && el.orderId != null && el.transactionTypeId?.coefficient == 1) {
            this.caisse_jour += el.amount
          }
        })
        let res = 0
        for (let i = 0; i <= 11; i++) {
          let tmp = new ChiffreAffaire();
          tmp.amount = 0
          let date_debut = new Date(new Date(new Date().setMonth(i, 1)).setHours(0, 0, 1))
          let nbr_day = 0
          if (i % 2 > 0) {
            nbr_day = 30
          }
          else if (i == 1) {
            if (new Date().getFullYear() % 4 == 0) {
              nbr_day = 28
            } else {
              nbr_day = 29
            }
          } else {
            nbr_day = 31
          }
          let date_end = new Date(new Date(new Date().setMonth(i, nbr_day)).setHours(23, 59, 59))
          data[0].forEach((el: Transaction) => {
            if (el.orderId != null && el.orderId.active) {
              let isValid = new Date(el.createdAt).getTime() >= date_debut.getTime() &&
                new Date(el.createdAt).getTime() <= date_end.getTime()
              tmp.month = i
              if (isValid) {
                tmp.amount += el.amount
                res += el.amount
              }
            }
          })
          this.chiffre_affaires.push(tmp)
        }
        this.chartAffaires()
      },
      err => {
        console.error('Observer got an error: ' + err)
      }
    );
  }
  getInvoiceNumber() {
    let filtre = new FilterDto()
    if (this.isAdmin) {
      filtre.where = { "entrepriseId": this.id_entreprise, "isDeleted": false, "active": true }
    } else {
      filtre.where = { "entrepriseId": this.id_entreprise, "pointId": this.point_id, "isDeleted": false, "active": true }
    }
    this.invoiceService.getInvoices(filtre).subscribe(
      data => {
        this.nbr_invoice += data[1]
      }
    )
  }
  getDevisNumber(filter) {
    this.quotationService.getQuotations(filter).subscribe(
      data => {
        this.nbr_devis = data[1]
      }
    )
  }
  getUserInfo() {
    let id_user = parseInt(getValueFromToken("id"))
    let filter = new FilterDto()
    filter.where = { "id": id_user, "entrepriseId": this.id_entreprise }
    if (this.userRole == "writer") {
      filter.relations = ["cratesId", "cratesId.pointid", "cratesId.pointid.storeId"]
      this.settingService.getUserInfo(filter).subscribe(
        data => {
          if (data[0][0] != undefined) {
            this.refreshUserAutorisation(id_user)
            this.point_id = data[0][0].cratesId.pointid.id
            this.filter = new FilterDto()
            this.filter.relations = ["transactionTypeId", "costTypeId", "pointId", "orderId", "buyingId", "userId"]
            this.filter.where = { "pointId": this.point_id, "entrepriseId": this.id_entreprise }
            if (this.userRole === "writer"){
              this.filter.where.createdBy = this.current_user
            }
            this.getTransactions(this.filter);
            let filtre = new FilterDto()
            filtre.relations = ["pointId"]
            filtre.where = { "entrepriseId": this.id_entreprise, "createdBy": this.current_user, "pointId": this.point_id, }
            this.getInvoiceNumber()
            this.getDevisNumber(filtre)
          }
        }
      )
    } else if (this.userRole == "responsable") {
      filter.relations = ["pointId", "pointId.storeId"]
      this.settingService.getUserInfo(filter).subscribe(
        data => {
          if (data[0][0] != undefined) {
            this.refreshUserAutorisation(id_user)
            this.point_id = data[0][0].pointId.id
            this.filter = new FilterDto()
            this.filter.relations = ["transactionTypeId", "costTypeId", "pointId", "orderId", "buyingId", "userId"]
            this.filter.where = { "pointId": this.point_id, "entrepriseId": this.id_entreprise }
            if (this.userRole === "writer"){
              this.filter.where.createdBy = this.current_user
            }
            this.getTransactions(this.filter);
            let filtre = new FilterDto()
            filtre.relations = ["pointId"]
            filtre.where = { "entrepriseId": this.id_entreprise, "pointId": this.point_id, }
            this.getInvoiceNumber()
            this.getDevisNumber(filtre) 
          }
        }
      )
    }
    else if (this.userRole == "carrier") {
      filter.relations = ["pointId", "pointId.storeId"]
      this.settingService.getUserInfo(filter).subscribe(
        data => {
          if (data[0][0] != undefined) {
            this.refreshUserAutorisation(id_user)
            this.point_id = data[0][0].pointId.id
            this.filter = new FilterDto()
            this.filter.relations = ["transactionTypeId", "costTypeId", "pointId", "orderId", "buyingId", "userId"]
            this.filter.where = { "pointId": this.point_id, "createdBy": this.current_user, "entrepriseId": this.id_entreprise }
            this.getTransactions(this.filter);
            let filtre = new FilterDto()
            filtre.relations = ["pointId"]
            filtre.where = { "entrepriseId": this.id_entreprise, "createdBy": this.current_user, "pointId": this.point_id, }
            this.getInvoiceNumber()
            this.getDevisNumber(filtre)
          }
        }
      )
    }
  }

  refreshUserAutorisation(id_user: number) {
    this.userService.getUserAutorisation(
      id_user
    ).subscribe(
      data => {
        let autorisationExist = localStorage.getItem("authorisation") != undefined
        if (autorisationExist) {
          localStorage.removeItem("authorisation")
        }
        let userAutorisation = data[0][0].autorisationId
        localStorage.setItem("authorisation", JSON.stringify(userAutorisation))
      }
    )
  }
  reloadData() {
    window.location.reload();
  }

  navigateTo(route: string) {
    this.router.navigateByUrl('/' + route);
  }

  setDropCaisse() {
    this.detailsCaisse = !this.detailsCaisse
  }
  setDropDepenses() {
    this.detailsDepenses = !this.detailsDepenses
  }
  setDropDevis() {
    this.detailsDevis = !this.detailsDevis
  }
  setDropFactures() {
    this.detailsFactures = !this.detailsFactures
  }
  setDropTtVentes() {
    this.detailsTtVentes = !this.detailsTtVentes
  }
  setDropTtAchats() {
    this.detailsTtAchats = !this.detailsTtAchats
  }
  setDropNbrVentes() {
    this.detailsNbrVentes = !this.detailsNbrVentes
  }
  setDropNbrAchats() {
    this.detailsNbrAchats = !this.detailsNbrAchats
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let elementCaisse = document.getElementById('fasCaisse');
    let elementDepenses = document.getElementById('fasDepenses');
    let elementDevis = document.getElementById('fasDevis');
    let elementFactures = document.getElementById('fasFactures');
    let elementTtVentes = document.getElementById('fasTtVentes');
    let elementTtAchats = document.getElementById('fasTtAchats');
    let elementNbrVentes = document.getElementById('fasNbrVentes');
    let elementNbrAchats = document.getElementById('fasNbrAchats');
    if (event.target !== elementCaisse) {
      this.detailsCaisse = false
    }
    if (event.target !== elementDepenses) {
      this.detailsDepenses = false
    }
    if (event.target !== elementDevis) {
      this.detailsDevis = false
    }
    if (event.target !== elementFactures) {
      this.detailsFactures = false
    }
    if (event.target !== elementTtVentes) {
      this.detailsTtVentes = false
    }
    if (event.target !== elementTtAchats) {
      this.detailsTtAchats = false
    }
    if (event.target !== elementNbrVentes) {
      this.detailsNbrVentes = false
    }
    if (event.target !== elementNbrAchats) {
      this.detailsNbrAchats = false
    }
  }
  routerLinkCaisse() {
    this.router.navigate(['/finance/transaction'], { queryParams: { todayCaisse: true } });
  }
  routerLinkDepenses() {
    this.router.navigate(['/finance/cost']);
  }
  routerLinkDevis() {
    this.router.navigate(['/sales/quotation']);
  }
  routerLinkFactures() {
    this.router.navigate(['/sales/invoice']);
  }
  routerLinkTtVentes() {
    this.router.navigate(['/sales/order']);
  }
  routerLinkTtAchats() {
    this.router.navigate(['/buying']);
  }
  routerLinkNbrVentes() {
    this.router.navigate(['/sales/order']);
  }
  routerLinkNbrAchats() {
    this.router.navigate(['/buying']);
  }
}
