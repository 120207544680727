import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Detail } from './detail';
import { DetailService } from './detail.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css', ]
})
export class DetailComponent implements OnInit {

  details: Detail[];
  selectedDetail: Detail
  count: number;
  showAlert: boolean;
  loading: boolean;
  filter: FilterDto;
  ascSort: ClrDatagridSortOrder;
  whereFindOperator: WhereFindOperator<Detail> = new WhereFindOperator<Detail>()
  showDetail: boolean;
  currentLang: string;
  id_entreprise: number
  userRole: string
  lg = "rtl"
  x = false;
  y: string;
  constructor(
    public translate: TranslateService, private detailService: DetailService, private filterService: FilterService) {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.y = localStorage.getItem('y');
    this.translate.use(this.currentLang);
  }

  ngOnInit() {
    this.ascSort = ClrDatagridSortOrder.ASC;
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getDetails({ where: { "entrepriseId": this.id_entreprise } })
  }



  reloadData() {
    window.location.reload();
  }

  
  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;
    this.filter = this.filterService.bind(state);
    this.whereFindOperator.key = this.filterService.dateSearch(state)
    if (this.whereFindOperator.key) {
      this.filter.where = Object.assign(this.filter.where, this.whereFindOperator.key)
    }
    this.filter.where = Object.assign(this.filter.where.valueOf(), { active: true })
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.getDetails(this.filter);
  }

  getDetails(filter: FilterDto<Detail>) {
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        this.count = data[1];
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }

  delete(detail: Detail) {
    this.selectedDetail = detail
    this.showAlert = true;
  }
  detail(detail: Detail) {
    this.selectedDetail = detail
    this.showDetail = true;
  }

  close() {
    this.getDetails(this.filter);
    this.showAlert = false;
    this.showDetail = false;
  }
}


