import { Component, Input } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { Subject } from 'rxjs';
import { Category } from 'src/app/catalog/category/category';
import { CategoryService } from 'src/app/catalog/category/category.service';
import { Detail } from 'src/app/catalog/detail/detail';
import { DetailService } from 'src/app/catalog/detail/detail.service';
import { Mark } from 'src/app/catalog/mark/mark';
import { MarkService } from 'src/app/catalog/mark/mark.service';
import { Product, QuantityProduct } from 'src/app/catalog/product/product';
import { ProductService } from 'src/app/catalog/product/product.service';
import { Tax } from 'src/app/catalog/tax/tax';
import { TaxService } from 'src/app/catalog/tax/tax.service';
import { Unity } from 'src/app/catalog/unity/unity';
import { UnityService } from 'src/app/catalog/unity/unity.service';
import { FilterDto } from 'src/app/filter.dto';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';

@Component({
  selector: 'app-combobox-filter',
  templateUrl: './combobox-filter.component.html',
  styleUrls: ['./combobox-filter.component.css']
})
export class ComboboxFilterComponent implements ClrDatagridFilterInterface<[]> {
  @Input() properties: string;
  @Input() table: string
  changes = new Subject<any>();
  categories: Category[] = [];
  points: Points[] = [];
  products: QuantityProduct[];
  marks: Mark[] = [];
  unities: Unity[] = [];
  taxs: Tax[] = [];
  details: Detail[] = [];
  filter: FilterDto = new FilterDto()
  saisie: string;
  loading: boolean;
  search: boolean = false
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  store_id: number
  constructor(private categoryService: CategoryService, private productService: ProductService, private markService: MarkService, private unityService: UnityService,
    private taxService: TaxService, private detailService: DetailService,
    private servicePoint: SalesPointService, private settingService: SettingService) {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
  }
  getStoreId() {
    if (this.isAdmin) {
      let filter = new FilterDto()
      filter.where = { "entrepriseId": this.id_entreprise, "name": "point de vente principale" }
      filter.relations = ["storeId"]
      this.servicePoint.getPoints(filter).subscribe(
        data => {
          if (data[1] > 0) {
            this.store_id = data[0][0].storeId.id
            this.filter = new FilterDto()
            this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
            this.filter.where = { "entrepriseId": this.id_entreprise }
            this.filter.relations = ["taxId"];
            this.getProducts(this.store_id, this.filter);
          }
        }
      )
    } else {
      const id_user = parseInt(getValueFromToken("id"))
      let filter = new FilterDto()
      filter.where = { "id": id_user, "entrepriseId": this.id_entreprise }
      filter.relations = ["cratesId","cratesId.pointid", "cratesId.pointid.storeId"]
      this.settingService.getUserInfo(filter).subscribe(
        data => {
          if (data[1] > 0) {
            this.store_id = data[0][0].cratesId.pointid.storeId.id
            this.filter = new FilterDto()
            this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
            this.filter.where = { "entrepriseId": this.id_entreprise }
            this.filter.relations = ["taxId"];
            this.getProducts(this.store_id, this.filter);
          }
        }
      )
    }
  }
  onSearchChange(args?: string) {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.search = false
    this.loading = true
    this.filter.where = { "entrepriseId": this.id_entreprise }
    if (args) {
      this.search = true;
      this.saisie = "*" + args + "*"
      if (this.properties == 'name') {
        this.filter.where = { name: { type: "like", value: this.saisie } }
      } else {
        if (this.properties == 'description') {
          this.filter.where = { description: { type: "like", value: this.saisie } }

        } else {
          if (this.properties == 'reference') {
            this.filter.where = { reference: { type: "like", value: this.saisie } }
          } else {
            if (this.properties == 'type') {
              this.filter.where = { type: { type: "like", value: this.saisie } }
            }
          }
        }
      }

      if (this.table == "product") {
        this.filter.select = ["reference", "name"];
        this.getProducts(this.store_id, this.filter)
      } else {
        if (this.table == "categories") {
          this.filter.select = ["name", "description"];
          this.getCategories(this.filter)
        } else {
          if (this.table == "mark") {
            this.filter.select = ["name"];
            this.getCategories(this.filter)
          }
          else {
            if (this.table == "unity") {
              this.filter.select = ["name"];
              this.getUnities(this.filter)
            } else {
              if (this.table == "tax") {
                this.filter.select = ["name"];
                this.getTaxs(this.filter)
              } else {
                if (this.table == "detail") {
                  this.filter.select = ["name", "type"];
                  this.getDetails(this.filter)
                } else {
                  if (this.table == "ponits") {
                    this.filter.select = ["name", "description"];
                    this.getPoints(this.filter)
                  }
                }
              }
            }
          }
        }
      }
    }
    else {
      this.search = false
      this.filter.take = 15;

      if (this.table == "product") {
        if (this.properties == 'reference') {
          this.filter.where = { reference: undefined }
        } else {
          this.filter.where = { name: undefined }
        }
        this.getProducts(this.store_id, this.filter)
      }
      else {
        if (this.table == "categories") {
          if (this.properties == 'name') {
            this.filter.where = { name: undefined }
          } else {
            this.filter.where = { description: undefined }
          }
          this.getCategories(this.filter)
        }
        else {
          if (this.table == "mark") {
            this.filter.where = { name: undefined }
            this.getMarks(this.filter)
          } else {
            if (this.table == "unity") {
              this.filter.where = { name: undefined }
              this.getUnities(this.filter)
            } else {
              if (this.table == "tax") {
                this.filter.where = { name: undefined }
                this.getTaxs(this.filter)
              } else {
                if (this.table == "detail") {
                  if (this.properties == 'type') {
                    this.filter.where = { type: undefined }
                  } else {
                    this.filter.where = { name: undefined }
                  }
                  this.getDetails(this.filter)
                }
                else {
                  if (this.table == "ponits") {
                    if (this.properties == 'name') {
                      this.filter.where = { description: undefined }
                    } else {
                      this.filter.where = { name: undefined }
                    }
                    this.getPoints(this.filter)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  getCategories(filter: FilterDto<Category>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.categoryService.getCategories(filter).subscribe(
      data => {
        this.categories = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  getProducts(store_id: number, filter: FilterDto<Product>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.productService.getInfoProducts(store_id, filter).subscribe(
      data => {
        this.products = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  getMarks(filter: FilterDto<Mark>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.markService.getMarks(filter).subscribe(
      data => {
        this.marks = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }
  getUnities(filter: FilterDto<Unity>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.unityService.getUnities(filter).subscribe(
      data => {
        this.unities = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }

  getTaxs(filter: FilterDto<Tax>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }
  getDetails(filter: FilterDto<Detail>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        if (this.properties == 'type') {
          for (var i = 0; i < this.details.length; i++) for (var j = i + 1; j < this.details.length; j++)
            if (this.details[j].type == this.details[i].type) {
              this.details.splice(j, 1);
              j--;
            }
        }
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }
  getPoints(filter: FilterDto<Points>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.servicePoint.getPoints(filter).subscribe(
      data => {
        this.points = data[0];
        this.changes.next(true);
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  accepts(): boolean {
    return true;
  }
  isActive(): boolean {
    return this.search;
  }
}
