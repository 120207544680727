import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-success-modal',
  templateUrl: './alert-success-modal.component.html',
  styleUrls: ['./alert-success-modal.component.css']
})
export class AlertSuccessModalComponent implements OnInit {
  @Input() msg: string
  constructor() { }

  ngOnInit(): void {
    this.msg = "marque ajouté avec succès"
  }

}
