import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/catalog/product/product';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from 'src/app/parts/parts';
import { Entreprise } from 'src/app/user/entreprise';
import { Devise, User } from 'src/app/user/user';
import { environment } from 'src/environments/environment';
import { Quotation, QuotationDetail } from './quotation';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  constructor(private http: HttpClient) { }
  getQuotations(filter: FilterDto<Quotation>): Observable<[Quotation[], number]> {
    return this.http.get(environment.api + '/quotations?filter=' + JSON.stringify(filter)) as Observable<[Quotation[], number]>;
  }
  getQuotationDetails(filter: FilterDto<QuotationDetail>): Observable<[QuotationDetail[], number]> {
    return this.http.get(environment.api + '/quotationsDetails?filter=' + JSON.stringify(filter)) as Observable<[QuotationDetail[], number]>
  }
  getCustomers(filter: FilterDto<Customer>): Observable<[Customer[], number]> {
    return this.http.get(environment.api + '/customers?filter=' + JSON.stringify(filter)) as Observable<[Customer[], number]>;
  }

  getProducts(filter: FilterDto<Product>): Observable<[Product[], number]> {
    return this.http.get(environment.api + '/products?filter=' + JSON.stringify(filter)) as Observable<[Product[], number]>;
  }

  postQuotation(quotation: Quotation): Observable<Quotation> {
    return this.http.post<Quotation>(environment.api + '/quotation', quotation)
  }

  deleteQuotation(ID: number) {
    return this.http.delete(environment.api + '/quotation/' + JSON.stringify(ID));
  }

  deleteQuotationDetail(ID: number) {
    return this.http.delete(environment.api + '/quotationdetail/' + JSON.stringify(ID));
  }

  getUser(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>;
  }
  getDevises(filter: FilterDto<any>): Observable<[Devise[], number]> {
    return this.http.get(environment.api + '/devises?filter=' + JSON.stringify(filter)) as Observable<[Devise[], number]>;
  }
  getEntreprise(filter: FilterDto<Entreprise>): Observable<[Entreprise[], number]> {
    return this.http.get(environment.api + '/entreprises?filter=' + JSON.stringify(filter)) as Observable<[Entreprise[], number]>;
  }
  patchEntreprise(id: number, Entreprise: Entreprise): Observable<Entreprise> {
    return this.http.patch<Entreprise>(environment.api + '/entreprise/' + JSON.stringify(id), Entreprise);
  }
  patchQuotation(ID: number, quotation: Quotation): Observable<Quotation> {
    return this.http.patch<Quotation>(environment.api + '/quotation/' + JSON.stringify(ID), quotation);
  }

  // sendMail(mail){
  //   return this.http.post( "https://iotech-mailer.ioc.tn/api/mails", mail)
  // }
  sendMail(mail) {
    return this.http.post(environment.api + '/send', mail)
  }
  sendMailReset(mail) {
    return this.http.post(environment.api + '/sendreset', mail)
  }
}
