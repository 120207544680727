import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken } from 'src/app/user/user.service';
import { Companys } from '../../parts';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})
export class CompanyDetailComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() companyId: number
  showAlert: boolean;
  companyObject: Companys
  id_entreprise: number
  userAdd: any
  userEdit: any
  constructor(private partsService: PartsService, private settingService: SettingService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    let filter: FilterDto = new FilterDto()
    filter.relations = ["contacts", "adress"];
    filter.where = { "id": this.companyId, "entrepriseId": this.id_entreprise };
    this.partsService.getCompanys(filter).subscribe(
      data => {
        this.companyObject = data[0][0]
        if (this.companyObject.createdBy) {
          if (!this.companyObject.byAdmin) {
            this.settingService.getUser(this.companyObject.createdBy).subscribe(
              data => {
                this.userAdd = data
              }
            )
          } else {
            let filter = new FilterDto()
            filter.where = { "id": this.id_entreprise }
            this.settingService.getAllEntreprise(filter).subscribe(
              data => {
                this.userAdd = data[0][0]
              }
            )
          }
        }
        if (this.companyObject.updatedBy) {
          if (!this.companyObject.updatebyAdmin) {
            this.settingService.getUser(this.companyObject.updatedBy).subscribe(
              data => {
                this.userEdit = data
              }
            )
          } else {
            let filter = new FilterDto()
            filter.where = { "id": this.id_entreprise }
            this.settingService.getAllEntreprise(filter).subscribe(
              data => {
                this.userEdit = data[0][0]
              }
            )
          }
        }
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  closeAction() {
    this.closed.emit(true);
  }
   

}
