import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { getValueFromToken } from 'src/app/user/user.service';
import { Detail } from '../detail';
import { DetailService } from '../detail.service';

@Component({
  selector: 'app-detail-add',
  templateUrl: './detail-add.component.html',
  styleUrls: ['./detail-add.component.css']
})
export class DetailAddComponent implements OnInit {
  showAlert: boolean;
  creerNouveau: boolean = false;
  id_entreprise: number;
  real_id: number


  constructor(private detailService: DetailService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.detailService.getDetails({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }

  submit(detail: Detail, form: NgForm) {
    detail.real_id = this.real_id
    detail.entrepriseId = this.id_entreprise;
    this.detailService.addDetail(detail)
      .subscribe(
        data => {
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.resetForm(form);
          } else {
            this.creerNouveau = true;
          }
        })
  }


  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false;
  }
}
