<h3 *ngIf="!edit">Ajout autorisation pour l'utilisateur <b><u>{{user?.name}}</u></b></h3>
<h3 *ngIf="edit">Modification des autorisation pour l'utilisateur <b><u>{{user?.name}}</u></b></h3>
<div *ngIf="showSuccess" class="alert alert-success" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
            </div>
            <span class="alert-text">
                {{successMsg}}
            </span>
        </div>
    </div>
    <button type="button" class="close" (click)="showSuccess = false " aria-label="Close">
        <clr-icon aria-hidden="true" shape="close"></clr-icon>
    </button>
</div>
<div *ngIf="showError" class="alert alert-danger" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                </svg>
            </div>
            <span class="alert-text">
                {{errorMsg}}
            </span>
        </div>
    </div>
    <button type="button" class="close" (click)="showError = false " aria-label="Close">
        <clr-icon aria-hidden="true" shape="close"></clr-icon>
    </button>
</div>
<br>
<button class="btn btn-primary" (click)="submit()">Enregistrer</button>

<div>
    <table class="table">
        <thead>
            <th>
                Autorisation
            </th>
            <th>
                Consultation
            </th>
            <th>
                Ajout
            </th>
            <th>
                Modifier
            </th>
            <th>
                Supprimer
            </th>
            <th>
                Impression
            </th>
            <th>
                Tout sélectionner
                <input (change)="selectAll()" [(ngModel)]="selected_all" type="checkbox" name="consultation">
            </th>
        </thead>
        <tbody>
            <tr>
                <td>
                    Article
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuArticle" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addArticle"  type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editArticle" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteArticle" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printArticle" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="articleLine()" [(ngModel)]="articleLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Produit Rebut
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuExpiredProd" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addExpiredProd" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editExpiredProd" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteExpiredProd" type="checkbox" name="consultation">
                </td>
                <td>
                    <input type="checkbox" disabled name="consultation">
                </td>
                <td>
                    <input (change)="expiredProductLine()" [(ngModel)]="expiredProductLineState"  type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Ventes
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuSales" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addSales" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editSales" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteSales" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printSales" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="salesLine()" [(ngModel)]="salesLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Achats
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuBuying" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addBuying" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editBuying" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteBuying" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="buyingLine()" [(ngModel)]="buyingLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Caisse
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCrates" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addCrates" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editCrates" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteCrates" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="caisseLine()" [(ngModel)]="caisseLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Devis
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuQuotation" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addQuotation" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editQuotation" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteQuotation" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printQuotation" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="quotationLine()" [(ngModel)]="quotationLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Factures
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuInvoice" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addInvoice" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editInvoice" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printInvoice" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="invoiceLine()" [(ngModel)]="invoiceLineState"  type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Bon de sortie
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuExitVoucher" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addExitVoucher" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteExitVoucher" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printExitVoucher" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="exitVoucherLine()" [(ngModel)]="exitVoucherLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Bon de livraison
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuSalesDelivery" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printSalesDelivery" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="salesDeliveryLine()" [(ngModel)]="salesDeliveryLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Livraison
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuLivraison" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addLivraison" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editLivraison" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteLivraison" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printLivraison"  type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="LivraisonLine()" [(ngModel)]="LivraisonLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Véhicules
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuVehicule" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addVehicule" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editVehicule" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteVehicule" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="vehiculeLine()" [(ngModel)]="vehiculeLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Livreur
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuLivreur" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addLivreur" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editLivreur" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteLivreur" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="livreurLine()" [(ngModel)]="livreurLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Dépots
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuDepot" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addDepot" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editDepot" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteDepot" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="depotLine()" [(ngModel)]="depotLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Mouvement
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuMouvement" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuMouvement" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Inventaires
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuInventaire" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addInventaire" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editInventaire" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteInventaire" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="inventaireLine()" [(ngModel)]="inventaireLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Transaction
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuTransaction" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addTransaction" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printTransaction" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="transactionLine()" [(ngModel)]="transactionLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Crédit
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCredit" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addCredit" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printCredit" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="creditLine()" [(ngModel)]="creditLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Mes Caisse 
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCaisseToday"  type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled  type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printCaisseToday" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="caisseTodayLine()" [(ngModel)]="caisseTodayLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Chèque & cheque de garantie
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCheque" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editChequeGarantie" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteChequeGarantie" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="chequeLine()" [(ngModel)]="chequeLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Dépense
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCost" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addCost" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editCost" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteCost" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="costLine()" [(ngModel)]="costLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Plus value
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuPlusValue" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addPlusValue" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editPlusValue" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deletePlusValue" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="plusValueLine()" [(ngModel)]="plusValueLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Comptabilite
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuRetenueSource" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuRetenueSource"  type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Clients
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCustomer" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addCustomer" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editCustomer" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteCustomer" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="customerLine()" [(ngModel)]="customerLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Fournisseur
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuProvider" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addProvider" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editProvider" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteProvider" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printProvider" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="providerLine()" [(ngModel)]="providerLineState"  type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Banque
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuBanque" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addBanque" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editBanque" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteBanque" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="bankLine()" [(ngModel)]="bankLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Société de livraison
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCompany " type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addCompany" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editCompany" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteCompany" type="checkbox" name="consultation">
                </td>
                <td>
                    <input disabled type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="companyLine()" [(ngModel)]="companyLineState" type="checkbox" name="consultation">
                </td>
            </tr>
            <tr>
                <td>
                    Carte Fidelité
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.menuCarteFidelite " type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.addCarteFidelite" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.editCarteFidelite" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.deleteCarteFidelite" type="checkbox" name="consultation">
                </td>
                <td>
                    <input [(ngModel)]="autorisationModel.printCarteFidelite" type="checkbox" name="consultation">
                </td>
                <td>
                    <input (change)="carteFideliteLine()" [(ngModel)]="carteFideliteLineState" type="checkbox" name="consultation">
                </td>
            </tr>
        </tbody>
    </table>
</div>