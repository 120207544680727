import { AfterContentChecked, ChangeDetectorRef, Component,  HostListener, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { ConfigService } from './shared/config/config.service';
import { tokenGetter } from './user/user.service';
// import '@clr/icons/shapes/core-shapes';
// import '@clr/icons/shapes/essential-shapes';
// import '@clr/icons/shapes/technology-shapes';
import '@clr/icons';
import '@clr/icons/shapes/all-shapes';
import { LoaderService } from './core/loader/loader.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', ]
})
export class AppComponent implements OnInit, AfterContentChecked {
  showMenu = false;
  userRole: string;
  clrVerticalNavCollapsed: boolean;
  lg: boolean = true;
  arabicLanguage: boolean = false
  openSidebarArabic: Subject<any> = new Subject();
  showCaisse: boolean = false
  showContactUs: boolean = true
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    if (event.target.innerWidth < 992) {
      this.clrVerticalNavCollapsed = true
    }
    else {
      this.clrVerticalNavCollapsed = false
    }
  }
  @HostListener('window:offline', ['$event'])
  checkConnection(event: any) {
    alert("merci de vérifier votre connexion internet ")
  }

  constructor(private router: Router, private configService: ConfigService,
    public loadService: LoaderService, private cdr: ChangeDetectorRef,
     public translate: TranslateService,private primengConfig : PrimeNGConfig) {

  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges()
  }

  ngOnInit() {
    this.primengConfig.ripple = true
    let count = 0
    let url = this.router.events.subscribe((el: any) => {
      if (el.url) {
        if (el.url.indexOf("user/login") != -1) {
          count++;
        }
        let checkUrlReset = el.url.indexOf("/user/reset-password")
        let checkUrlActivate = el.url.indexOf("/user/activateAccount")
        if (tokenGetter().length == 0 && checkUrlActivate == -1 && checkUrlReset == -1 && count == 0) {
          this.router.navigate(['/user/login']);
          this.lg = false
        }
      }
    })
    this.router.events.subscribe((event: any) => {
      if (event.url === '/caisse'){
        this.showCaisse = true
        this.showContactUs = false
      }
      if (event.url) {
        let checkUrlReset = event.url.indexOf("/user/reset-password")
        let checkUrlActivate = event.url.indexOf("/user/activateAccount")
        if (event.url.indexOf("user/login") != -1) {
          count++;
        }
        if (tokenGetter().length == 0 && checkUrlActivate == -1 && checkUrlReset == -1 && count == 0) {
          this.router.navigate(['/user/login']);
          this.lg = false
        } else
          if ((event.url === '/user/login' || event.url.indexOf("/user/activateAccount") != -1 || event.url.indexOf('/user/reset-password?') != -1 || event.url.indexOf('/sales/quotation/print') != -1 || event.url.indexOf('/sales/order/print') != -1 || event.url.indexOf('/transport/print') != -1 || event.url.indexOf('/finance/transaction/print') != -1
            || event.url.indexOf('/finance/crates/print') != -1 || event.url.indexOf('/finance/cheque/print') != -1 || event.url.indexOf('/sales/invoice/print') != -1
            || event.url.indexOf('/user/sign-in') != -1 || event.url.indexOf('venteticket/print/') != -1 ||
            event.url.indexOf('/product/printList') != -1 || event.url.indexOf('/caisse') != -1 || event.url.indexOf('/customer/printList') != -1
            || event.url.indexOf('/provider/printList') != -1 || event.url.indexOf('/exit-voucher/print') != -1 || event.url.indexOf('/sales-delivery/print') != -1)
            || event.url.indexOf("/autorisations/detail") != -1 || event.url.indexOf("carte-fidelite/print") != -1
            || !this.lg || tokenGetter().length == 0) {
          this.showMenu = false;
          this.showContactUs = false
        } else if (tokenGetter().length > 0) {
          this.showMenu = true;
        }
      }
      if (event.url === '/user/login'){
        this.showContactUs = true
      }
    });
    this.userRole = this.configService.getRole()
    this.clrVerticalNavCollapsed = false
    if (window.innerWidth < 992) {
      this.clrVerticalNavCollapsed = true
    } else {
      this.clrVerticalNavCollapsed = false
    }
    this.EventLang();
  }
  EventLang() {
    let lang = localStorage.getItem('currentLang')
    if (lang == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  changeEventLang(event: any) {
    this.translate.use(event.target.value);
    if (event.target.value == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  OpenSidebar() {
    this.openSidebarArabic.next();
  }
}
