<div class="container">
  <div class="slider-picture" *ngIf="imageObject.length > 0">
    <ng-image-slider #nav [images]="imageObject" [infinite]="true" [autoSlide]="1"
      [imageSize]="{width: '100%', height: 'auto'}" slideImage="1"></ng-image-slider>
  </div>
</div>

<!-- ngx carousel slider  -->
<!-- <hr style="background-color:black" />
<div class="row">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
            <img src="{{ image.url }}" width="100%">
        </div>
    </ngx-slick-carousel>
</div> -->