<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Détail de banque</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <div class="clr-row" *ngIf="bankObject">
          <div class="clr-col-3">
            <span><b>Id </b></span><br>
            <span><b>Nom </b></span><br>
            <span><b>Compte </b></span><br>
            <span><b>Status </b></span><br>
            <span><b>Création par</b></span><br>
            <span *ngIf="bankObject.updatedBy && userEdit"><b>Modification par</b></span>
          </div>
          <div class="clr-col-9">
            <span>{{bankObject.real_id}}</span><br>
            <span>{{bankObject.name}}</span><br>
            <span>{{bankObject.account}}</span><br>
            <span class="label label-success">Activée</span><br>
            <span *ngIf="bankObject.createdBy && userAdd">
              <strong>{{userAdd?.name}}</strong> à <strong>{{bankObject?.createdAt | date:
                'dd/MM/yyyy'}}</strong>
            </span>
            <br>
            <span *ngIf="bankObject.updatedBy && userEdit">
              <strong>{{userEdit?.name}}</strong> à <strong>{{bankObject?.updatedAt | date: 'dd/MM/yyyy'
                }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>