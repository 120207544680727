import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PartsService } from 'src/app/parts/parts.service';
import { FilterService } from 'src/app/shared/filter.service';
import { Chart } from 'chart.js';
import { FilterDto } from 'src/app/filter.dto';
import { Customer, Statestiques } from 'src/app/parts/parts';
import { ConfigService } from 'src/app/shared/config/config.service';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-customer-statestique',
  templateUrl: './customer-statestique.component.html',
  styleUrls: ['./customer-statestique.component.css']
})
export class CustomerStatestiqueComponent implements OnInit {
  @Input() customerId: number
  transactionArray = [];
  statestique: Statestiques = new Statestiques()
  @ViewChild('trChart') trChart;
  categorieChart: Chart;
  paiementChart: Chart;
  year: number
  customerObject: Customer;
  dateList: { date: number; }[];
  financeChart: any;
  buyingLabel = []
  buyingValues = []
  otherAmount: number = 0;
  tabValues = []
  tabLabel = []
  soldeTab = []
  cfTable = []
  data: any;
  months = ['janvier', 'février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  tabColor: string[];
  tabLabelPaiement: string[];
  tabColorPaiement: string[];
  id_entreprise: number
  devise: string

  constructor(
    private partsService: PartsService, private configService: ConfigService,
    private filterService: FilterService,) {
  }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.configService.getEntrepriseInfo({
      where: { "id": this.id_entreprise }, relations: ["deviseId"]
    }).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code
      }
    )
    let filter: FilterDto = new FilterDto();
    filter.relations = ['contacts', 'adress'];
    filter.where = { id: this.customerId };
    this.partsService.getCustomers(filter).subscribe(
      loaded => {
        this.customerObject = loaded[0][0];
        this.loadStatestique();
      },
      (err) => console.error('Observer got an error: ', err)
    );
    this.dateFilterList()
    this.getALLchart();
  }
   
  loadStatestique() {
    this.partsService.getCustomerDetails(this.customerId, this.year).subscribe(
      data => {
        this.data = data
        if (this.data.solde[0]) { this.statestique.solde = this.data.solde[0].solde; }
        if (this.data.cf[0]) { this.statestique.chiffAffaire = this.data.cf[0].totalprice; }
        if (this.data.nbrCmd[0]) { this.statestique.nbrCmd = this.data.nbrCmd[0].commande; }
        if (this.data.cmdNonPaye[0]) { this.statestique.nbrCmdNonPaye = this.data.cmdNonPaye[0].cmdnonpaye; }
        if (this.data.cmdPaye[0]) { this.statestique.nbrCmdPaye = this.data.cmdPaye[0].cmdpaye; }
        if (this.data.livraison[0]) { this.statestique.totalDelivery = this.data.livraison[0].livraision; }
        if (this.data.livre[0]) { this.statestique.livre = this.data.livre[0].livraision; }
        if (this.data.nonlivre[0]) { this.statestique.nonLivre = this.data.nonlivre[0].livraision; }
      },
      (err) => console.error('Observer got an error: ', err),
      () => {
        setTimeout(() => {
          this.getPaiementChart();
          this.getCaregorieChart();
        }, 5);
      }
    );
  }

  changeDate() {
    this.year = +this.year
    this.getALLchart();
  }

  dateFilterList() {
    let date = new Date()
    this.year = date.getFullYear();
    this.dateList = [{ date: this.year }, { date: this.year - 1 }, { date: this.year - 2 }]
  }
  getALLchart() {
    this.partsService.getCustomerChart(this.customerId, this.year).subscribe(
      chart => {
        this.financeChart = chart
        this.getChartTransaction();
        this.financeChart.categorie.forEach(item => {
          this.buyingLabel.push(item.element.name)
          this.buyingValues.push(item.amount)
        });
        for (let i = 0; i < 3; i++) {
          this.tabValues[i] = this.buyingValues[i]
          this.tabLabel[i] = this.buyingLabel[i]
        }
        for (let j = 3; j < this.buyingValues.length; j++) {
          this.otherAmount = this.otherAmount + this.buyingValues[j]
        }
        if (this.financeChart.categorie.length < 3) {
          this.tabValues[this.financeChart.categorie.length] = this.otherAmount
          this.tabLabel[this.financeChart.categorie.length] = 'autre Catégorie'

        } else {
          this.tabValues[3] = this.otherAmount
          this.tabLabel[3] = 'autre Catégorie'
        }
        this.buyingLabel = this.tabLabel.filter(item => item != undefined)
        this.buyingValues = this.tabValues.filter(item => item != undefined)
        this.isEmptyCategorie();
      }
    )
  }
  isEmptyCategorie() {
    if (this.buyingValues[0] == undefined) {
      this.buyingValues = []
      this.buyingLabel = []
      this.tabColor = []
      this.buyingValues[0] = 100
      this.buyingLabel[0] = 'Pas de Transaction'
    } else {
      this.tabColor = ['#FFD700', '#2ECC40', '#f64f89', '#DC143C', '#1E90FF']
    }
  }
  getChartTransaction() {
    this.soldeTab = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.cfTable = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.financeChart.chartTransaction.forEach(element => {
      this.soldeTab[element.month - 1] = this.soldeTab[element.month - 1] + element.solde
    });
    this.financeChart.chartCommande.forEach(element => {
      this.cfTable[element.month - 1] = element.totalprice2
    });

    var canvas = this.trChart.nativeElement;
    var ctx1 = canvas.getContext('2d');
    let transactionChart = new Chart(ctx1, {
      type: 'line',
      data: {
        labels: this.months,
        datasets: [
          {
            label: 'Transaction',
            backgroundColor: 'rgba(255, 99, 132,0.4)',
            borderColor: 'rgb(255, 99, 132)',
            fill: false,
            data: this.soldeTab
          },
          {
            label: 'commande',
            backgroundColor: 'rgba(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            fill: false,
            borderDash: [5, 5],
            data: this.cfTable
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              type: 'linear',
              ticks: {
                userCallback: function (tick) {
                  return tick.toString() + ' TND';
                },
              },
              scaleLabel: {
                labelString: 'Prix',
                display: true,
              },
            },
          ],
        },
      },
    });
  }

  getPaiementChart() {
    if (this.data.trPayee[0] > 0 || this.data.trPayee[1] > 0) {
      this.transactionArray = []
      this.transactionArray.push(this.data.trPayee[0], this.data.trPayee[1]);
      this.tabLabelPaiement = ['Chèque', 'Espése']
      this.tabColorPaiement = ['#87CEEB', '#f64f89']
    } else {
      this.tabLabelPaiement = ['Aucune transaction']
      this.transactionArray = [100]
      this.tabColorPaiement = []
    }
    this.paiementChart = new Chart('canvas2', {
      type: 'doughnut',
      data: {
        labels: this.tabLabelPaiement,
        datasets: [
          {
            label: 'test',
            data: this.transactionArray,
            backgroundColor: this.tabColorPaiement,
          },
        ],
      },
      options: {
        title: {
          display: false,
          text: 'Color test',
        },
        legend: {
          position: 'left',
          display: true,
          fullWidth: true,
          labels: {
            fontSize: 11,
          },
        },
        responsive: true,
      },
    });
  }

  getCaregorieChart() {
    if (this.buyingLabel[0] == "autre Catégorie") {
      this.buyingLabel[0] = "Aucune Vente"
      this.tabColor = []
      this.buyingValues[0] = 100
    }

    this.categorieChart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: this.buyingLabel,
        datasets: [
          {
            label: 'totale',
            data: this.buyingValues,
            backgroundColor: this.tabColor,
          },
        ],
      },
      options: {
        title: {
          display: false,
          text: 'Color test',
        },
        legend: {
          position: 'left',
          display: true,
          fullWidth: true,
          labels: {
            fontSize: 11,
          },
        },
      },
    });
  }
}
