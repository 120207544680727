import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { tokenGetter } from 'src/app/user/user.service';
import { categoryService } from '../../category-service/categoryService';
import { categoryserviceService } from '../../category-service/categoryService.service';

@Component({
  selector: 'app-add-category-service-modal',
  templateUrl: './add-category-service-modal.component.html',
  styleUrls: ['./add-category-service-modal.component.css']
})
export class AddCategoryServiceModalComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<any>();
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  real_id: number
  loading: boolean = true;
  constructor(private categoryserviceService: categoryserviceService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(this.getCookie("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getCategories({ where: { "entrepriseId": this.id_entreprise } })
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  getCategories(filter: FilterDto<categoryService>) {
    this.categoryserviceService.getCategoriesService(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }
  submit(category: categoryService, form: NgForm) {
    category.real_id = this.real_id
    category.entrepriseId = this.id_entreprise
    category.byAdmin = this.isAdmin
    this.categoryserviceService.addCategoryService(category)
      .subscribe(
        data => {
          this.CancelAction();
          this.saved.emit(data);
        })
  }
  CancelAction() {
    this.closed.emit(true)
  }
}
