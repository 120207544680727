import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QuantityProduct } from '../catalog/product/product';
import { FilterDto } from '../filter.dto';
import { Points } from '../sales-point/points';
import { User } from '../user/user';
import { Crates, SessionCrates } from './crates';

@Injectable({
  providedIn: 'root'
})
export class CratesService {

  constructor(private http: HttpClient) { }
  getCrates(filter: FilterDto<Crates>): Observable<[Crates[], number]> {
    return this.http.get(environment.api + '/crates?filter=' + JSON.stringify(filter)) as Observable<[Crates[], number]>;
  }

  async getCratesNumber(filter: FilterDto<Points>): Promise<any> {
    const url = `${environment.api}/crates?filter=${JSON.stringify(filter)}`;
    try {
      const response = await this.http.get(url).toPromise();
      return response;
    } catch (error) {
      // Handle errors here or rethrow the error
      throw error;
    }
  }

  getSalesPoint(filter: FilterDto): Observable<[Points[], number]> {
    return this.http.get(environment.api + "/sales-points?filter=" + JSON.stringify(filter)) as Observable<[Points[], number]>
  }
  addCrates(crate: Crates): Observable<Crates> {
    return this.http.post<Crates>(environment.api + '/crates', crate);
  }
  getCrate(id: number): Observable<Crates> {
    return this.http.get(environment.api + '/crates/' + JSON.stringify(id)) as Observable<Crates>;
  }
  editCrate(ID: number, crate: Crates): Observable<Crates> {
    return this.http.patch<Crates>(environment.api + '/crates/' + JSON.stringify(ID), crate);
  }
  deleteCrate(ID: number): Observable<Crates> {
    return this.http.delete(environment.api + '/crates/' + JSON.stringify(ID));
  }
  getSessionCrates(filter: FilterDto): Observable<[SessionCrates[], number]> {
    return this.http.get(environment.api + '/session?filter=' + JSON.stringify(filter)) as Observable<[SessionCrates[], number]>
  }
  addSessionCrate(model: SessionCrates): Observable<SessionCrates> {
    return this.http.post<SessionCrates>(environment.api + '/session', model)
  }
  patchSessionCrates(id: number, model: SessionCrates): Observable<SessionCrates> {
    return this.http.patch<SessionCrates>(environment.api + '/session/' + JSON.stringify(id), model)
  }
  deleteSessionCrates(id: number) {
    return this.http.delete(environment.api + '/session/' + JSON.stringify(id))
  }
  getUser(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>;
  }
  getInfoProducts(storeId: number, filter: FilterDto): Observable<[QuantityProduct[], number]> {
    return this.http.get(environment.api + '/products/details/' + JSON.stringify(storeId) + '?filter=' + JSON.stringify(filter)) as Observable<[QuantityProduct[], number]>;
  }
}
