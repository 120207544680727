import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { getValueFromToken } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Cashiers } from '../parts';
import { PartsService } from '../parts.service';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.css']
})
export class CashierComponent implements OnInit {

  filter: FilterDto<Cashiers> = new FilterDto<Cashiers>()
  whereFindOperator = new WhereFindOperator()
  descSort: ClrDatagridSortOrder;
  showAlert: boolean;
  cashierArray: Cashiers
  count: number
  loading: boolean
  cashierId: number;
  searchString: string;
  pathName: string;
  showDetail: boolean;
  id_entreprise: number

  constructor(private filterService: FilterService, private partsservice: PartsService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
  }
   
  refresh(state?: ClrDatagridStateInterface) {
    if (state) {
      this.filter = this.filterService.bind(state);
    }
    if (state?.filters) {
      this.whereFindOperator.key = this.filterService.dateSearch(state)
    }
    if (!this.whereFindOperator.key) { this.whereFindOperator.key = undefined }
    let data = { active: true }
    if (!state?.filters) {
      this.filter.where = data
    }
    else {
      this.filter.where = Object.assign(this.filter.where.valueOf(), data)
      if (this.whereFindOperator.key) {
        this.filter.where = Object.assign(this.filter.where.valueOf(), this.whereFindOperator.key)
      }
    }
    if (this.searchString) {
      this.filter.where = Object.assign(this.filter.where.valueOf(), { name: { type: "like", value: "*" + this.searchString + "*" } })
    }
    this.filter.order = { "id": "DESC" }
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.partsservice.getCashiers(this.filter).subscribe(
      data => {
        this.cashierArray = data[0];
        this.count = data[1];
      }
    )
  }
  deleteAction(item) {
    this.cashierId = item.id
    this.showAlert = true;
  }
  detailAction(item) {
    this.cashierId = item.id
    this.showDetail = true;
  }
  closeAction() {
    this.showAlert = false;
    this.showDetail = false;
  }
}
