import { Component, OnInit } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from 'src/app/parts/parts';
import { Commande, CommandeDetail, CommandeProduct, EtatCommande } from '../../Commande';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CommandeService } from '../../Service/commande-service.service';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-commande-edit',
  templateUrl: './commande-edit.component.html',
  styleUrls: ['./commande-edit.component.css']
})
export class CommandeEditComponent implements OnInit {

  msgAlert: string = ""
  showAlertError: boolean = false
  showAlertSuccess: boolean = false
  client: Customer = new Customer();
  etatCommande: EtatCommande = new EtatCommande()
  etatCommandes: EtatCommande[] = []
  tmpEtatCommandes: EtatCommande[] = []
  clients: Customer[] = [];
  filter: FilterDto<Customer> = new FilterDto<Customer>()
  tmpListClients: Customer[] = []
  id_entreprise: number
  commandModel: Commande = new Commande()
  loading: boolean = false
  today_date: string
  id: number
  saved : boolean = false 
  firstLoad : boolean = false 
  firstLoadClient : boolean = false 


  constructor(private _quotationService: QuotationService,
    private _activatedRoute: ActivatedRoute,
    private _commandeService: CommandeService,
  ) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.today_date = new Date().toISOString().slice(0, 10)
    this.getCustomers()
    this.getEtatCommande();
    this._activatedRoute.params.subscribe((param: Params) => {
      this.id = param['id']
      this.getCommande(this.id)
    })
  }


  submitDetail(elementList: CommandeProduct[]) {
    this.commandModel.etatCommandeId = this.etatCommande.id
    this.commandModel.commandeDetails = []
    elementList.forEach(el => {
      let commandeDetailModel = new CommandeDetail()
      commandeDetailModel.entrepriseId = el.entrepriseId
      if (el.productId) {
        commandeDetailModel.productId = el.productId
      } else if (el.serviceId) {
        commandeDetailModel.serviceId = el.serviceId
      }
      commandeDetailModel.quantity = el.quantity
      commandeDetailModel.id = el.id
      commandeDetailModel.entrepriseId = this.id_entreprise
      this.commandModel.entrepriseId = this.id_entreprise;
      this.commandModel.commandeDetails.push(commandeDetailModel);
    })
    this._commandeService.editCommande(this.commandModel.id, this.commandModel)
      .subscribe(
        data => {
          this.showAlertSuccess = true
          this.showAlertError = false
          this.saved = true 
          this.msgAlert = "Commande modifié avec succès"
        }, err => {
          this.showAlertError = true
          this.showAlertSuccess = false
          this.msgAlert = "Erreur lors de la modification de la commande"
        }
      )
  }

  getEtatCommande() {
    this._commandeService.getEtatsCommande({}).subscribe(
      data => {
        this.etatCommandes = data[0]
        console.log(this.etatCommandes)
        this.tmpEtatCommandes = this.etatCommandes
        this.firstLoad = true 
      }
    )
  }

  etatChanged(etat: EtatCommande) {
    this.etatCommande = etat
  }

  getCommande(id: number) {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "id": id, "active": true }
    filter.relations = ["customerId", "commandeDetails", "etatCommandeId",
      "commandeDetails.productId", "commandeDetails.serviceId"]
    this._commandeService.getAllCommande(filter).subscribe(
      data => {
        this.commandModel = data[0][0]
        this.client = this.commandModel.customerId
        this.etatCommande = this.commandModel.etatCommandeId
      }
    )
  }

  clientChanged(item: Customer) {
    this.commandModel.customerId = item.id
  }

  getCustomers() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    filter.relations = ["contacts"]
    this._quotationService.getCustomers(filter).subscribe(
      customers => {
        this.clients = customers[0];
        this.tmpListClients = this.clients
        this.loading = false
        this.firstLoadClient = true 
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  onSearchChange(args?: string) {
    if(this.firstLoadClient){
      this.firstLoadClient = false
      return
    }
    this.loading = true
    if (args == undefined || args?.length == 0 ) {
      this.clients = this.tmpListClients
    } else {
      this.clients = []
      this.tmpListClients.forEach(el => {
        if (el.name.indexOf(args) != -1) {
          this.clients.push(el);
        }
      })
      this.loading = false
    }
  }

  onSearchEtatChange(args?: string) {
    if(this.firstLoad){
      this.firstLoad = false
      return
    }
    this.loading = true
    if (args == undefined || args?.length == 0) {
      this.etatCommandes = this.tmpEtatCommandes
    } else {
      this.etatCommandes = []
      this.tmpEtatCommandes.forEach(el => {
        if (el.name.indexOf(args) != -1) {
          this.etatCommandes.push(el)
        }
      })
      this.loading = false
    }
  }
}
