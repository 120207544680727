import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { Autorisation } from './autorisation';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutorisationService {

  constructor(private http : HttpClient) { }

  getAutorisation(filter : FilterDto): Observable<[Autorisation[],number]>{
    return this.http.get(environment.api+"/autorisations?filter="+JSON.stringify(filter)) as Observable<[Autorisation[],number]>
  }

  addAutorisation(model : Autorisation):Observable<Autorisation>{
    return this.http.post<Autorisation>(environment.api+"/autorisation",model)
  }

  updateAutorisation(id:number,model : Autorisation):Observable<Autorisation>{
    return this.http.patch<Autorisation>(environment.api+"/autorisations/"+JSON.stringify(id),model)
  }

  deleteAutorisation(id : number){
    return this.http.delete(environment.api+"/autorisations/"+JSON.stringify(id))
  }
}
