import { Component, EventEmitter, Input, Output} from '@angular/core';
import { Mark } from '../mark';
import { MarkService } from '../mark.service';

@Component({
  selector: 'app-mark-delete',
  templateUrl: './mark-delete.component.html',
  styleUrls: ['./mark-delete.component.css']
})
export class MarkDeleteComponent {
  @Input() mark: Mark;
  @Output() closed = new EventEmitter<boolean>();

  constructor(protected markService: MarkService) { }

  close() {
    this.closed.emit(true);
  }

  save() {
    this.markService.deleteMark(this.mark.id)
      .subscribe(
        data => {
          this.closed.emit(true);
        })
  }
}