import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parts-demo',
  templateUrl: './parts-demo.component.html',
  styleUrls: ['./parts-demo.component.css']
})
export class PartsDemoComponent implements OnInit {

  @Input() link:any
  @Input() modalVideoDemo:boolean
  constructor() { }

  ngOnInit(): void {
    const iframe = document.getElementById('videoPlayer');
    iframe.setAttribute("src",this.link)
  }

}
