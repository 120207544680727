import { Buying } from "src/app/buying/buying";
import { Product } from "src/app/catalog/product/product";
import { Transaction } from "src/app/finance/finance";
import { pack } from "src/app/pack/pack";
import { Customer } from "src/app/parts/parts";
import { Points } from "src/app/sales-point/points";
import { Operation } from "src/app/stock/stock";
import { Position } from "../shared-sales/shared-sales";

export class Order {
  constructor(
    public id?: number,
    public deliveryPrice?: number,
    public description?: string,
    public totalPrice?: number,
    public globalDiscount?: number,
    public isCompleted?: boolean,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public customerId?: Customer,
    public orderDetails?: OrderDetail[],
    public transactions?: Transaction[],
    public operations?: Operation[],
    public positions?: Position[],
    public userName?: string,
    public paiement_livraison?: boolean,
    public entrepriseId?: number,
    public real_id?: number,
    public sessionId?: number,
    public pointId?: number & Points,
    public retenues?: Retenue[],
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public updateCarteFidelite?: boolean
  ) { }
}
export class Retenue {
  constructor(
    public id?: number,
    public name?: string,
    public value?: number,
    public active?: boolean
  ) { }
}
export class OrderDetail {
  constructor(
    public id?: number,
    public tax?: number,
    public amount?: number,
    public price?: number,
    public quantity?: number,
    public gratuite?: number,
    public discount?: number,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public productId?: Product,
    public packId?: pack,
    public orderId?: number & Order,
    public entrepriseId?: number,
    public real_id?: number,
    public byAdmin?: boolean,
    public isFromCarteFid?: boolean
  ) { }
}

export class OperationDetail {
  constructor(
    public orderId?: Order,
    public buyingId?: Buying,
    public montant_ht?: number,
    public montant_ttc?: number,
    public tax?: number
  ) { }
}
export class productOrder {
  constructor(
    public orderId?: Order,
    public buyingId?: Buying,
    public productId?: Product,
    public quantity?: number
  ) { }
}

export class QuantityProduct {
  constructor(
    public orderDetail?: OrderDetail,
    public quantityLivre?: number,
    public quantityRestant?: number
  ) { }
}
