import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Category } from '../../category/category';
import { CategoryService } from '../../category/category.service';
import { Detail } from '../../detail/detail';
import { DetailService } from '../../detail/detail.service';

@Component({
  selector: 'app-add-category-modal',
  templateUrl: './add-category-modal.component.html',
  styleUrls: ['./add-category-modal.component.css']
})
export class AddCategoryModalComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<any>();
  showAlert: boolean;
  details: Detail[];
  loading: boolean = true;
  filter: FilterDto<Detail> = new FilterDto<Detail>()
  saisie: string;
  creerNouveau: boolean = false;
  id_entreprise: number
  real_id: number
  showAddTypeModal: boolean = false
  userRole: string
  isAdmin: boolean = false
  constructor(private detailsService: DetailService, private categoryService: CategoryService, private detailService: DetailService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getCategories({ where: { "entrepriseId": this.id_entreprise } })
  }
  getCategories(filter: FilterDto<Category>) {
    this.categoryService.getCategories(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }
  AddTypePopUp() {
    this.showAddTypeModal = true
  }
  AddType(type) {
    type.real_id = this.real_id;
    type.entrepriseId = this.id_entreprise
    this.detailsService.addDetail(type).subscribe(x => {
    })
  }
  CloseAction() {
    this.showAddTypeModal = false
  }
 
  submit(category: Category, form: NgForm) {
    category.real_id = this.real_id
    category.entrepriseId = this.id_entreprise
    category.byAdmin = this.isAdmin
    this.categoryService.addCategory(category)
      .subscribe(
        data => {
          this.CancelAction();
          this.saved.emit(data);
        })
  }

  fetchDetails(search?: string) {
    this.loading = true
    if (search) {
      this.saisie = "*" + search + "*"
      this.filter.take = 10;
      this.filter.where = { name: { type: "like", value: this.saisie } }
    }
    else {
      this.filter.take = 15;
      this.filter.where = undefined
    }
    this.filter.order = { "createdAt": 'DESC' };
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.getDetails(this.filter)

  }
  getDetails(filter: FilterDto<Detail>) {
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  CancelAction() {
    this.closed.emit(true)
  }
}
