import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken } from 'src/app/user/user.service';
import { Tax } from '../../tax/tax';
import { TaxService } from '../../tax/tax.service';

@Component({
  selector: 'app-add-tax-modal',
  templateUrl: './add-tax-modal.component.html',
  styleUrls: ['./add-tax-modal.component.css']
})
export class AddTaxModalComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>()
  id_entreprise: number
  real_id: number
  constructor(private TaxeService: TaxService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getTaxs({
      where: {
        "entrepriseId": this.id_entreprise
      }
    });
  }

  getTaxs(filter: FilterDto<Tax>) {
    this.TaxeService.getTaxs(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  CloseAction() {
    this.closed.emit(true);
  }
  submit(form: Tax) {
    form.real_id = this.real_id
    form.entrepriseId = this.id_entreprise
    this.TaxeService.addTax(form).subscribe(success => {
      this.CloseAction();
      this.saved.emit(true);
    })
  }
}
