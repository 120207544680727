import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Detail } from '../../detail/detail';
import { DetailService } from '../../detail/detail.service';
import { Category } from '../category';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.css']
})
export class CategoryAddComponent implements OnInit {
  showAlert: boolean;
  details: Detail[];
  loading: boolean = true;
  filter: FilterDto<Detail> = new FilterDto<Detail>()
  saisie: string;
  creerNouveau: boolean = false;
  id_entreprise: number;
  real_id: number
  showAddPopUp: boolean = false
  userRole: string
  isAdmin: boolean = false
  constructor(private categoryService: CategoryService, private detailService: DetailService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getCategories({ where: { "entrepriseId": this.id_entreprise } })
  }
  getCategories(filter: FilterDto<Category>) {
    this.categoryService.getCategories(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }

  submit(category: Category, form: NgForm) {
    category.real_id = this.real_id;
    category.entrepriseId = this.id_entreprise
    category.byAdmin = this.isAdmin
    this.categoryService.addCategory(category)
      .subscribe(
        data => {
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.resetForm(form);
          } else {
            this.creerNouveau = true;
          }
        })
  }


  getTypes() {
    this.CloseAction()
    this.fetchDetails();
  }

  fetchDetails(search?: string) {
    this.loading = true
    if (search) {
      this.saisie = "*" + search + "*"
      this.filter.take = 10;
      this.filter.where = { name: { type: "like", value: this.saisie } }
    }
    else {
      this.filter.take = 15;
      this.filter.where = undefined
    }
    this.filter.order = { "createdAt": 'DESC' };
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.getDetails(this.filter)

  }
  getDetails(filter: FilterDto<Detail>) {
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  AddTypePopUp() {
    this.showAddPopUp = true
  }
  CloseAction() {
    this.showAddPopUp = false
  }

  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false
    this.real_id ++ 
  }
}
