import { Component, Input, OnInit } from '@angular/core';
import { HistoriqueObject, HistoriqueReception } from 'src/app/parts/parts';
import { PartsService } from 'src/app/parts/parts.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { FilterService } from 'src/app/shared/filter.service';
import { formatDate } from 'src/app/whereFindOperator';
import { TranslateService } from '@ngx-translate/core';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-customer-historique',
  templateUrl: './customer-historique.component.html',
  styleUrls: ['./customer-historique.component.css'],
})
export class CustomerHistoriqueComponent implements OnInit {
  @Input() customerId: number;
  searchObject: HistoriqueObject = new HistoriqueObject();
  HistoryData: HistoriqueReception = new HistoriqueReception();
  operationArray = [];
  emptyHistory: boolean = false;
  showOrder: boolean;
  showoperation: boolean;
  showTrans: boolean;
  showAlert: boolean;
  showQuotation: boolean;

  count: number = 0;
  indexOrder: number;
  indexOp: number;
  indexTr: number;
  indexQuot: number;
  data = [];
  allData: any[];
  counter: number = 0
  devise: string
  id_entreprise: number
  arabicLanguage: boolean = false

  constructor(
    private partsService: PartsService,
    private filterService: FilterService,
    private configService: ConfigService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.configService.getEntrepriseInfo({
      where: { "id": this.id_entreprise }, relations: ["deviseId"]
    }).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code
      }
    )
    this.searchObject.all = true;
    this.searchObject.customerId = this.customerId;
    this.getHistory();
    this.EventLang();
  }
   
  getHistory() {
    this.count = 0
    this.emptyHistory = false
    this.operationArray = []
    this.dateSetting();
    this.data = []
    this.allData = []
    this.partsService.getCustomerHistory(this.searchObject).subscribe(
      history => {
        this.allData = history.result
        this.sortData();
        this.count = history.result.length
        if (this.allData.length == 0) {
          this.emptyHistory = true
        } else {
          this.devideTable();
        }
      }
    )
  }


  devideTable() {

    if (this.allData?.length > 14) {
      for (let i = 0; i < 15; i++) {
        if (this.allData[i]?.id) {
          this.data.push(this.allData[i])
          this.allData.shift();
        }

      }
    }
    else {
      this.data = this.allData
      this.count = this.allData.length
    }
  }

  sortData() {
    if (this.allData) {
      let newData = []
      let operationDetails;
      if (this.searchObject.customerId) {
        operationDetails = this.allData.filter((item) => item.operationDetails && item.orderId?.customerId?.id == this.searchObject.customerId);
        let operation = operationDetails.filter((item) => item.operationDetails[0]?.operationTypeId?.id == 1);
        newData = this.allData.filter((item) => !item.operationDetails)
        this.allData = []
        this.allData = newData
        operation.forEach((element) => {
          this.allData.push(element);
        });
        this.count = this.allData.length
        this.allData = this.allData.sort((a, b) => b.createdAt - a.createdAt);
      }
    }
  }

  loadMore() {
    this.emptyHistory = false
    this.operationArray = []
    this.devideTable();
  }


  dateSetting() {
    if (this.searchObject.dateDebut) {
      let dateDeb = formatDate(new Date(this.searchObject.dateDebut));
      this.searchObject.dateDebut = dateDeb;
    }
    if (this.searchObject.dateFin) {
      let dateFin = formatDate(new Date(this.searchObject.dateFin));
      this.searchObject.dateFin = dateFin;
    }
  }

  resetRadioButtonNonValide() {
    this.searchObject.valid = undefined;
    this.searchObject.allEtat = undefined;
    this.searchObject.isCompleted = false;
  }

  resetRadioButtonValide() {
    this.searchObject.isCompleted = true;
    this.searchObject.inValid = undefined;
    this.searchObject.allEtat = undefined;
  }

  resetStartDate() {
    this.searchObject.dateDebut = undefined;
  }
  resetEndDate() {
    this.searchObject.dateFin = undefined;
  }

  allEtat() {
    this.searchObject.isCompleted = undefined;
    this.searchObject.inValid = undefined;
    this.searchObject.valid = undefined;
  }


  closeDtailOrder() {
    this.showOrder = false
  }
  detailOrder(id) {
    this.showOrder = true;
    this.indexOrder = id;
  }
  detailOperation(id) {
    this.showoperation = true;
    this.indexOp = id;
  }
  closeOpDetail() {
    this.showoperation = false;
  }
  detailQuotation(id) {
    this.showQuotation = true;
    this.indexQuot = id;
  }
  closeQtDetail() {
    this.showQuotation = false;
  }

  detailTrans(id) {
    this.showTrans = true;
    this.indexTr = id;
  }
  closeTrDetail() {
    this.showTrans = false;
  }
  EventLang() {
    let lang = localStorage.getItem('currentLang')
    if (lang == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  changeEventLang(event: any) {
    this.translate.use(event.target.value);
    if (event.target.value == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
}
