<section>
  <div class="wrapper">
    <div class="demo-wrapper">
    <span>Comment modifier un pack</span>
    <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
      <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
      <span class="demo-span" id="demo-span">Demo</span>
    </button>
  </div>
  <h3>Modification du pack</h3>
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'commun.mc' | translate }}
        </span>
      </div>
    </div>
  </div>
  <br>
  <form clrStepper [clrInitialStep]="initialStep">
    <clr-stepper-panel ngModelGroup="step1" (click)="clrInitialStep('step1')">
      <clr-step-title>
        Pack
      </clr-step-title>
      <clr-step-content>
        <div class="clr-row">
          <div class="clr-col-8 clo-col-md-10">
            <form clrForm>
              <clr-input-container>
                <label> {{ 'login.VotreNom' | translate }} *</label>
                <input placeholder=" {{ 'login.VotreNom' | translate }}" clrInput [(ngModel)]="modelpack.nom" name="nom"
                  required />
                <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
              </clr-input-container>
              <clr-input-container>
                <label>{{ 'article.Prod.Réference' | translate }} </label>
                <input placeholder="{{ 'article.Prod.Réference' | translate }} " [(ngModel)]="modelpack.reference"
                  name="reference" clrInput />
              </clr-input-container>
              <clr-input-container>
                <label>Code à barre </label>
                <input placeholder="Code à barre " [(ngModel)]="modelpack.bar_code" name="bar_code" clrInput />
              </clr-input-container>
            </form>
          </div>
        </div>
        <button clrStepButton="next" [disabled]="!modelpack.nom">Suivant</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step2" (click)="clrInitialStep('step2')">
      <clr-step-title>Liste des produits </clr-step-title>
      <clr-step-content>
        <div class="clr-row">
          <div class="clr-col-8 clr-col-md-10">
            <form clrForm>
              <app-pack-edit-product [packId]="Id_pack"
                (totalProduitsEvent)="packProductsEvent($event)"></app-pack-edit-product>
              <button clrStepButton="next"
                [disabled]="listeproducts.length == 0 && modelpack?.details?.length ==0">Suivant</button>
            </form>
          </div>
        </div>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step3" (click)="clrInitialStep('step3')">
      <clr-step-title> {{ 'commun.r' | translate }}</clr-step-title>
      <clr-step-content>
        <div class="clr-row">
          <div class="clr-col-8 clr-col-md-10">
            <form clrForm>
              <clr-combobox-container>
                <label> {{ 'article.taxe.Taxe' | translate }} %*</label>
                <clr-combobox name="taxId" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)"
                  [ngModel]="selectedTax" placeholder="Select taxs">
                  <clr-options *ngIf="taxs">
                    <clr-option *ngFor="let item of taxs;" clrValue={{item.percentage}} (click)="itemChanged(item)">
                      {{item?.name}} ({{item?.percentage}} %)
                    </clr-option>
                  </clr-options>
                  <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
                </clr-combobox>
              </clr-combobox-container>
              <clr-control-helper>
                <button class="btn btn-link btn-sm" (click)="AddTaxePopUp()">
                  {{ 'commun.jt' | translate }} {{ 'article.taxe.Taxe' | translate }}
                </button>
              </clr-control-helper>
              <clr-input-container>
                <label> Prix HT *</label>
                <input clrInput type="number" [(ngModel)]="modelpack.price" name="price" min="0" required />
              </clr-input-container>
              <br>
            </form>
          </div>
        </div>
      </clr-step-content>
    </clr-stepper-panel>
  </form>
  <div class="btn-group btn-outline-primary">
    <button class="btn" routerLink="../..">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> Retour
    </button>
    <button class="btn" (click)="submit()" *ngIf="modelpack.price != 0  && modelpack.price && modelpack.nom  ">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> Enregistrer
    </button>
  </div>
  </div>
</section>
<!-- <app-catalog-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-catalog-demo>  -->
<app-add-tax-modal *ngIf="showAddTaxe" (closed)="CloseAction()" (saved)="AddTaxe()"></app-add-tax-modal>