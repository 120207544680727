import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { UserService, getValueFromToken } from "../user/user.service";
import { FilterDto } from "../filter.dto";
import { SalesPointService } from "../sales-point/sales-point.service";
import { CratesService } from "../crates/crates.service";

@Injectable({
    providedIn: 'root'
})

export class NumberMaxGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private salesPointService : SalesPointService,
        private cratesService : CratesService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,)
        : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        if (state.url.indexOf("add-user") != -1) {
           return  this.verifyMaxUsers()
        }
        if(state.url.indexOf("sales-point/add") != -1){
            return this.verifyMaxSalesPoint()
        }

        if(state.url.indexOf("crates/add") != -1){
            return this.verifyMaxCaisse()
        }

       // return true
    }

    async verifyMaxUsers() {
        const maxUserNumber = getValueFromToken("nbUser")
        if (maxUserNumber) {
            const id_entreprise = getValueFromToken("idEntreprise")
            let filter = new FilterDto();
            filter.where = {
                "entrepriseId": id_entreprise, "active": true
            }
            filter.select=["id"]
            const data = await this.userService.getUserNumber(filter)
            if (data[1] < maxUserNumber) {
                return true
            } else {
                return false
            }
        }
    }

    async verifyMaxSalesPoint() {
        const maxSalesPointNumber = getValueFromToken("nbSalesPoint")
        if (maxSalesPointNumber) { 
            const id_entreprise = getValueFromToken("idEntreprise")
            let filter = new FilterDto();
            filter.where = {
                "entrepriseId": id_entreprise, "active": true
            }
            filter.select=["id"]
            const data = await this.salesPointService.getPointNumber(filter)
            if (data[1] < maxSalesPointNumber) {
                return true
            } else {
                return false
            }
        }
    }

    async verifyMaxCaisse() {
        const maxSalesPointNumber = getValueFromToken("nbCaisse")
        if (maxSalesPointNumber) { 
            const id_entreprise = getValueFromToken("idEntreprise")
            let filter = new FilterDto();
            filter.where = {
                "entrepriseId": id_entreprise, "active": true
            }
            filter.select=["id"]
            const data = await this.cratesService.getCratesNumber(filter)
            if (data[1] < maxSalesPointNumber) {
                return true
            } else {
                return false
            }
        }
    }

}