import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Entreprise } from '../entreprise';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  //user: User = new User()
  entreprise: Entreprise = new Entreprise()
  year: number
  isEqual: boolean = true
  alertSuccess: boolean = false
  alertError: boolean;
  token: string;
  userId: number;
  hasAcces: boolean;


  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private userService: UserService) { }

  ngOnInit(): void {
    let date = new Date()
    this.year = date.getFullYear();
    const array = this.router.url.split("&");
    let tokenarray = array[0].split("=");
    this.token = tokenarray[1]
    this.userId = + array[1].split("=")[1]
    this.getEntrepriseInfo()
  }
  changePwd() {
    if (this.entreprise.password == this.entreprise.repassword && this.hasAcces) {
      let request = {
          email : this.entreprise.email,
          password : this.entreprise.password,
          resToken : this.token
      }
      this.userService.resetEntreprisePasswordWithToken(request).subscribe(
        data => {
          this.alertSuccess = true
          this.alertError = false
          setTimeout(() => {
            this.router.navigate(['/user/login']);
          }, 3000);
        },
        () => {
          this.alertError = true
          this.alertSuccess = false
        },
      )
    }

  }

  getEntrepriseInfo() {
    let request = {resToken : this.token}
    this.userService.getEntrepriseByResToken(request).subscribe(
      user => {
        this.entreprise = user[0][0]
        if (this.entreprise) {
          if (this.entreprise.resToken == this.token) {
            this.hasAcces = true
          }
        }
      },
    )
  }

}
