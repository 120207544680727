<clr-combobox-container *ngIf="openCustomerIds && !filterClicked && !filterProvider">
  <clr-combobox name="name" required placeholder="Nom de client" [clrLoading]="loading"
    (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of clients;" clrValue={{item.name}}>
        <clr-icon shape="user"></clr-icon>{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="filterProvider && !filterClicked">
  <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of providers" clrValue={{item.name}}>
        <clr-icon shape="user"></clr-icon>{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="filterClicked && !filterProvider">
  <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of products" clrValue={{item.name}}>
        <clr-icon shape="user"></clr-icon>{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="entityName=='carriersFilter'">
  <clr-combobox name="name" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of carriers" clrValue={{item.name}}>
        <clr-icon shape="user"></clr-icon>{{item?.name}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
<clr-combobox-container *ngIf="entityName=='marksFilter'">
  <clr-combobox name="mark" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of trucks" clrValue={{item.mark}}>
        <clr-icon shape="user"></clr-icon>{{item?.mark}}
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
