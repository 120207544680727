<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css" />
    <script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"></script>
  </head>
  <section>
    <h3> {{ 'commun.jt' | translate }} {{ 'commun.cl' | translate }}</h3>
    <div *ngIf="showAlert" class="alert alert-success" role="alert" style="margin-top: 20px; margin-bottom: 0px;">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
              viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <span class="alert-text">
            {{ 'commun.jd' | translate }}
          </span>
        </div>
      </div>
    </div>
    <!-- <label> {{ 'commun.Tel' | translate }}*</label>
    <input type="tel" id="phone" (keypress)="onKeyPress($event)" [(ngModel)]="phoneModel"> -->
    <form clrForm (ngSubmit)="submit()" [formGroup]="customerForm">
    <h3>{{ 'commun.cl' | translate }}</h3>
            <div class="wrapper1-client">
              <clr-input-container class="clrInput">
                <label> {{ 'login.VotreNom' | translate }} *</label>
                <input  name="name" type="text" clrInput [(ngModel)]="customerObject.name" (keypress)="keyPressNom($event)"
                  formControlName="name" required />
                <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
              </clr-input-container>
              <clr-input-container class="clrInput">
                <label> {{ 'commun.mf' | translate }} *</label>
                <input name="mf" clrInput [(ngModel)]="customerObject.mf" formControlName="mf"
                  (keypress)="keyPressMF($event)"
                  pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$" required/>
                <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
              </clr-input-container>
              <clr-input-container class="clrInput">
                <label> {{ 'commun.ad' | translate }} *</label>
                <input name="ville" clrInput [(ngModel)]="customerObject.ville" formControlName="ville"
                  pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$" required/>
                <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
              </clr-input-container>
            </div>
            <table class="table" *ngIf="customerObject.name">
            <thead>
              <tr>
                <th> {{ 'Transactions.Chèque.Ncl' | translate }} </th>
                <th> {{ 'commun.mf' | translate }}</th>
                <th> {{ 'commun.ad' | translate }} </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{customerObject.name}}</td>
                <td *ngIf="customerObject.mf">{{customerObject.mf}}</td>
                <td *ngIf="!customerObject.mf"><span class="label label-warning"> {{ 'commun.nd' | translate
                    }}</span></td>
                <td *ngIf="customerObject.ville">{{customerObject.ville}}</td>
                <td *ngIf="!customerObject.ville"><span class="label label-warning"> {{ 'commun.nd' | translate }}</span>
                </td>
              </tr>
            </tbody>
          </table>
            <h3>{{ 'sales.adresses' | translate }}</h3>
            <div class="wrapper1-client">
              <clr-input-container class="clrInput">
                <label> {{ 'commun.pa' | translate }} </label>
                <input name="ville" clrInput [(ngModel)]="adresse.state" formControlName="state"
                  pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
                <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
              </clr-input-container>
              <clr-input-container class="clrInput">
                <label> {{ 'sales.ville' | translate }} </label>
                <input name="ville" clrInput [(ngModel)]="adresse.city" formControlName="city"
                  pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
                <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
              </clr-input-container>
              <clr-input-container class="clrInput">
                <label> {{ 'commun.cdpo' | translate }} </label>
                <input name="ville" clrInput [(ngModel)]="adresse.geoCode" formControlName="geoCode"
                  pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
                <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
              </clr-input-container>
              <!-- <clr-textarea-container class="clrInput">
                <label> {{ 'Points.desc' | translate }} </label>
                <textarea clrTextarea name="description" [(ngModel)]="customerObject.description"
                  formControlName="description"
                  pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"></textarea>
              </clr-textarea-container> -->
            </div>
            <button class="btn btn-sm" *ngIf="adresse.state && adresse.city && adresse.geoCode" type="submit" (click)="addAdresse()">
              {{ 'commun.vl' | translate }}
            </button>
            <clr-datagrid *ngIf="showDetailAdr && adressArray.length > 0">
              <clr-dg-column>
                {{ 'commun.pa' | translate }}
              </clr-dg-column>
              <clr-dg-column>
                {{ 'Relation.Clients.vll' | translate }}
              </clr-dg-column>
  
              <clr-dg-column>
                {{ 'commun.cdp' | translate }}
              </clr-dg-column>
              <!--
              <clr-dg-column>
                {{ 'commun.fax' | translate }}
              </clr-dg-column> -->
  
              <clr-dg-row *ngFor="let item of adressArray">
                <clr-dg-action-overflow>
                  <button class="action-item" (click)="deleteAdresse(item)"> {{ 'commun.sp' | translate }} </button>
                </clr-dg-action-overflow>
                <clr-dg-cell *ngIf="item.state">{{item.state}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.state"><span class="label label-warning"> {{ 'commun.nd' | translate }}
                  </span></clr-dg-cell>
  
                <clr-dg-cell *ngIf="item.city">{{item.city}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.city"><span class="label label-warning"> {{ 'commun.nd' | translate }}</span>
                </clr-dg-cell>
  
                <clr-dg-cell *ngIf="item.geoCode">{{item.geoCode}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.geoCode"><span class="label label-warning"> {{ 'commun.nd' | translate }}</span>
                </clr-dg-cell>
  
                <!-- <clr-dg-cell *ngIf="item.fax">{{item.fax}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.fax"><span class="label label-warning"> {{ 'commun.nd' | translate }}</span>
                </clr-dg-cell> -->
  
              </clr-dg-row>
            </clr-datagrid> 
            <h3>{{ 'commun.cnt' | translate }}</h3>
            <!-- <div *ngIf="showDetail" class="alert alert-success" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                  </div>
                  <span class="alert-text">
                    {{ 'commun.jd' | translate }}
                  </span>
                </div>
              </div>
            </div> -->
            <!-- <div *ngIf="usedMail" class="alert alert-danger" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                  </div>
                  <span class="alert-text">
                    {{ 'commun.ced' | translate }}
                  </span>
                </div>
              </div>
            </div> -->
            <div class="wrapper1-client">
              <!-- <clr-input-container class="clrInput">
                <label> {{ 'commun.Tel' | translate }} *</label>
                <input type="number" formControlName="tel" clrInput (change)="verif_tel()" [(ngModel)]="phoneModel" name="tel"
                  pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" />
                <clr-control-error *clrIfError="'required'"> {{ 'reg.ob' | translate }} </clr-control-error>
                <clr-control-error *clrIfError="'pattern'">{{ 'commun.nui' | translate }} </clr-control-error>
              </clr-input-container>  -->
              <div class="telInput">
                <label class="clr-control-label"> {{ 'commun.Tel' | translate }} *</label>
                <input type="tel" id="phone" (keypress)="onKeyPress($event)" [(ngModel)]="phoneModel" 
                pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" formControlName="tel">
              </div>
              <clr-input-container class="clrInput">
                <label> {{ 'login.VotreEmail' | translate }} </label>
                <input clrInput type="text" name="email" formControlName="email"
                  [(ngModel)]="contact.email" email />
                <clr-control-error>
                  {{ 'login.VotreEmail' | translate }} {{ 'commun.nv' | translate }}
                </clr-control-error>
              </clr-input-container>
            </div>
            <button class="btn btn-sm" *ngIf="contact.tel || contact.email" type="submit" (click)="addContact()">
              {{ 'commun.vl' | translate }}
            </button>
            <clr-datagrid *ngIf="showDetail && contactArray.length > 0">
              <clr-dg-column>
                {{ 'Transactions.Chèque.Ncl' | translate }}
              </clr-dg-column>
              <clr-dg-column>
                {{ 'commun.Tel' | translate }}
              </clr-dg-column>
              <clr-dg-column>
                {{ 'login.VotreEmail' | translate }}
              </clr-dg-column>
              <!--
              <clr-dg-column>
                {{ 'commun.fax' | translate }}
              </clr-dg-column> -->
              <clr-dg-row *ngFor="let item of contactArray">
                <clr-dg-action-overflow>
                  <button class="action-item" (click)="deleteContact(item)"> {{ 'commun.sp' | translate }} </button>
                </clr-dg-action-overflow>
                <clr-dg-cell *ngIf="item.firstName">{{item.firstName}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.firstName"><span class="label label-warning"> {{ 'commun.nd' | translate }}
                  </span></clr-dg-cell>
                <clr-dg-cell *ngIf="item.tel">{{item.tel}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.tel"><span class="label label-warning"> {{ 'commun.nd' | translate }}</span>
                </clr-dg-cell>
                <clr-dg-cell *ngIf="item.email">{{item.email}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.email"><span class="label label-warning"> {{ 'commun.nd' | translate }}</span>
                </clr-dg-cell>
                <!-- <clr-dg-cell *ngIf="item.fax">{{item.fax}}</clr-dg-cell>
                <clr-dg-cell *ngIf="!item.fax"><span class="label label-warning"> {{ 'commun.nd' | translate }}</span>
                </clr-dg-cell> -->
              </clr-dg-row>
            </clr-datagrid>
            <br>
            <button class="btn btn-outline-primary" type="submit" *ngIf="customerObject.name && customerObject.mf && customerObject.ville && !addNew">
              <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-plus" viewBox="-1 -4 16 16">
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg> {{ 'reg.e' | translate }}
            </button>
    </form>

    <br>
    <div class="btn-group btn-outline-primary">
      <button class="btn" routerLink="../" *ngIf="!fromOrder && ! fromQuot">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
          class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
          <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
        </svg> {{ 'login.tt' | translate }}
      </button>
      <button class="btn" [routerLink]="['/sales/order/add']" *ngIf="fromOrder">
        <clr-icon shape="times"></clr-icon> {{ 'login.tt' | translate }}
      </button>
      <button class="btn" [routerLink]="['/sales/quotation/add']" *ngIf="fromQuot">
        <clr-icon shape="times"></clr-icon> {{ 'login.tt' | translate }}
      </button>
  
      <button class="btn" (click)="resetForm()" *ngIf="addNew">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-plus" viewBox="-1 -4 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> {{ 'commun.crn' | translate }}
      </button>
    </div>
  </section>
  <!-- <app-parts-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-parts-demo>  -->
  