<section>
  <div class="wrapper">
  <div class="demo-wrapper">
      <span>Comment modifir un client</span>
      <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
        <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
        <span class="demo-span" id="demo-span">Demo</span>
      </button>
    </div>
  <h3> {{ 'commun.mod' | translate }} {{ 'commun.cl' | translate }}</h3>
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'commun.jd' | translate }}
        </span>
      </div>
    </div>
  </div>
  <br>
  <form clrStepper clrInitialStep={{initaStep}} *ngIf="customerObject" style="margin-top: 10px;">
    <clr-stepper-panel ngModelGroup="step1" (click)="clrInitialStep('step1')">
      <clr-step-title> {{ 'commun.cl' | translate }}</clr-step-title>
      <clr-step-content>
        <form clrForm>
          <clr-input-container>
            <label> {{ 'login.VotreNom' | translate }} *</label>
            <input name="name" clrInput [(ngModel)]="customerObject.name" />
            <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label> {{ 'commun.mf' | translate }}</label>
            <input name="mf" clrInput [(ngModel)]="customerObject.mf" />
          </clr-input-container>
          <clr-input-container>
            <label> {{ 'commun.ad' | translate }} </label>
            <input name="adresse" clrInput [(ngModel)]="customerObject.ville" />
          </clr-input-container>
          <clr-textarea-container>
            <label> {{ 'Points.desc' | translate }} </label>
            <textarea clrTextarea name="description" [(ngModel)]="customerObject.description"></textarea>
          </clr-textarea-container>
        </form>
        <button clrStepButton="next" (click)="submit()" [disabled]="!customerObject.name"> {{ 'commun.sv' | translate
          }}</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step2" (click)="clrInitialStep('step2')">
      <clr-step-title> {{ 'commun.cnt' | translate }} </clr-step-title>
      <clr-step-content>
        <h3> {{ 'commun.cnc' | translate }} </h3>
        <div *ngIf="showDetail" class="alert alert-success" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{ 'commun.jd' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="usedMail" class="alert alert-danger" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{ 'commun.ced' | translate }}
              </span>
            </div>
          </div>
        </div>
        <form clrForm>
          <clr-input-container>
            <label> {{ 'login.VotreNom' | translate }} *</label>
            <input clrInput [(ngModel)]="contact.firstName" name="firstName" />
          </clr-input-container>

          <clr-input-container>
            <label> {{ 'commun.Tel' | translate }}*</label>
            <input type="number" clrInput [(ngModel)]="contact.tel" name="tel"
              pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" />
            <clr-control-error *clrIfError="'required'">
              {{ 'reg.ob' | translate }}
            </clr-control-error>
            <clr-control-error *clrIfError="'pattern'">
              {{ 'commun.nui' | translate }}
            </clr-control-error>
          </clr-input-container>

          <!-- <clr-input-container>
            <label>  {{ 'commun.fax' | translate }}</label>
            <input type="number" clrInput [(ngModel)]="contact.fax" name="fax" pattern="[0-9]{8}" />
            <clr-control-error>Numéro de fax est incorrect </clr-control-error>
          </clr-input-container> -->

          <clr-input-container>
            <label> {{ 'login.VotreEmail' | translate }}</label>
            <input clrInput type="text" class="form-control" name="email" [(ngModel)]="contact.email" #email="ngModel"
              [ngClass]="{ 'valid': email.valid}" email />
            <clr-control-error>
              {{ 'login.VotreEmail' | translate }} {{ 'commun.nv' | translate }}
            </clr-control-error>
          </clr-input-container>

          <br>
          <button class="btn btn-sm" (click)="addContact()" [disabled]="!contact.firstName || !contact.tel">
            {{ 'commun.jt' | translate }} {{ 'commun.cnt' | translate }}
          </button>
          <clr-datagrid *ngIf="customerObject.contacts.length>0">
            <clr-dg-column>
              {{ 'Transactions.Chèque.Ncl' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'commun.Tel' | translate }}
            </clr-dg-column>
            <!-- <clr-dg-column>
               {{ 'commun.fax' | translate }}
            </clr-dg-column> -->
            <clr-dg-column>
              {{ 'login.VotreEmail' | translate }}
            </clr-dg-column>
            <clr-dg-row *ngFor="let item of customerObject.contacts">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="deleteContact(item)"> {{ 'commun.sp' | translate }}</button>
                <button class="action-item" (click)="editContact(item)"> {{ 'commun.mod' | translate }}</button>
              </clr-dg-action-overflow>

              <clr-dg-cell *ngIf="item.firstName">{{item.firstName}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.firstName"><span class="label label-warning">{{ 'commun.nd' | translate
                  }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.tel">{{item.tel}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.tel"><span class="label label-warning">{{ 'commun.nd' | translate
                  }}</span></clr-dg-cell>

              <!-- <clr-dg-cell *ngIf="item.fax">{{item.fax}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.fax"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell> -->

              <clr-dg-cell *ngIf="item.email">{{item.email}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.email"><span class="label label-warning">{{ 'commun.nd' | translate }}
                </span></clr-dg-cell>

            </clr-dg-row>
          </clr-datagrid>
        </form>
        <button clrStepButton="next" (click)="submit()" [disabled]="editedContact"> {{ 'commun.sv' | translate
          }}</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step3" (click)="clrInitialStep('step3')">
      <clr-step-title> {{ 'commun.ad' | translate }}</clr-step-title>
      <clr-step-content>
        <h3> {{ 'commun.adcl' | translate }} </h3>
        <div *ngIf="showDetailAdr" class="alert alert-success" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{ 'commun.jd' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="usedAdr" class="alert alert-danger" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{ 'commun.nau' | translate }}
              </span>
            </div>
          </div>
        </div>
        <form clrForm>
          <clr-input-container>
            <label> {{ 'commun.num' | translate }}*</label>
            <input type="number" clrInput [(ngModel)]="adresse.number" name="adrName" />
          </clr-input-container>
          <clr-input-container>
            <label> {{ 'commun.rt' | translate }}*</label>
            <input type="text" clrInput [(ngModel)]="adresse.street" name="street" />
          </clr-input-container>
          <clr-input-container>
            <label> {{ 'commun.cdp' | translate }}</label>
            <input type="number" clrInput [(ngModel)]="adresse.geoCode" name="geoCode" />
          </clr-input-container>
          <clr-input-container>
            <label> {{ 'Relation.Clients.vll' | translate }}*</label>
            <input type="text" clrInput [(ngModel)]="adresse.city" name="city" />
          </clr-input-container>
          <clr-input-container>
            <label> {{ 'commun.gvr' | translate }}</label>
            <input type="text" clrInput [(ngModel)]="adresse.state" name="state" />
          </clr-input-container>
          <br>
          <button class="btn btn-sm" type="submit" (click)="addAdresse()"
            [disabled]="!adresse.number || !adresse.street || !adresse.city">Ajouter Addresse</button>
          <clr-datagrid *ngIf="customerObject.adress.length>0">
            <clr-dg-column>
              {{ 'commun.num' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'commun.rt' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'commun.cdp' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'Relation.Clients.vll' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'commun.gvr' | translate }}
            </clr-dg-column>
            <clr-dg-row *ngFor="let item of customerObject.adress">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="deleteAdresse(item)"> {{ 'commun.sp' | translate }}</button>
                <button class="action-item" (click)="editAdresse(item)"> {{ 'commun.mod' | translate }}</button>

              </clr-dg-action-overflow>

              <clr-dg-cell *ngIf="item.number">{{item.number}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.number"><span class="label label-warning">{{ 'commun.nd' | translate
                  }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.street">{{item.street}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.street"><span class="label label-warning">{{ 'commun.nd' | translate
                  }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.geoCode">{{item.geoCode}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.geoCode"><span class="label label-warning">{{ 'commun.nd' | translate
                  }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.city">{{item.city}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.city"><span class="label label-warning">{{ 'commun.nd' | translate
                  }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.state">{{item.state}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.state"><span class="label label-warning">{{ 'commun.nd' | translate
                  }}</span></clr-dg-cell>

            </clr-dg-row>
          </clr-datagrid>
        </form>
        <button clrStepButton="next" (click)="submit()" [disabled]="editedAdress"> {{ 'commun.sv' | translate
          }}</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step4" (click)="clrInitialStep('step4')">
      <clr-step-title> {{ 'commun.rc' | translate }}</clr-step-title>
      <clr-step-content *ngIf="customerObject">
        <h3> {{ 'commun.ddt' | translate }} {{ 'commun.cl' | translate }}</h3>
        <div class="overflow-x">
          <table class="table">
            <thead>
              <th> {{ 'Transactions.Chèque.Ncl' | translate }}</th>
              <th> {{ 'Points.desc' | translate }}</th>
            </thead>
            <tbody>
              <tr>
                <td>{{customerObject.name}}</td>
                <td>
                  <span *ngIf="customerObject.description">{{customerObject.description}}</span>
                  <span *ngIf="!customerObject.description"><span class="label label-warning">
                      {{ 'commun.nd' | translate }}
                    </span></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 *ngIf="customerObject.contacts.length>0"> {{ 'commun.cnt' | translate }}</h3>
        <div class="overflow-x" *ngIf="customerObject.contacts.length>0">
          <table class="table">
            <thead>
              <tr>
                <th> {{ 'login.VotreNom' | translate }}</th>
                <th> {{ 'commun.Tel' | translate }}</th>
                <th> {{ 'login.VotreEmail' | translate }}</th>
                <!-- <th>  {{ 'commun.fax' | translate }}</th> -->
              </tr>
            </thead>
            <tbody *ngFor="let item of customerObject.contacts">
              <tr>
                <td>
                  <span *ngIf="item.firstName">{{item.firstName}}</span>
                  <span *ngIf="!item.firstName"><span class="label label-warning">{{ 'commun.nd' | translate
                      }}</span></span>
                </td>

                <td>
                  <span *ngIf="item.tel">{{item.tel}}</span>
                  <span *ngIf="!item.tel"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
                </td>

                <td>
                  <span *ngIf="item.email">{{item.email}}</span>
                  <span *ngIf="!item.email"><span class="label label-warning">{{ 'commun.nd' | translate
                      }}</span></span>
                </td>

                <!-- <td>
                  <span *ngIf="item.fax">{{item.fax}}</span>
                  <span *ngIf="!item.fax"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
                </td> -->



              </tr>
            </tbody>
          </table>
        </div>



        <h3 *ngIf="customerObject.adress.length>0"> {{ 'commun.ad' | translate }}</h3>
        <div class="overflow-x" *ngIf="customerObject.adress.length>0">
          <table class="table">
            <thead>
              <tr>
                <th> {{ 'commun.num' | translate }}</th>
                <th> {{ 'commun.rt' | translate }}</th>
                <th> {{ 'Relation.Clients.vll' | translate }}</th>
                <th> {{ 'commun.cdp' | translate }}</th>
                <th> {{ 'commun.gvr' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of customerObject.adress">
              <tr>

                <td>
                  <span *ngIf="item.number">{{item.number}}</span>
                  <span *ngIf="!item.number"><span class="label label-warning">{{ 'commun.nd' | translate
                      }}</span></span>
                </td>

                <td>
                  <span *ngIf="item.street">{{item.street}}</span>
                  <span *ngIf="!item.street"><span class="label label-warning">{{ 'commun.nd' | translate
                      }}</span></span>
                </td>

                <td>
                  <span *ngIf="item.city">{{item.city}}</span>
                  <span *ngIf="!item.city"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
                </td>

                <td>
                  <span *ngIf="item.geoCode">{{item.geoCode}}</span>
                  <span *ngIf="!item.geoCode"><span class="label label-warning">{{ 'commun.nd' | translate
                      }}</span></span>
                </td>

                <td>
                  <span *ngIf="item.state">{{item.state}}</span>
                  <span *ngIf="!item.state"><span class="label label-warning">{{ 'commun.nd' | translate
                      }}</span></span>
                </td>

              </tr>
            </tbody>
          </table>
        </div>

      </clr-step-content>
    </clr-stepper-panel>
  </form>
  <div class="btn-group btn-outline-primary">
    <button class="btn" routerLink="../../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> {{ 'login.tt' | translate }}
    </button>
  </div>
  </div>
</section>
<!-- <app-parts-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-parts-demo>  -->
