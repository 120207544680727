import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Service } from '../service';
import { serviceService } from '../service.service';

@Component({
  selector: 'app-service-delete',
  templateUrl: './service-delete.component.html',
  styleUrls: ['./service-delete.component.css']
})
export class ServiceDeleteComponent {
  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() service: Service;
  constructor(private serviceService:serviceService) { }

  valideAction() {
    this.serviceService.deleteService(this.service.id).subscribe(
      res => {
        this.saved.emit(true);
      }
    );
  }

  closeAction() {
    this.closed.emit(true);
  }

}
