<div class="sales-ticket">
    <div class="">
        <div class="entreprise_name">
            {{EntrepriseObject?.name}}
            <div class="address" *ngIf="address">
                {{address || ""}}
            </div>
        </div>
        <div class="header-actions">
        </div>
    </div>
    <div class="clr-row">
        <div class="clr-col-5">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th><b>NOM:</b> {{EntrepriseObject?.name}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="clr-col-2">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th style="text-align: center;"><b>Ticket N°:</b>{{Order?.real_id}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="clr-col-5">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th><b>Vendeur : </b> {{vendeur?.name}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br>
    <div class="clr-row">
        <div class="clr-col-5">
            <span><b>Client:</b> {{Order?.customerId?.name}}</span><br>
            <span><b>Création à:</b> {{Order?.createdAt | date:'dd/MM/yyyy à HH:mm'}}</span>
        </div>
        <div class="clr-col-2">
        </div>
        <div class="clr-col-5">
            <!-- <span><b>Matricule Fiscale:</b> {{Order?.customerId?.name}}</span><br> -->
            <!-- <span><b>Adresse:</b> {{Order.totalPrice?.toFixed(2)}} DT</span> -->
        </div>
    </div>
    <h3>Liste des produits</h3>
    <table class="table table-noborder">
        <thead>
            <tr>
                <th>Produit</th>
                <th>Quantité</th>
                <th>P.U HT</th>
                <th>TVA (%)</th>
                <th>Remise</th>
                <th class="right">Montant TTC</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of Order?.orderDetails">
                <td>{{item?.productId?.name}}</td>
                <td>{{item?.quantity}}</td>
                <td>{{item?.price}} {{devise}}</td>
                <td>{{item?.productId?.taxId?.percentage}}</td>
                <td>{{item?.discount}}</td>
                <td class="right">{{item?.amount?.toFixed(2)}}</td>
            </tr>
        </tbody>
    </table>
    <div class="clr-row">
        <div class="clr-col-5">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th>Total TTC: {{Order?.totalPrice?.toFixed(2)}} {{devise}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
