<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{ 'sales.detailcommande' | translate }}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="close()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <clr-tabs>
          <clr-tab>
            <button clrTabLink id="link1">{{ 'sales.presentation' | translate }}</button>
            <clr-tab-content id="content1" *clrIfActive="true">
              <br>
              <div class="clr-row">
                <div class="clr-col-6 clr-col-md-4 clr-col-lg-4 clr-col-xl-2">
                  <span><b>{{ 'sales.client' | translate }}</b></span><br>
                  <span><b>{{ 'commun.etat' | translate }}</b></span><br>
                  <span><b>{{ 'sales.creationpar' | translate }}</b></span><br><br>
                </div>
                <div class="clr-col-6 clr-col-md-8 clr-col-lg-8 clr-col-xl-10">
                  <span *ngIf="commande.customerId?.name">{{commande?.customerId?.name}}</span>
                  <span *ngIf="!commande.customerId?.name" class="label label-danger">{{ 'commun.nd' | translate
                    }}</span><br>
                  <span class="label label-danger" *ngIf="commande.etatCommandeId?.name!='approuvée'">{{
                    commande.etatCommandeId?.name }}</span>
                  <span class="label label-info" *ngIf="commande.etatCommandeId?.name=='approuvée'">{{
                    commande.etatCommandeId?.name }}</span>
                  <span *ngIf="!userAdd?.name" class="label label-danger">{{ 'commun.nd' | translate }}</span><br>
                  <span *ngIf="!arabicLanguage"><span *ngIf="userAdd?.name">{{userAdd?.name}}</span> {{ 'sales.a' |
                    translate }} <b>{{commande?.createdAt | date:'dd/MM/yyyy
                      HH:mm'}}</b></span>
                  <span *ngIf="arabicLanguage"><b>{{commande?.createdAt | date:'dd/MM/yyyy HH:mm'}}</b> {{ 'sales.a' |
                    translate }}
                    <span *ngIf="userAdd?.name">{{userAdd?.name}}</span></span>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'sales.details' | translate }}</button>
            <clr-tab-content *clrIfActive>
              <div *ngIf="commande.commandeDetails.length == 0">
                <div class="alert alert-info" role="alert">
                  <div class="alert-items">
                    <div class="alert-item static">
                      <span class="alert-text">{{ 'commun.ldvi' | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="overflow-x">
                <div *ngIf="commandeProducts.length >0">
                  <h5>{{ 'pack.produits' | translate }}</h5>
                  <table class="table table-compact">
                    <thead>
                      <tr>
                        <th>{{ 'pack.produit' | translate }}</th>
                        <th>{{ 'pack.quantite' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of commandeProducts">
                        <td *ngIf="item?.productId?.name">{{item?.productId?.name}}</td>
                        <td>{{item?.quantity}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="commandeServices.length >0">
                  <h5>{{ 'article.services' | translate }}</h5>
                  <table class="table table-compact">
                    <thead>
                      <tr>
                        <th>{{ 'article.service' | translate }}</th>
                        <th>{{ 'pack.quantite' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of commandeServices">
                        <td *ngIf="item?.serviceId?.name">{{item?.serviceId?.name}}</td>
                        <td>{{item?.quantity}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
        </clr-tabs>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>