import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { categoryService } from '../categoryService';
import { categoryserviceService } from '../categoryService.service';

@Component({
  selector: 'app-category-service-delete',
  templateUrl: './category-service-delete.component.html',
  styleUrls: ['./category-service-delete.component.css']
})
export class CategoryServiceDeleteComponent {
  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() category: categoryService;
  constructor(private categoryService: categoryserviceService) { }

  valideAction() {
    this.categoryService.deleteCategoryService(this.category.id).subscribe(
      res => {
        this.saved.emit(true);
      }
    );
  }

  closeAction() {
    this.closed.emit(true);
  }
}
