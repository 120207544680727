<div [ngClass]="{'modal-wrapper' : modalVideoDemo}">
  <div class="modal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
      <div class="modal-content" id="modal-content">
        <div class="modal-body">
            <iframe id="videoPlayer" width="560" height="315" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>   
  <div class="modal-backdrop" aria-hidden="true"></div>
</div>