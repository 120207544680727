<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> {{ 'article.dt' | translate }}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="close()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <div class="clr-row" *ngIf="detail">
          <div class="clr-col-3">
            <span><b> {{ 'commun.id' | translate }}</b></span><br>
            <span><b> {{ 'login.VotreNom' | translate }}</b></span><br>
            <span><b> {{ 'commun.type' | translate }}</b></span><br>
            <span><b> {{ 'Points.desc' | translate }}</b></span><br>
            <span><b> {{ 'commun.stt' | translate }}</b></span>
          </div>
          <div class="clr-col-9">
            <span>{{detail.real_id}}</span>
            <br>
            <span>{{detail.name}}</span>
            <br>
            <span *ngIf="detail.type" class="label label-info">{{detail.type}}</span>
            <br>
            <span *ngIf="detail.description">{{detail.description}}</span>
            <span class="label label-danger" *ngIf="!detail.description"> {{ 'commun.nd' | translate
              }}</span>
            <br>
            <span *ngIf="detail.active" class="label label-success"> {{ 'commun.act' | translate }}</span>
            <span *ngIf="!detail.active" class="label label-danger">{{ 'commun.dsc' | translate }}</span>
          </div>
          <div class="clr-col-12">
            <span *ngIf="detail.createdBy">
              {{ 'commun.cp' | translate }} <strong>{{detail.createdBy}}</strong> à
              <strong>{{detail.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</strong>
            </span>
            <br>
            <span *ngIf="detail.updatedBy">
              {{ 'commun.mdp' | translate }}<strong>{{detail.updatedBy}}</strong> à
              <strong>{{detail.updatedAt | date: 'dd/MM/yyyy HH:mm:ss' }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
