import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { Points } from 'src/app/sales-point/points';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { Store } from 'src/app/stock/stock';
import { StockService } from 'src/app/stock/stock.service';
import { getValueFromToken } from '../../user.service';

@Component({
  selector: 'app-add-sales-point-modal',
  templateUrl: './add-sales-point-modal.component.html',
  styleUrls: ['./add-sales-point-modal.component.css']
})
export class AddSalesPointModalComponent implements OnInit {
  @Output() saved = new EventEmitter<any>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() type: any
  pointObject: Points = new Points()
  stores: Store[] = []
  loading: boolean = false
  selectedStore: Store = new Store()
  id_entreprise: number
  filter: FilterDto
  saisie: string
  real_id: number
  showAddStore: boolean = false
  constructor(private stockService: StockService, private salespointService: SalesPointService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getAllStores({ where: { "entrepriseId": this.id_entreprise } });
    this.getAllPoints({ where: { "entrepriseId": this.id_entreprise } })
  }
  itemChange(item: Store) {
    this.selectedStore = item
  }
  onSearchStore(args: any) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      this.filter.where = {
        name: { type: "like", value: this.saisie },
        "entrepriseId": this.id_entreprise, active: true
      }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true, "entrepriseId": this.id_entreprise }
    }
    this.filter.order = { "createdAt": 'DESC' };
    this.filter.select = ["id", "name", "createdAt"];
    this.getAllStores(this.filter)
  }
  getAllPoints(filter: FilterDto) {
    this.salespointService.getPoints(filter).subscribe(
      data => {
        this.real_id = data[1] + 1
      }
    )
  }
  getAllStores(filter: FilterDto) {
    this.stockService.getStore(filter).subscribe(
      data => {
        this.stores = data[0];
      }
    )
  }
  addStoreModal() {
    this.showAddStore = true
  }
  savedStore() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    this.stockService.getStore(filter).subscribe(
      data => {
        this.stores = data[0]
      }
    )
  }
  close() {
    this.showAddStore = false
  }
  CloseAction() {
    this.closed.emit(true);
  }
  submit() {
    this.pointObject.storeId = this.selectedStore.id
    this.pointObject.real_id = this.real_id
    this.pointObject.entrepriseId = this.id_entreprise
    this.salespointService.addPoints(this.pointObject).subscribe(
      data => {
        this.saved.emit(data)
      }
    )
  }
}
