
<clr-combobox-container>
  <clr-combobox name="name" [clrLoading]="loading" [ngModel]="choosed" (clrInputChange)="onSearchChange($event)">
    <clr-options>
      <clr-option *ngFor="let item of nameArray;" clrValue={{item.name}}_{{item.role}}_{{item.customerType}}_{{item.id}}>
      <div class="clr-row">
        <div class="clr-col-6">
        {{item?.name}}
        </div>
        <div class="clr-col-2">
          <span *ngIf="item.role" class="label label-danger">Utilisateur</span>
          <span *ngIf="item.customerType" class="label label-info">Client</span>
          <span *ngIf="!item.role && !item.customerType" class="label label-info">Fournisseur</span>
        </div>
      </div>
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>

