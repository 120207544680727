import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { getValueFromToken } from 'src/app/user/user.service';
import { CoreService } from '../../core.service';

@Component({
  selector: 'app-impexpo-alert',
  templateUrl: './impexpo-alert.component.html',
  styleUrls: ['./impexpo-alert.component.css']
})
export class ImpexpoAlertComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  id_entreprise: number
  constructor(
    private coreService: CoreService
  ) { }

  closeAction() {
    this.closed.emit(true);
  }
  ExportData() {
    this.coreService.exportData(this.id_entreprise).subscribe(
      res => {
        this.closeAction();
        alert("File saved in the folder C:\\data")
      }
    )
  }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
  }
   

}
