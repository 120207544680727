import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-company-delete',
  templateUrl: './company-delete.component.html',
  styleUrls: ['./company-delete.component.css']
})
export class CompanyDeleteComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() companyId: number
  deleteAlert: boolean;
  alertError: boolean;
  real_id: number
  constructor(private partsService: PartsService, private router: Router) { }
  ngOnInit(): void {
    this.partsService.getCompanys({
      where: {
        "id": this.companyId
      }
    }).subscribe(
      data => {
        this.real_id = data[0][0].real_id;
      }
    )
  }
  deleteCompany() {
    if (!this.deleteAlert) {
      this.partsService.deleteCompany(this.companyId).subscribe(
        data => {
          this.saved.emit(true)
          this.closed.emit(true);
          this.router.navigate(['/parts/company'])
        },
        err => {
          console.error('Observer got an error: ' + err)
          this.alertError = true
        }
      )
    }
  }
  closeAction() {
    this.closed.emit(true);
  }
}
