import { Component, HostListener, OnInit } from '@angular/core';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import jwt_decode from "jwt-decode";
import { FilterDto } from 'src/app/filter.dto';
import { categoryService } from './categoryService';
import { Autorisation } from 'src/app/user/autorisation/autorisation';
import { categoryserviceService } from './categoryService.service';


@Component({
  selector: 'app-category-service',
  templateUrl: './category-service.component.html',
  styleUrls: ['./category-service.component.css']
})
export class CategoryServiceComponent implements OnInit {

  loading: boolean;
  categories: categoryService[] = [];
  tmpList: categoryService[] = [];
  showCatService: categoryService[] = []
  selectedCategory: categoryService;
  showDetail: boolean;
  id_entreprise: number
  userId : number
  isAdmin : boolean = false
  userRole: string
  filter: FilterDto<categoryService> = new FilterDto<categoryService>()
  userAutorisation : Autorisation = new Autorisation()
  total: number;
  currentPage: number = 1
  pageSize: number = 5
  nbr_page: number
  insertIndex: number = 0
  videoDemo: boolean = false
  showAlert: boolean;
  modalVideoDemo: boolean = false

  constructor( private categoryserviceService: categoryserviceService, 
    private userService : UserService ) { this.userRole = jwt_decode(tokenGetter())["role"]; }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userId = parseInt(jwt_decode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getUserAutorisation();
    }
    this.filter = new FilterDto()
    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.filter.take = this.pageSize
    this.getCategoriesService(this.filter);
  }
  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  }
  getCategoriesService(filter: FilterDto<categoryService>) {
    if (!this.categories[this.insertIndex]){
      this.categoryserviceService.getCategoriesService(filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.categories[this.insertIndex + i] = data[0][i];
          }
          this.tmpList = this.categories
          this.showCatService = this.categories.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.total = data[1];
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        },
        err => console.error('Observer got an error: ' + err),
        () => this.loading = false
      );
    } else {
      this.showCatService = this.categories.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getCategoriesService(this.filter)
    if (this.categories.length < this.total) {
    }
  }
  detail(category: categoryService) {
    this.selectedCategory = category
    this.showDetail = true;
  }
  close() {
    this.getCategoriesService(this.filter);
    this.showAlert = false;
    this.showDetail = false;
  }
  delete(category: categoryService) {
    this.selectedCategory = category
    this.showAlert = true;
  }
  saved() {
    this.getCategoriesService(this.filter)
    this.showAlert = false;
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
