import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { getValueFromToken } from 'src/app/user/user.service';
import { Tax } from '../tax';
import { TaxService } from '../tax.service';

@Component({
  selector: 'app-tax-add',
  templateUrl: './tax-add.component.html',
  styleUrls: ['./tax-add.component.css']
})
export class TaxAddComponent implements OnInit {
  showAlert: boolean;
  creerNouveau: boolean = false;
  id_entreprise: number
  real_id: number

  constructor(private taxService: TaxService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.taxService.getTaxs({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }

  submit(tax: Tax, form: NgForm) {
    tax.real_id = this.real_id
    tax.entrepriseId = this.id_entreprise
    this.taxService.addTax(tax).subscribe(
      data => {
        this.showAlert = true;
        if (this.creerNouveau == true) {
          this.resetForm(form);
        } else {
          this.creerNouveau = true;
        }
      })
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false;
  }

}