import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from 'src/app/parts/parts';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Commande, CommandeProduct, CommandeDetail } from '../../Commande';
import { Store } from 'src/app/stock/stock';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { Points } from 'src/app/sales-point/points';
import { StockService } from 'src/app/stock/stock.service';
import { SettingService } from 'src/app/setting/setting.service';
import { CommandeService } from '../../Service/commande-service.service';

@Component({
  selector: 'app-commande-add',
  templateUrl: './commande-add.component.html',
  styleUrls: ['./commande-add.component.css']
})
export class CommandeAddComponent implements OnInit {

  client: Customer = new Customer();
  clients: Customer[] = [];

  filter: FilterDto<Customer> = new FilterDto<Customer>()
  tmpListClients: Customer[] = []
  id_entreprise: number
  saisie: string;
  userRole: string
  today_date: string
  loading: boolean = false;
  currentLang: string; 
  commandModel : Commande = new Commande()
  isAdmin : boolean = false 
  point_id : number
  store_id : number
  real_id : number
  showAlertSuccess : boolean = false 
  msgAlert : string = ""
  showAlertError : boolean = false 
  etatCommandeId : number
  saved : boolean = false 
  constructor(
    private _translate: TranslateService, private _router: Router,
    private _quotationService: QuotationService,
    private _salespointService : SalesPointService,
    private _stockService : StockService,
    private _settingService : SettingService,
    private _commandeService : CommandeService
  ) {
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this._translate.use(this.currentLang);
  }

  ngOnInit(): void {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.today_date = new Date().toISOString().slice(0, 10)
    this.getCustomers() 
    this.getEtatCommandeId();
    this.isAdmin = this.userRole === "admin"
    if (this.isAdmin) {
      this.verifExitancePointSales()
    } else {
      this.getUserInfo()
    }
    this.getCommandeRealId();
  }

  getEtatCommandeId(){
    let filter = new FilterDto()
    filter.where={"coefficient":1}
    filter.select=["id"]
    this._commandeService.getEtatsCommande(filter).subscribe(
      data => {
        this.etatCommandeId = data[0][0].id
      }
    )
  }

  submitDetail(elementList : CommandeProduct[]){
    this.commandModel.entrepriseId = this.id_entreprise
    this.commandModel.byAdmin = this.isAdmin
    this.commandModel.isApproved = false 
    this.commandModel.pointId=this.point_id
    this.commandModel.real_id = this.real_id
    this.commandModel.etatCommandeId = this.etatCommandeId;
    this.commandModel.commandeDetails = []
    elementList.forEach(el=>{ 
      let commandeDetailModel = new CommandeDetail()
      commandeDetailModel.entrepriseId = el.entrepriseId
      commandeDetailModel.byAdmin = this.isAdmin
      if(el.productId){
        commandeDetailModel.productId = el.productId
      }else if(el.serviceId){
        commandeDetailModel.serviceId = el.serviceId
      }
      commandeDetailModel.quantity = el.quantity
      this.commandModel.commandeDetails.push(commandeDetailModel);
    })
    this._commandeService.addCommande(this.commandModel).subscribe(
      data => {
        this.showAlertSuccess = true 
        this.showAlertError = false 
        this.msgAlert = "Commande ajouté avec succès"
      },err=>{
        this.showAlertError = true
        this.showAlertSuccess = false 
        this.msgAlert = "Erreur lors de l'ajout de la commande"
      }
    )
  }

  clientChanged(item:Customer){
    this.commandModel.customerId = item.id
  }

  getCustomers() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    filter.relations = ["contacts"]
    this._quotationService.getCustomers(filter).subscribe(
      customers => {
        this.clients = customers[0];
        this.tmpListClients = this.clients
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  onSearchChange(args?: string) {
    if (args == undefined || args?.length == 0) {
      this.clients = this.tmpListClients
    } else {
      this.clients = []
      this.tmpListClients.forEach(el => {
        if (el.name.indexOf(args) != -1) {
          this.clients.push(el);
        }
      })
    }
  }

  customerAdd() {
    this._router.navigate(['/parts/customer/add'], { queryParams: { fromOrder: true } })
  }

  verifExitancePointSales() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, name: "point de vente principale" }
    filter.relations = ["storeId"]
    this._salespointService.getPoints(filter).subscribe(
      data => {
        if (data[1] == 0) {
          let storeModel = new Store()
          storeModel.real_id = 1
          storeModel.entrepriseId = this.id_entreprise
          storeModel.name = "Depot de la point de vente principale "
          this._stockService.addStore(storeModel).subscribe(
            data => {
              this.store_id = data.id
              let pointModel = new Points()
              pointModel.real_id = 1
              pointModel.storeId = data.id
              pointModel.name = "point de vente principale"
              pointModel.entrepriseId = this.id_entreprise
              this._salespointService.addPoints(pointModel).subscribe(
                data => {
                  this.point_id = data.id
                }
              )
            }
          )
        } else {
          this.store_id = data[0][0].storeId.id
          this.point_id = data[0][0].id
        }
      }
    )
  }

  getUserInfo() {
    let id_user = parseInt(getValueFromToken("id"))
    let filter = new FilterDto()
    filter.where = { "id": id_user, "active": true, "entrepriseId": this.id_entreprise }
    if (this.userRole == "writer") {
      filter.relations = ["cratesId","cratesId.pointid", "cratesId.pointid.storeId"]
      this._settingService.getUserInfo(filter).subscribe(
        data => {
          if (data[0][0] != undefined) {
            this.store_id = data[0][0].cratesId.pointid.storeId.id
            this.point_id = data[0][0].cratesId.pointid.id
          }
        }
      )
    } else {
      filter.relations = ["pointId", "pointId.storeId"]
      this._settingService.getUserInfo(filter).subscribe(
        data => {
          this.store_id = data[0][0].pointId.storeId.id
          this.point_id = data[0][0].pointId.id
        }
      )
    }
  }

  getCommandeRealId(){
    let filter = new FilterDto()
    filter.where={"entrepriseId":this.id_entreprise}
    this._commandeService.getAllCommande(filter).subscribe(
      data => {
        this.real_id = data[1]+1
      }
    )
  }
}
