import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { environment } from 'src/environments/environment';
import { Carrier } from './carrier';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(private http: HttpClient) { }
  getCarriers(filter: FilterDto<Carrier>): Observable<[Carrier[], number]> {
    return this.http.get(environment.api + '/carriers?filter=' + JSON.stringify(filter)) as Observable<[Carrier[], number]>;
  }

  addCarrier(carrier: Carrier): Observable<Carrier> {
    return this.http.post<Carrier>(environment.api + '/carrier', carrier);
  }

  deleteCarrier(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/carrier/' + JSON.stringify(ID)) as Observable<Carrier[]>;
  }

  getCarrierDetail(filter: FilterDto<Carrier>): Observable<[Carrier[], number]> {
    return this.http.get(environment.api + '/carriers?filter=' + JSON.stringify(filter)) as Observable<[Carrier[], number]>;
  }
  editCarrier(ID: number, carrier: Carrier): Observable<any> {
    return this.http.patch<Carrier>(environment.api + '/carrier/' + JSON.stringify(ID), carrier);
  }
}
