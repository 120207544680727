import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { categoryService } from '../categoryService';
import { categoryserviceService } from '../categoryService.service';


@Component({
  selector: 'app-category-service-add',
  templateUrl: './category-service-add.component.html',
  styleUrls: ['./category-service-add.component.css']
})
export class CategoryServiceAddComponent implements OnInit {
  showAlert: boolean;
  creerNouveau: boolean = false;
  loading: boolean = true;
  id_entreprise: number;
  real_id: number
  isAdmin: boolean = false
  userRole: string

  constructor(private categoryserviceService: categoryserviceService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getCategoriesService({ where: { "entrepriseId": this.id_entreprise } })

  }

  getCategoriesService(filter: FilterDto<categoryService>) {
    this.categoryserviceService.getCategoriesService(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }

  submit(category: categoryService, form: NgForm) {
    category.real_id = this.real_id;
    category.entrepriseId = this.id_entreprise
    category.byAdmin = this.isAdmin
    this.categoryserviceService.addCategoryService(category)
      .subscribe(
        data => {
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.resetForm(form);
          } else {
            this.creerNouveau = true;
          }
        })
  }

  

  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false
    this.real_id ++ 
  }
}
