<section>
  <div class="clr-row">
    <div id="resultDiv" class="clr-col-md-7  clr-col-12">
      <div class="alert alert-app-level alert-info" role="alert" *ngIf="emptyHistory">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
            </div>
            <div class="alert-text" *ngIf="!searchObject.dateDebut && !searchObject.dateFin">
              {{ 'sales.clAucHist' | translate }}
            </div>
            <div class="alert-text" *ngIf="searchObject.dateDebut || searchObject.dateFin">
              {{ 'sales.clAuHistPer' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-row">

    <div id="resultDiv" class="clr-col-12 clr-col-md-8">

      <ul class="clr-timeline clr-timeline-vertical dot-circle clr-col-md-12">
        <!--order-->
        <li class="clr-timeline-step" *ngFor="let item of data">
          <div class="clr-timeline-step-header font-10">{{item?.createdAt | date:'dd/MM/yyyy à HH:mm'}}</div>
          <clr-icon shape="dot-circle" aria-current="true" aria-label="Current"></clr-icon>

          <div class="clr-timeline-step-body" *ngIf="item?.orderDetails">
            <span class="clr-timeline-step-title">
              <a href="/sales/order/detail/{{item.id}}" target="_blank">
                <h3 class="no-margin-top" *ngIf="!arabicLanguage">{{ 'commun.cmdn' | translate }} : {{item.real_id}}</h3>
                <h3 class="no-margin-top" *ngIf="arabicLanguage">{{item.real_id}} : {{ 'commun.cmdn' | translate }}</h3>
              </a>
              <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted">{{ 'sales.attValid' | translate }}</span>
              <span class="label label-success no-margin-top" *ngIf="item.isCompleted">{{ 'sales.valide' | translate }}</span>
            </span>
            <span class="clr-timeline-step-description">
              <h6 class="no-margin-top" *ngIf="!arabicLanguage">{{ 'sales.monTotal' | translate }} <b>{{item.totalPrice |
                  currency:devise:'symbol':'1.2-3':'fr'}}</b>
              </h6>
              <h6 class="no-margin-top" *ngIf="arabicLanguage" style="text-align: justify;"><b>{{item.totalPrice |
                  currency:devise:'symbol':'1.2-3':'fr'}}</b> {{ 'sales.monTotal' | translate }}
              </h6>
              <button class="btn btn-sm" (click)="detailOrder(item.id)" *ngIf="!showOrder">{{ 'sales.details' | translate }}</button>
              <button class="btn btn-sm" (click)="closeDtailOrder()"
                *ngIf="showOrder && indexOrder==item.id">{{ 'sales.fermer' | translate }}</button>

              <div class="overflow-x">
                <table class="table table-compact no-margin-top" *ngIf="showOrder && indexOrder==item.id">
                  <thead>
                    <tr>
                      <th class="left">{{ 'pack.produit' | translate }}</th>
                      <th>{{ 'sharedSales.réference' | translate }}</th>
                      <th>{{ 'pack.quantite' | translate }}</th>
                      <th>{{ 'sales.prix' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of item.orderDetails">
                    <tr>
                      <td class="left">{{item.productId.name}}</td>
                      <td>{{item.productId.reference}}</td>
                      <td>{{item.quantity}}</td>
                      <td>{{item.quantity * item.productId.price | currency:devise:'symbol':'1.2-3':'fr'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card" *ngIf="showOrder && item.operations.length>0 && indexOrder==item.id">
                <div class="card-block">
                  <div class="card-title">
                    {{ 'sales.lisOpera' | translate }}
                  </div>
                  <div class="card-text">
                    <div *ngFor="let element of item.operations">
                      <div>
                        <small *ngIf="!arabicLanguage">{{ 'sales.date' | translate }} <b>{{element.createdAt | date:'dd/MM/yyyy à HH:mm'}}</b> </small>
                        <small *ngIf="arabicLanguage"> <b>{{element.createdAt | date:'dd/MM/yyyy à HH:mm'}}</b> {{ 'sales.date' | translate }}</small>
                      </div>
                      <small *ngIf="!arabicLanguage"><span class="margin-right-10">{{ 'sales.operation' | translate }} <b>{{element.real_id}}</b> </span><br>
                        {{ 'sales.etat' | translate }} <span *ngIf="item.isCompleted"> <b>{{ 'sales.validé' | translate }}</b> </span>
                        <span *ngIf="!item.isCompleted"> <b>{{ 'sales.Nonvalidé' | translate }}</b></span></small>
                      <small *ngIf="arabicLanguage"><span class="margin-right-10"><b>{{element.real_id}}</b> {{ 'sales.operation' | translate }}</span></small><br>
                      <small *ngIf="arabicLanguage" style="display: inline-flex;"><span *ngIf="item.isCompleted" style="margin-right: 5px;"><b>{{ 'sales.validé' | translate }}</b></span>
                        <span *ngIf="!item.isCompleted" style="margin-right: 5px;"><b>{{ 'sales.Nonvalidé' | translate }}</b></span>  {{ 'sales.etat' | translate }} 
                      </small>
                      <table class="table table-compact no-margin-top">
                        <thead>
                          <tr>
                            <th class="left">{{ 'pack.produit' | translate }}</th>
                            <th>{{ 'pack.quantite' | translate }}</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let element of element.operationDetails">
                          <tr>
                            <td class="left">{{element.productId.name}}</td>
                            <td>{{element.quantity}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>

          <div class="clr-timeline-step-body" *ngIf="item?.operationDetails">
            <span class="clr-timeline-step-title flex">
              <h3 class="no-margin-top">{{ 'sales.operation' | translate }} {{item.real_id}}</h3>
              <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted && !item.forDelivery">
                {{ 'commun.nv' | translate }}</span>
              <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted && item.forDelivery">
                {{ 'sales.nonLivre' | translate }}</span>
              <span class="label label-success" *ngIf="item.isCompleted && item.forDelivery">{{ 'sales.livre' | translate }}</span>
              <span class="label label-success" *ngIf="item.isCompleted && !item.forDelivery">{{ 'sales.validé' | translate }}</span>
            </span>
            <span class="clr-timeline-step-description">
              <h6 class="no-margin-top" *ngIf="item.orderId">{{ 'sales.opeDe' | translate }} <b>{{ 'sales.vente' | translate }}</b> {{ 'sales.pour' | translate }}
                <b>{{item.orderId.customerId.name}}</b>
              </h6>
              <div *ngIf="showoperation && indexOp==item.id">
                <h6 class="no-margin-top" *ngIf="item.orderId">{{ 'sales.nCmde' | translate }} {{item.orderId.real_id}}</h6>
                <h6 class="no-margin-top" *ngIf="item.buyingId">{{ 'sales.nAch' | translate }} {{item.buyingId.real_id}}</h6>
                <h6 class="no-margin-top" *ngIf="item.orderId">{{ 'sales.mnt' | translate }} {{item.orderId?.totalPrice |
                  currency:devise:'symbol':'1.2-3':'fr'}}</h6>
                <h6 class="no-margin-top" *ngIf="item.buyingId">{{ 'sales.mnt' | translate }} {{item.buyingId?.totalPrice |
                  currency:devise:'symbol':'1.2-3':'fr'}}</h6>
                <h6 class="no-margin-top">{{ 'sales.livrable' | translate }}
                  <span class="label label-success" *ngIf="item.forDeliver">{{ 'sales.oui' | translate }}<br></span>
                  <span class="label label-danger" *ngIf="!item.forDeliver">{{ 'sales.non' | translate }}</span><br>
                </h6>

                <h6 class="no-margin-top">{{ 'sales.etat' | translate }}
                  <span class="label label-success" *ngIf="item.isCompleted">{{ 'sales.validé' | translate }}<br></span>
                  <span class="label label-danger" *ngIf="!item.isCompleted">{{ 'sales.Nonvalidé' | translate }}</span><br>
                </h6>
                <h6 class="no-margin-top">{{ 'sales.date' | translate }} {{item.createdAt | date:'dd/MM/yyyy'}}</h6>
                <div class="overflow-x">
                  <table class="table table-compact">
                    <thead>
                      <tr>
                        <th class="left">{{ 'sales.nomProd' | translate }}</th>
                        <th>{{ 'sharedSales.réference' | translate }}</th>
                        <th>{{ 'pack.quantite' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let element of item.operationDetails">
                        <td class="left">{{element.productId.name}}</td>
                        <td>{{element.productId.reference}}</td>
                        <td>{{element.quantity}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button class="btn btn-sm" (click)="detailOperation(item.id)" *ngIf="!showoperation">{{ 'sales.details' | translate }}</button>
              <button class="btn btn-sm" (click)="closeOpDetail()"
                *ngIf="showoperation && indexOp==item.id">{{ 'sales.fermer' | translate }}</button>
            </span>
          </div>

          <div class="clr-timeline-step-body" *ngIf="item?.quotationDetails">
            <span class="clr-timeline-step-description">
              <a href="/sales/quotation/detail/{{item.id}}" target="_blank">
                <h3 class="no-margin-top">{{ 'sales.devis' | translate }} <b>{{item.real_id}}</b></h3>
              </a>
              <h6 class="no-margin-top">{{ 'sales.demandé' | translate }}<b>{{item.customerId.name}}</b></h6>
              <h6 class="no-margin-top">{{ 'sales.montotal' | translate }}<b>{{item.totalPrice | currency:devise:'symbol':'1.2-3':'fr'}}</b>
              </h6>
              <button *ngIf="item.quotationDetails.length>0 && !showQuotation" class="btn btn-sm"
                (click)="detailQuotation(item.id)">{{ 'sales.details' | translate }}</button>
              <button class="btn btn-sm" (click)="closeQtDetail()"
                *ngIf="showQuotation && indexQuot==item.id">{{ 'sales.fermer' | translate }}</button>

              <div class="overflow-x">
                <table class="table table-compact" *ngIf="showQuotation && indexQuot==item.id  ">
                  <thead>
                    <tr>
                      <th>{{ 'sharedSales.réference' | translate }}</th>
                      <th>{{ 'sales.nomProd' | translate }}</th>
                      <th>{{ 'pack.quantite' | translate }}</th>
                      <th>{{ 'sales.prixunitaire' | translate }} HT</th>
                      <th>TVA</th>
                      <th>{{ 'sales.total' | translate }} HT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let element of item.quotationDetails">
                      <td>{{element.productId.reference}}</td>
                      <td>{{element.productId.name}}</td>
                      <td>{{element.quantity}}</td>
                      <td>{{element.productId.price}}</td>
                      <td>{{element.tax}}</td>
                      <td>{{element.amount}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </span>
          </div>

          <div class="clr-timeline-step-body" *ngIf="item?.transactionTypeId">
            <span class="clr-timeline-step-title flex">
              <h3 class="no-margin-top">{{ 'sales.transaction' | translate }} {{item.real_id}}</h3>
              <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted">{{ 'commun.nv' | translate }}</span>
              <span class="label label-success no-margin-top" *ngIf="item.isCompleted">{{ 'sales.valide' | translate }}</span>
            </span>
            <div class="flex">
              <h6 class="no-margin-top" *ngIf="item.orderId"><b>{{ 'sales.transVente' | translate }} {{item.orderId.real_id}}</b>
              </h6>
              <h6 class="no-margin-top" *ngIf="item.buyingId"><b>{{ 'sales.transAchat' | translate }} {{item.buyingId.real_id}}</b>
              </h6>
            </div>
            <span class="clr-timeline-step-description">
              <h6 class="no-margin-top" *ngIf="item.buyingId">{{ 'sales.fournisseur' | translate }} <b>{{item.providerId.name}}</b></h6>
              <h6 class="no-margin-top">{{ 'sales.montTrans' | translate }} <b>{{item.amount |
                  currency:devise:'symbol':'1.2-3':'fr'}}</b></h6>
              <h6 class="no-margin-top" *ngIf="buyingId">{{ 'sales.montAchat' | translate }} <b>{{item.buyingId.totalprice |
                  currency:devise:'symbol':'1.2-3':'fr'}}</b></h6>
              <h6 class="no-margin-top" *ngIf="item.orderId">{{ 'sales.montCmde' | translate }} <b>{{item.orderId.totalPrice |
                  currency:devise:'symbol':'1.2-3':'fr'}}</b></h6>
              <h6 class="no-margin-top" *ngIf="item.transactionTypeId">{{ 'sales.typeTrans' | translate }} <b>{{item.transactionTypeId.name}}</b></h6>

              <button class="btn btn-sm" (click)="detailTrans(item.id)"
                *ngIf="!showTrans && item.checkImage">Détails</button>
              <button class="btn btn-sm" (click)="closeTrDetail()" *ngIf="showTrans && indexTr==item.id">{{ 'sales.fermer' | translate }}</button>

              <div class="clr-row" *ngIf="showTrans && indexTr==item.id">
                <div class="clr-col-12 overflow-x">
                  <div class="card" *ngIf="item.checkImage">
                    <div class="card-header">
                      {{ 'sales.imgCheque' | translate }}
                    </div>
                    <div class="card-block">
                      <div class="card-text">
                        <h6 class="no-margin-top" *ngIf="item.checkImage"><img src={{item.checkImage}} width="50"
                            height="150"></h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </div>

    <div class="clr-col-12 clr-col-md-4" id="searchDiv">
      <div class="card fixit">
        <div class="card-header center">
          {{ 'sales.recherche' | translate }}
        </div>
        <div class="card-block">
          <div id="checkboxDiv">
            <clr-checkbox-container>
              <label><b>{{ 'sales.affichage' | translate }}</b></label>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="all" value="true" [(ngModel)]="searchObject.all" />
                <label>{{ 'sales.tous' | translate }}</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="quotations" value="true"
                  [(ngModel)]="searchObject.quotations" />
                <label>{{ 'sales.devis' | translate }}</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="orders" value="true" [(ngModel)]="searchObject.orders" />
                <label>{{ 'sales.bonCmde' | translate }}</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="delivery" value="true" [(ngModel)]="searchObject.delivery" />
                <label>{{ 'commun.opi' | translate }}</label>
              </clr-checkbox-wrapper>
              <!--<clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="Factures"  value="true"
                  [(ngModel)]="searchObject.Factures" />
                <label>Factures</label>
              </clr-checkbox-wrapper>-->
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="transactions" value="true"
                  [(ngModel)]="searchObject.transactions" />
                <label>{{ 'Transactions.Tr' | translate }}</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </div>
          <clr-radio-container clrInline>
            <label>{{ 'sales.etat' | translate }} </label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="valide" [value]="searchObject.allEtat"
                [(ngModel)]="searchObject.allEtat" (click)="allEtat()" />
              <label>{{ 'sales.tous' | translate }}</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="valide" value="false" [(ngModel)]="searchObject.valid"
                (click)="resetRadioButtonValide()" />
              <label>{{ 'sales.validé' | translate }}</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="valide" value="true" [(ngModel)]="searchObject.inValid"
                (click)="resetRadioButtonNonValide()" />
              <label>{{ 'sales.Nonvalidé' | translate }}</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <div id="dateDiv" class="clr-col-12 no-pading">
            <div class="flex">
              <clr-date-container>
                <label>{{ 'sales.de' | translate }}</label>
                <input type="date" [(clrDate)]="searchObject.dateDebut">
              </clr-date-container>
              <button type="button" class="btn btn-icon btn-sm btn-danger-outline resetDateButton"
                title="réinitialiser le date de début" (click)="resetStartDate()">
                <clr-icon shape="times"></clr-icon>
              </button>
            </div>
            <div class="flex">
              <clr-date-container>
                <label>{{ 'sales.jusqu' | translate }}</label>
                <input type="date" [(clrDate)]="searchObject.dateFin">
              </clr-date-container>
              <button type="button" class="btn btn-icon btn-sm btn-danger-outline resetDateButton"
                title="réinitialiser le date de fin" (click)="resetEndDate()">
                <clr-icon shape="times"></clr-icon>
              </button>
            </div>
          </div><br>
        </div>
        <div class="card-footer">
          <button class="btn btn-sm" (click)="getHistory()">
            <clr-icon shape="search"></clr-icon> {{ 'sales.recherche' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <br><br>
  <div class="clr-offset-md-4 clr-offset-lg-5" *ngIf="this.data.length < count  && this.data.length > 0">
    <button [clrLoading]="validateBtnState" class="btn btn-link " (click)="loadMore()">{{ 'sales.affichSuite' | translate }}</button>
  </div>
  <br><br>
</section>