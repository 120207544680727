import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { ClarityModule } from '@clr/angular';
import { CheckboxListFilterComponent } from './checkbox-list-filter/checkbox-list-filter.component';
import { FormsModule } from '@angular/forms';
import localeFr from '@angular/common/locales/fr';
import { NameFilterComponent } from './name-filter/name-filter.component';
import { CustomerNameFilterComponent } from './customer-name-filter/customer-name-filter.component';
import { ComboboxListFilterComponent } from './combobox-list-filter/combobox-list-filter.component';
import { DescriptionListFilterComponent } from './description-list-filter/description-list-filter.component';
import { FamilleListFilterComponent } from './famille-list-filter/famille-list-filter.component';
import { EmailListFilterComponent } from './email-list-filter/email-list-filter.component';
import { ComboboxFilterComponent } from './combobox-filter/combobox-filter.component';
import { CdsModule } from '@cds/angular';
registerLocaleData(localeFr);


@NgModule({
  declarations: [DateFilterComponent, CheckboxListFilterComponent, NameFilterComponent, ComboboxListFilterComponent,CustomerNameFilterComponent
    , DescriptionListFilterComponent, FamilleListFilterComponent, FamilleListFilterComponent,EmailListFilterComponent,ComboboxFilterComponent],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    CdsModule

  ],
  exports: [
    DateFilterComponent,
    CheckboxListFilterComponent,
    NameFilterComponent,
    CustomerNameFilterComponent,
    ComboboxFilterComponent,
    ComboboxListFilterComponent,
    DescriptionListFilterComponent,
    FamilleListFilterComponent,
    EmailListFilterComponent
  ],
  providers:[  { provide: LOCALE_ID, useValue: 'fr-FR'}
  ]
})
export class SharedModule { }
