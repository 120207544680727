<div class="modal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title"> {{ 'commun.spu' | translate }} </h3>
          <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
            fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </div>
        <br>
        <div class="modal-body">
          <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
            <clr-alert-item>
              <span class="alert-text">
                voulez vous vraiment supprimer les autorisations de l'utilisateur
                {{user?.name}} !
              </span>
            </clr-alert-item>
          </clr-alert>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline" type="button" (click)="closeAction()"> {{ 'commun.nn' | translate }}</button>
          <button class="btn btn-primary" type="button" (click)="deleteAutorisation()"> {{ 'commun.sp' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop" aria-hidden="true"></div>
  