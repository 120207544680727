import { Component, HostListener } from '@angular/core';
import { Customer } from '../parts';
import { ClrDatagridStateInterface, ClrDatagridSortOrder } from '@clr/angular';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { PartsService } from '../parts.service';
import { CoreService } from 'src/app/core/core.service';
import jwtDecode from 'jwt-decode';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent {
  filter: FilterDto<Customer> = new FilterDto<Customer>()
  whereFindOperator = new WhereFindOperator()
  descSort: ClrDatagridSortOrder;
  customerFilter: boolean
  descCustomer: boolean
  openCustomerFilter: boolean;
  showAlert: boolean;
  customerArray: Customer[] = []
  showCustomer: Customer[] = []
  tmpList: Customer[] = []
  count: number
  loading: boolean
  CustomerObject: Customer;
  customerId: Customer;
  searchString: string;
  pathName: string;
  showDetail: boolean;
  id_entreprise: number
  userRole: string
  currentPage: number = 1
  pageSize: number = 5
  total: number
  nbr_page: number = 1
  insertIndex: number = 0
  type: string
  types: string[] = ["date", "nom"]
  date_search: Date
  search_text: string
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""  
  userAutorisation : Autorisation = new Autorisation()
  userId : number
  isAdmin : boolean = false 

  constructor(private userService : UserService, private partsservice: PartsService, 
    private serviceCore: CoreService, private translate:TranslateService) {
    this.pathName = window.location.pathname
    this.descSort = ClrDatagridSortOrder.ASC;
    if (this.pathName == '/search') {
      this.filter = new FilterDto<Customer>()
      window.addEventListener('find', (evt: any) => {
        this.searchString = evt.detail
        this.refresh()
      });
    } else {
      this.searchString = undefined
    }
  }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.userId = parseInt(jwtDecode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getUserAutorisation()
    }
    this.descSort = ClrDatagridSortOrder.ASC;
    this.filter.where = {}
    this.filter.take = this.pageSize
    this.getCustomer()
    if (this.pathName == '/search') {
      this.filter = new FilterDto<Customer>()
      this.filter.where = {}
      this.filter.take = this.pageSize
      this.searchString = this.serviceCore.getSearchStream()
    } else {
      this.searchString = undefined
    }
  }
  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  }
  typeChanged(type: string) {
    this.type = this.translateItem(type)
    this.customerArray = this.tmpList
    this.total = this.customerArray.length
    if (this.total > 0) {
      this.nbr_page = Math.ceil(this.total / this.pageSize)
    } else {
      this.nbr_page = 1
    }
  }
  getCustomer() {
    if (!this.customerArray[this.insertIndex]) {
      this.filter.order = { "id": "DESC" }
      this.filter.where = Object.assign(this.filter.where.valueOf(), {
        "entrepriseId": this.id_entreprise 
      })
      this.partsservice.getCustomers(this.filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.customerArray[this.insertIndex + i] = data[0][i];
          }
          this.tmpList = this.customerArray
          this.showCustomer = this.customerArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.total = data[1]
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        }
      )
    } else {
      this.showCustomer = this.customerArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getCustomer()
  }
  search_client(event:Event) {
    let saisie = ((event.target as HTMLInputElement).value)
    if (this.search_text.length == 0) {
      this.customerArray = []
      this.total = 1
      this.filter.where = {}
      this.getPaginatedData(1)
    } else {
      this.customerArray = []
      this.tmpList.filter(x => {
        if (x.name.toLowerCase().indexOf(this.search_text.toLowerCase().trim()) != -1) {
          this.customerArray.push(x);
        }
      })  
      this.total = this.customerArray.length
      if (this.total > 0) {
        this.nbr_page = Math.ceil(this.total / this.pageSize)
      } else {
        this.nbr_page = 1
      }
      this.showCustomer = this.customerArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
      if (this.customerArray.length == 0 && saisie[saisie.length - 1] !== ' ') {
        this.filter.order = { "id": "ASC" }
        this.filter.take = this.pageSize;
        this.filter.where = { name: {
          type: "like",
          value: "*" + this.search_text.trim() + "*"
        }}
        this.getCustomer()
      }
    }
  }
  searchWithDate(event:Event) {
    let date_search = (event.target as HTMLInputElement).value
    if (date_search !== '' && date_search.split('-')[0].startsWith('2')) {
      let date_debut = new Date(date_search).setHours(0, 0, 1)
      let date_fin = new Date(date_search).setHours(23, 59, 59)
      this.filter.where = {
        createdAt: {
          type: "between",
          value: [new Date(date_debut), new Date(date_fin)],
        },
      };
      this.initialVariables()
      this.getCustomer()
    } else if (date_search == '') {
      this.filter.where = {}
      this.initialVariables()
      this.getCustomer()
    }
  }
  initialVariables(){
    this.customerArray = []
    this.insertIndex = 0
    this.currentPage = 1
    this.filter.skip = 0
  }
  refresh(state?: ClrDatagridStateInterface) {
    // if (state) {
    //   this.filter = this.filterService.bind(state);
    // }
    // if (state?.filters) {
    //   this.whereFindOperator.key = this.filterService.dateSearch(state)
    // }
    // if (!this.whereFindOperator.key) { this.whereFindOperator.key = undefined }
    // let data = { active: true }
    // if (!state?.filters) {
    //   this.filter.where = data
    // }
    // else {
    //   this.filter.where = Object.assign(this.filter.where.valueOf(), data)
    //   if (this.whereFindOperator.key) {
    //     this.filter.where = Object.assign(this.filter.where.valueOf(), this.whereFindOperator.key)
    //   }
    // }

    // if (this.searchString) {
    //   this.filter.where = Object.assign(this.filter.where.valueOf(), { name: { type: "like", value: "*" + this.searchString + "*" } })
    // }
    // this.filter.order = { "id": "DESC" }
    // this.filter.where = { "entrepriseId": this.id_entreprise }
    // this.partsservice.getCustomers(this.filter).subscribe(
    //   data => {
    //     this.customerArray = data[0];
    //     this.count = data[1];
    //   }
    // )
  }
  
  deleteAction(item) {
    this.showAlert = true;
    this.customerId = item.id
  }
  detailAction(item) {
    this.showDetail = true;
    this.customerId = item
  }

  closeAction() {
    this.showAlert = false;
    this.showDetail = false;
  }

  openDescription() {
    this.descCustomer = true
  }
  openNamefilter() {
    this.openCustomerFilter = true
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
  translateItem(item: string): string {
    return this.translate.instant(`sharedSales.${item}`);
  }
}
