<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> Detail de pack </h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="close()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <div class="clr-row">
          <div class="clr-col-4">
            <span><b> Nom de pack </b></span><br>
            <span><b> Prix </b></span><br>
            <span><b>Tax </b></span><br>
            <span><b> Creé par </b></span><br>
            <span *ngIf="userEdit && modelpack.updatedBy"><b>Modifieé par </b></span>
          </div>
          <div class="clr-col-8">
            <span>{{modelpack?.nom}}</span><br>
            <span>{{modelpack?.price| number:'1.2-2'}}</span><br>
            <span>{{modelpack?.taxId?.percentage}} %</span><br>
            <span>{{userAdd?.name}}</span> à <b>{{modelpack?.createdAt | date:'dd/MM/yyyy à HH:mm'}}</b>
            <br>
            <span *ngIf="userEdit && modelpack.updatedBy">
              <span>{{userEdit?.name}}</span> à <b>{{modelpack?.updatedAt | date:'dd/MM/yyyy à HH:mm'}}</b>
            </span>
          </div>
          <table class="table table-compact">
            <thead>
              <tr>
                <th> {{ 'article.Prod.Pro' | translate }}</th>
                <th> {{ 'article.Prod.qnt' | translate }}</th>
                <th> {{ 'commun.puht' | translate }}</th>
                <th> {{ 'commun.tva' | translate }}(%)</th>
                <!-- <th class="right"> {{ 'commun.mttc' | translate }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of modelpack?.details">
                <td>{{item.productid?.name}}</td>
                <td>{{item.qte_produit}}</td>
                <td>{{item.productid?.price}} {{devise}}</td>
                <td>{{item.productid?.taxId?.percentage}} %</td>
                <!-- <td class="right">{{(item.qte_produit*item.qte_produit)*(1+(item.productid?.tax?.percentage/100)) |
                  number:'1.2-2'}}
                  {{devise }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>