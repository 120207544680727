import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommandeRoutingModule } from './commande-routing.module';
import { CommandeComponent } from './commande/commande.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommandeAddComponent } from './commande/commande-add/commande-add.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommandeProductComponent } from './commande/commande-product/commande-product.component';
import { CommandeEditComponent } from './commande/commande-edit/commande-edit.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommandeDetailComponent } from './commande/commande-detail/commande-detail.component';
import { CommandeDeleteComponent } from './commande/commande-delete/commande-delete.component';


@NgModule({
  declarations: [
    CommandeComponent,
    CommandeAddComponent,
    CommandeProductComponent,
    CommandeEditComponent,
    CommandeDetailComponent,
    CommandeDeleteComponent
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    CommandeRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgbModule
  ]
})
export class CommandeModule { }
