import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Product } from 'src/app/catalog/product/product';
import { ProductService } from 'src/app/catalog/product/product.service';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-search-product-modal',
  templateUrl: './search-product-modal.component.html',
  styleUrls: ['./search-product-modal.component.css']
})
export class SearchProductModalComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>()
  search_form: UntypedFormGroup
  product_info: Product
  entrepriseId: number
  show_info: boolean = false
  store_id: number
  constructor(private fb: FormBuilder, private productService: ProductService,
    private settingService: SettingService) {
    this.search_form = this.fb.group({
      reference: ['', [
        Validators.required
      ]]
    })
  }

  ngOnInit(): void {
    this.entrepriseId = parseInt(getValueFromToken("idEntreprise"))
    this.show_info = false
    this.getUserInfo()
  }
  getUserInfo() {
    let id_user = parseInt(getValueFromToken("id"))
    let filter = new FilterDto()
    filter.where = { "id": id_user, "entrepriseId": this.entrepriseId }
    filter.relations = ["cratesId","cratesId.pointid", "cratesId.pointid.storeId"]
    this.settingService.getUserInfo(filter).subscribe(
      data => {
        if (data[0][0] != undefined) {
          this.store_id = data[0][0].cratesId.pointid.storeId.id
        }
      }
    )
  }
  closeAction() {
    this.closed.emit(true)
  }
  research() {
    this.show_info = false
    this.product_info = new Product()
    this.search_form.reset();
  }
  searchProduct(item) {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.entrepriseId }
    filter.relations = ["markId", "taxId", "unityId", "categoryId", "productPrices", "productPrices.priceTypeId", "productDetails", "productDetails.detailId"];
    this.productService.getInfoProducts(this.store_id, filter).subscribe(
      data => {
        let index = data[0].findIndex(x => x.product.bar_code == item.bar_code)
        if (index > -1) {
          this.product_info = data[0][index].product
          this.show_info = true
        }
      }
    )
  }


}
