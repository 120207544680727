import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tokenGetter } from '../user/user.service';
import jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeRoles = route.data && route.data.roles;
    const token = tokenGetter()
    if (token.length > 0 && routeRoles.includes((jwt_decode(token)["role"]))) {
      return true;
    }
    else if (token.length == 0) {
      this.router.navigate(['/user/login']);
      return false;
    } else {
      this.router.navigate(['/dashboard'])
    }
  }
}