import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PartsService } from '../../parts.service';
import { StockService } from '../../../stock/stock.service';
import { FilterDto } from 'src/app/filter.dto';
import { Contact } from 'src/app/user/user';
import { Address, Providers } from '../../parts';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';

@Component({
  selector: 'app-provider-edit',
  templateUrl: './provider-edit.component.html',
  styleUrls: ['./provider-edit.component.css']
})
export class ProviderEditComponent implements OnInit {
  providerId: number;
  showAlert: boolean;
  showDetail: boolean;
  showDetailAdr: boolean;
  providerObject: Providers = new Providers()
  contact = new Contact()
  adresse = new Address()
  adressArray = []
  contactArray = []
  allContact = []
  allAdress = []
  editedContact: boolean;
  editedAdress: boolean;
  usedMail: boolean;
  usedAdr: boolean;
  editMode: boolean;
  initaStep: string
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private route: ActivatedRoute, private partsService: PartsService, private stockService: StockService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.initaStep = 'step4'
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.providerId = +params.get('id')
    })
    let filter: FilterDto<Providers> = new FilterDto()
    filter.relations = ["contacts", "adress"]
    filter.where = { id: this.providerId, active: true, "entrepriseId": this.id_entreprise }
    this.partsService.getProviders(filter).subscribe(
      data => {
        this.providerObject = data[0][0]
      }
    )
    this.partsService.getContacts({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allContact = data[0]
      }
    )
    this.stockService.getAdress({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allAdress = data[0]
      }
    )

  }
   

  addContact() {
    const existsInBd = this.allContact.filter(element =>
      element.email === this.contact.email
      && element.entrepriseId == this.id_entreprise
    )
    const existsInTab = this.contactArray.filter(element => element.email === this.contact.email)
    if (this.editMode) {
      if (existsInTab.length > 0) {
        this.usedMail = true
        this.showDetail = false
      }
      else {
        this.contact.entrepriseId = this.id_entreprise
        this.providerObject.contacts.push(this.contact)
        if (this.contact.tel) {
          this.providerObject.tel = this.contact.tel
        } else {
          this.providerObject.tel = this.contactArray[0].tel
        }
        this.contact = new Contact()
        this.showDetail = true;
        this.editedContact = false
        this.usedMail = false
        this.editMode = false
      }
    }
    else {
      if (existsInBd.length > 0 || existsInTab.length > 0) {
        this.usedMail = true
        this.showDetail = false
      }
      else {
        this.contact.entrepriseId = this.id_entreprise
        this.providerObject.contacts.push(this.contact)
        if (this.contact.tel) {
          this.providerObject.tel = this.contact.tel
        } else {
          this.providerObject.tel = this.contactArray[0].tel
        }
        this.contact = new Contact()
        this.showDetail = true;
        this.editedContact = false
        this.usedMail = false
        this.editMode = false

      }
    }



  }


  deleteContact(item) {
    this.providerObject.contacts.splice(this.contactArray.indexOf(item), 1);
    this.showDetail = false;
  }
  editContact(item) {
    this.editedContact = true
    this.contact = item
    this.providerObject.contacts.splice(this.contactArray.indexOf(item), 1);
    this.editMode = true
  }
  addAdresse() {
    this.adresse.entrepriseId = this.id_entreprise
    this.providerObject.adress.push(this.adresse)
    this.adresse = new Address()
    this.showDetailAdr = true;
    this.editedAdress = false
    this.usedAdr = false
  }

  editAdresse(item) {
    this.editedAdress = true
    this.adresse = item
    this.providerObject.adress.splice(this.adressArray.indexOf(item), 1);

  }

  deleteAdresse(item) {
    this.providerObject.adress.splice(this.adressArray.indexOf(item), 1);
    this.showDetailAdr = false;
  }

  submit() {
    this.providerObject.entrepriseId = this.id_entreprise
    this.providerObject.updatebyAdmin = this.isAdmin
    this.partsService.editProvider(this.providerId, this.providerObject).subscribe(
      data => {
        this.showAlert = true;
      }
    )
    this.showAlert = true;
  }

  clrInitialStep(initialStep: string) {
    this.initaStep = initialStep
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
