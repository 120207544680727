import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Detail } from '../detail';
import { DetailService } from '../detail.service';

@Component({
  selector: 'app-detail-detail',
  templateUrl: './detail-detail.component.html',
  styleUrls: ['./detail-detail.component.css']
})
export class DetailDetailComponent implements OnInit {
  @Input() detail: Detail;
  @Output() closed = new EventEmitter<boolean>();
  showAlert: boolean;

  constructor(private detailService: DetailService) { }

  ngOnInit() {
    this.detailService.getDetailById(this.detail.id)
      .subscribe(
        data => {
          this.detail = data
        })
  }
  close() {
    this.closed.emit(true);
  }
}