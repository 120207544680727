import { Component, OnInit, HostListener } from '@angular/core';
import { HistoriqueObject, HistoriqueReception } from '../parts/parts';
import { PartsService } from '../parts/parts.service';
import { FilterService } from '../shared/filter.service';
import { formatDate } from '../whereFindOperator';
import { FilterDto } from '../filter.dto';
import { Customer } from 'src/app/parts/parts';
import { QuotationService } from '../sales/quotation/quotation.service';
import { Client } from '../sales/quotation/quotation';
import { id } from '@cds/core/internal';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'app-activites',
  templateUrl: './activites.component.html',
  styleUrls: ['./activites.component.css']
})
export class ActivitesComponent implements OnInit {

  searchObject: HistoriqueObject = new HistoriqueObject()
  operationArray = []
  emptyHistory: boolean = false;
  showOrder: boolean;
  showoperation: boolean;
  showTrans: boolean;
  showAlert: boolean;
  showQuotation: boolean
  saisie: string
  filter: FilterDto<Customer> = new FilterDto<Customer>()
  loading: boolean
  clients: Customer[] = [];
  client: Customer = new Customer();
  count: number = 0
  indexOrder: number
  indexOp: number
  indexTr: number
  indexQuot: number
  data = []
  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  allData: any[];

  constructor(
    private partsService: PartsService,
    private filterService: FilterService,
    private quotationService: QuotationService) { }

  ngOnInit(): void {
    this.searchObject.all = true
    this.getHistory()
  }

  getHistory() {
    this.count = 0
    this.emptyHistory = false
    this.operationArray = []
    this.dateSetting();
    this.data=[]
    this.allData=[]
    this.partsService.getCustomerHistory(this.searchObject).subscribe(
      history => {
        this.allData = history.result
        this.count = history.result.length
        if (this.allData.length == 0) {
          this.emptyHistory = true
        } else {
          this.sortData();
          this.devideTable();
        }
      }
    )
  }

  devideTable() {
    if (this.allData?.length > 14) {
      for (let i = 0; i < 15; i++) {
        if(this.allData[i]?.id){
          this.data.push(this.allData[i])
          this.allData.shift();
        }
      }
    }
    else {
      if(this.searchObject.customerId){
        this.data = this.allData
        this.count = this.allData.length
      }else{
        this.partsService.getCustomerHistory(this.searchObject).subscribe(
          history => {
            this.data = history.result
            this.count = history.result.length
          }
        )
      }
    }
  }


  loadMore() {
    this.emptyHistory = false
    this.operationArray = []
    this.devideTable();
  }


  onSearchChange(args?: string) {
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.where = { name: { type: "like", value: this.saisie }, active: true }
    }
    else {
      this.filter.where = { active: true }
    }
    this.filter.order = { "createdAt": 'DESC' };
    this.filter.select = ["id", "name", "createdAt"];
    this.getCustomers(this.filter)

  }
  getCustomers(filter: FilterDto<Customer>) {
    this.quotationService.getCustomers(filter).subscribe(
      customers => {
        this.clients = customers[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ', err),
    );
  }

  clientChanged(client: Client) {
    this.searchObject.customerId = client.id
    this.client = client
  }
  resetName() {
    this.client = new Customer();
    this.searchObject.customerId = undefined
  }

  sortData() {
    if (this.allData) {
      let newData = []
      let operationDetails;
      this.allData = this.allData.sort((a, b) => b.createdAt - a.createdAt);
      if (this.searchObject.customerId) {
        operationDetails = this.allData.filter((item) => item.operationDetails && item.orderId?.customerId?.id == this.searchObject.customerId);
        let operation = operationDetails.filter((item) => item.operationDetails[0]?.operationTypeId?.id == 1);
        this.allData.forEach((element) => {
          if (!element.operationDetails) {
            newData.push(element)
          }
        });
        this.allData = []
        this.allData = newData
        operation.forEach((element) => {
          this.allData.push(element);
        });
        this.count = this.allData.length
        this.allData = this.allData.sort((a, b) => b.createdAt - a.createdAt);
      }
    }
  }


  closeDtailOrder() {
    this.showOrder = false
  }

  dateSetting() {
    if (this.searchObject.dateDebut) {
      let dateDeb = formatDate(new Date(this.searchObject.dateDebut))
      this.searchObject.dateDebut = dateDeb
    }
    if (this.searchObject.dateFin) {
      let dateFin = formatDate(new Date(this.searchObject.dateFin))
      this.searchObject.dateFin = dateFin
    }
  }

  resetRadioButtonNonValide() {
    this.searchObject.valid = undefined;
    this.searchObject.allEtat = undefined;
    this.searchObject.isCompleted = false
  }

  resetRadioButtonValide() {
    this.searchObject.isCompleted = true
    this.searchObject.inValid = undefined;
    this.searchObject.allEtat = undefined;
  }

  resetStartDate() {
    this.searchObject.dateDebut = undefined
  }
  resetEndDate() {
    this.searchObject.dateFin = undefined
  }

  allEtat() {
    this.searchObject.isCompleted = undefined
    this.searchObject.inValid = undefined;
    this.searchObject.valid = undefined;
  }

  detailOrder(id) {
    this.showOrder = true
    this.indexOrder = id
  }
  detailOperation(id) {
    this.showoperation = true
    this.indexOp = id
  }
  closeOpDetail() {
    this.showoperation = false
  }
  detailQuotation(id) {
    this.showQuotation = true
    this.indexQuot = id
  }
  closeQtDetail() {
    this.showQuotation = false
  }

  detailTrans(id) {
    this.showTrans = true
    this.indexTr = id
  }
  closeTrDetail() {
    this.showTrans = false
  }

}
