import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../user/user.service";

@Injectable({
  providedIn: 'root'
})

export class AccessGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.userService.isAdmin() == true) {
      return true
    } else {
      const autorisation = JSON.parse(localStorage.getItem("authorisation"));
      if (state.url.indexOf('catalog') != -1) {
        if (autorisation.menuArticle == false) {
          this.router.navigate(["dashboard"])
          return false
        }
        else if (autorisation.menuArticle == true) {
          if (state.url.indexOf('add') == -1 && state.url.indexOf('edit') == -1) {
            return true
          }
          else if (
            state.url.indexOf('unity/add') != -1 ||
            state.url.indexOf('mark/add') != -1 ||
            state.url.indexOf('category/add') != -1 ||
            state.url.indexOf('product/add') != -1 ||
            state.url.indexOf('pack/add') != -1
          ) {
            return autorisation.addArticle == true
          }
          else if (
            state.url.indexOf('unity/edit') != -1 ||
            state.url.indexOf('mark/edit') != -1 ||
            state.url.indexOf('category/edit') != -1 ||
            state.url.indexOf('product/edit') != -1 ||
            state.url.indexOf('pack/edit') != -1
          ) {
            return autorisation.editArticle == true
          }
        }
      } else if (state.url.indexOf("buying") != -1) {
        if (autorisation.menuBuying == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuBuying == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addBuying == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editBuying == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("sales/order") != -1) {
        if (autorisation.menuSales == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuSales == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addSales == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editSales == true
          } else if (state.url.indexOf("print") != -1) {
            return autorisation.printSales == true
          }
          else {
            return true
          }
        }
      } else if (state.url == "crates") {
        if (autorisation.menuCrates == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuCrates == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addCrates == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editCrates == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("sales/quotation") != -1) {
        if (autorisation.menuQuotation == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuQuotation == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addQuotation == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editQuotation == true
          } else if (state.url.indexOf("print") != -1) {
            return autorisation.printQuotation == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("sales/invoice") != -1) {
        if (autorisation.menuInvoice == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuInvoice == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addInvoice == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editInvoice == true
          } else if (state.url.indexOf("print") != -1) {
            return autorisation.printInvoice == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("sales/exit-voucher") != -1) {
        if (autorisation.menuExitVoucher == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuExitVoucher == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addExitVoucher == true
          } else if (state.url.indexOf("print") != -1) {
            return autorisation.printExitVoucher == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("stock/sales-delivery") != -1) {
        if (autorisation.menuSalesDelivery == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuSalesDelivery == true) {
          return true
        }
      } else if (state.url.indexOf("transport") != -1) {
        if (state.url == "/transport") {
          if (autorisation.menuLivraison == false) {
            this.router.navigate(["dashboard"])
            return false
          } else if (autorisation.menuLivraison == true) {
            if (state.url.indexOf("add") != -1) {
              return autorisation.addLivraison == true
            } else if (state.url.indexOf("edit") != -1) {
              return autorisation.editLivraison == true
            } else if (state.url.indexOf("print") != -1) {
              return autorisation.printLivraison == true
            } else {
              return true
            }
          }
        } else if (state.url.indexOf("transport/truck") != -1) {
          if (autorisation.menuVehicule == false) {
            this.router.navigate(["dashboard"])
            return false
          } else if (autorisation.menuVehicule == true) {
            if (state.url.indexOf("add") != -1) {
              return autorisation.addVehicule == true
            } else if (state.url.indexOf("edit") != -1) {
              return autorisation.editVehicule == true
            } else {
              return true
            }
          }
        } else if (state.url.indexOf("transport/carrier") != -1) {
          if (autorisation.menuLivreur == false) {
            this.router.navigate(["dashboard"])
            return false
          } else if (autorisation.menuLivreur == true) {
            if (state.url.indexOf("add") != -1) {
              return autorisation.addLivreur == true
            } else if (state.url.indexOf("edit") != -1) {
              return autorisation.editLivreur == true
            } else {
              return false
            }
          }
        }
      } else if (state.url.indexOf("stock/store") != -1) {
        if (autorisation.menuDepot == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuDepot == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addDepot == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editDepot == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("stock/mouvement") != -1) {
        return autorisation.menuMouvement == true
      } else if (state.url.indexOf("stock/inventory") != -1) {
        if (autorisation.menuInventaire == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuInventaire == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addInventaire == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editInventaire == true
          } else {
            return true
          }
        }
      }else if (state.url.indexOf("stock/expired-product") != -1) {
        if (autorisation.menuExpiredProd == false) {
          this.router.navigate(["dashboard"])
          return false
        } else if (autorisation.menuExpiredProd == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addExpiredProd == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editExpiredProd == true
          } else {
            return true
          }
        }
      } 
      else if (state.url.indexOf("finance/transaction") != -1) {
        if (autorisation.menuTransaction == false) {
          this.router.navigate(["dashboard"]);
          return false
        } else if (autorisation.menuTransaction == true) {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addTransaction == true
          } else if (state.url.indexOf("print") != -1) {
            return autorisation.printTransaction == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("finance/credit") != -1) {
        if (autorisation.menuCredit == false) {
          this.router.navigate(["dashboard"])
          return false
        } else {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addCredit == true
          } else if (state.url.indexOf("print") != -1) {
            return autorisation.printCredit == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("finance/crates") != -1) {
        if (autorisation.menuCaisseToday == false) {
          this.router.navigate(["dashboard"])
          return false
        } else {
          if (state.url.indexOf("print") != -1) {
            return autorisation.printCaisseToday == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("finance/cheque") != -1) {
        if (autorisation.menuCheque == false) {
          this.router.navigate(["dashboard"])
          return false
        } else {
          if (state.url.indexOf("edit") != -1) {
            return autorisation.editChequeGarantie == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("finance/cost") != -1) {
        if (autorisation.menuCost == false) {
          this.router.navigate(["dashboard"])
          return false
        } else {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addCost == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editCost == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("finance/plus-value") != -1) {
        if (autorisation.menuPlusValue == false) {
          this.router.navigate(["dashboard"])
          return false
        } else {
          if (state.url.indexOf("add") != -1) {
            return autorisation.addPlusValue == true
          } else if (state.url.indexOf("edit") != -1) {
            return autorisation.editPlusValue == true
          } else {
            return true
          }
        }
      } else if (state.url.indexOf("comptabilite/retenuesource") != -1) {
        if (autorisation.menuRetenueSource == false) {
          this.router.navigate(["dashboard"])
          return false
        } else {
          return true
        }
      }else if(state.url.indexOf("parts/customer") != -1){
        if(autorisation.menuCustomer == false){
          this.router.navigate(["dashboard"])
          return false 
        }else {
          if(state.url.indexOf("add") != -1 ){
            return autorisation.addCustomer == true
          }else if(state.url.indexOf("edit") != -1) {
            return autorisation.editCustomer == true  
          }
          else {
            return true
          }
        }
      }else if(state.url.indexOf("parts/provider") != -1){
        if(autorisation.menuProvider == false){
          this.router.navigate(["dashboard"])
          return false 
        }else {
          if(state.url.indexOf("add") != -1){
            return autorisation.addProvider == true
          }else if(state.url.indexOf("edit") != -1){
            return autorisation.editProvider == true
          }else {
            return true
          }
        }
      }else if(state.url.indexOf("parts/bank") != -1){
        if(autorisation.menuBanque == false){
          this.router.navigate(["dashboard"])
          return false 
        }else {
          if(state.url.indexOf("add") != -1){
            return autorisation.addBanque == true
          }else if(state.url.indexOf("edit") != -1){
            return autorisation.editBanque == true
          }else {
            return true
          }
        }
      }
      else if(state.url.indexOf("parts/company") != -1){
        if(autorisation.menuCompany == false){
          this.router.navigate(["dashboard"])
          return false 
        }else {
          if(state.url.indexOf("add") != -1){
            return autorisation.addCompany == true
          }else if(state.url.indexOf("edit") != -1){
            return autorisation.editCompany == true
          }else {
            return true
          }
        }
      }else if(state.url.indexOf("carte-fidelite") != -1){
        if(autorisation.menuCarteFidelite == false){
          this.router.navigate(["dashboard"])
          return false 
        }else {
          if(state.url.indexOf("add") != -1){
            return autorisation.addCarteFidelite == true
          }else if(state.url.indexOf("edit") != -1){
            return autorisation.editCarteFidelite == true
          }else if(state.url.indexOf("edit") != -1){
            return autorisation.printCarteFidelite == true 
          }
          else {
            return true
          }
        }
      }
    }
  }

}