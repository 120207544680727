import { Component,EventEmitter,HostListener,Input,OnInit, Output } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from '../../parts';


@Component({
  selector: 'app-customer-detail',
  templateUrl:'./customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
})
export class CustomerDetailComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() customerId:Customer;
  showAlert: boolean;
  inFlow:boolean
  filter: FilterDto<any> = new FilterDto<any>()
  completedOrd=[]
  delivredOrd=[]

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  event.target.innerWidth;
  if(event.target.innerWidth < 768){
    this.inFlow = true
  }
  else{
    this.inFlow=false
  }
}


  constructor(private route: ActivatedRoute) { }

  async ngOnInit() { 
  }

  closeAction() {
    this.closed.emit(true);
  }
}