<br>
<div class="clr-row" *ngIf="customerObject">
  <div class="clr-col-6 clr-col-md-3">
    <!-- <span><b>Nom&nbsp;de&nbsp;client </b></span><br> -->
    <span><b> {{ 'sharedSales.ncl' | translate }} </b></span><br>
    <span><b>{{ 'commun.mf' | translate }}</b></span><br>
    <span><b>{{ 'Relation.Clients.vll' | translate }}</b></span><br>
    <span><b>{{ 'Points.desc' | translate }}</b></span><br>
    <span><b>{{ 'sharedSales.crpar' | translate }}</b></span><br>
    <span *ngIf="customerObject.updatedAt"><b>{{ 'commun.mdp' | translate }}</b></span>
  </div>
  <div class="clr-col-6  clr-col-md-9 ">
    <span>{{customerObject.name}}</span>
    <br>
    <span *ngIf="customerObject.mf">{{customerObject.mf}}</span>
    <span class="label label-warning" *ngIf="!customerObject.mf">{{ 'commun.nd' | translate }}</span><br>
    <span *ngIf="customerObject.ville">{{customerObject.ville}}</span>
    <span *ngIf="!customerObject.ville"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
    <clr-textarea-container class="no-margin-top" *ngIf="customerObject.description">
      <textarea clrTextarea name="description" [(ngModel)]="customerObject.description" readonly></textarea>
    </clr-textarea-container>
    <br>
    <span *ngIf="!customerObject.description"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span><br>
    <span>{{userAdd?.name}} à {{customerObject?.createdAt | date: 'dd/MM/yyyy'}}</span>
    <span *ngIf="userEdit && customerObject.updatedBy">{{userEdit?.name}}
      à {{customerObject?.updatedAt | date: 'dd/MM/yyyy'}}
    </span>
  </div>
</div>
<h3 *ngIf="customerObject.contacts?.length>0">{{ 'commun.cnt' | translate }}</h3>
<div class="overflow-x" *ngIf="customerObject.contacts?.length>0">
  <table class="table">
    <thead>
      <tr>
        <th>{{ 'login.VotreNom' | translate }}</th>
        <th>{{ 'commun.Tel' | translate }}</th>
        <th>{{ 'login.VotreEmail' | translate }}</th>
        <!-- <th>Fax</th> -->
      </tr>
    </thead>
    <tbody *ngFor="let item of customerObject.contacts">
      <tr>
        <td>
          <span *ngIf="item.firstName">{{item.firstName}}</span>
          <span *ngIf="!item.firstName"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

        <td>
          <span *ngIf="item.tel">{{item.tel}}</span>
          <span *ngIf="!item.tel"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

        <td>
          <span *ngIf="item.email">{{item.email}}</span>
          <span *ngIf="!item.email"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

        <!-- <td>
          <span *ngIf="item.fax">{{item.fax}}</span>
          <span *ngIf="!item.fax"><span class="label label-warning">Non disponible</span></span>
        </td> -->

      </tr>
    </tbody>
  </table>
</div>
<h3 *ngIf="customerObject.adress?.length>0">{{ 'sales.adresses' | translate }}</h3>
<div class="overflow-x" *ngIf="customerObject.adress?.length>0">
  <table class="table">
    <thead>
      <tr>
        <th>{{ 'sales.numero' | translate }}</th>
        <th>{{ 'sales.route' | translate }}</th>
        <th>{{ 'sales.ville' | translate }}</th>
        <th>{{ 'commun.cdp' | translate }}</th>
        <th>{{ 'commun.gvr' | translate }}</th>
      </tr>
    </thead>
    <tbody *ngFor="let item of customerObject.adress">
      <tr>

        <td>
          <span *ngIf="item.number">{{item.number}}</span>
          <span *ngIf="!item.number"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

        <td>
          <span *ngIf="item.street">{{item.street}}</span>
          <span *ngIf="!item.street"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

        <td>
          <span *ngIf="item.city">{{item.city}}</span>
          <span *ngIf="!item.city"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

        <td>
          <span *ngIf="item.geoCode">{{item.geoCode}}</span>
          <span *ngIf="!item.geoCode"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

        <td>
          <span *ngIf="item.state">{{item.state}}</span>
          <span *ngIf="!item.state"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></span>
        </td>

      </tr>
    </tbody>
  </table>
</div>