import { User } from "../user";

export class Autorisation {
    constructor(
        public id?: number,
        public menuArticle?: boolean, 
        public addArticle?: boolean,
        public editArticle?: boolean,
        public deleteArticle?: boolean,
        public printArticle?:boolean,
        public menuExpiredProd?: boolean,
        public addExpiredProd?: boolean,
        public editExpiredProd?: boolean,
        public deleteExpiredProd?: boolean,
        public menuSales?: boolean, 
        public addSales?: boolean,
        public editSales?: boolean,
        public deleteSales?: boolean,
        public printSales?: boolean,
        public menuBuying?: boolean,
        public addBuying?: boolean,
        public editBuying?: boolean,
        public deleteBuying?: boolean,
        public menuCrates?: boolean,
        public addCrates?: boolean,
        public editCrates?: boolean,
        public deleteCrates?: boolean,
        public InventoryCrates?: boolean,
        // public menuDocuments?: boolean,
        public menuQuotation?: boolean, 
        public addQuotation?: boolean,
        public editQuotation?: boolean,
        public deleteQuotation?: boolean,
        public printQuotation?:boolean,
        public commandQuotation?:boolean,
        public menuInvoice?: boolean,
        public addInvoice?: boolean,
        public editInvoice?: boolean,
        public deleteInvoice?: boolean,
        public printInvoice?:boolean,
        public menuExitVoucher?: boolean,
        public addExitVoucher?: boolean,
        public printExitVoucher?: boolean,
        public deleteExitVoucher?: boolean,
        public menuSalesDelivery?: boolean,
        public printSalesDelivery?: boolean,
        // public menuLivraisons?: boolean,
        public menuLivraison?: boolean,
        public addLivraison?: boolean,
        public editLivraison?: boolean,
        public deleteLivraison?: boolean,
        public printLivraison?: boolean,
        public menuVehicule?: boolean,
        public addVehicule?: boolean,
        public editVehicule?: boolean,
        public deleteVehicule?: boolean,
        public menuLivreur?: boolean,
        public addLivreur?: boolean,
        public editLivreur?: boolean,
        public deleteLivreur?: boolean,
        public menuDepot?: boolean,
        public addDepot?: boolean,
        public editDepot?: boolean,
        public deleteDepot?: boolean,
        public menuMouvement?: boolean,
        public menuInventaire?: boolean,
        public addInventaire?: boolean,
        public editInventaire?: boolean,
        public deleteInventaire?: boolean,
        // public menuTransactions?: boolean,
        public menuTransaction?: boolean,
        public addTransaction?: boolean,
        public printTransaction?: boolean,
        public menuCredit?: boolean,
        public addCredit?: boolean,
        public printCredit?: boolean,
        public confirmCredit?: boolean,
        public menuCaisseToday?: boolean,
        public printCaisseToday?: boolean,
        public menuCheque?: boolean,
        public editChequeGarantie?: boolean,
        public deleteChequeGarantie?: boolean,
        public menuCost?: boolean,
        public addCost?: boolean,
        public editCost?: boolean,
        public deleteCost?: boolean,
        public menuPlusValue?: boolean,
        public addPlusValue?: boolean,
        public editPlusValue?: boolean,
        public deletePlusValue?: boolean,
        public menuComptabilite?: boolean,
        public menuRetenueSource?: boolean,
        public menuCustomer?: boolean,
        public addCustomer?: boolean,
        public editCustomer?: boolean,
        public deleteCustomer?: boolean,
        public menuProvider?: boolean,
        public addProvider?: boolean,
        public editProvider?: boolean,
        public deleteProvider?: boolean,
        public printProvider?:boolean,
        public menuBanque?: boolean,
        public addBanque?: boolean,
        public editBanque?: boolean,
        public deleteBanque?: boolean,
        public menuCompany?: boolean,
        public addCompany?: boolean,
        public editCompany?: boolean,
        public deleteCompany?: boolean,
        public createdAt?: Date,
        public updatedAt?: Date,
        public userId?: User & number,
        public menuCarteFidelite?: boolean,
        public addCarteFidelite?: boolean,
        public editCarteFidelite?: boolean,
        public deleteCarteFidelite?: boolean,
        public printCarteFidelite?: boolean,
    ) { }
}