<section>
  <div class="wrapper">
    <div class="demo-wrapper">
    <span>Comment modifier un produit</span>
    <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
      <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
      <span class="demo-span" id="demo-span">Demo</span>
    </button>
  </div>
  <h3> {{ 'commun.mod' | translate }} {{ 'article.Prod.Pro' | translate }}</h3>
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'commun.mds' | translate }}
        </span>
      </div>
    </div>
  </div><br>
  <div class="alert alert-danger" role="alert" *ngIf="tailleInvalid">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'commun.fv' | translate }}
        </span>
      </div>
    </div>
  </div>
  <br>
  <form clrStepper [clrInitialStep]="initialStep">
    <clr-stepper-panel ngModelGroup="step1" (click)="clrInitialStep('step1')">
      <clr-step-title> {{ 'article.Prod.Pro' | translate }} </clr-step-title>
      <clr-step-content>
        <form clrForm>
          <clr-input-container>
            <label> {{ 'article.deta.nom' | translate }} *</label>
            <input placeholder="{{ 'article.deta.nom' | translate }}" clrInput [(ngModel)]="model.name" name="name"
              required />
            <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label> {{ 'article.Prod.Réference' | translate }} *</label>
            <input placeholder=" {{ 'article.Prod.Réference' | translate }}" [(ngModel)]="model.reference"
              name="reference" clrInput />
            <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>Code à barre</label>
            <input placeholder="code à barre" [(ngModel)]="model.bar_code" name="bar_code" clrInput />
          </clr-input-container>
          <clr-input-container *ngIf="model.categoryId">
            <label> {{ 'article.Mcatg.catg' | translate }} *</label>
            <input clrInput name="nameCategory" readonly [ngModel]="model.categoryId.name" />
            <clr-control-helper class="mt-0">
              <button class="btn btn-link btn-sm mt-0" (click)="editCategory()">
                {{ 'commun.mod' | translate }} {{ 'article.Mcatg.catg' | translate }}
              </button>
            </clr-control-helper>
          </clr-input-container>
          <clr-combobox-container *ngIf="!model.categoryId">
            <label> {{ 'article.Mcatg.catg' | translate }}*</label>
            <clr-combobox name="categoryId" required [clrLoading]="loading" (ngModel)="model.categoryId.name"
              type="button" (clrInputChange)="onSearchChange($event,'category')" placeholder="Select category">
              <clr-options *ngIf="categories">
                <clr-option *ngFor="let item of categories;" clrValue={{item.name}}
                  (click)="itemChanged(item,'category')">{{item?.name}}
                </clr-option>
              </clr-options>
              <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
            </clr-combobox>
          </clr-combobox-container>
          <clr-control-helper>
            <button class="btn btn-link btn-sm" (click)="AddCategoriePopUp()">
              {{ 'commun.jt' | translate }} {{ 'article.Mcatg.catg' | translate }}
            </button>
          </clr-control-helper>
          <clr-combobox-container *ngIf="model.markId">
            <label> {{ 'article.Marque.Mrq' | translate }} *</label>
            <clr-combobox name="markId" required [clrLoading]="loading" [(ngModel)]="model.markId.name"
              (clrInputChange)="onSearchChange($event,'mark')" placeholder="Select marks">
              <clr-options *ngIf="marks">
                <clr-option *ngFor="let item of marks;" clrValue={{item.name}} (click)="itemChanged(item,'mark')">
                  {{item?.name}}
                </clr-option>
              </clr-options>
              <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
            </clr-combobox>
          </clr-combobox-container>
          <clr-control-helper>
            <button class="btn btn-link btn-sm" (click)="AddMarquePopUp()">
              {{ 'commun.jt' | translate }} {{ 'article.Marque.Mrq' | translate }}
            </button>
          </clr-control-helper>
          <clr-combobox-container *ngIf="model.unityId">
            <label>{{ 'sharedSales.unité' | translate }} *</label>
            <clr-combobox name="unityId" required [clrLoading]="loading" [ngModel]="model.unityId.name"
              (clrInputChange)="onSearchChange($event,'unity')" placeholder="Select marks">
              <clr-options *ngIf="unities">
                <clr-option *ngFor="let item of unities;" clrValue={{item.name}} (click)="itemChanged(item,'unity')">
                  {{item?.name}}
                </clr-option>
              </clr-options>
              <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
            </clr-combobox>
          </clr-combobox-container>
          <clr-control-helper>
            <button class="btn btn-link btn-sm" (click)="AddUnityPopUp()">
              {{ 'commun.jt' | translate }} {{ 'article.unites.unite' | translate }}
            </button>
          </clr-control-helper>
          <clr-input-container>
            <label> {{ 'article.Prod.stocksecur' | translate }}</label>
            <input clrInput type="number" [(ngModel)]="model.stock_security" name="stock_security" min="0" />
          </clr-input-container>
          <clr-textarea-container>
            <label> {{ 'Points.desc' | translate }}</label>
            <textarea clrTextarea [(ngModel)]="model.description" name="description"></textarea>
          </clr-textarea-container>
        </form>
        <div>* {{ 'reg.ob' | translate }}</div>
        <button clrStepButton="next" hidden> {{ 'commun.sv' | translate }} </button>
        <div *ngIf="editCateory">
          <div class="modal">
            <div class="modal-dialog" role="dialog" aria-hidden="true">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title"> {{ 'commun.nti' | translate }} </h3>
                  <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CancelAction()" width="20"
                    height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                      d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </div>
                <div class="modal-body">
                  {{ 'commun.nti' | translate }}
                  <p>{{ 'commun.lidp' | translate }}</p>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-outline" type="button" (click)="cancelEditCategory()"> {{ 'commun.nn' |
                    translate }}</button>
                  <button class="btn btn-primary" type="button" (click)="activeEditCatgory()"> {{ 'commun.vl' |
                    translate }}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-backdrop" aria-hidden="true"></div>
        </div>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step2" (click)="clrInitialStep('step2')">
      <clr-step-title> {{ 'commun.r' | translate }} </clr-step-title>
      <clr-step-content>
        <div class="clr-row">
          <div class="clr-col-8 clr-col-md-10">
            <form clrForm>
              <clr-combobox-container *ngIf="model.taxId">
                <label> {{ 'article.taxe.Taxe' | translate }} %*</label>
                <clr-combobox name="taxId" required [clrLoading]="loading" [ngModel]="model.taxId.percentage"
                  (clrInputChange)="onSearchChange($event,'tax')" placeholder="Select taxs">
                  <clr-options *ngIf="taxs">
                    <clr-option *ngFor="let item of taxs;" clrValue={{item.percentage}}
                      (click)="itemChanged(item,'tax')">
                      {{item?.name}} ({{item?.percentage}} %)
                    </clr-option>
                  </clr-options>
                  <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
                </clr-combobox>
              </clr-combobox-container>
              <clr-control-helper>
                <button class="btn btn-link btn-sm" (click)="AddTaxePopUp()">
                  {{ 'commun.jt' | translate }} {{ 'article.taxe.Taxe' | translate }}
                </button>
              </clr-control-helper>
              <clr-input-container>
                <label> {{ 'article.Prod.Prixbuy' | translate }}*</label>
                <input clrInput type="number" [(ngModel)]="model.priceBuy" name="priceBuy" min="0" required />
              </clr-input-container>
              <clr-input-container>
                <label> {{ 'article.Prod.Prix' | translate }}*</label>
                <input clrInput type="number" [(ngModel)]="model.price" name="price" min="0" required />
              </clr-input-container>
              <!-- <h5> {{ 'commun.up' | translate }} </h5>
              <div> {{ 'commun.apd' | translate }} * </div>
              <clr-combobox-container>
                <label> {{ 'commun.up' | translate }} </label>
                <clr-combobox name="priceType" [ngModel]="modelProductprice.priceTypeId?.name" required
                  [clrLoading]="loading" (clrInputChange)="onSearchChange($event,'priceType')"
                  placeholder="Select priceType">
                  <clr-options *ngIf="priceTypes">
                    <clr-option *ngFor="let item of priceTypes" clrValue={{item.name}}
                      (click)="itemChanged(item,'priceType')">{{item.name}}
                    </clr-option>
                  </clr-options>
                  <clr-control-error>{{ 'reg.ob' | translate }} !</clr-control-error>
                </clr-combobox>
              </clr-combobox-container>
              <br>
              <clr-input-container>
                <label> {{ 'commun.r' | translate }}</label>
                <input clrInput type="number" name="productPriceValue" [(ngModel)]="modelProductprice.price" />
              </clr-input-container><br>
              <button class="btn btn-sm" (click)="ajoutProductprice()"
                [disabled]="!modelProductprice.priceTypeId || !modelProductprice.price">
                <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-plus" viewBox="-1 -4 16 16">
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> {{ 'commun.jt' | translate }}
              </button> -->
            </form>
          </div>
        </div>
        <clr-datagrid *ngIf="productPriceArray.length > 0">
          <clr-dg-column>
            {{ 'commun.type' | translate }}
          </clr-dg-column>
          <clr-dg-column>
            {{ 'commun.r' | translate }}
          </clr-dg-column>
          <clr-dg-row *ngFor="let item of productPriceArray; let i=index">
            <clr-dg-action-overflow>
              <button class="action-item" (click)="editProductPrice(item,i)"> {{ 'commun.mod' | translate }}</button>
              <button class="action-item" (click)="deleteProductPrice(item,i)"> {{ 'commun.sp' | translate }}</button>
            </clr-dg-action-overflow>
            <clr-dg-cell>{{item?.priceTypeId?.name}}</clr-dg-cell>
            <clr-dg-cell>{{item?.price}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
        <button clrStepButton="next" hidden> {{ 'commun.sv' | translate }} </button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step3" (click)="clrInitialStep('step3')">
      <clr-step-title> {{ 'commun.ig' | translate }} </clr-step-title>
      <clr-step-content>
        <div class="clr-row" *ngIf="!pictureDetails">
          <div class="clr-col-12 clr-col-sm-12	clr-col-md-4	clr-col-lg-4	clr-col-xl-2">
            <a (click)="fileInput.click()" class="card">
              <clr-icon class="plus-icon" shape="plus-circle"></clr-icon>
            </a>
            <input hidden type="file" #fileInput (change)="picked($event)">
          </div>
          <div *ngFor="let item of pictureArray ;let i=index"
            class="clr-col-12 clr-col-sm-3	clr-col-md-4	clr-col-lg-4	clr-col-xl-2   img">
            <div class="card clickable" (click)="pictureDetailAction(item,i)">
              <div class="card-img container">
                <div class="clr-row clr-justify-content-between">
                  <div class="clr-col-4">
                    <span class="label label-success" *ngIf="item.defaults == true"> {{ 'commun.prc' | translate }}
                    </span>
                  </div>
                  <div class="clr-justify-content-end">
                    <span>
                      <button type="button" class="btn btn-icon btn-warning btn-sm delete-icon" aria-label="warning"
                        (click)="deletePicAction(item)">
                        <clr-icon shape="times"></clr-icon>
                      </button>
                    </span>
                  </div>
                </div>
                <img [src]="item.url">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="pictureDetails">
          <div class="clr-col-12 clr-col-sm-12 clr-col-md-6 clr-col-lg-4 clr-col-xl-3">
            <div class="card">
              <div class="card-img container">
                <img [src]="pictureString.url" class="img-class">
              </div>
            </div>
          </div>
          <form clrForm>
            <clr-checkbox-container>
              <label> {{ 'commun.ig' | translate }} {{ 'commun.prc' | translate }}</label>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="defaults" [(ngModel)]="pictureArray[pictureIndex].defaults"
                  [disabled]="desableDefCheck" />
                <label>*{{ 'commun.icp' | translate }}</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-select-container>
              <label> {{ 'article.dt' | translate }} </label>
              <select clrSelect name="options" name="selectedDetailPicture"
                [(ngModel)]="pictureArray[pictureIndex].detailId" required>
                <clr-control-error>{{ 'reg.ob' | translate }} </clr-control-error>
                <option *ngFor="let item of model.productDetails" value={{item.detailId.id}}>{{item.detailId.name}}
                </option>
              </select>
            </clr-select-container>
            <button class="btn btn-primary btn-sm" type="submit" (click)="submitPictureDetails()">{{ 'reg.e' | translate
              }}</button>
          </form>
        </div>
      </clr-step-content>
    </clr-stepper-panel>
  </form>
  <div class="btn-group btn-outline-primary">
    <button class="btn" routerLink="/catalog/product">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> {{ 'login.tt' | translate }}
    </button>
    <button class="btn" (click)="submit()"
      [disabled]="!model.unityId || !model.markId || !model.categoryId || !model.name  || !model.taxId || !model.price">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> {{ 'reg.e' | translate }}
    </button>
  </div>
  </div>
</section>
<!-- <app-catalog-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-catalog-demo>  -->
<app-add-category-modal *ngIf="showAddCategory" (closed)="CloseAction()" (saved)="AddCategory()">
</app-add-category-modal>
<app-add-marque-modal *ngIf="showAddMarque" (closed)="CloseAction()" (saved)="AddMarque()"></app-add-marque-modal>
<app-add-unity-modal *ngIf="showAddUnite" (closed)="CloseAction()" (saved)="AddUnity()"></app-add-unity-modal>
<app-add-tax-modal *ngIf="showAddTaxe" (closed)="CloseAction()" (saved)="AddTaxe()"></app-add-tax-modal>