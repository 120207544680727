import { Component, HostListener, OnInit } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { Order, Retenue } from 'src/app/sales/order/order';
import { OrderService } from 'src/app/sales/order/order.service';
import { Entreprise } from 'src/app/setting/setting';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken } from 'src/app/user/user.service';
import { RetenueService } from '../retenue.service';

@Component({
  selector: 'app-retenue-source',
  templateUrl: './retenue-source.component.html',
  styleUrls: ['./retenue-source.component.css']
})
export class RetenueSourceComponent implements OnInit {

  retenues: Retenue[] = []
  orders: any[] = []
  id_entreprise: number
  devise: string
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private orderService: OrderService, private settingService: SettingService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getRetenues();
    this.getEntrepriseInfo();
  }
  getEntrepriseInfo() {
    let filter = new FilterDto()
    filter.where = { "id": this.id_entreprise }
    filter.relations = ["deviseId"]
    this.settingService.getAllEntreprise(filter).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code
      }
    )
  }
  getRetenues() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    filter.relations = ["retenues", "orderDetails", "orderDetails.productId", "orderDetails.productId.taxId"]
    this.orderService.getOrders(filter).subscribe(
      data => {
        data[0].forEach(el => {
          if (el.retenues.length > 0) {
            let obj = { "order": undefined, "retenue1": 0, "retenue2": 0 }
            let index1 = el.retenues.findIndex(x => x.value == 1)
            let index2 = el.retenues.filter(x => x.value == 25)
            obj.order = el;
            obj.retenue1 = index1
            if (index2.length > 0) {
              let tmp = this.calculRetenue(el)
              obj.retenue2 = (tmp * (index2[0].value / 100))
            } else {
              obj.retenue2 = 0
            }
            this.orders.push(obj)
          }
        })
      }
    )
  }
  calculRetenue(order: Order): number {
    let res = 0
    order.orderDetails.forEach(el => {
      res += (el.productId.price * el.quantity) * (el.productId.taxId.percentage / 100)
    })
    return res;
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
