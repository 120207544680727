<section>
  <br>
  <div class="alert alert-app-level alert-info" role="alert" *ngIf="emptyHistory || data.length==0 ">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <div class="alert-text" *ngIf="!searchObject.dateDebut && !searchObject.dateFin">
          Pas d'historique
        </div>
        <div class="alert-text" *ngIf="searchObject.dateDebut || searchObject.dateFin">
          Pas d'historique a cette période
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row">
    <div id="resultDiv" class="clr-col-md-7  clr-col-12">
      <ul class="clr-timeline clr-timeline-vertical dot-circle clr-col-md-12" >
        <!--order-->
        <li class="clr-timeline-step" *ngFor="let item of data" >
          <div class="clr-timeline-step-header font-10" *ngIf="item">{{item?.createdAt | date:'dd/MM/yyyy à HH:mm'}}</div>
          <clr-icon shape="dot-circle" aria-current="true" aria-label="Current"  *ngIf="item"></clr-icon>
          <div class="clr-timeline-step-body" *ngIf="item?.orderDetails">
            <span class="clr-timeline-step-title">
              <a href="/sales/order/detail/{{item.id}}" target="_blank"><h3 class="no-margin-top">Commande n°:{{item.id}}</h3></a>
              <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted">En attente de validation</span>
              <span class="label label-success no-margin-top" *ngIf="item.isCompleted">Valide</span>
            </span>
            <span class="clr-timeline-step-description">
              <h6 class="no-margin-top">Montant total : <b>{{item.totalPrice | currency:'DT':'symbol':'1.2-3':'fr'}}</b>
              </h6>
              <h6 class="no-margin-top">Commandé par : <b>{{item.customerId.name}}</b></h6>
              <button class="btn btn-sm" (click)="detailOrder(item.id)"*ngIf="!showOrder">Détails</button>
              <button class="btn btn-sm" (click)="closeDtailOrder()" *ngIf="showOrder && indexOrder==item.id">fermer</button>

              <div class="overflow-x">
                <table class="table table-compact no-margin-top" *ngIf="showOrder && indexOrder==item.id">
                  <thead>
                    <tr>
                      <th class="left">Produit</th>
                      <th>Réference</th>
                      <th>Quantité</th>
                      <th>Prix</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of item.orderDetails">
                    <tr>
                      <td class="left">{{item.productId.name}}</td>
                      <td>{{item.productId.reference}}</td>
                      <td>{{item.quantity}}</td>
                      <td>{{item.quantity * item.productId.price | currency:'DT':'symbol':'1.2-3':'fr'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card" *ngIf="showOrder && item.operations.length>0 && indexOrder==item.id">
                <div class="card-block">
                  <div class="card-title">
                    Liste des operations
                  </div>
                  <div class="card-text">
                    <div *ngFor="let element of item.operations">
                      <div>
                        <small>Date: <b>{{element.createdAt | date:'dd/MM/yyyy à HH:mm'}}</b> </small>
                      </div>
                      <small><span class="margin-right-10">Opération N°: <b>{{element.id}}</b> </span>
                        Etat:<span *ngIf="item.isCompleted"> <b>validé</b> </span>
                        <span *ngIf="!item.isCompleted"> <b>non Validé</b></span></small>
                      <table class="table table-compact no-margin-top">
                        <thead>
                          <tr>
                            <th class="left">Produit</th>
                            <th>Quantité</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let element of element.operationDetails">
                          <tr>
                            <td class="left">{{element.productId.name}}</td>
                            <td>{{element.quantity}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>

          <div class="clr-timeline-step-body" *ngIf="item?.operationDetails">
            <span class="clr-timeline-step-title flex">
              <h3 class="no-margin-top">Opération n°:{{item.id}}</h3>
              <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted && !item.forDelivery">non valide</span>
              <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted && item.forDelivery">non livré</span>
              <span class="label label-success" *ngIf="item.isCompleted && item.forDelivery">Livré</span>
              <span class="label label-success" *ngIf="item.isCompleted && !item.forDelivery">Validé</span>
            </span>
            <span class="clr-timeline-step-description">
              <h6 class="no-margin-top" *ngIf="item.orderId">Opération de <b>Vente</b> pour <b>{{item.orderId.customerId.name}}</b></h6>
              <h6 class="no-margin-top" *ngIf="item.buyingId">Opération <b>d'achat</b> depuis <b>{{item.buyingId.providerId.name}}</b></h6>
              <h6 class="no-margin-top" *ngIf="item.operationDetails[0]?.operationTypeId?.id==3">Opération <b>de
                  Retour</b></h6>
              <h6 class="no-margin-top" *ngIf="item.operationDetails[0]?.operationTypeId?.id==4">Opération <b>de Rébut</b>
              </h6>
              <h6 class="no-margin-top" *ngIf="item.operationDetails[0]?.operationTypeId?.id==5">Opération
                <b>d'alimentation du stock</b></h6>
              <button class="btn btn-sm" (click)="detailOperation(item.id)" *ngIf="!showoperation">Détails</button>
              <button class="btn btn-sm" (click)="closeOpDetail()" *ngIf="showoperation && indexOp==item.id">fermer</button>

              <div *ngIf="showoperation && indexOp==item.id">
                <div class="overflow-x">
                  <table class="table table-compact no-margin-top">
                    <thead>
                      <tr>
                        <th class="left" *ngIf="item.orderId">N° commande</th>
                        <th *ngIf="item.buyingId">N° d'achat</th>
                        <th *ngIf="item.orderId">Montant commande </th>
                        <th *ngIf="item.buyingId">Montant d'achat </th>
                        <th>Livrable</th>
                        <th>Etat</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td *ngIf="item.orderId || item.buyingId ">
                          <span *ngIf="item.orderId">{{item.orderId.id}}</span>
                          <span *ngIf="item.buyingId">{{item.buyingId.id}}</span>
                        </td>
                        <td *ngIf="item.orderId || item.buyingId ">
                          <span *ngIf="item.orderId">{{item.orderId?.totalPrice |
                            currency:'DT':'symbol':'1.2-3':'fr'}}</span>
                          <span *ngIf="item.buyingId">{{item.buyingId?.totalPrice |
                            currency:'DT':'symbol':'1.2-3':'fr'}}</span>
                        </td>
                        <td>
                          <span class="label label-success" *ngIf="item.forDeliver">Oui</span>
                          <span class="label label-danger" *ngIf="!item.forDeliver">Non</span>
                        </td>
                        <td>
                          <span class="label label-success" *ngIf="item.isCompleted">validé</span>
                          <span class="label label-danger" *ngIf="!item.isCompleted">non Validé</span>
                        </td>
                        <td> {{item.createdAt | date:'dd/MM/yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="overflow-x">
                  <table class="table table-compact">
                    <thead>
                      <tr>
                        <th class="left">Nom de produit</th>
                        <th>Réference</th>
                        <th>Quantité</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let element of item.operationDetails">
                        <td class="left">{{element.productId.name}}</td>
                        <td>{{element.productId.reference}}</td>
                        <td>{{element.quantity}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </span>
          </div>

          <div class="clr-timeline-step-body" *ngIf="item?.quotationDetails">
            <span class="clr-timeline-step-description">
              <a href="/sales/quotation/detail/{{item.id}}" target="_blank"> <h3 class="no-margin-top">Devis n° :<b>{{item.id}}</b></h3></a>
              <h6 class="no-margin-top">Demandé par :<b>{{item.customerId.name}}</b></h6>
              <h6 class="no-margin-top">Montant total:<b>{{item.totalPrice | currency:'DT':'symbol':'1.2-3':'fr'}}</b>
              </h6>
              <button *ngIf="item.quotationDetails.length>0 && !showQuotation" class="btn btn-sm" (click)="detailQuotation(item.id)">Détails</button>
              <button class="btn btn-sm" (click)="closeQtDetail()" *ngIf="showQuotation && indexQuot==item.id">fermer</button>

              <div class="overflow-x">
                <table class="table table-compact" *ngIf="showQuotation && indexQuot==item.id  " >
                  <thead>
                    <tr>
                      <th>Réference</th>
                      <th>Nom de produit</th>
                      <th>Qté</th>
                      <th>PU HT</th>
                      <th>TVA</th>
                      <th>Total HT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let element of item.quotationDetails">
                      <td>{{element.productId.reference}}</td>
                      <td>{{element.productId.name}}</td>
                      <td>{{element.quantity}}</td>
                      <td>{{element.productId.price}}</td>
                      <td>{{element.tax}}</td>
                      <td>{{element.amount}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </span>
          </div>

          <div class="clr-timeline-step-body" *ngIf="item?.transactionTypeId">
              <span class="clr-timeline-step-title flex">
                <h3 class="no-margin-top">Transaction n°:{{item.id}}</h3>
                <span class="label label-warning no-margin-top" *ngIf="!item.isCompleted">non valide</span>
                <span class="label label-success no-margin-top" *ngIf="item.isCompleted">Valide</span>
              </span>
              <div class="flex">
                <h6 class="no-margin-top" *ngIf="item.orderId"><b>Transaction de Vente N°:{{item.orderId.id}}</b></h6>
                <h6 class="no-margin-top" *ngIf="item.buyingId"><b>Transaction d'achat N°:{{item.buyingId.id}}</b></h6>
              </div>
              <span class="clr-timeline-step-description">
                <h6 class="no-margin-top" *ngIf="item.orderId">Client :<b> {{item.customerId.name}}</b></h6>
                <h6 class="no-margin-top" *ngIf="item.buyingId">Fournisseur :<b> {{item.providerId.name}}</b></h6>
                <h6 class="no-margin-top">Montant de transaction: <b>{{item.amount | currency:'DT':'symbol':'1.2-3':'fr'}}</b></h6>
                <h6 class="no-margin-top" *ngIf="buyingId">Montant d'achat: <b>{{item.buyingId.totalprice | currency:'DT':'symbol':'1.2-3':'fr'}}</b></h6>
                <h6 class="no-margin-top" *ngIf="item.orderId">Montant de commande: <b>{{item.orderId.totalPrice | currency:'DT':'symbol':'1.2-3':'fr'}}</b></h6>
                <h6 class="no-margin-top" *ngIf="item.transactionTypeId">Type de transaction: <b>{{item.transactionTypeId.name}}</b></h6>
  
                <button  class="btn btn-sm" (click)="detailTrans(item.id)" *ngIf="!showTrans && item.checkImage">Détails</button>
                <button class="btn btn-sm" (click)="closeTrDetail()" *ngIf="showTrans && indexTr==item.id">fermer</button>

                <div class="clr-row" *ngIf="showTrans && indexTr==item.id">
                  <div class="clr-col-12 overflow-x">
                    <div class="card" *ngIf="item.checkImage">
                      <div class="card-header">
                        Image de chèque:
                      </div>
                      <div class="card-block">
                        <div class="card-text">
                          <h6 class="no-margin-top" *ngIf="item.checkImage"><img src={{item.checkImage}} width="50"
                              height="150"></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
        </li>
      </ul>
    </div>

    <div class="clr-col-12 clr-col-md-5" id="searchDiv">
      <div class="card fixit">
        <div class="card-header center">
          Recherche
        </div>
        <div class="card-block">
          <div id="checkboxDiv">
            <div class="flex">
              <clr-combobox-container>
                <label>Nom de client</label>
                <clr-combobox [(ngModel)]="client.name" name="name" [clrLoading]="loading"
                  (clrInputChange)="onSearchChange($event)">
                  <clr-options *ngIf="clients">
                    <clr-option *ngFor="let item of clients;" clrValue={{item.name}} (click)="clientChanged(item)">
                      <clr-icon shape="user"></clr-icon>{{item?.name}}
                    </clr-option>
                  </clr-options>
                </clr-combobox>
              </clr-combobox-container>
              <button type="button" class="btn btn-icon btn-sm btn-danger-outline no-margin-top reset"
                title="réinitialiser le nom du client " (click)="resetName()">
                <clr-icon shape="times"></clr-icon>
              </button>
            </div>
            <clr-checkbox-container>
              <label><b>Affichage</b></label>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="all"  value="true" [(ngModel)]="searchObject.all" />
                <label>Tous</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="quotations"  value="true"
                  [(ngModel)]="searchObject.quotations" />
                <label>Devis</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="orders"  value="true"
                  [(ngModel)]="searchObject.orders" />
                <label>Bon de commande</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="delivery"  value="true"
                  [(ngModel)]="searchObject.delivery" />
                <label>Opérations</label>
              </clr-checkbox-wrapper>
              <!--<clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="Factures"  value="true"
                  [(ngModel)]="searchObject.Factures" />
                <label>Factures</label>
              </clr-checkbox-wrapper>-->
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="transactions"  value="true"
                  [(ngModel)]="searchObject.transactions" />
                <label>Transactions</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </div>
          <clr-radio-container clrInline>
            <label>Etat </label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="valide" [value]="searchObject.allEtat"
                [(ngModel)]="searchObject.allEtat" (click)="allEtat()" />
              <label>Tous</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="valide" value="false" [(ngModel)]="searchObject.valid"
                (click)="resetRadioButtonValide()" />
              <label>Validé</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="valide" value="true" [(ngModel)]="searchObject.inValid"
                (click)="resetRadioButtonNonValide()" />
              <label>Non Validé</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <div id="dateDiv" class="clr-col-12 no-pading">
            <div class="flex">
              <clr-date-container>
                <label>De</label>
                <input type="date" [(clrDate)]="searchObject.dateDebut">
              </clr-date-container>
              <button type="button" class="btn btn-icon btn-sm btn-danger-outline resetDateButton"
                title="réinitialiser le date de début" (click)="resetStartDate()">
                <clr-icon shape="times"></clr-icon>
              </button>
            </div>
            <div class="flex">
              <clr-date-container>
                <label>Jusqu'à</label>
                <input type="date" [(clrDate)]="searchObject.dateFin">
              </clr-date-container>
              <button type="button" class="btn btn-icon btn-sm btn-danger-outline resetDateButton"
                title="réinitialiser le date de fin" (click)="resetEndDate()">
                <clr-icon shape="times"></clr-icon>
              </button>
            </div>
          </div><br>
        </div>
        <div class="card-footer">
          <button class="btn btn-sm" (click)="getHistory()">
            <clr-icon shape="search"></clr-icon> Recherche
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-offset-md-4 clr-offset-lg-5" *ngIf="this.data.length < count  && this.data.length > 0" >
    <button  class="btn btn-link " (click)="loadMore()" >Afficher la suite...</button>
  </div>
</section>