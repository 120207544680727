import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../catalog/product/product.service';
import { SessionCrates } from '../crates/crates';
import { CratesService } from '../crates/crates.service';
import { FilterDto } from '../filter.dto';
import { Transaction } from '../finance/finance';
import { Customer } from '../parts/parts';
import { PartsService } from '../parts/parts.service';
import { Order, OrderDetail } from '../sales/order/order';
import { OrderService } from '../sales/order/order.service';
import { ElementSelected, ListeElement } from '../sales/shared-sales/shared-sales';
import { SettingService } from '../setting/setting.service';
import { Operation, OperationDetails, Store } from '../stock/stock';
import { StockService } from '../stock/stock.service';
import { User } from '../user/user';
import { Entreprise } from '../user/entreprise';
import { Quotation } from '../sales/quotation/quotation';
import { QuotationService } from '../sales/quotation/quotation.service';
import { ConfigService } from '../shared/config/config.service';
import { Product, ProductCaisse } from '../catalog/product/product';
import { PackService } from '../pack/pack.service';
import { pack } from '../pack/pack';
import { Category } from '../catalog/category/category';
import { deleteCookie, getValueFromToken } from '../user/user.service';
import { CarteFidelite } from '../carte-fidelite/models/CarteFidelite';
import { CategoryService } from '../catalog/category/category.service';
import { take } from 'rxjs/operators';
declare var $: any

@Component({
  selector: 'app-caisse',
  templateUrl: './caisse.component.html',
  styleUrls: ['./caisse.component.css'],
})
export class CaisseComponent implements OnInit {
  constructor(private cratesService: CratesService, private productService: ProductService,
    private settingService: SettingService, private partsService: PartsService,
    private orderService: OrderService, private stockService: StockService,
    private router: Router, private quotationService: QuotationService,
    private configService: ConfigService, private packService: PackService,
    private categoryService: CategoryService
  ) { }
  sessionObject: SessionCrates = new SessionCrates();
  user_id: number;
  entrepriseId: number
  entrepriseObject: Entreprise = new Entreprise()
  currentUser: User
  elementSelected: ElementSelected = new ElementSelected();
  order: Order = new Order();
  orderDetail: OrderDetail = new OrderDetail();
  orderDetails: OrderDetail[] = []
  transaction: Transaction = new Transaction()
  liste_transactions: Transaction[] = []
  operations: Operation[] = []
  operation: Operation
  operationsDetails: OperationDetails[] = []
  customer: Customer = new Customer();
  defaultCustomer: Customer = new Customer()
  opr_type_id: number
  quantity: number = 1
  modelProduct: Product = new Product()
  modelPack: pack = new pack()
  initialeProductPrice: number
  remise: number = 0
  promo: number
  remisePartielle: number = 0
  remiseGlobale: number = 0
  liste_elements: ListeElement = new ListeElement([])
  alertStockSecurity: boolean = false
  showAlert: boolean = false
  msgAlert: string
  stockSecurity: number
  StockMsg: string
  trans_type_id: number
  modelOperationDetail: OperationDetails
  alertSuccess: boolean = false
  real_id_order: number
  real_id_order_det: number
  real_id_operation: number
  real_id_operation_detail: number
  real_id_trans: number
  name: string
  result: number = 0
  tmp: Product
  id_selected: any
  reste: number
  showSearch: boolean = false
  showEnterCode: boolean = false
  id_order_to_print: Order
  address: string
  quotations: Quotation[] = []
  total: number = 0
  filter: FilterDto
  triale: boolean = false
  abonnee: boolean = false
  showQteModal: boolean = false;
  QteReturned: number = 0
  storeId: Store
  code: string = ''
  pointId: number
  retenue_source: boolean = false
  retenue_source_second: boolean = false
  retenueSoureId: number
  second_retenue: number
  res: any[] = []
  devise: string = ''
  search_prod: string
  amount_entered: string
  editQteElement: boolean = false
  categories: Category[] = []
  allProduct: ProductCaisse[] = []
  tmpAllProduct: ProductCaisse[] = []
  allQuantity: number[] = []
  tmpAllQuantity: number[] = []
  customers: Customer[] = []
  tmpListCustomers: Customer[] = []
  show_customers: boolean = false
  showFelicitationModal: boolean = false
  felicitationMessages: string[] = []
  carteFideliteList: CarteFidelite[] = []
  showQuestion: boolean = false
  showCarteBarcode: boolean = false
  totalProductCount: number = 0
  showProducts : boolean = false 


  @HostListener('document:keydown', ['$event'])
  printDocument(event: KeyboardEvent) {
    if (event.key == 'p') {
      if (this.id_order_to_print != null) {
        let button = document.getElementById("btn_print")
        button.click();
      }
    } else if (event.key == "Enter") {
      if (this.liste_elements.totalElements.length > 0) {
        let button = document.getElementById("btn_submit")
        button.click();
      }
    }
  }

  @HostListener('window:keypress', ['$event'])
  ScanCode(event: KeyboardEvent) {
    this.res.push(event.key);
    if (event.key == "Enter") {
      this.res.pop();
      let code = this.res.join("")
      let index_prod = this.allProduct.findIndex(x => x.productId?.bar_code == code)
      let index_pack = this.allProduct.findIndex(x => x.packId?.bar_code == code)
      if (index_prod != -1) {
        this.selectedProductChange(this.allProduct[index_prod].productId)
      } else if (index_pack != -1) {
        this.selectedPackChange(this.allProduct[index_pack].packId)
      }
      this.res = [];
    }
  }

  ngOnInit(): void {
    this.customer.name = ""
    this.allProduct = []
    this.allQuantity = []
    this.order.transactions = []
    this.user_id = parseInt(getValueFromToken("id"))
    this.getUserInfo()
    this.entrepriseId = parseInt(getValueFromToken("idEntreprise"))
    this.triale = JSON.parse(getValueFromToken('triale'))
    this.abonnee = JSON.parse(getValueFromToken('abonee'))
    this.getEntrepriseInfo();
    this.getCrates();
    this.getOperation({})
    this.getOperationDetails({});
    this.getOperationTypeId();
    this.getTransactionTypeId();
    this.getTransactions({});
    this.getOrders({})
    this.getOrderDetails({})
    this.getQuotations();
    this.getConfig({ where: { "entrepriseId": this.entrepriseId } })
    this.getPacks({})
    this.getRetenueId()
    this.getCategories({})
    //commented by dali 
    // setTimeout(x => {
    //   if (!this.sessionObject?.id) {
    //     this.sessionObject.openedAt = new Date()
    //     this.sessionObject.userId = this.user_id
    //     this.sessionObject.cratesId = this.currentUser.cratesId.id
    //     this.sessionObject.entrepriseId = this.entrepriseId
    //     this.sessionObject.openedBy = this.user_id
    //     this.sessionObject.active = true
    //     this.cratesService.addSessionCrate(this.sessionObject).subscribe(
    //       succ => {
    //         this.sessionObject = succ
    //       } 
    //     )
    //   } else {
    //   }
    // }, 3000)
  }

  getCategories(filter: FilterDto) {
    filter.where = { "entrepriseId": this.entrepriseId, "active": true }
    this.categoryService.getCategories(filter).subscribe(
      data => {
        this.categories = data[0]
      }
    )
  }

  onRemiseChange() {
    if (this.elementSelected.remiseDisplay.indexOf("%") != -1) {
      this.elementSelected.remise = parseInt(this.elementSelected.remiseDisplay.split("%")[0])
    } else {
      this.elementSelected.remise = parseInt(this.elementSelected.remiseDisplay)
    }
    this.calculeMontant()
    this.calculeMontantTotal();
  }
  searchProdChanged() {
    if (this.search_prod != "" && this.search_prod != undefined) {
      this.allProduct = []
      this.tmpAllProduct.forEach(el => {
        if (el.productId != undefined) {
          if (el.productId?.name.toLowerCase().indexOf(this.search_prod.toLowerCase()) != -1) {
            this.allProduct.push(el)
          }
        }
        if (el.packId != undefined) {
          if (el.packId?.nom.toLowerCase().indexOf(this.search_prod.toLowerCase()) != -1) {
            this.allProduct.push(el);
          }
        }
      })
    } else {
      this.allProduct = this.tmpAllProduct
    }
    if (this.allProduct.length == 0) {
      let filter = new FilterDto()
      filter.where = {
        name: {
          type: "like",
          value: "*" + this.search_prod.trim() + "*"
        }
      }
      this.getProductsList(Infinity,0,filter)
    }
  }
  checkClientCarteFidelite(carteFidelite: CarteFidelite, orderDetail: OrderDetail[]) {
    let messages = []
    if (carteFidelite.active) {
      orderDetail.forEach(el => {
        if (el.productId) {
          let exist = carteFidelite.carteFideliteDetails
            .filter(x => x.productId?.id == el.productId.id)
          if (exist.length > 0) {
            if (exist[0].qteRealise + el.quantity >= exist[0].qteMax) {
              messages.push(`Produit :  ${el.productId.name}   Quantité :   ${exist[0].qteOffre}`)
              let elementSelected = new ElementSelected()
              elementSelected.elementPrice = 0
              elementSelected.productId = el.productId
              elementSelected.quantity = exist[0].qteOffre
              elementSelected.tax = el.productId.taxId.percentage
              elementSelected.montant_TTC = 0
              this.addOffreToOperation(elementSelected)
            }
          }
        }
        else if (el.packId) {
          let exist = carteFidelite.carteFideliteDetails
            .filter(x => x.packId?.id == el.packId.id)
          if (exist.length > 0) {
            if (exist[0].qteRealise + el.quantity >= exist[0].qteMax) {
              messages.push(`Pack :  ${el.packId.nom}  Quantité : ${exist[0].qteOffre}`)
              let elementSelected = new ElementSelected()
              elementSelected.elementPrice = 0
              elementSelected.packId = el.packId
              elementSelected.quantity = exist[0].qteOffre
              elementSelected.tax = el.packId.taxId.percentage
              elementSelected.montant_TTC = 0
              this.addOffreToOperation(elementSelected)
            }
          }
        }
      })
    }
    return messages
  }
  addOffreToOperation(element: ElementSelected) {
    if (element.productId) {
      //adding operationDetail 
      this.modelOperationDetail = new OperationDetails()
      this.modelOperationDetail.productId = element.productId
      this.modelOperationDetail.entrepriseId = this.entrepriseId
      this.modelOperationDetail.quantity = element.quantity
      this.modelOperationDetail.real_id = this.real_id_operation
      this.modelOperationDetail.operationTypeId = this.opr_type_id
      this.modelOperationDetail.byAdmin = false
      this.modelOperationDetail.createdBy = this.currentUser.id
      this.order.operations[0].operationDetails.push(this.modelOperationDetail)
      // this.order.operations
      //adding OrderDetail
      this.orderDetail = new OrderDetail()
      this.orderDetail.tax = element.tax;
      this.orderDetail.quantity = element.quantity
      this.orderDetail.price = element.elementPrice
      this.orderDetail.amount = element.montant_TTC
      this.orderDetail.productId = element.productId
      this.orderDetail.entrepriseId = this.entrepriseId
      this.orderDetail.real_id = this.real_id_order_det
      this.orderDetail.createdBy = this.currentUser.id
      this.orderDetail.byAdmin = false
      this.orderDetail.isFromCarteFid = true
      this.order.orderDetails.push(this.orderDetail)
    } else if (element.packId) {
      element.packId.details.forEach(detail => {
        //adding operationDetail
        this.modelOperationDetail = new OperationDetails()
        this.modelOperationDetail.productId = detail.productid
        this.modelOperationDetail.entrepriseId = this.entrepriseId
        this.modelOperationDetail.quantity = detail.qte_produit * element.quantity
        this.modelOperationDetail.real_id = this.real_id_operation + 1
        this.modelOperationDetail.operationTypeId = this.opr_type_id
        this.modelOperationDetail.byAdmin = false
        this.modelOperationDetail.createdBy = this.currentUser.id
        this.operation.operationDetails.push(this.modelOperationDetail)
        this.order.operations[0].operationDetails.push(this.modelOperationDetail)
      })
      //adding OrderDetail
      this.orderDetail = new OrderDetail()
      this.orderDetail.tax = element.tax
      this.orderDetail.quantity = element.quantity
      this.orderDetail.price = element.elementPrice
      this.orderDetail.amount = element.montant_TTC
      this.orderDetail.packId = element.packId
      this.orderDetail.entrepriseId = this.entrepriseId
      this.orderDetail.real_id = this.real_id_order_det
      this.orderDetail.byAdmin = false
      this.orderDetail.isFromCarteFid = true
      // this.orderDetails.push(this.orderDetail)
      this.order.orderDetails.push(this.orderDetail)
    }
  }
  getRetenueId() {
    let filter = new FilterDto()
    filter.where = { "active": true }
    this.settingService.getRetenues(filter).subscribe(
      data => {
        if (data[0][0]) {
          data[0].forEach(el => {
            if (el.value) {
              this.retenueSoureId = el.id
            } else if (el.value == 25) {
              this.second_retenue = el.id
            }
          })
        }
      }
    )
  }
  getPacks(filter: FilterDto) {
    filter.where = { "entrepriseId": this.entrepriseId, "isDeleted": false }
    filter.relations = ["details", "details.productid", "details.productid.categoryId",
      "taxId"]
    this.packService.getAllPacks(filter).subscribe(
      data => {
        data[0].forEach(el => {
          let model = new ProductCaisse()
          model.packId = el
          this.allProduct.push(model)
          this.allQuantity.push(0)
          this.tmpAllProduct.push(model)
          this.tmpAllQuantity.push(0)
        })
      }
    )
  }
  NotFoundCode() {
    this.showEnterCode = false;
    this.showAlert = true
    this.showQuestion = false
    this.msgAlert = "Produit introuvable "
  }
  getQteReturned($event) {
    if (!this.editQteElement) {
      if (this.elementSelected.productId) {
        this.QteReturned = $event
        this.onChange("quantity", this.QteReturned)
        this.showQteModal = false
      } else if (this.elementSelected.packId) {
        this.QteReturned = $event
        this.onChangePackQte("quantity", this.QteReturned)
        this.showQteModal = false
      }
    } else {
      this.QteReturned = $event
      this.editQteElementFct(this.QteReturned)
      this.showQteModal = false
    }
  }
  showTheModal() {
    this.showQteModal = true
  }
  getConfig(filter: FilterDto) {
    this.settingService.getAllConfigurations(filter).subscribe(
      data => {
        this.show_customers = data[0][0].show_customer_crates
        if (data[0][0].stock_security) {
          this.stockSecurity = data[0][0].stock_security
        } else {
          this.stockSecurity = 1;
        }
        if (data[0][0].retenue_source && !data[0][0].retenue_source_second) {
          this.retenue_source = data[0][0].retenue_source
          this.retenue_source_second = data[0][0].retenue_source_second
        } else if (!data[0][0].retenue_source && data[0][0].retenue_source_second) {
          this.retenue_source = false
          this.retenue_source_second = true
        }
        this.checkExistencePassager(this.show_customers)
      }
    )
  }
  getEntrepriseInfo() {
    let filter = new FilterDto()
    filter.where = { "id": this.entrepriseId }
    filter.relations = ["deviseId"]
    this.settingService.getAllEntreprise(filter).subscribe(
      data => {
        this.entrepriseObject = data[0][0]
        this.devise = this.entrepriseObject.deviseId.code
      }
    )
  }
  setFullScreen() {
    var elem = document.documentElement
    let isFull = true
    if (!window.screenTop && !window.screenY) {
      isFull = false
    }
    if (!isFull) {
      elem.requestFullscreen();
    } else if (isFull) {
      document.exitFullscreen();
    }
  }
  getOperationDetails(filter: FilterDto) {
    filter.where = { "entrepriseId": this.entrepriseId }
    this.stockService.getOperationDetaile(filter).subscribe(
      data => {
        this.real_id_operation_detail = data[1] + 1
      }
    )
  }
  getOperation(filter: FilterDto) {
    filter.where = { "entrepriseId": this.entrepriseId }
    this.stockService.getAlloperation(filter).subscribe(
      data => {
        this.real_id_operation = data[1];
      }
    )
  }
  getTransactions(filter: FilterDto) {
    filter.where = { "entrepriseId": this.entrepriseId }
    this.settingService.getTransactions(filter).subscribe(
      data => {
        this.real_id_trans = data[1] + 1
      }
    )
  }
  getTransactionTypeId() {
    let filter = new FilterDto()
    filter.where = { "coefficient": 1 }
    this.settingService.getTransactionTypes(filter).subscribe(
      succ => {
        this.trans_type_id = succ[0][0].id
      }
    )
  }
  checkExistencePassager(showAllCustomers: boolean) {
    let filter = new FilterDto()
    filter.where = { "active": true, "entrepriseId": this.entrepriseId }
    filter.relations = ["carteFidelites", "carteFidelites.carteFideliteDetails",
      "carteFidelites.carteFideliteDetails.productId",
      "carteFidelites.carteFideliteDetails.packId"]
    this.partsService.getCustomerInfo(filter).subscribe(
      data => {
        this.customers = data[0].filter(x => x.name != "passager")
        this.tmpListCustomers = this.customers
        this.carteFideliteList = this.customers.map(x => x.carteFidelites[0])
        this.defaultCustomer = data[0].filter(x => x.name == "passager")[0]
        if (this.defaultCustomer == undefined ||
          this.defaultCustomer.id == undefined) {
          let client = new Customer()
          client = {
            name: "passager",
            real_id: 1,
            customerType: "defaultCustomer",
            entrepriseId: this.entrepriseId
          }
          this.partsService.addCustomer(client).subscribe(
            data => {
              this.defaultCustomer = data
            }
          )
        }
      }
    )
  }

  getCustomers() {
    let filter = new FilterDto()
    filter.where = { "active": true, "entrepriseId": this.entrepriseId }
    filter.relations = ["carteFidelites", "carteFidelites.carteFideliteDetails",
      "carteFidelites.carteFideliteDetails.productId",
      "carteFidelites.carteFideliteDetails.packId"]
    this.partsService.getCustomerInfo(filter).subscribe(
      data => {
        this.customers = data[0]
        this.tmpListCustomers = this.customers
        this.carteFideliteList = this.customers.map(x => x.carteFidelites[0])
        this.defaultCustomer = this.customers.filter(x => x.name == "passager")[0]
      }
    )
  }
  getOperationTypeId() {
    let filter = new FilterDto()
    filter.where = { "coefficient": -1 }
    this.settingService.getOperationTypes(filter).subscribe(
      data => {
        this.opr_type_id = data[0][0].id
      }
    )
  }
  getOrders(filter: FilterDto) {
    filter.where = { "entrepriseId": this.entrepriseId }
    this.orderService.getOrders(filter).subscribe(
      data => {
        this.real_id_order = data[1] + 1
      }
    )
  }
  getOrderDetails(filter: FilterDto) {
    filter.where = { "entrepriseId": this.entrepriseId }
    this.orderService.getOrdersDetails(filter).subscribe(
      data => {
        this.real_id_order_det = data[1] + 1;
      }
    )
  }
  selectFavorite(item: any) {
    if (item.productId) {
      this.selectedProductChange(item.productId);
    } else if (item.packId) {
      this.selectedPackChange(item.packId)
    }
  }

  getProductsList(take: number, skip: number, filter: FilterDto) {
    console.log("geting products")
    filter.relations = ["taxId", "pictures", "categoryId"]
    filter.where = Object.assign(filter.where.valueOf(),
      { "entrepriseId": this.entrepriseId, "active": true }
    )
    filter.take = take
    filter.skip = skip
    this.cratesService.getInfoProducts(this.storeId.id, filter).subscribe(
      data => {
        console.log("products received")
        //this.tmp = data[0][0]?.product
        data[0].forEach(el => {
          let model = new ProductCaisse()
          let index = this.tmpAllProduct.findIndex(x => x.productId?.id == el.product?.id)
          if(index == -1){
            model.productId = el.product
            this.allProduct.push(model)
            this.allQuantity.push(el.quantity)
            this.tmpAllProduct.push(model)
            this.tmpAllQuantity.push(el.quantity)
          }
        })
        this.showProducts = true
        this.totalProductCount = data[0].length
        // this.searchProdChanged()
      }
    )
  }
  selectedOther(item: any) {
    if (item?.productId) {
      this.selectedProductChange(item.productId)
    } else if (item.packId) {
      this.selectedPackChange(item.packId)
    }
  }
  setCategory(item: Category) {
    this.allProduct = []
    this.allProduct = this.tmpAllProduct.filter(x => {
      if (x.productId) {
        return x.productId?.categoryId?.id === item.id
      } else {
        return x.packId?.details.some(s => s.productid?.categoryId?.id == item.id)
      }
    })
    // this.allProduct = this.tmpAllProduct.filter(x => x.productId?.categoryId?.id === item.id)
  }
  getQuotations() {
    let filter = new FilterDto();
    filter.where = { "entrepriseId": this.entrepriseId }
    filter.relations = ["customerId", "quotationDetails", "quotationDetails.productId"];
    this.quotationService.getQuotations(filter).subscribe(
      quotations => {
        this.quotations = quotations[0];
        this.total = quotations[1];
      },
      err => console.error('Observer got an error: ', err),
    );
  }
  addQuotation() {
    this.getQuotations();
    if (this.triale) {
      if (this.total < 1) {
        window.open("#/sales/quotation/add", '_blank')
      } else {
        this.showAlert = true
        this.msgAlert = "Max de devis "
        this.showQuestion = false
      }

    }
    else if (this.abonnee) {
      if (this.entrepriseObject.abonnementId.premuim || this.entrepriseObject.abonnementId.facturation_only || this.entrepriseObject.abonnementId.facturation_point_vente || this.entrepriseObject.abonnementId.premuim) {
        window.open("#/sales/quotation/add", '_blank')
      } else {
        this.showAlert = true
        this.showQuestion = false
        this.msgAlert = "Max de devis "
        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    }
  }
  OpenInvoices() {
    window.open("#/sales/invoice", '_blank')
  }
  OpenMySales() {
    window.open("#/sales/order", "_blank")
  }
  OpenMyOrders() {
    window.open("#/commande", "_blank")
  }
  CalculResteInput(event: any) {
    this.reste = 0
    this.reste = event.target.value - this.result
  }
  onChange(name: string, args: number) {
    if (args > 0) {
      this.showAlert = false;
      if (name == "quantity") {
        let index = this.tmpAllProduct.findIndex(x => x.productId?.id == this.elementSelected.productId.id)
        let qte = this.tmpAllQuantity[index];
        if (qte <= 0) {
          this.showAlert = true
          this.showQuestion = false
          this.msgAlert = "Ce produit n'est pas disponible dans votre stock "
        }
        else
          if (qte - args - this.stockSecurity == 0) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "Ce produit a atteint le stock de sécurité "
              + "il ne reste que " + (qte - args) + "dans votre stock"
          }
          else if (args > qte) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "Epuise de stock, la quantité entré n'est pas disponible ";
          } else
            if (args == qte - this.stockSecurity) {
              this.showAlert = true
              this.showQuestion = false
              this.msgAlert = "Pour cette quantité le produit entre dans le stock de sécurité"
            }
        if (args <= qte) {
          this.elementSelected.quantity = args;
          this.liste_elements.totalElements.push(this.elementSelected);
          this.showAlert = false;
          this.calculeMontant()
          this.calculeMontantTotal()
        }
      }
      else {
        if (name == "discount") {
          this.elementSelected.remise = +args;
          this.calculeMontant()
        } else {
          if (name == "remiseGlobale") {
            this.calculeMontantTotal()
            this.enregistrer()
          } else {
            if (name == "promo") {
              this.elementSelected.gratuite = +args;
            }
          }
        }
      }
    }
    else {
      if (args < 0) {
        this.msgAlert = "veuillez saisir une valeur positive"
        this.showAlert = true;
        this.showQuestion = false
      }
      else {
        if (name == "quantity") {
          this.msgAlert = "veuillez saisir une valeur superieur a zéro"
          this.showAlert = true;
          this.showQuestion = false
        } else {
          if (name == "discount") {
            this.elementSelected.remise = 0
            this.calculeMontant()
            this.showAlert = false;
          }
          else {
            if (name == "remiseGlobale") {
              this.liste_elements.remiseGlobale = 0
              this.calculeMontantTotal()
              this.showAlert = false;
              this.enregistrer()
            } else {
              if (name == "promo") {
                this.elementSelected.gratuite = 0;
              }
            }
          }
        }
      }
    }
  }

  onChangePackQte(name: string, args: number) {
    if (args > 0) {
      this.showAlert = false;
      if (name == "quantity") {
        var qte = 0;
        this.elementSelected.packId?.details.forEach(el => {
          let index = this.tmpAllProduct.findIndex(x => x.productId?.id == el.productid.id)
          qte = this.tmpAllQuantity[index];
          let qte_in_pack = el.qte_produit
          if (qte <= 0) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "le produit " + this.tmpAllProduct[index].productId.name + " n'est pas disponible dans votre stock "
          }
          if (qte - this.stockSecurity <= 0) {
            this.alertStockSecurity = true
            this.StockMsg = "Le produit " + this.tmpAllProduct[index].productId.name + " a atteint le stock de sécurité ";
          } else {
            this.alertStockSecurity = false;
          }
          if (args * qte_in_pack > qte) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "Epuise de stock, la quantité entré pour le produit " + this.tmpAllProduct[index].productId.name + " n'est pas disponible ";
          } else
            if (args * qte_in_pack >= qte - this.stockSecurity) {
              this.alertStockSecurity = true
              this.StockMsg = "Pour cette quantité le produit " + this.tmpAllProduct[index].productId.name + " entre dans le stock de sécurité"
            } else {
              this.alertStockSecurity = false
            }
        })

        if (!this.showAlert && !this.alertStockSecurity) {
          this.elementSelected.quantity = +args;
          this.showAlert = false;
          this.calculeMontant()
          this.liste_elements.totalElements.push(this.elementSelected)
          this.calculeMontantTotal();
        }
      }
      else {
        if (name == "discount") {
          this.elementSelected.remise = +args;
          this.calculeMontant()
        } else {
          if (name == "remiseGlobale") {
            this.calculeMontantTotal()
            this.enregistrer()
          } else {
            if (name == "promo") {
              this.elementSelected.gratuite = +args;
            }
          }
        }
      }
    }
    else {
      if (args < 0) {
        this.msgAlert = "veuillez saisir une valeur positive"
        this.showAlert = true;
        this.showQuestion = false
      }
      else {
        if (name == "quantity") {
          this.msgAlert = "veuillez saisir une valeur superieur a zéro"
          this.showAlert = true;
          this.showQuestion = false
        } else {
          if (name == "discount") {
            this.elementSelected.remise = 0
            this.calculeMontant()
            this.showAlert = false;
          }
          else {
            if (name == "remiseGlobale") {
              this.liste_elements.remiseGlobale = 0
              this.calculeMontantTotal()
              this.showAlert = false;
              this.enregistrer()
            } else {
              if (name == "promo") {
                this.elementSelected.gratuite = 0;
              }
            }
          }
        }
      }
    }
  }
  CloseAction() {
    this.showAlert = false
    this.showSearch = false
    this.showEnterCode = false
    this.showQteModal = false
  }
  get_product_selected(event: any) {
    if (event.productId) {
      let index = this.liste_elements.totalElements.filter(x => x.productId.id == event.productId.id)
      if (index.length > 0) {
        this.showEnterCode = false
        this.showAlert = true;
        this.showQuestion = false
        this.msgAlert = "produit existe deja "
      } else {
        this.showEnterCode = false
        this.elementSelected = new ElementSelected();
        this.elementSelected.id = event.product.id
        if (event.product?.gratuite) {
          this.elementSelected.gratuite = event.product.gratuite
        } else {
          this.elementSelected.gratuite = 0
        }
        this.elementSelected.productId = event.product
        this.elementSelected.elementName = event.product.name
        this.elementSelected.elementPrice = event.product.price
        if (event.product?.remise) {
          this.elementSelected.remise = event.product.remise
        } else {
          this.elementSelected.remise = 0
        }
        this.elementSelected.taxId = event.product.taxId
        this.showEnterCode = false
        this.showQteModal = true;
      }
    } else if (event.packId) {
      let index = this.liste_elements.totalElements.filter(x => x.packId.id == event.packId.id)
      if (index.length > 0) {
        this.showEnterCode = false
        this.showAlert = true;
        this.showQuestion = false
        this.msgAlert = "produit existe deja "
      } else {
        this.showEnterCode = false
        this.elementSelected = new ElementSelected();
        this.elementSelected.id = event.packId.id
        if (event.packId?.gratuite) {
          this.elementSelected.gratuite = event.packId.gratuite
        } else {
          this.elementSelected.gratuite = 0
        }
        this.elementSelected.packId = event.packId
        this.elementSelected.elementName = event.packId.nom
        this.elementSelected.elementPrice = event.packId.price
        if (event.packId?.remise) {
          this.elementSelected.remise = event.packId.remise
        } else {
          this.elementSelected.remise = 0
        }
        this.elementSelected.taxId = event.packId.taxId
        this.showEnterCode = false
        this.showQteModal = true;
      }
    }
  }

  searchCarteModal() {
    this.showEnterCode = true
    this.showCarteBarcode = true
    this.showAlert = false
  }

  searchProdModal() {
    this.showAlert = false
    this.showEnterCode = true
    this.showCarteBarcode = false
  }

  selectedCarteFidelite(carteFid: CarteFidelite) {
    this.showEnterCode = false
    if (carteFid == undefined) {
      this.showQuestion = false
      this.showAlert = true
      this.msgAlert = "Carte fidelite introuvable"
      return;
    } else {
      this.customer = carteFid.customerId
      this.customer.carteFidelites = []
      carteFid.customerId = null
      this.customer.carteFidelites.push(carteFid)
    }
  }

  searchProduct() {
    this.showSearch = true
  }
  EnterCode() {
    this.showAlert = true
    this.showQuestion = true
    this.msgAlert = "Merci de préciser l'action à effectuer";
  }
  enregistrer() {
    this.order.totalPrice = this.liste_elements.montantTotal_TTC
    this.liste_elements.totalElements.forEach(el => {
      this.orderDetail = new OrderDetail()
      if (el.productId) {
        this.orderDetail.tax = el.tax;
        this.orderDetail.quantity = el.quantity
        this.orderDetail.price = el.elementPrice
        this.orderDetail.amount = el.montant_TTC
        this.orderDetail.discount = el.remise
        this.orderDetail.gratuite = el.gratuite
        this.orderDetail.productId = el.productId
        this.orderDetail.entrepriseId = this.entrepriseId
        this.orderDetail.real_id = this.real_id_order_det
        this.orderDetail.createdBy = this.currentUser.id
        this.orderDetail.byAdmin = false
        this.orderDetails.push(this.orderDetail)
      } else if (el.packId) {
        this.orderDetail.tax = el.tax
        this.orderDetail.quantity = el.quantity
        this.orderDetail.price = el.elementPrice
        this.orderDetail.amount = el.montant_TTC
        this.orderDetail.discount = el.remise
        this.orderDetail.gratuite = el.gratuite
        this.orderDetail.packId = el.packId
        this.orderDetail.entrepriseId = this.entrepriseId
        this.orderDetail.real_id = this.real_id_order_det
        this.orderDetail.byAdmin = false
        this.orderDetails.push(this.orderDetail)
      }
    })
    this.order.orderDetails = this.orderDetails
    this.transaction.amount = this.order.totalPrice
    this.orderDetails = []
  }
  addTransaction(customer: Customer) {
    this.order.transactions = []
    this.transaction.transactionTypeId = this.trans_type_id
    this.transaction.customerId = customer.id
    this.transaction.TypeTransaction = "espece"
    this.transaction.isPayed = true
    this.transaction.isCompleted = true
    this.transaction.entrepriseId = this.entrepriseId
    this.transaction.real_id = this.real_id_trans
    this.transaction.pointId = this.pointId
    this.transaction.createdBy = this.currentUser.id
    this.transaction.byAdmin = false
    this.transaction.cratesId = this.currentUser.cratesId.id
    this.order.transactions.push(this.transaction)
  }
  selectedProductChange(product: Product) {
    const index = this.liste_elements.totalElements.filter(element =>
      element.productId?.id == product.id)
    if (index.length > 0) {
      this.showAlert = true
      this.showQuestion = false
      this.msgAlert = "Produit existe dejà";
    } else if (index.length == 0) {
      this.initialeProductPrice = product.price
      this.elementSelected = new ElementSelected();
      this.modelProduct = product;
      this.elementSelected.taxId = product.taxId
      this.elementSelected.productId = product;
      this.elementSelected.elementName = product.name;
      this.elementSelected.elementPrice = product.price;
      this.elementSelected.tax = product.taxId.percentage;
      this.elementSelected.quantity = this.quantity;
      this.elementSelected.remise = this.remise;
      this.elementSelected.gratuite = this.promo;
      this.elementSelected.real_id = product.real_id
      this.showQteModal = true;
    }
  }
  selectedPackChange(item: pack) {
    let index = this.liste_elements.totalElements.filter(x => x.packId?.id == item.id)
    if (index.length > 0) {
      this.showAlert = true
      this.showQuestion = false
      this.msgAlert = "Produit existe dejà";
    } else {
      this.initialeProductPrice = item.price
      this.elementSelected = new ElementSelected();
      this.modelPack = item;
      this.elementSelected.taxId = item.taxId
      this.elementSelected.packId = item;
      this.elementSelected.elementName = item.nom;
      this.elementSelected.elementPrice = item.price;
      this.elementSelected.tax = item.taxId.percentage;
      this.elementSelected.quantity = this.quantity;
      this.elementSelected.remise = this.remise;
      this.elementSelected.gratuite = this.promo;
      this.elementSelected.real_id = item.real_id
      this.showQteModal = true;
    }
  }
  calculeMontant() {
    this.elementSelected.montant_HT = (this.elementSelected.quantity * this.elementSelected.elementPrice)
    this.elementSelected.montant_TTC = ((this.elementSelected.montant_HT - this.elementSelected.montant_HT * this.elementSelected.remise / 100) + (this.elementSelected.montant_HT - this.elementSelected.montant_HT * this.elementSelected.remise / 100) * (this.elementSelected.taxId.percentage / 100))
    this.elementSelected.montant_U_TTC = ((this.elementSelected.elementPrice - this.elementSelected.elementPrice * this.elementSelected.remise / 100) + ((this.elementSelected.elementPrice - this.elementSelected.elementPrice * this.elementSelected.remise / 100) * this.elementSelected.taxId.percentage / 100))
    this.remisePartielle = this.elementSelected.quantity * this.elementSelected.elementPrice * this.elementSelected.remise / 100
  }

  ValidQteProduct(qte) {
  }

  calculeMontantTotal() {
    this.liste_elements.montantTotal_HT = this.liste_elements.totalElements.reduce((acc, product) => acc + product.montant_HT, 0);

    this.liste_elements.remiseTotal = this.liste_elements.totalElements.reduce((acc, product) => acc + (product.montant_HT * (product.remise / 100)), 0);

    this.liste_elements.montantTotal_TVA = this.liste_elements.totalElements.reduce((acc, product) => acc + (product.montant_HT - (product.montant_HT * this.remise) / 100) * (product.taxId.percentage / 100), 0);

    if (this.liste_elements.remiseGlobale > 0) {
      this.remiseGlobale = this.liste_elements.montantTotal_HT * this.liste_elements.remiseGlobale / 100
    } else {
      this.liste_elements.remiseGlobale = 0
      this.remiseGlobale = 0
    }

    this.liste_elements.montantTotal_TTC = this.liste_elements.totalElements.reduce((acc, product) => acc + ((product.montant_HT - product.montant_HT * product.remise / 100 - product.montant_HT * this.liste_elements.remiseGlobale / 100) + (product.montant_HT - product.montant_HT * product.remise / 100 - product.montant_HT * this.liste_elements.remiseGlobale / 100) * (product.taxId.percentage / 100)), 0);

    this.liste_elements.montantTotal_TTC = +this.liste_elements.montantTotal_TTC.toFixed(2)
    this.result = this.liste_elements.montantTotal_TTC
  }

  addProductToOrder() {
    if (this.elementSelected.productId) {
      const exist = this.liste_elements.totalElements.filter(x => x.id == this.elementSelected.productId.id)
      if (exist.length == 0) {
        this.liste_elements.totalElements.push(this.elementSelected)
      }
    } else if (this.elementSelected.packId) {
      const exist = this.liste_elements.totalElements.filter(x => x.packId.id == this.elementSelected.packId.id)
      if (exist.length == 0) {
        this.liste_elements.totalElements.push(this.elementSelected)
      }
    }
  }
  getCrates() {
    let filter = new FilterDto()
    filter.relations = ["userId", "cratesId", "cratesId.pointid", "cratesId.pointid.adress"]
    filter.where = { "entrepriseId": this.entrepriseId, "userId": this.user_id, "active": true }
    this.cratesService.getSessionCrates(filter).subscribe(
      data => {
        let currentDate = new Date()
        data[0].forEach(el => {
          let ch = el.openedAt.toString()
          let day = ch.split('-')[2]
          let month = ch.split('-')[1]
          let year = ch.split('-')[0]
          if (day === currentDate.getDate().toString() && month === (currentDate.getMonth() + 1).toString() && year === currentDate.getFullYear().toString()) {
            if (!el.closedAt && !el.closedBy) {
              this.sessionObject = data[0][0]
            }
          }
        })
        if (!this.sessionObject?.id) {
          this.sessionObject = {
            openedAt: new Date()
          }
        }
      })
  }
  saveOperation(customer: Customer) {
    this.order.operations = []
    this.operation = new Operation()
    this.operation.operationDetails = []
    var opDetail = this.real_id_operation_detail - 1
    this.liste_elements.totalElements.forEach(el => {
      this.modelOperationDetail = new OperationDetails()
      if (el.productId) {
        this.modelOperationDetail.productId = el.productId
        if (el.gratuite) {
          this.modelOperationDetail.quantity = el.quantity + el.gratuite
        } else {
          this.modelOperationDetail.quantity = el.quantity
        }
        this.modelOperationDetail.entrepriseId = this.entrepriseId
        this.modelOperationDetail.real_id = opDetail + 1
        this.modelOperationDetail.operationTypeId = this.opr_type_id
        this.modelOperationDetail.byAdmin = false
        this.modelOperationDetail.createdBy = this.currentUser.id
        this.operation.operationDetails.push(this.modelOperationDetail)
        opDetail++;
      } else if (el.packId) {
        el.packId.details.forEach(element => {
          this.modelOperationDetail = new OperationDetails()
          this.modelOperationDetail.productId = element.productid
          this.modelOperationDetail.entrepriseId = this.entrepriseId
          this.modelOperationDetail.quantity = element.qte_produit * el.quantity
          this.modelOperationDetail.real_id = opDetail + 1
          this.modelOperationDetail.operationTypeId = this.opr_type_id
          this.modelOperationDetail.byAdmin = false
          this.modelOperationDetail.createdBy = this.currentUser.id
          this.operation.operationDetails.push(this.modelOperationDetail)
          opDetail++;
        })
      }
    })
    this.real_id_operation_detail = opDetail++
    this.operation.entrepriseId = this.entrepriseId
    this.operation.isCompleted = true
    this.operation.real_id = this.real_id_operation
    this.operation.storeId = this.storeId.id
    this.operation.createdBy = this.currentUser.id
    this.operation.byAdmin = false
    this.order.operations.push(this.operation)
    this.enregistrer()
    this.addTransaction(customer);
    this.modelOperationDetail = new OperationDetails()
    this.operation = new Operation()
  }
  //added by Dali 19/08/2023 
  clientChanged(item: Customer) {
    this.customer = item
  }

  onSearchChange(args?: string) {
    if (args == undefined || args?.length == 0) {
      this.customers = this.tmpListCustomers
    } else {
      this.customers = []
      this.tmpListCustomers.forEach(el => {
        if (el.name?.toLowerCase().indexOf(args?.toLowerCase()) != -1) {
          this.customers.push(el);
        }
      })
    }
  }

  //end
  submitOrder() {
    this.order.isCompleted = true
    this.order.real_id = this.real_id_order
    if (this.customer == null || this.customer?.id == null) {
      this.saveOperation(this.defaultCustomer);
      this.order.customerId = this.defaultCustomer
      this.order.updateCarteFidelite = false
    } else {
      this.order.customerId = this.customer
      this.order.updateCarteFidelite = this.customer.carteFidelites.length > 0
      this.saveOperation(this.customer)
    }
    this.order.createdBy = this.currentUser.id
    this.order.byAdmin = false
    this.order.sessionId = this.sessionObject.id
    this.order.entrepriseId = this.entrepriseId
    this.order.pointId = this.pointId
    if (this.order.updateCarteFidelite) {
      // this.refreshCustomerInfo(this.customer.id)
      this.felicitationMessages = this.checkClientCarteFidelite(this.customer.carteFidelites[0], this.order.orderDetails)
      if (this.felicitationMessages.length > 0) {
        this.showFelicitationModal = true
      } else if (this.felicitationMessages.length == 0) {
        this.addOrder();
      }
    } else {
      this.addOrder();
    }
  }
  refreshCustomerInfo(id: number) {
    let filter = new FilterDto()
    filter.where = { "active": true, "id": id, "entrepriseId": this.entrepriseId }
    filter.relations = ["carteFidelites", "carteFidelites.carteFideliteDetails",
      "carteFidelites.carteFideliteDetails.productId",
      "carteFidelites.carteFideliteDetails.packId"]
    this.partsService.getCustomerInfo(filter).subscribe(
      data => {
        this.customer = data[0][0]
      }
    )
  }
  confirmed() {
    this.showFelicitationModal = false
    this.addOrder()
  }
  addOrder() {
    this.orderService.postOrder(this.order).subscribe(
      data => {
        this.id_order_to_print = data
        this.alertSuccess = true
        this.resetForm();

        alert("Vente enregistré avec succès")
      }, err => {
        console.log(err)
      }
    )
  }
  resetForm() {
    this.real_id_operation++
    this.real_id_operation_detail++
    this.real_id_order++
    this.real_id_order_det++
    this.real_id_trans++
    this.order = new Order()
    this.transaction = new Transaction()
    this.operationsDetails = []
    this.liste_elements = new ListeElement([])
    this.elementSelected = new ElementSelected()
    this.getOperation({})
    this.getOperationDetails({});
    this.getTransactions({});
    this.getOrders({})
    this.getOrderDetails({})
    this.getQuotations();
    this.getConfig({ where: { "entrepriseId": this.entrepriseId } })
  }
  closeSession() {
    let model: SessionCrates = new SessionCrates()
    model.active = false;
    model.closedAt = new Date()
    model.closedBy = this.user_id
    this.cratesService.patchSessionCrates(this.sessionObject.id, model).subscribe(
      data => {
        //console.log("closed")
      }
    )
  }

  OpenProductList() {
    window.open("#/catalog/product")
  }
  navigateToHome() {
    window.open("#/dashboard")
  }
  logout() {
    this.router.navigate(['/user/login']);
    localStorage.removeItem("authorisation")
    deleteCookie("token")
  }

  editQte(item: ElementSelected) {
    this.elementSelected = new ElementSelected()
    this.showQteModal = true
    this.editQteElement = true
    this.elementSelected = item
  }


  editQteElementFct(args) {
    let index_el = -1
    let i = 0
    this.liste_elements.totalElements.forEach(
      el => {
        if (el.productId?.id == this.elementSelected?.productId?.id) {
          index_el = i;
        } else if (el.packId?.id == this.elementSelected?.packId?.id) {
          index_el = i;
        }
        i++;
      }
    )
    if (index_el > -1) {
      if (this.elementSelected.productId) {
        let index = this.tmpAllProduct.findIndex(x => x.productId?.id == this.elementSelected.productId.id)
        let qte = this.tmpAllQuantity[index];
        if (qte <= 0) {
          this.showAlert = true
          this.showQuestion = false
          this.msgAlert = "Ce produit n'est pas disponible dans votre stock "
        }
        else
          if (qte - args - this.stockSecurity == 0) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "Ce produit a atteint le stock de sécurité "
              + "il ne reste que " + (qte - args) + "dans votre stock"
          }
          else if (args > qte) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "Epuise de stock, la quantité entré n'est pas disponible ";
          } else
            if (args == qte - this.stockSecurity) {
              this.showAlert = true
              this.showQuestion = false
              this.msgAlert = "Pour cette quantité le produit entre dans le stock de sécurité"
            }
        if (args <= qte) {
          this.elementSelected.quantity = args;
          this.liste_elements.totalElements[index_el].quantity = args
          this.showAlert = false;
          this.calculeMontant()
          this.calculeMontantTotal()
          this.editQteElement = false
        }
      } else if (this.elementSelected.packId) {
        var qte = 0;
        let tab_prod = this.elementSelected.packId?.details.forEach(el => {
          let index = this.tmpAllProduct.findIndex(x => x.productId?.id == el.productid.id)
          qte = this.tmpAllQuantity[index];
          let qte_in_pack = el.qte_produit
          if (qte <= 0) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "le produit " + this.tmpAllProduct[index].productId.name + " n'est pas disponible dans votre stock "
          }
          if (qte - this.stockSecurity <= 0) {
            this.alertStockSecurity = true
            this.StockMsg = "Le produit " + this.tmpAllProduct[index].productId.name + " a atteint le stock de sécurité ";
          } else {
            this.alertStockSecurity = false;
          }
          if (args * qte_in_pack > qte) {
            this.showAlert = true
            this.showQuestion = false
            this.msgAlert = "Epuise de stock, la quantité entré pour le produit " + this.tmpAllProduct[index].productId.name + " n'est pas disponible ";
          } else
            if (args * qte_in_pack >= qte - this.stockSecurity) {
              this.alertStockSecurity = true
              this.StockMsg = "Pour cette quantité le produit " + this.tmpAllProduct[index].productId.name + " entre dans le stock de sécurité"
            } else {
              this.alertStockSecurity = false
            }
        })

        if (!this.showAlert && !this.alertStockSecurity) {
          this.elementSelected.quantity = +args;
          this.showAlert = false;
          this.calculeMontant()
          this.liste_elements.totalElements[index_el].quantity = args
          this.calculeMontantTotal();
        }
      }
    }
  }


  getUserInfo() {
    let filter = new FilterDto()
    filter.where = { "id": this.user_id, "entrepriseId": this.entrepriseId }
    filter.relations = ["cratesId", "entrepriseId", "cratesId.pointid", "cratesId.pointid.storeId", "cratesId.pointid.adress"]
    let number = ''
    let street = ''
    let city = ''
    let geocode = ''
    this.cratesService.getUser(filter).subscribe(
      data => {
        this.currentUser = data[0][0]
        this.storeId = data[0][0].cratesId.pointid.storeId
        this.pointId = data[0][0].cratesId.pointid.id
        let filter = new FilterDto()
        filter.where = {}
        this.getProductsList(10, 0,filter)
        this.name = data[0][0].name
        number = (this.currentUser.cratesId.pointid?.adress[0]?.number != undefined) ? this.currentUser.cratesId.pointid?.adress[0].number.toString() : '';
        street = (this.currentUser.cratesId.pointid?.adress[0]?.street != undefined) ? this.currentUser.cratesId.pointid?.adress[0].street : '';
        city = (this.currentUser.cratesId.pointid?.adress[0]?.city != undefined) ? this.currentUser.cratesId.pointid?.adress[0].city : '';
        geocode = (this.currentUser.cratesId.pointid?.adress[0]?.geoCode != undefined) ? this.currentUser.cratesId.pointid?.adress[0].geoCode.toString() : '';
        this.address = `${number} ${street} ${city} ${geocode} `
      }
    )
  }
  input: string = '';
  pressNum(num: string) {
    if (num == "0") {
      if (this.input == "") {
        return;
      }
      const PrevKey = this.input[this.input.length - 1];
      if (PrevKey === '/' || PrevKey === '*' || PrevKey === '-' || PrevKey === '+') {
        return;
      }
    }
    this.input = this.input + num
    var nb = 0;
    for (let i = 0; i < this.input.length; i++) {
      if (this.input[i] == '.') {
        nb++;
      }
    }
  }


  getLastOperand() {
    let pos: number;
    pos = this.input.toString().lastIndexOf("+")
    if (this.input.toString().lastIndexOf("-") > pos) pos = this.input.lastIndexOf("-")
    if (this.input.toString().lastIndexOf("*") > pos) pos = this.input.lastIndexOf("*")
    if (this.input.toString().lastIndexOf("/") > pos) pos = this.input.lastIndexOf("/")
    return this.input.substr(pos + 1)
  }


  pressOperator(op: string) {
    //Do not allow operators more than once
    const lastKey = this.input[this.input.length - 1];
    if (lastKey === '/' || lastKey === '*' || lastKey === '-' || lastKey === '+') {
      return;
    }
    this.input = this.input + op
    this.calcAnswer();
  }


  clear() {
    if (this.input != "") {
      this.input = this.input.substr(0, this.input.length - 1)
    }
  }

  allClear() {
    this.input = '';
    this.onChange("quantity", 1)
  }

  calcAnswer() {
    //this.result = parseInt(this.input) * this.productSelected.montant_TTC
  }

  getAnswer() {
    this.addProductToOrder();
    this.input = ""
    if (this.input == "0") this.input = "";
  }
  selectTableRow(item: any) {
    this.id_selected = item
    $("#table_products tr").click(function () {
      $(this).addClass('selected').siblings().removeClass('selected');
      var value = $(this).find('td:first').html()
    })
    $('.ok').on('click', function (e) {
      alert($("#table tr.selected td:first").html());
    })
  }
  DeleteLine() {
    if (this.id_selected?.productId) {
      const index = this.liste_elements.totalElements.findIndex(x => x.productId.id == this.id_selected.productId.id)
      if (index != -1) {
        this.liste_elements.totalElements.splice(index, 1);
        this.calculeMontantTotal();
      }
    } else if (this.id_selected?.packId) {
      const index = this.liste_elements.totalElements.findIndex(s => s.packId?.id == this.id_selected.packId.id)
      if (index != -1) {
        this.liste_elements.totalElements.splice(index, 1);
        this.calculeMontantTotal();
      }
    }
  }
  ValidateLine() {
    this.elementSelected = new ElementSelected();
    this.input = ''
    this.reste = undefined
  }
  CalculReste(amount: any) {
    this.reste = 0
    if (amount == "5") {
      this.reste = 5 - this.result
    } else if (amount == "10") {
      this.reste = 10 - this.result

    } else if (amount == "20") {
      this.reste = 20 - this.result

    } else if (amount == "50") {
      this.reste = 50 - this.result
    } else {
      this.reste = amount - this.result
    }
  }

  makeScroll(divId: any) {
    var div = document.getElementById(divId)
    div.scrollLeft += 20
  }
  makeScrollLeft(divId: any) {
    var div = document.getElementById(divId)
    div.scrollLeft -= 20
  }

  scannedCarteFideliteHandler(carteFideliteCode: string) {
    let carteFideliteModel = this.carteFideliteList.filter(x => x.bar_code == carteFideliteCode)[0]
    if (carteFideliteModel != undefined && carteFideliteModel.customerId != undefined) {
      this.customer = carteFideliteModel.customerId;
    }
  }

}
