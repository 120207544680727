import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken } from 'src/app/user/user.service';
import { Commande, CommandeDetail } from '../../Commande';
import { CommandeService } from '../../Service/commande-service.service';

@Component({
  selector: 'app-commande-detail',
  templateUrl: './commande-detail.component.html',
  styleUrls: ['./commande-detail.component.css']
})
export class CommandeDetailComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();
  @Input() commandeId: number;
  showAlert: boolean;
  filter: FilterDto<Commande> = new FilterDto<Commande>()
  commande: Commande = new Commande();
  commandeProducts: CommandeDetail[] = []
  commandeServices: CommandeDetail[] = []
  userAdd: any
  id_entreprise: number
  arabicLanguage: boolean = false

  constructor(private commandeService : CommandeService, private settingService: SettingService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getCommande(this.commandeId);
    this.EventLang();
  }

  getCommande(commandeId: number) {
    this.filter.relations = ["customerId", "etatCommandeId", "pointId", "commandeDetails", "commandeDetails.productId", "commandeDetails.serviceId"];
    this.filter.where = { "id": commandeId,"entrepriseId":this.id_entreprise };
    this.commandeService.getAllCommande(this.filter).subscribe(
      data => {
        this.commande = data[0][0];
        this.commandeProducts = this.commande?.commandeDetails?.filter(item => item.active == true && item.productId)
        this.commandeServices = this.commande?.commandeDetails?.filter(item => item.active == true && item.serviceId)
        if (this.commande.createdBy) {
          if (!this.commande.byAdmin) {
            this.settingService.getUser(this.commande.createdBy).subscribe(
              data => {
                this.userAdd = data
              }
            )
          } else {
            let filter = new FilterDto()
            filter.where = { "id": this.id_entreprise }
            this.settingService.getAllEntreprise(filter).subscribe(
              data => {
                this.userAdd = data[0][0]
              }
            )
          }
        }
      },
      err => console.error('Observer got an error: ', err),
    );
    this.showAlert = false;
  }
  close() {
    this.closed.emit(true);
  }
  EventLang() {
  let lang = localStorage.getItem('currentLang')
  if (lang == 'ar') {
    this.arabicLanguage = true
  } else {
    this.arabicLanguage = false
  }
  }
  changeEventLang(event: any) {
    this.translate.use(event.target.value);
    if (event.target.value == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
}
