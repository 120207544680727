import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken } from 'src/app/user/user.service';
import { Detail } from '../../detail/detail';
import { DetailService } from '../../detail/detail.service';

@Component({
  selector: 'app-add-modal-type',
  templateUrl: './add-modal-type.component.html',
  styleUrls: ['./add-modal-type.component.css']
})
export class AddModalTypeComponent implements OnInit {
  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();

  id_entreprise: number
  real_id: number
  constructor(private detailsService: DetailService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getDetails({ where: { "entrepriseId": this.id_entreprise } })
  }
  getDetails(filter: FilterDto<Detail>) {
    this.detailsService.getDetails(filter).subscribe(
      data => {
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err),
    );
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  CancelAction() {
    this.closed.emit(true)
  }
  submit(type: Detail) {
    type.real_id = this.real_id;
    type.entrepriseId = this.id_entreprise
    this.detailsService.addDetail(type).subscribe(x => {
      this.saved.emit(true);
      this.CancelAction()
    })
  }
}
