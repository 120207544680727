import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Detail } from '../detail';
import { DetailService } from '../detail.service';

@Component({
  selector: 'app-detail-delete',
  templateUrl: './detail-delete.component.html',
  styleUrls: ['./detail-delete.component.css']
})
export class DetailDeleteComponent {
  @Input() detail: Detail;
  @Output() closed = new EventEmitter<boolean>();
  
  constructor(protected detailService:DetailService) { }
  
  close() {
    this.closed.emit(true);
  }
  
  save() {
    this.detailService.deleteDetail(this.detail.id)
    .subscribe(
      data => {
        this.closed.emit(true);
      })
  }
}
