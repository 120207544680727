<app-nav></app-nav>

<div class="login-wrapper">


  <form class="login">
    <section class="title">
      <img src="assets\inveep.png" width="250" />
      <h5 class="hint">{{ 'login.RNGC' | translate }}<a><img src="assets\log.png" width="30" /></a></h5>
      <h5 class="hint" *ngIf="isLogin && !linkSent">
        {{ 'reg.err' | translate }}
      </h5>
      <h5 class="hint" *ngIf="linkSent && isLogin">
        {{ 'reg.er' | translate }}<b class="text-primary">{{resetMail}}</b> {{ 'reg.rr' | translate }}
      </h5>
    </section>
    <div class="alert alert-danger" role="alert" *ngIf="alertUser">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-check-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <span class="alert-text">
            {{msgError}}
          </span>
        </div>
      </div>
    </div>
    <div class="login-group">
      <div *ngIf="userId">
        <svg xmlns="http://www.w3.org/2000/svg" (click)="editEmail()" style="color: red; cursor:pointer;" width="16"
          height="16" fill="currentColor" class="bi bi-x-circle-danger" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>&nbsp;
        <!-- <clr-icon shape="times-circle" class="edit-btn" (click)="editEmail()"></clr-icon> -->
        <!-- <img class="card-media-image" [src]="picture" width="50px" height="50px" />&nbsp; -->
        <span><b>{{user.email}}</b></span>
      </div>
      <br>
      <form *ngIf="!isLogin">
        <clr-input-container *ngIf="!userId">
          <label class="clr-sr-only">{{ 'login.VotreEmail' | translate }}</label>
          <input type="text" name="email" clrInput placeholder="Inveep@inv.tn" [(ngModel)]="user.email" />
        </clr-input-container>
        <clr-password-container>
          <label class="clr-sr-only">{{ 'login.MDP' | translate }}</label>
          <input type="password" name="password" clrPassword placeholder="********" [(ngModel)]="user.password" />
        </clr-password-container>
        <clr-checkbox-wrapper>
          <label>{{ 'login.svnr' | translate }}</label>
          <input type="checkbox" name="rememberMe " clrCheckbox [(ngModel)]="user.rememberMe "
            (change)="onChange($event)" />
        </clr-checkbox-wrapper>
        <div *ngIf="show && !isLogin" class="error active"> {{ 'login.ee' | translate }}</div>
      </form>
      <div *ngIf="isLogin">
        <clr-input-container *ngIf="!userId">
          <label class="clr-sr-only">{{ 'login.VotreEmail' | translate }}</label>
          <input type="text" name="email" clrInput placeholder="Votre adresse mail" [(ngModel)]="resetMail"
            (ngModelChange)="valuechange($event)" />
        </clr-input-container>
        <div *ngIf="mailInvalid || !resetMail && resetMail !=''  " class="error active"> {{ 'login.ee' | translate }}
        </div>
      </div>
      <button type="submit" *ngIf="!isLogin" class="btn btn-success" (click)="login()">{{ 'login.CONNEXION' | translate
        }}</button>
        <!-- <button class="button" (click)="login()">
          <p>{{ 'login.CONNEXION' | translate}}</p>
        </button> -->
      <a routerLink="/user/sign-in" *ngIf="!isLogin" class="signup">{{ 'login.INSCRPTION' | translate }}</a>
      <button type="button" *ngIf="isLogin && !linkSent" class="btn btn-primary" (click)="submit()">{{ 'login.vv' |
        translate }}</button>
      <button type="button" *ngIf="isLogin && linkSent" class="btn btn-success cursor-no-drop">{{ 'login.ll' | translate
        }}</button>
      <br>
      <div class="row clr-justify-content-end flex">
        <div class="col-4 ">
          <span class="text-right cursor-pointer text-primary" *ngIf="!isLogin" (click)="isLogin=!isLogin">{{
            'login.Mdpobli' | translate }}</span>
          <span class="text-right cursor-pointer text-primary" *ngIf="isLogin" (click)="isLogin=!isLogin"> {{ 'login.tt'
            | translate }}</span>
        </div>
      </div>
    </div>
  </form>
</div>
