import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { OrderService } from 'src/app/sales/order/order.service';
import { SettingService } from 'src/app/setting/setting.service';
import { environment } from 'src/environments/environment';
import { Entreprise } from '../entreprise';

@Component({
  selector: 'app-activation-account',
  templateUrl: './activation-account.component.html',
  styleUrls: ['./activation-account.component.css']
})
export class ActivationAccountComponent implements OnInit {

  token: string
  entrepriseObject: Entreprise
  showAlert: boolean = false
  showSuccess: boolean = false
  mail: { from: any; to: string; subject: string; html: string };
  constructor(private router: Router, private settingService: SettingService,
    private orderService: OrderService) { }

  ngOnInit(): void {
    let url = this.router.url.split("=")
    this.token = url[1]
    let filter = new FilterDto()
    filter.where = { "activationId": this.token }
    this.settingService.getAllEntreprise(filter).subscribe(
      data => {
        if (data[0][0] != undefined) {
          this.entrepriseObject = data[0][0]
          if (this.entrepriseObject.confirmedEmail) {
            this.showAlert = true
            setTimeout(() => {
              this.router.navigate(["/user/login"])
            }, 3000);
          } else {
            let model = new Entreprise()
            model.confirmedEmail = true
            this.settingService.editEntreprise(this.entrepriseObject.id, model).subscribe(
              data => {
                this.showSuccess = true
                let content
                content = `
                <div><u></u>
  <style>
    .CToWUd {
      width: 10%;
      height: 45;
    }

    .facture {
      width: 8%;
      height: 10%;
    }

    .Livraison {
      width: 8%;
      height: 10%;
    }

    .caisse, pt_sales {
      width: 7%;
      height: 11%;
    }

    .text {
      display: flex;
      align-items: center;
    }

    .text-property {
      padding-left: 5px
    }
  </style>
  <div>
    <div>
      <div>
        <img src="https://inveep.com/images/logos/logo-2.png" alt="Inveep" class="CToWUd" data-bit="iit">
      </div>

      <div>
        <div>
          <p>Bonjour ${this.entrepriseObject.name} ,</p>
          <p>Bienvenue chez Inveep .
          </p>
          <p>Nous sommes ravis de vous avoir comme client et promettons de vous tenir au courant de nos offres.</p>
          <p>Vous disposez désormais de 14 jours d'essai gratuit.
          <p>
          <p>Voici quelques conseils pour profiter au mieux d'Inveep::
          </p>
        </div>

        <div class="text">
          <img src="https://inveep.com/web/frontOffice/images/icon/facturation.png" alt="Facturation" class="facture" data-bit="iit">
          <span class="text-property"><b>Facturation</b> | choisissez Abonnement Facturation<span>
              <span>Pour plus d'informations,</span>
              <a href="http://inveep.com"> veuillez cliquer ici</a>
        </div>
        <br>
        <div class="text">
          <img src="https://inveep.com/web/frontOffice/images/icon/livraison.png" alt="Livraison" class="Livraison" data-bit="iit">
          <span class="text-property"> <b>Livraison</b> | choisissez Abonnement Livraison
            Pour plus d'informations, veuillez
            <a href="http://inveep.com">cliquer ici</a></span>
        </div>
        <br>
        <div class="text">
          <img src="https://inveep.com/web/frontOffice/images/icon/point_de_vente.png" class="pt_sales" alt="Point de vente" data-bit="iit">
          <span class="text-property"><b>Gestion des points de vente | Gérer vos points de vente à distance</b> 
            Pour plus d'informations, veuillez <a href="http://inveep.com" target="_blank">cliquer
              ici</a></span>
        </div>
        <br>
        <div class="text">
          <img src="https://inveep.com/web/frontOffice/images/icon/caisse.png" class="caisse" alt="Caisse" data-bit="iit">
          <span class="text-property"><b>Gestion des caisses | Decouvrir nos caisses connectés </b> 
            Pour plus d'informations, veuillez <a href="http://inveep.com" target="_blank">cliquer
              ici</a></span>
        </div>
        <br>
      </div>
      <hr>
      <span>Merci pour l'intérêt que vous portez</span><br>
      <span> A très bientôt.</span><br>
      <span>L'équipe Inveep</span>
    </div>
  </div>
</div>
                `
                this.mail = {
                  from: environment.emailSociete,
                  to: this.entrepriseObject.email,
                  subject: "Bienvenue à Inveep",
                  //template: environment.path_template + '/regis.hbs',
                  html: content
                }
                this.orderService.sendMail(this.mail).subscribe()
                setTimeout(() => {
                  this.router.navigate(["/user/login"])
                }, 3000);
              }
            )
          }
        }
      }
    )
  }

}
