import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { StockService } from 'src/app/stock/stock.service';
import { Contact } from 'src/app/user/user';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Address, Customer } from '../../parts';
import { PartsService } from '../../parts.service';


@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css']
})
export class CustomerEditComponent {

  customerId: number;
  showAlert: boolean;
  showDetail: boolean;
  showDetailAdr: boolean;
  customerObject: Customer
  contact = new Contact()
  adresse = new Address()
  adressArray = []
  contactArray = []
  allContact = []
  allAdress = []
  editedContact: boolean;
  editedAdress: boolean;
  usedMail: boolean;
  usedAdr: boolean;
  editMode: boolean;
  initaStep: string
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private route: ActivatedRoute, private partsService: PartsService, private stockService: StockService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.initaStep = 'step4'
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.customerId = +params.get('id')
    })
    let filter: FilterDto<Customer> = new FilterDto()
    filter.where = { id: this.customerId, active: true, "entrepriseId": this.id_entreprise }
    filter.relations = ["contacts", "adress"]
    this.partsService.getCustomers(filter).subscribe(
      data => {
        this.customerObject = data[0][0]
      }
    )
    this.partsService.getContacts({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allContact = data[0]
      }
    )
    this.stockService.getAdress({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allAdress = data[0]
      }
    )
  }
   
  addContact() {
    const existsInBd = this.allContact.filter(element =>
      element.email === this.contact.email
      && element.entrepriseId == this.id_entreprise
    )
    const existsInTab = this.customerObject.contacts.filter(element => element.email === this.contact.email)

    if (this.editMode) {
      if (existsInTab.length > 0) {
        this.usedMail = true
        this.showDetail = false
      }
      else {
        this.contact.entrepriseId = this.id_entreprise
        this.customerObject.contacts.push(this.contact)
        this.contact = new Contact()
        this.showDetail = true;
        this.editedContact = false
        this.usedMail = false
        this.editMode = false
      }
    }
    else {
      if (existsInBd.length > 0 || existsInTab.length > 0) {
        this.usedMail = true
        this.showDetail = false
      }
      else {
        this.contact.entrepriseId = this.id_entreprise
        this.customerObject.contacts.push(this.contact)
        this.contact = new Contact()
        this.showDetail = true;
        this.editedContact = false
        this.usedMail = false
        this.editMode = false
      }
    }



  }
  editContact(item) {
    this.editedContact = true
    this.contact = item
    this.customerObject.contacts.splice(this.customerObject.contacts.indexOf(item), 1);
    this.editMode = true
  }

  deleteContact(item) {
    this.customerObject.contacts.splice(this.customerObject.contacts.indexOf(item), 1);
    this.showDetail = false;
  }



  addAdresse() {
    this.adresse.entrepriseId = this.id_entreprise
    this.customerObject.adress.push(this.adresse)
    this.adresse = new Address()
    this.showDetailAdr = true;
    this.editedAdress = false
    this.usedAdr = false
  }

  editAdresse(item) {
    this.editedAdress = true
    this.adresse = item
    this.customerObject.adress.splice(this.customerObject.adress.indexOf(item), 1);
  }

  deleteAdresse(item) {
    this.customerObject.adress.splice(this.customerObject.adress.indexOf(item), 1);
    this.showDetailAdr = false;
  }


  submit() {
    this.customerObject.entrepriseId = this.id_entreprise
    this.customerObject.updatebyAdmin = this.isAdmin
    this.partsService.editCustomer(this.customerId, this.customerObject).subscribe(
      data => {
        this.showAlert = true;
      }
    )
  }
  clrInitialStep(initialStep: string) {
    this.initaStep = initialStep
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
