import { Product } from "../catalog/product/product";
import { Tax } from "../catalog/tax/tax";
import { Entreprise } from "../setting/setting";

export class pack {
  constructor() { }
  public id?: number;
  public real_id?: number;
  public nom?: string;
  public price?: number;
  public createdAt?: Date;
  public updatedAt?: Date;
  public createdBy?: number;
  public updatedBy?: number;
  public taxId?: Tax & number;
  public entrepriseId?: Entreprise | number;
  public isDeleted?: boolean;
  public active?: boolean;
  public reference?: string;
  public details?: detailpack[];
  public bar_code?: string;
  public favorite?: boolean;
  public byAdmin?: boolean;
  public updatebyAdmin?: boolean;
}

export class detailpack {
  constructor() { }
  public id?: number;
  public qte_produit?: number;
  public createdAt?: Date;
  public updatedAt?: Date;
  public createdBy?: number;
  public updatedBy?: number;
  public packId?: number | pack;
  public entrepriseId?: number | Entreprise;
  public isDeleted?: boolean;
  public productid?: Product;
  public byAdmin?: boolean;
  public updatebyAdmin?: boolean;
}
