import { Component, HostListener, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { StockService } from 'src/app/stock/stock.service';
import { Contact } from 'src/app/user/user';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Address, Companys } from '../../parts';
import { PartsService } from '../../parts.service';

@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.css']
})
export class CompanyAddComponent implements OnInit {

  companyObject = new Companys()
  contact = new Contact()
  adresse = new Address()
  adressArray = []
  contactArray = []
  allContact = [];
  allAdress = [];
  showAlert: boolean;
  showDetail: boolean;
  showDetailAdr: boolean;
  usedMail: boolean;
  initaStep: string;
  addNew: any;
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private partsService: PartsService, private stockService: StockService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.partsService.getContacts({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allContact = data[0]
      }
    )
    this.partsService.getCompanys({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )

  }
   
  loadAdresse() {
    this.stockService.getAdress({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allAdress = data[0]
      }
    )
  }

  addContact() {
    const existsInBd = this.allContact.filter(element =>
      element.email === this.contact.email &&
      element.email != undefined
      && element.entrepriseId == this.id_entreprise
    )
    const existsInTab = this.contactArray.filter(element => element.email === this.contact.email && element.email != undefined)
    if (existsInBd.length > 0 || existsInTab.length > 0) {
      this.usedMail = true
    }
    else {
      this.contact.entrepriseId = this.id_entreprise
      this.contactArray.push(this.contact)
      this.contact = new Contact()
      this.companyObject.contacts = this.contactArray
      this.companyObject.tel = this.contactArray[0].tel
      this.showDetail = true;
      this.usedMail = false
    }
  }
  addAdresse() {
    this.adresse.entrepriseId = this.id_entreprise
    this.adressArray.push(this.adresse)
    this.adresse = new Address()
    this.companyObject.adress = this.adressArray
    this.showDetailAdr = true;
  }

  deleteContact(item) {
    this.contactArray.splice(this.contactArray.indexOf(item), 1);
    this.showDetail = false;
  }

  deleteAdresse(item) {
    this.adressArray.splice(this.adressArray.indexOf(item), 1);
    this.showDetailAdr = false;
  }
  submit() {
    if (!this.addNew) {
      this.companyObject.entrepriseId = this.id_entreprise
      this.companyObject.real_id = this.real_id;
      this.companyObject.byAdmin = this.isAdmin
      this.partsService.addCompany(this.companyObject).subscribe(
        data => {
          this.showAlert = true;
          this.addNew = true;
        }
      )
    }
  }

  resetForm() {
    this.addNew = false;
    this.allContact = []
    this.allAdress = []
    this.adressArray = []
    this.contactArray = []
    this.companyObject.contacts = []
    this.companyObject.adress = []
    this.companyObject = new Companys();
    this.showAlert = false
    this.showDetail = false
    this.showDetailAdr = false
    this.usedMail = false
    this.initaStep = 'step1'
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
