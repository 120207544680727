import { Component, HostListener, OnInit } from '@angular/core';
import { ClrDatagridStateInterface, ClrDatagridSortOrder } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Bank } from '../../buying/buying';
import { PartsService } from '../parts.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {
  filter: FilterDto<Bank> = new FilterDto<Bank>()
  whereFindOperator = new WhereFindOperator()
  descSort: ClrDatagridSortOrder;
  accountFilter: boolean
  filterEtat: boolean; 
  openBkFilter: boolean;
  showAlert: boolean;
  banksArray: Bank[] = []
  tmpList: Bank[] = []
  showBank: Bank[] = []
  count: number
  loading: boolean
  filtrePrice = [{ active: true, name: "activer" }, { active: false, name: "désactiver" }]
  bankObject: Bank;
  bankId: number;
  showDetail: boolean;
  id_entreprise: number
  userRole: string
  currentPage: number = 1
  total: number
  nbr_page: number = 1
  pageSize: number = 5
  insertIndex: number = 0
  type: string
  types: string[] = ["nom", "numero compte"]
  search_text: string
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  userId : number
  isAdmin : boolean = false 
  userAutorisation : Autorisation = new Autorisation()
  
  constructor(private filterService: FilterService,private userService : UserService,
     private partsservice: PartsService, private translate:TranslateService) {
    this.descSort = ClrDatagridSortOrder.ASC;
  }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.userId = parseInt(jwtDecode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    this.filter.where = {}
    this.filter.take = this.pageSize
    if(!this.isAdmin){
      this.getUserAutorisation()
    }
    this.getBanks();
  }
  typeChanged(type: string) {
    this.type = this.translateItem(type)
    // this.banksArray = this.tmpList
    // this.total = this.banksArray.length
    // if (this.total > 0) {
    //   this.nbr_page = Math.ceil(this.total / this.pageSize)
    // } else {
    //   this.nbr_page = 1
    // }
  }
  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  }
  refresh(state: ClrDatagridStateInterface) {
    if (state) {
      this.filter = this.filterService.bind(state);
    }
    this.whereFindOperator.key = this.filterService.dateSearch(state)
    if (!this.whereFindOperator.key) { this.whereFindOperator.key = undefined }
    let data = { active: true }
    if (!state?.filters) {
      this.filter.where = data
    }
    else {
      this.filter.where = Object.assign(this.filter.where.valueOf(), data)
      if (this.whereFindOperator.key) {
        this.filter.where = Object.assign(this.filter.where.valueOf(), this.whereFindOperator.key)
      }
    }

  }
  search_nom(event:Event) {
    let saisie = ((event.target as HTMLInputElement).value)
    if (this.search_text.length == 0) {
      this.banksArray = []
      this.total = 1
      this.filter.where = {}
      this.getPaginatedData(1)
    } else {
      this.banksArray = []
      this.tmpList.filter(x => {
        if (x.name.toLowerCase().indexOf(this.search_text.toLowerCase().trim()) != -1) {
          this.banksArray.push(x);
        }
      })  
      this.total = this.banksArray.length
      if (this.total > 0) {
        this.nbr_page = Math.ceil(this.total / this.pageSize)
      } else {
        this.nbr_page = 1
      }
      this.showBank = this.banksArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
      if (this.banksArray.length == 0 && saisie[saisie.length - 1] !== ' ') {
        this.filter.order = { "id": "ASC" }
        this.filter.take = this.pageSize;
        this.filter.where = { name: {
          type: "like",
          value: "*" + this.search_text.trim() + "*"
        }}
        this.getBanks()
      }
    }
  }
  search_numero(event:Event) {
    let saisie = ((event.target as HTMLInputElement).value)
    if (this.search_text.length == 0) {
      this.banksArray = []
      this.total = 1
      this.filter.where = {}
      this.getPaginatedData(1)
    } else {
      this.banksArray = []
      this.tmpList.filter(x => {
        if (x.account.toLowerCase().indexOf(this.search_text.toLowerCase().trim()) != -1) {
          this.banksArray.push(x);
        }
      })  
      this.total = this.banksArray.length
      if (this.total > 0) {
        this.nbr_page = Math.ceil(this.total / this.pageSize)
      } else {
        this.nbr_page = 1
      }
      this.showBank = this.banksArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
      if (this.banksArray.length == 0 && saisie[saisie.length - 1] !== ' ') {
        this.filter.order = { "id": "ASC" }
        this.filter.take = this.pageSize;
        this.filter.where = { account: {
          type: "like",
          value: "*" + this.search_text.trim() + "*"
        }}
        this.getBanks()
      }
    }

    // this.banksArray = []
    // this.tmpList.filter(x => {
    //   if (x.account.indexOf(this.search_text) != -1) {
    //     this.banksArray.push(x)
    //   }
    // })
    // this.total = this.banksArray.length
    // if (this.total > 0) {
    //   this.nbr_page = Math.ceil(this.total / this.pageSize)
    // } else {
    //   this.nbr_page = 1
    // }
  }
  getBanks() {
    if (!this.banksArray[this.insertIndex]){
      this.filter.order = { "id": "DESC" }
      this.filter.where =Object.assign(this.filter.where.valueOf(), { 
        "entrepriseId": this.id_entreprise 
      })
      this.partsservice.getBanks(this.filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.banksArray[this.insertIndex + i] = data[0][i];
          }
          this.tmpList = this.banksArray
          this.showBank = this.banksArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
          this.total = data[1]
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        }
      )
    } else {
      this.showBank = this.banksArray.slice(this.insertIndex, this.insertIndex + this.pageSize)
    }
  }
  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.insertIndex = this.filter.skip ? this.filter.skip : 0
    this.getBanks()
  }
  OpenBankFilter() {
    this.openBkFilter = true
  }
  deleteAction(item) {
    this.bankId = item.id
    this.showAlert = true;
  }
  detailAction(item) {
    this.bankId = item
    this.showDetail = true;
  }
  closeAction() {
    this.showAlert = false;
    this.showDetail = false;
  }
  etatFilter() {
    this.filterEtat = true
  }
  openAccountFilter() {
    this.accountFilter = true
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
  translateItem(item: string): string {
    return this.translate.instant(`sharedSales.${item}`);
  }
}