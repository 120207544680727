import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-catalog-demo',
  templateUrl: './catalog-demo.component.html',
  styleUrls: ['./catalog-demo.component.css']
})
export class CatalogDemoComponent implements OnInit {

  @Input() link:any
  @Input() modalVideoDemo:boolean
  constructor() { }

  ngOnInit(): void {
    const iframe = document.getElementById('videoPlayer');
    iframe.setAttribute("src",this.link)
  }

}
