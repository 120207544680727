<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> {{ 'commun.ddt' | translate }} {{ 'commun.cl' | translate }}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <clr-tabs>
          <clr-tab>
            <button clrTabLink id="link1" (click)="ngOnInit()"> {{ 'commun.sta' | translate }}</button>
            <clr-tab-content id="content1" *clrIfActive="true">
              <app-customer-statestique [customerId]="customerId.id"></app-customer-statestique>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink> {{ 'commun.Historique' | translate }}</button>
            <clr-tab-content *clrIfActive>
              <app-customer-historique [customerId]="customerId.id"></app-customer-historique>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink [clrTabLinkInOverflow]="inFlow"> {{ 'commun.cor' | translate }}</button>
            <clr-tab-content *clrIfActive>
              <app-customer-infos [customerId]="customerId"></app-customer-infos>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink [clrTabLinkInOverflow]="inFlow"> {{ 'commun.cmdd' | translate }}</button>
            <clr-tab-content *clrIfActive>
              <app-customer-commandes [customerId]="customerId.id"></app-customer-commandes>
            </clr-tab-content>
          </clr-tab>
        </clr-tabs>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
