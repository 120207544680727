import { Points } from "../sales-point/points";
import { User } from "../user/user";

export class Crates {
  constructor(
    public id?: number,
    public numero?: string,
    public name?: string,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public entrepriseId?: number,
    public real_id?: number,
    public pointid?: Points,
    public openedAt?: Date,
    public openedBy?: number,
    public closedAt?: number,
    public userId?: User[] ,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
  ) { }
}

export class SessionCrates {
  public id?: number;
  public active?: boolean;
  public userId?: User[] | number
  public cratesId?: Crates[] | number
  public createdAt?: Date;
  public updatedAt?: Date;
  public openedAt?: Date
  public closedAt?: Date
  public openedBy?: number
  public closedBy?: number
  public entrepriseId?: number
}
