import { FilterDto } from 'src/app/filter.dto';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Service } from './service';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class serviceService {
    constructor(private http: HttpClient) { }

    getServices(filter: FilterDto<Service>): Observable<[Service[], number]> {
        return this.http.get(environment.api + '/services?filter=' + JSON.stringify(filter)) as Observable<[Service[], number]> 
    }

    getServiceById(id: number) {
        return this.http.get(environment.api + '/service/' + JSON.stringify(id))
    }

    editService(id: number,service: Service) {
        return this.http.patch<Service>(environment.api + '/service/' +JSON.stringify(id), service)
    }

    addService(service: Service): Observable<Service> {
        return this.http.post(environment.api + '/service', service)
    }

    deleteService(id: number): Observable<Service[]> {
        return this.http.delete(environment.api + '/service/' + JSON.stringify(id)) as Observable<Service[]>
    }
}