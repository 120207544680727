import { Component, OnInit } from '@angular/core';
import { Autorisation } from './autorisation';
import { AutorisationService } from './autorisation.service';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { User } from '../user';
import { getValueFromToken } from '../user.service';

@Component({
  selector: 'app-autorisation',
  templateUrl: './autorisation.component.html',
  styleUrls: ['./autorisation.component.css']
})
export class AutorisationComponent implements OnInit {

  entrepriseId : number
  userList : User[]=[]
  showDelete : boolean = false 
  user : User = new User()
  constructor(
    private settingService : SettingService
  ) { }

  ngOnInit(): void {
    this.entrepriseId = parseInt(getValueFromToken("idEntreprise"))
    this.getAutorisation()
  }

  getAutorisation(){
    let filter = new FilterDto()
    filter.where = {"entrepriseId":this.entrepriseId,"active":true}
    filter.relations = ["autorisationId"]
    this.settingService.getUserInfo(filter).subscribe(
      data => {
        this.userList = data[0]
      }
    )
  }

  deleteAction(item : User){
    this.user = item
    this.showDelete = true 
  }

  refreshList(){
    this.showDelete = false 
    this.getAutorisation()
  }

}
