<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Recherche d'un article </h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <form clrForm #search_form="ngForm">
          <clr-input-container *ngIf="!show_info">
            <label>Reference article *</label>
            <input clrInput type="text" name="reference" [(ngModel)]="search_form.reference" required />
            <clr-control-error>Ce champs est obligatoire</clr-control-error>
          </clr-input-container>
        </form>
        <div class="clr-row" *ngIf="show_info">
          <div class="clr-col-3">
            <span><b> {{ 'article.deta.nom' | translate }}</b></span><br>
            <span><b> {{ 'article.Prod.Réference' | translate }}</b></span><br>
            <span><b>Prix</b></span>
            <span><b> {{ 'article.Mcatg.catg' | translate }}</b></span><br>
            <span><b> {{ 'article.Marque.Mrq' | translate }}</b></span><br>
            <span><b> {{ 'article.unites.unite' | translate }}</b></span><br>
            <span><b> {{ 'Points.desc' | translate }}</b></span><br>
          </div>
          <div class="clr-col">
            <span *ngIf="product_info.name">{{product_info?.name}}</span>
            <span *ngIf="!product_info.name" class="label label-danger">{{ 'commun.nd' | translate
              }}</span><br>
            <span *ngIf="product_info.reference">{{product_info?.reference}}</span>
            <span *ngIf="!product_info.reference" class="label label-danger">{{ 'commun.nd' | translate
              }}</span><br>
            <span *ngIf="product_info.price">{{product_info?.price}}</span>
            <span *ngIf="!product_info.price" class="label label-danger">{{ 'commun.nd' | translate
              }}</span><br>
            <span *ngIf="product_info?.categoryId?.name">{{product_info?.categoryId?.name}}</span>
            <span *ngIf="!product_info?.categoryId?.name" class="label label-danger">{{ 'commun.nd' |
              translate }}</span><br>
            <span *ngIf="product_info?.markId?.name">{{product_info?.markId?.name}}</span>
            <span *ngIf="!product_info?.markId?.name" class="label label-danger">{{ 'commun.nd' | translate
              }}</span><br>
            <span *ngIf="product_info?.unityId?.name">{{product_info?.unityId?.name}}</span>
            <span *ngIf="!product_info?.unityId?.name" class="label label-danger">{{ 'commun.nd' | translate
              }}</span><br>
            <span *ngIf="product_info.description">{{product_info?.description}}</span>
            <span class="label label-danger" *ngIf="!product_info.description">{{ 'commun.nd' | translate
              }}</span><br>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="closeAction()">Annuler</button>
        <button class="btn btn-primary" *ngIf="!show_info" [disabled]="!search_form.reference" type="button"
          (click)="searchProduct(search_form)">Rechercher Article</button>
        <button class="btn btn-primary" (click)="research()" *ngIf="show_info" type="button">
          Rechercher de nouveau
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
