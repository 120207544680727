import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SettingService } from 'src/app/setting/setting.service';
import { User } from '../../user';
import {Router} from "@angular/router"


@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent {
  @Output() closed = new EventEmitter<boolean>();
  @Input() userObject:User
  @Output() saved = new EventEmitter<boolean>();

  deleteAlert: boolean;
  alertError: boolean;
  constructor(private settingService:SettingService,private router: Router) { }

  deleteUser(){
    if(!this.deleteAlert){

      this.settingService.deleteUser(this.userObject.id).subscribe(
        retour=>{
          this.saved.emit(true)
          this.closed.emit(true);
          this.router.navigate(['/users'])
        },
        err => {
        console.error('Observer got an error: ' + err)
        this.alertError=true
        },
        () => this.saved.emit(true)
      )
    }
  }

  closeAction() {
    this.closed.emit(true);
  }

}
