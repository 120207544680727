import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Unity } from '../../unity/unity';
import { UnityService } from '../../unity/unity.service';

@Component({
  selector: 'app-add-unity-modal',
  templateUrl: './add-unity-modal.component.html',
  styleUrls: ['./add-unity-modal.component.css']
})
export class AddUnityModalComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<any>()

  id_entreprise: number;
  real_id: number
  userRole: string
  isAdmin: boolean = false

  constructor(private unityService: UnityService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getUnities({ where: { "entrepriseId": this.id_entreprise } })
  }
  getUnities(filter: FilterDto<Unity>) {
    this.unityService.getUnities(filter).subscribe(
      data => {
        //this.unities = data[0];
        this.real_id = data[1] + 1;
      },
      err => console.error('Observer got an error: ' + err)
    );
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  CancelAction() {
    this.closed.emit(true);
  }
  submit(unity: Unity) {
    unity.entrepriseId = this.id_entreprise
    unity.real_id = this.real_id
    unity.byAdmin = this.isAdmin
    this.unityService.addUnity(unity)
      .subscribe(
        data => {
          this.CancelAction();
          this.saved.emit(data);
        })
  }
}
