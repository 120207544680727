import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { OrderDetail } from 'src/app/sales/order/order';
import { environment } from 'src/environments/environment';
import { Detailproduct, Product, ProductPicture, Productprice, QuantityProduct } from './product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient) { }
  getProducts(filter: FilterDto): Observable<[Product[], number]> {
    return this.http.get(environment.api + '/products?filter=' + JSON.stringify(filter)) as Observable<[Product[], number]>;
  }
  getOrderDetail(filter: FilterDto): Observable<[OrderDetail[], number]> {
    return this.http.get(environment.api + '/orderdetail?filter=' + JSON.stringify(filter)) as Observable<[OrderDetail[], number]>;
  }
  getInfoProducts(store_id: number, filter: FilterDto): Observable<[QuantityProduct[], number]> {
    return this.http.get(environment.api + '/products/details/' + JSON.stringify(store_id) + '?filter=' + JSON.stringify(filter)) as Observable<[QuantityProduct[], number]>;
  }
  getInfoProductsQteStore(id: number, filter: FilterDto): Observable<[QuantityProduct[], number]> {
    return this.http.get(environment.api + '/products/details/' + JSON.stringify(id) + '?filter=' + JSON.stringify(filter)) as Observable<[QuantityProduct[], number]>;
  }
  getProductById(ID: number): Observable<any> {
    return this.http.get(environment.api + '/product/' + JSON.stringify(ID)) as Observable<Product[]>;
  }

  editProductById(ID: number, product: Product): Observable<Product> {
    return this.http.patch<Product>(environment.api + '/product/' + JSON.stringify(ID), product);
  }

  addProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(environment.api + '/product', product)
  }

  deleteProduct(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/product/' + JSON.stringify(ID)) as Observable<Product[]>;
  }

  addPicture(picture: ProductPicture): Observable<ProductPicture> {
    return this.http.post<ProductPicture>(environment.api + '/picture', picture)
  }

  getPictures(filter: FilterDto): Observable<[ProductPicture[], number]> {
    return this.http.get(environment.api + '/pictures?filter=' + JSON.stringify(filter)) as Observable<[ProductPicture[], number]>
  }

  deleteProductPicture(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/picture/' + JSON.stringify(ID)) as Observable<ProductPicture[]>;
  }

  editProductPicture(ID: number, picture: ProductPicture): Observable<ProductPicture> {
    return this.http.patch<ProductPicture>(environment.api + '/picture/' + JSON.stringify(ID), picture);
  }

  deleteProductPrice(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/productPrice/' + JSON.stringify(ID)) as Observable<Productprice[]>;
  }

  deleteProductDetail(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/detailProduit/' + JSON.stringify(ID)) as Observable<Detailproduct[]>;
  }

  deleteProductPictures(idsPictures: number[]): Observable<any> {
    return this.http.post(environment.api + '/pictures/product', idsPictures);
  }

  deleteProductDetails(idsDetails: number[]): Observable<any> {
    return this.http.post(environment.api + '/detailsProduct', idsDetails);
  }

}
