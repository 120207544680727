import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Crates } from 'src/app/crates/crates';
import { CratesService } from 'src/app/crates/crates.service';
import { FilterDto } from 'src/app/filter.dto';
import { Points } from 'src/app/sales-point/points';
import { getValueFromToken } from '../../user.service';

@Component({
  selector: 'app-add-caisse-modal',
  templateUrl: './add-caisse-modal.component.html',
  styleUrls: ['./add-caisse-modal.component.css']
})
export class AddCaisseModalComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>()
  cratesObject: Crates = new Crates();
  loading: boolean = false
  points: Points[]
  tmpLisPoints: Points[]
  id_entreprise: number
  filter: FilterDto
  saisie: string
  real_id: number
  selected_pt: Points
  showAddPoint: boolean = false
  constructor(private cratesService: CratesService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getPoints()
    this.cratesService.getCrates({
      where: {
        "entrepriseId": this.id_entreprise
      }
    }).subscribe(data => {
      this.real_id = data[1] + 1
    })
  }
  CloseAction() {
    this.closed.emit(true)
  }
  submit() {
    this.cratesObject.entrepriseId = this.id_entreprise
    this.cratesObject.real_id = this.real_id
    this.cratesObject.pointid = this.selected_pt
    this.cratesService.addCrates(this.cratesObject).subscribe(
      data => {
        this.saved.emit(true);
      }
    )
  }
  getSalesPoint(item: Points) {
    this.itemChange(item)
    this.showAddPoint = false
    this.getPoints();
  }
  itemChange(item) {
    this.selected_pt = item
  }
  getPoints() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    this.cratesService.getSalesPoint(filter).subscribe(
      data => {
        this.points = data[0]
        this.tmpLisPoints = this.points
      }
    )
  }
  onSearchPoint(args?: string) {
    if (args == undefined || args?.length == 0) {
      this.points = this.tmpLisPoints
    } else {
      this.points = []
      this.tmpLisPoints.forEach(el => {
        if (el?.name.indexOf(args) != -1) {
          this.points.push(el)
        }
      })
    }
  }
}
