<div class="modal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">{{'sharedSales.ajoutproduit' | translate}}</h3>
                <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CloseAction()" width="20"
                    height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
            </div>
            <div class="modal-body">
                <clr-tabs>
                    <clr-tab>
                        <button clrTabLink id="link1">{{'pack.produit' | translate}}</button>
                        <clr-tab-content id="content1" *clrIfActive="true">
                            <form clrForm>
                                <clr-input-container>
                                    <label>{{'sharedSales.nom' | translate}} *</label>
                                    <input placeholder="{{'sharedSales.nom' | translate}}" clrInput
                                        [(ngModel)]="model.name" name="name" required />
                                    <clr-control-error>{{'pack.champobligatoire' | translate}}</clr-control-error>
                                </clr-input-container>
                                <clr-input-container>
                                    <label>{{'sharedSales.réference' | translate}}</label>
                                    <input placeholder="{{'sharedSales.réference' | translate}}"
                                        [(ngModel)]="model.reference" name="reference" clrInput />
                                </clr-input-container>
                                <clr-input-container *ngIf="model.categoryId">
                                    <label>{{'sharedSales.categorie' | translate}}*</label>
                                    <input clrInput name="nameCategory" readonly [ngModel]="model.categoryId.name" />

                                    <clr-control-helper class="mt-0">
                                        <button class="btn btn-link btn-sm mt-0"
                                            (click)="editCategory()">{{'sharedSales.modifcatégorie' |
                                            translate}}</button>
                                    </clr-control-helper>
                                </clr-input-container>
                                <clr-combobox-container *ngIf="!model.categoryId">
                                    <label>{{'sharedSales.categorie' | translate}} *</label>
                                    <clr-combobox name="categoryId" required [clrLoading]="loading"
                                        (clrInputChange)="onSearchChange($event,'category')"
                                        placeholder="{{'sharedSales.selectcategory' | translate}}">
                                        <clr-options *ngIf="categories">
                                            <clr-option *ngFor="let item of categories;" clrValue={{item.name}}
                                                (click)="itemChanged(item,'category')">{{item?.name}}
                                            </clr-option>
                                        </clr-options>
                                        <!-- <clr-control-error>Ce champ est obligatoire!</clr-control-error> -->
                                    </clr-combobox>
                                </clr-combobox-container>
                                <clr-control-helper>
                                    <button class="btn btn-link btn-sm"
                                        (click)="AddCategoriePopUp()">{{'sharedSales.ajoutcatégorie' |
                                        translate}}</button>
                                </clr-control-helper>
                                <clr-combobox-container>
                                    <label>{{'sharedSales.marque' | translate}} *</label>
                                    <clr-combobox name="markId" required [clrLoading]="loading" [ngModel]="selectedMark"
                                        (clrInputChange)="onSearchChange($event,'mark')"
                                        placeholder="{{'sharedSales.selectmarks' | translate}}">
                                        <clr-options *ngIf="marks">
                                            <clr-option *ngFor="let item of marks;" clrValue={{item.name}}
                                                (click)="itemChanged(item.id,'mark')">{{item?.name}}
                                            </clr-option>
                                        </clr-options>
                                        <!-- <clr-control-error>Ce champ est obligatoire!</clr-control-error> -->
                                    </clr-combobox>
                                </clr-combobox-container>
                                <clr-control-helper>
                                    <button class="btn btn-link btn-sm"
                                        (click)="AddMarquePopUp()">{{'sharedSales.ajoutmarque' |
                                        translate}}</button>
                                </clr-control-helper>
                                <clr-combobox-container>
                                    <label>{{'sharedSales.unité' | translate}} *</label>
                                    <clr-combobox name="unityId" required [clrLoading]="loading"
                                        [ngModel]="selectedUnity" (clrInputChange)="onSearchChange($event,'unity')"
                                        placeholder="{{'sharedSales.selectmarks' | translate}}">
                                        <clr-options *ngIf="unities">
                                            <clr-option *ngFor="let item of unities;" clrValue={{item.name}}
                                                (click)="itemChanged(item.id,'unity')">{{item?.name}}
                                            </clr-option>
                                        </clr-options>
                                        <clr-control-error>{{'pack.champobligatoire' | translate}}</clr-control-error>
                                    </clr-combobox>
                                </clr-combobox-container>
                                <clr-control-helper>
                                    <button class="btn btn-link btn-sm"
                                        (click)="AddUnityPopUp()">{{'sharedSales.ajoutunité' |
                                        translate}}</button>
                                </clr-control-helper>
                            </form>
                        </clr-tab-content>
                    </clr-tab>
                    <clr-tab>
                        <button clrTabLink>{{'sharedSales.typeproduit' | translate}}</button>
                        <clr-tab-content *clrIfActive>
                            <clr-radio-container>
                                <label>{{'sharedSales.type' | translate}} *</label>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio (value)="false" name="vendre"
                                        [(ngModel)]="model.forBuying" />
                                    <label>{{'sharedSales.acheter' | translate}}</label>
                                </clr-radio-wrapper>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio value="true" name="fabrication"
                                        [(ngModel)]="model.forBuying" />
                                    <label>{{'sharedSales.fabriquer' | translate}}</label>
                                </clr-radio-wrapper>
                            </clr-radio-container>
                        </clr-tab-content>
                    </clr-tab>
                    <clr-tab>
                        <button clrTabLink>{{'sharedSales.prix' | translate}}</button>
                        <clr-tab-content *clrIfActive>
                            <form clrForm>
                                <clr-combobox-container>
                                    <label>{{'sharedSales.tax' | translate}} %*</label>
                                    <clr-combobox name="taxId" required [clrLoading]="loading"
                                        (clrInputChange)="onSearchChange($event,'tax')" [ngModel]="selectedTax"
                                        placeholder="{{'sharedSales.selecttaxs' | translate}}">
                                        <clr-options *ngIf="taxs">
                                            <clr-option *ngFor="let item of taxs;" clrValue={{item.percentage}}
                                                (click)="itemChanged(item,'tax')"> {{item?.name}}
                                                ({{item?.percentage}} %)
                                            </clr-option>
                                        </clr-options>
                                        <clr-control-error>{{'pack.champobligatoire' | translate}}</clr-control-error>
                                    </clr-combobox>
                                </clr-combobox-container>
                                <!-- <clr-control-helper>
                    <button class="btn btn-link btn-sm" (click)="AddTaxePopUp()">Ajouter
                      Taxe</button>
                  </clr-control-helper> -->
                                <clr-input-container>
                                    <label>{{ 'article.Prod.Prix' | translate }}*</label>
                                    <input clrInput type="number" [(ngModel)]="model.price" name="price" min="0"
                                        required />
                                </clr-input-container>
                                <clr-input-container>
                                    <label> {{ 'article.Prod.Prixbuy' | translate }}*</label>
                                    <input clrInput type="number" [(ngModel)]="model.priceBuy" name="price" min="0"
                                        required />
                                </clr-input-container>

                            </form>
                        </clr-tab-content>
                    </clr-tab>
                    <clr-tab>
                        <button clrTabLink>{{'sharedSales.images' | translate}}</button>
                        <clr-tab-content *clrIfActive>
                            <div class="clr-row" *ngIf="!pictureDetails">
                                <div class="clr-col-12 clr-col-sm-12	clr-col-md-4	clr-col-lg-4	clr-col-xl-2">
                                    <a (click)="fileInput.click()" class="card">
                                        <clr-icon class="plus-icon" shape="plus-circle"></clr-icon>
                                    </a>
                                    <input hidden type="file" #fileInput (change)="picked($event)">
                                </div>
                                <div *ngFor="let item of pictureArray ;let i=index"
                                    class="clr-col-12 clr-col-sm-3	clr-col-md-4	clr-col-lg-4	clr-col-xl-2   img">
                                    <div class="card clickable" (click)="pictureDetailAction(item,i)">
                                        <div class="card-img container">
                                            <div class="clr-row clr-justify-content-between">
                                                <div class="clr-col-4">
                                                    <span class="label label-success"
                                                        *ngIf="item.defaults == true">{{'sharedSales.principal' |
                                                        translate}}</span>
                                                </div>
                                                <div class="clr-justify-content-end">
                                                    <span>
                                                        <button type="button"
                                                            class="btn btn-icon btn-warning btn-sm delete-icon"
                                                            aria-label="warning" (click)="deletePicAction(item)">
                                                            <clr-icon shape="times"></clr-icon>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                            <img [src]="item.url">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-group btn-outline-primary">
                                <button class="btn" (click)="CloseAction()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
                                        <path fill-rule="evenodd"
                                            d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                        <path
                                            d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                                    </svg> {{'pack.annuler' | translate}}
                                </button>
                                <button class="btn" (click)="submit()" *ngIf="!creerNouveau"
                                    [disabled]="!model.unityId || !model.markId || !model.categoryId || !model.name || !model.taxId || !model.price">
                                    <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-plus" viewBox="-1 -4 16 16">
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg> {{'pack.enregistrer' | translate}}
                                </button>
                            </div>
                        </clr-tab-content>
                    </clr-tab>
                </clr-tabs>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
<app-add-category-modal [isAdmin]="isAdmin" *ngIf="showAddCategory" (saved)="AddCategory($event)"
    (closed)="CloseActionPopUp()">
</app-add-category-modal>
<app-add-marque-modal [isAdmin]="isAdmin" *ngIf="showAddMarque" (saved)="AddMarque($event)"
    (closed)="CloseActionPopUp()"></app-add-marque-modal>
<app-add-unity-modal [isAdmin]="isAdmin" *ngIf="showAddUnite" (saved)="AddUnity($event)"
    (closed)="CloseActionPopUp()"></app-add-unity-modal>
<!-- <app-add-modal-taxe [isAdmin]="isAdmin" *ngIf="showAddTaxe" (saved)="AddTax()" (closed)="CloseActionPopUp()"></app-add-modal-taxe> -->