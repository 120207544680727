import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../catalog/product/product';
import { FilterDto } from '../filter.dto';
import { OrderDetail } from '../sales/order/order';
import { OperationType } from '../setting/type/type';
import { Address, ExpiredProduct, Inventory, InventoryDetail, OperationDetails, Order, Store } from './stock';
import { Operation } from './stock';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  getOperationDetaile(search: FilterDto): Observable<OperationDetails> {
    return this.http.get(environment.api + '/operation-details?filter=' + JSON.stringify(search)) as Observable<OperationDetails>;
  }

  getDetailExpiredProduct(search: FilterDto): Observable<[OperationDetails[], number]> {
    return this.http.get(environment.api + "/operation-details?filter=" + JSON.stringify(search)) as Observable<[OperationDetails[], number]>
  }

  updateOperationDetail(id: number, model: OperationDetails): Observable<OperationDetails> {
    return this.http.patch<OperationDetails>(environment.api + "/operation-detail/" + JSON.stringify(id), model)
  }
  removeOperationDetail(id: number): Observable<any> {
    return this.http.delete(environment.api + "/operation-detail/" + JSON.stringify(id))
  }

  getOrderDetail(filter: FilterDto): Observable<[OrderDetail[], number]> {
    return this.http.get(environment.api + '/orderdetail?filter=' + JSON.stringify(filter)) as Observable<[OrderDetail[], number]>
  }
  getStore(search: FilterDto): Observable<Store> {
    return this.http.get(environment.api + '/stores?filter=' + JSON.stringify(search)) as Observable<Store>;
  }
  deleteStore(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/store/' + JSON.stringify(ID)) as Observable<Store[]>;
  }
  addStore(store: Store): Observable<Store> {
    return this.http.post<Store>(environment.api + '/store', store);
  }
  editStore(ID: number, depot: Store): Observable<any> {
    return this.http.patch<Store>(environment.api + '/store/' + JSON.stringify(ID), depot);
  }
  getOperation(search: FilterDto): Observable<[Operation[], number]> {
    return this.http.get(environment.api + '/operations?filter=' + JSON.stringify(search)) as Observable<[Operation[], number]>;
  }
  getOperationDetail(search: FilterDto): Observable<Operation> {
    return this.http.get(environment.api + '/operations?filter=' + JSON.stringify(search)) as Observable<Operation>;
  }
  getAlloperation(filter: FilterDto): Observable<[Operation[], number]> {
    return this.http.get(environment.api + "/operations?filter=" + JSON.stringify(filter)) as Observable<[Operation[], number]>
  }
  getOperationDetailsAll(search: FilterDto): Observable<[Operation[], number]> {
    return this.http.get(environment.api + '/orderdetail?filter=' + JSON.stringify(search)) as Observable<[Operation[], number]>
  }
  getOperationType(search: FilterDto<OperationType>): Observable<[OperationType, number]> {
    return this.http.get(environment.api + '/operation-types?filter=' + JSON.stringify(search)) as Observable<[OperationType, number]>;
  }
  getProduct(filter: FilterDto): Observable<[Product[], number]> {
    return this.http.get(environment.api + '/products?filter=' + JSON.stringify(filter)) as Observable<[Product[], number]>;
  }
  getAdress(search: FilterDto): Observable<Address> {
    return this.http.get(environment.api + '/adress?filter=' + JSON.stringify(search)) as Observable<Address>;
  }

  getEditInvoiceOperation(search: FilterDto): Observable<[Operation[], number]> {
    return this.http.get(environment.api + '/invoicesEdit/operation?filter=' + JSON.stringify(search)) as Observable<[Operation[], number]>;
  }

  getAddInvoiceOperation(search: FilterDto): Observable<[Operation[], number]> {
    return this.http.get(environment.api + '/invoicesAdd/operation?filter=' + JSON.stringify(search)) as Observable<[Operation[], number]>;
  }

  getInvoiceOperation(filter: FilterDto): Observable<Operation[]> {
    return this.http.get(environment.api + '/operations?filter=' + JSON.stringify(filter)) as Observable<Operation[]>
  }

  getOrders(filter: FilterDto): Observable<[Order[], number]> {
    return this.http.get(environment.api + '/orders?filter=' + JSON.stringify(filter)) as Observable<[Order[], number]>
  }

  AddOperation(operation: Operation): Observable<Operation> {
    return this.http.post<Operation>(environment.api + '/operation', operation)
  }

  editOperation(ID: number, operation: Operation): Observable<any> {
    return this.http.patch<Operation>(environment.api + '/operation/' + JSON.stringify(ID), operation);
  }

  deleteOperation(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/operation/' + JSON.stringify(ID)) as Observable<Operation[]>;
  }
  addAddress(address: Address): Observable<Address> {
    return this.http.post<Address>(environment.api + '/adress', address);
  }

  getInventories(filter: FilterDto): Observable<[Inventory[], number]> {
    return this.http.get(environment.api + "/inventories?filter=" + JSON.stringify(filter)) as Observable<[Inventory[], number]>
  }

  getAllInventoryDetail(filter: FilterDto): Observable<[InventoryDetail[], number]> {
    return this.http.get(environment.api + "/inventoriesDetails?filter=" + JSON.stringify(filter)) as Observable<[InventoryDetail[], number]>
  }
  addInventory(model: Inventory): Observable<Inventory> {
    return this.http.post<Inventory>(environment.api + "/inventory", model)
  }

  addInventoryDetail(model: InventoryDetail): Observable<InventoryDetail> {
    return this.http.post<InventoryDetail>(environment.api + "/inventoryDetail", model)
  }

  deleteInventory(id: number) {
    return this.http.delete(environment.api + "/inventory/" + JSON.stringify(id))
  }

  updateInventory(id: number, model: Inventory): Observable<Inventory> {
    return this.http.patch<Inventory>(environment.api + "/inventory/" + JSON.stringify(id), model);
  }
  updateInventoryDetail(id: number, model: InventoryDetail): Observable<InventoryDetail> {
    return this.http.patch<InventoryDetail>(environment.api + "/inventoryDetail/" + JSON.stringify(id), model);
  }
  getExpiredProducts(filter: FilterDto): Observable<[ExpiredProduct[], number]> {
    return this.http.get(environment.api + "/expired-products?filter=" + JSON.stringify(filter)) as Observable<[ExpiredProduct[], number]>
  }

  addExpiredProduct(model: ExpiredProduct): Observable<ExpiredProduct> {
    return this.http.post<ExpiredProduct>(environment.api + "/expired-product", model);
  }

  updateExpiredProduct(id: number, model: ExpiredProduct): Observable<ExpiredProduct> {
    return this.http.patch<ExpiredProduct>(environment.api + "/expired-product/" + JSON.stringify(id), model);
  }

  deleteExpiredProduct(id: number): Observable<any> {
    return this.http.delete(environment.api + "/expired-product/" + JSON.stringify(id));
  }

}
