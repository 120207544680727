import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken } from 'src/app/user/user.service';
import { OperationType } from '../../setting/type/type';

@Component({
  selector: 'app-famille-list-filter',
  templateUrl: './famille-list-filter.component.html',
  styleUrls: ['./famille-list-filter.component.css']
})
export class FamilleListFilterComponent implements ClrDatagridFilterInterface<{ key: string, value: string }> {

  @Input() public openFamFilter: boolean;

  public changes = new Subject<any>();
  familleList = []
  loading: boolean;
  saisie: string;
  id_entreprise: number

  filter: FilterDto = new FilterDto();
  constructor(private settingService: SettingService) { }


  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  onSearchChange(args?: string) {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.select = ["id", "famille"];
      this.filter.where = { famille: { type: "like", value: this.saisie }, "entrepriseId": this.id_entreprise, active: true }
      if (this.openFamFilter) {
        this.getFamilleTypes(this.filter)
      }
    }
    else {
      this.filter.where = { active: true }
      this.filter.take = 15;
      this.getFamilleTypes(this.filter)
    }

  }
  getFamilleTypes(filter: FilterDto<OperationType>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getOperationTypes(filter).subscribe(
      data => {
        this.familleList = data[0]
        this.changes.next(true);
        this.loading = false
      }
    )
  }

  accepts(item): boolean {
    return true;
  }

  isActive(): boolean {
    return this.familleList != null && this.familleList.length > 0;;
  }






}
