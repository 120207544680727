import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { tokenGetter } from 'src/app/user/user.service';
import { Unity } from '../unity';
import { UnityService } from '../unity.service';
@Component({
  selector: 'app-unity-edit',
  templateUrl: './unity-edit.component.html',
  styleUrls: ['./unity-edit.component.css']
})
export class UnityEditComponent implements OnInit {
  showAlert: boolean;
  showMsg: boolean;
  unity: Unity;
  id: number;
  userRole: string
  isAdmin: boolean = false


  constructor(private unityService: UnityService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.unityService.getUnityById(this.id)
        .subscribe(
          data => {
            this.unity = data
          })
    });
  }

  delete() {
    this.showAlert = true;
  }

  close() {
    this.showAlert = false;
  }

  submit(unity: Unity) {
    unity.updatebyAdmin = this.isAdmin
    this.unityService.editUnity(this.id, unity)
      .subscribe(
        data => {
          this.showAlert = true;
        })
  }

}