import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { User } from 'src/app/user/user';
import { getValueFromToken } from 'src/app/user/user.service';
import { Mark } from '../mark';
import { MarkService } from '../mark.service';

@Component({
  selector: 'app-mark-detail',
  templateUrl: './mark-detail.component.html',
  styleUrls: ['./mark-detail.component.css']
})
export class MarkDetailComponent implements OnInit {
  @Input() mark: Mark;
  @Output() closed = new EventEmitter<boolean>();
  showAlert: boolean;
  searchUser: FilterDto<User> = new FilterDto<User>()
  user: any
  user2: any
  id_entreprise: number

  constructor(private markService: MarkService, private quotationService: QuotationService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.markService.getMarkById(this.mark.id)
      .subscribe(
        data => {
          this.mark = data
          if (this.mark.createdBy) {
            if (!this.mark.byAdmin) {
              this.searchUser.select = ["name"];
              this.searchUser.where = { "id": this.mark.createdBy };
              this.quotationService.getUser(this.searchUser).subscribe(
                user => {
                  this.user = user[0][0];
                },
                err => console.error('Observer got an error: ', err),
              );
            } else {
              let filter = new FilterDto()
              filter.select = ["name"];
              filter.where = { "id": this.id_entreprise };
              this.quotationService.getEntreprise(filter).subscribe(
                data => {
                  this.user = data[0][0]
                }
              )
            }
          }
          if (this.mark.updatedAt) {
            if (!this.mark.updatebyAdmin) {
              this.searchUser.select = ["name"];
              this.searchUser.where = { "id": this.mark.createdBy };
              this.quotationService.getUser(this.searchUser).subscribe(
                user => {
                  this.user2 = user[0][0];
                },
                err => console.error('Observer got an error: ', err),
              );
            } else {
              let filter = new FilterDto()
              filter.select = ["name"];
              filter.where = { "id": this.id_entreprise };
              this.quotationService.getEntreprise(filter).subscribe(
                data => {
                  this.user2 = data[0][0]
                }
              )
            }
          }
        })
  }
  close() {
    this.closed.emit(true);
  }
}