import { Component, OnInit } from '@angular/core';
import { User } from '../../user';
import { SettingService } from 'src/app/setting/setting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Autorisation } from '../autorisation';
import { AutorisationService } from '../autorisation.service';
import { getValueFromToken } from '../../user.service';

@Component({
  selector: 'app-add-autorisation',
  templateUrl: './add-autorisation.component.html',
  styleUrls: ['./add-autorisation.component.css']
})
export class AddAutorisationComponent implements OnInit {

  user: User = new User()
  id_user: number
  autorisation_id: number
  id_entreprise: number
  autorisationModel: Autorisation = new Autorisation()
  articleLineState: boolean
  expiredProductLineState: boolean
  salesLineState: boolean
  buyingLineState: boolean
  caisseLineState: boolean
  quotationLineState: boolean
  invoiceLineState: boolean
  exitVoucherLineState: boolean
  salesDeliveryLineState: boolean
  LivraisonLineState : boolean
  vehiculeLineState: boolean
  livreurLineState: boolean
  depotLineState: boolean
  inventaireLineState: boolean
  transactionLineState: boolean
  creditLineState: boolean
  caisseTodayLineState: boolean
  chequeLineState: boolean
  costLineState: boolean
  plusValueLineState: boolean
  customerLineState: boolean
  providerLineState: boolean
  bankLineState: boolean
  companyLineState: boolean
  carteFideliteLineState : boolean
  selected_all: boolean
  showSuccess: boolean = false
  showError: boolean = false
  edit: boolean = false
  successMsg: string = ""
  errorMsg: string = ""
  constructor(
    private settingService: SettingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private autorisationService: AutorisationService
  ) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.edit = this.router.url.indexOf("edit") != -1
    if (this.edit == false) {
      this.activatedRoute.params.subscribe(
        params => {
          this.id_user = +params['id']
          this.getUserInfo()
        }
      )
    } else {
      this.activatedRoute.params.subscribe(
        params => {
          this.autorisation_id = +params['id']
          this.getAutorisationInfo()
        }
      )
    }
  }

  submit() {
    this.autorisationModel.userId = this.user.id
    if (this.user?.autorisationId == undefined) {
      this.autorisationService.addAutorisation(this.autorisationModel).subscribe(
        data => {
          let user = new User()
          user.autorisationId = data.id
          this.settingService.editUser(this.user.id, user).subscribe(
            data => {
              this.showSuccess = true
              this.successMsg = "Ajout des autorisations effectués avec succès"
              setTimeout(() => {
                this.showSuccess = false 
                this.router.navigate(["user/autorisations"])
              }, 2000);
            }
          ), er => {
            this.showError = true
            this.errorMsg = "Ajout des autorisations echoué"
          }
        }
      )
    } else {
      this.autorisationModel.userId = undefined
      this.autorisationService.updateAutorisation(this.autorisation_id, this.autorisationModel).subscribe(
        data => {
          this.showSuccess = true
          this.successMsg = "Ajout des autorisations effectués avec succès"
          setTimeout(() => {
            this.showSuccess = false 
            this.router.navigate(["user/autorisations"])
          }, 2000);
        }, er => {
          this.showError = true
          this.errorMsg = "Modifications des autorisations echoué"
        }
      )
    }
  }

  getAutorisationInfo() {
    let filter = new FilterDto()
    filter.where = { "autorisationId": this.autorisation_id, "entrepriseId": this.id_entreprise }
    filter.relations = ["autorisationId"]
    this.settingService.getUserInfo(filter).subscribe(
      data => {
        this.user = data[0][0]
        this.autorisationModel = this.user.autorisationId
      }
    )
  }

  getUserInfo() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "id": this.id_user, "active": true }
    filter.relations = ["autorisationId"]
    this.settingService.getUserInfo(filter).subscribe(
      data => {
        this.user = data[0][0]
        if (data[0].length == 0) {
          this.router.navigate(["user/autorisations"])
        }
      }
    )
  }
  selectAll() {
    this.articleLineState = this.selected_all
    this.expiredProductLineState = this.selected_all
    this.salesLineState = this.selected_all
    this.buyingLineState = this.selected_all
    this.caisseLineState = this.selected_all
    this.quotationLineState = this.selected_all
    this.invoiceLineState = this.selected_all
    this.exitVoucherLineState = this.selected_all
    this.salesDeliveryLineState = this.selected_all
    this.LivraisonLineState = this.selected_all
    this.vehiculeLineState = this.selected_all
    this.livreurLineState = this.selected_all
    this.depotLineState = this.selected_all
    this.inventaireLineState = this.selected_all
    this.transactionLineState = this.selected_all
    this.creditLineState = this.selected_all
    this.caisseTodayLineState = this.selected_all
    this.chequeLineState = this.selected_all
    this.costLineState = this.selected_all
    this.plusValueLineState = this.selected_all
    this.customerLineState = this.selected_all
    this.providerLineState = this.selected_all
    this.bankLineState = this.selected_all
    this.companyLineState = this.selected_all
    this.carteFideliteLineState = this.selected_all
    this.autorisationModel.menuMouvement = this.selected_all
    this.autorisationModel.menuRetenueSource = this.selected_all
    this.articleLine()
    this.expiredProductLine()
    this.salesLine()
    this.buyingLine()
    this.caisseLine()
    this.quotationLine()
    this.invoiceLine()
    this.exitVoucherLine()
    this.salesDeliveryLine()
    this.vehiculeLine()
    this.livreurLine()
    this.depotLine()
    this.inventaireLine()
    this.transactionLine()
    this.creditLine()
    this.caisseTodayLine()
    this.chequeLine()
    this.costLine()
    this.plusValueLine()
    this.customerLine()
    this.providerLine()
    this.bankLine()
    this.companyLine()
    this.carteFideliteLine()
    this.LivraisonLine()
  }

  articleLine() {
    this.autorisationModel.menuArticle = this.articleLineState
    this.autorisationModel.addArticle = this.articleLineState
    this.autorisationModel.editArticle = this.articleLineState
    this.autorisationModel.deleteArticle = this.articleLineState
    this.autorisationModel.printArticle = this.articleLineState
  }

  expiredProductLine() {
    this.autorisationModel.menuExpiredProd = this.expiredProductLineState
    this.autorisationModel.addExpiredProd = this.expiredProductLineState
    this.autorisationModel.editExpiredProd = this.expiredProductLineState
    this.autorisationModel.deleteExpiredProd = this.expiredProductLineState
  }

  salesLine() {
    this.autorisationModel.menuSales = this.salesLineState
    this.autorisationModel.addSales = this.salesLineState
    this.autorisationModel.editSales = this.salesLineState
    this.autorisationModel.deleteSales = this.salesLineState
    this.autorisationModel.printSales = this.salesLineState
  }

  buyingLine() {
    this.autorisationModel.menuBuying = this.buyingLineState
    this.autorisationModel.addBuying = this.buyingLineState
    this.autorisationModel.editBuying = this.buyingLineState
    this.autorisationModel.deleteBuying = this.buyingLineState
  }

  caisseLine() {
    this.autorisationModel.menuCrates = this.caisseLineState
    this.autorisationModel.addCrates = this.caisseLineState
    this.autorisationModel.editCrates = this.caisseLineState
    this.autorisationModel.deleteCrates = this.caisseLineState
  }

  quotationLine() {
    this.autorisationModel.menuQuotation = this.quotationLineState
    this.autorisationModel.addQuotation = this.quotationLineState
    this.autorisationModel.editQuotation = this.quotationLineState
    this.autorisationModel.deleteQuotation = this.quotationLineState
    this.autorisationModel.printQuotation = this.quotationLineState
  }

  invoiceLine() {
    this.autorisationModel.menuInvoice = this.invoiceLineState
    this.autorisationModel.addInvoice = this.invoiceLineState
    this.autorisationModel.editInvoice = this.invoiceLineState
    this.autorisationModel.deleteInvoice = this.invoiceLineState
    this.autorisationModel.printInvoice = this.invoiceLineState
  }

  exitVoucherLine() {
    this.autorisationModel.menuExitVoucher = this.exitVoucherLineState
    this.autorisationModel.addExitVoucher = this.exitVoucherLineState
    this.autorisationModel.deleteExitVoucher = this.exitVoucherLineState
    this.autorisationModel.printExitVoucher = this.exitVoucherLineState
  }

  salesDeliveryLine() {
    this.autorisationModel.menuSalesDelivery = this.salesDeliveryLineState
    this.autorisationModel.printSalesDelivery = this.salesDeliveryLineState
  }

  LivraisonLine(){
    this.autorisationModel.menuLivraison = this.LivraisonLineState
    this.autorisationModel.addLivraison = this.LivraisonLineState
    this.autorisationModel.editLivraison = this.LivraisonLineState
    this.autorisationModel.deleteLivraison = this.LivraisonLineState
    this.autorisationModel.printLivraison = this.LivraisonLineState
  }

  vehiculeLine() {
    this.autorisationModel.menuVehicule = this.vehiculeLineState
    this.autorisationModel.addVehicule = this.vehiculeLineState
    this.autorisationModel.editVehicule = this.vehiculeLineState
    this.autorisationModel.deleteVehicule = this.vehiculeLineState
  }

  livreurLine() {
    this.autorisationModel.menuLivreur = this.livreurLineState
    this.autorisationModel.addLivreur = this.livreurLineState
    this.autorisationModel.editLivreur = this.livreurLineState
    this.autorisationModel.deleteLivreur = this.livreurLineState
  }

  depotLine() {
    this.autorisationModel.menuDepot = this.depotLineState
    this.autorisationModel.addDepot = this.depotLineState
    this.autorisationModel.editDepot = this.depotLineState
    this.autorisationModel.deleteDepot = this.depotLineState
  }

  inventaireLine() {
    this.autorisationModel.menuInventaire = this.inventaireLineState
    this.autorisationModel.addInventaire = this.inventaireLineState
    this.autorisationModel.editInventaire = this.inventaireLineState
    this.autorisationModel.deleteInventaire = this.inventaireLineState
  }

  transactionLine() {
    this.autorisationModel.menuTransaction = this.transactionLineState
    this.autorisationModel.addTransaction = this.transactionLineState
    this.autorisationModel.printTransaction = this.transactionLineState
  }

  creditLine() {
    this.autorisationModel.menuCredit = this.creditLineState
    this.autorisationModel.addCredit = this.creditLineState
    this.autorisationModel.printCredit = this.creditLineState
  }

  caisseTodayLine() {
    this.autorisationModel.menuCaisseToday = this.caisseTodayLineState
    this.autorisationModel.printCaisseToday = this.caisseTodayLineState
  }

  chequeLine() {
    this.autorisationModel.menuCheque = this.chequeLineState
    this.autorisationModel.editChequeGarantie = this.chequeLineState
    this.autorisationModel.deleteChequeGarantie = this.chequeLineState
  }

  costLine() {
    this.autorisationModel.menuCost = this.costLineState
    this.autorisationModel.addCost = this.costLineState
    this.autorisationModel.editCost = this.costLineState
    this.autorisationModel.deleteCost = this.costLineState
  }
  plusValueLine() {
    this.autorisationModel.menuPlusValue = this.plusValueLineState
    this.autorisationModel.addPlusValue = this.plusValueLineState
    this.autorisationModel.editPlusValue = this.plusValueLineState
    this.autorisationModel.deletePlusValue = this.plusValueLineState
  }

  customerLine() {
    this.autorisationModel.menuCustomer = this.customerLineState
    this.autorisationModel.addCustomer = this.customerLineState
    this.autorisationModel.editCustomer = this.customerLineState
    this.autorisationModel.deleteCustomer = this.customerLineState
  }


  providerLine() {
    this.autorisationModel.menuProvider = this.providerLineState
    this.autorisationModel.addProvider = this.providerLineState
    this.autorisationModel.editProvider = this.providerLineState
    this.autorisationModel.deleteProvider = this.providerLineState
    this.autorisationModel.printProvider = this.providerLineState
  }
  bankLine() {
    this.autorisationModel.menuBanque = this.bankLineState
    this.autorisationModel.addBanque = this.bankLineState
    this.autorisationModel.editBanque = this.bankLineState
    this.autorisationModel.deleteBanque = this.bankLineState
  }
  companyLine() {
    this.autorisationModel.menuCompany = this.companyLineState
    this.autorisationModel.addCompany = this.companyLineState
    this.autorisationModel.editCompany = this.companyLineState
    this.autorisationModel.deleteCompany = this.companyLineState
  }

  carteFideliteLine() {
    this.autorisationModel.menuCarteFidelite = this.carteFideliteLineState
    this.autorisationModel.addCarteFidelite = this.carteFideliteLineState
    this.autorisationModel.editCarteFidelite = this.carteFideliteLineState
    this.autorisationModel.deleteCarteFidelite = this.carteFideliteLineState
    this.autorisationModel.printCarteFidelite = this.carteFideliteLineState

  }


}
