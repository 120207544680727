import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { getValueFromToken, tokenGetter } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = tokenGetter();
    if (token.length === 0) {
      this.router.navigate(['/user/login']);
      return false;
    }

    const userRole = getValueFromToken("role");
    const abonne = getValueFromToken("abonee");
    const triale = getValueFromToken("triale");
    const reste_triale = getValueFromToken("reste_triale");
    const active_account = getValueFromToken("active_account");
    const active_abn = getValueFromToken("active_abn");
    const type_abonnement = getValueFromToken("type_abn");

    if (this.isUserAuthorized(abonne, active_abn, triale, reste_triale, active_account, state.url)) {
      return true;
    }

    // if (this.isRedirectRequired(triale, reste_triale, type_abonnement,state.url)) {
    //   this.handleRedirect(state.url,reste_triale,triale,abonne);
    //   return false;
    // }

    return false;
  }

  private isUserAuthorized(abonne: boolean, active_abn: boolean,
    triale: boolean, reste_triale: boolean, active_account: boolean,
    url: string): boolean {
    return ((abonne && active_abn) || triale || reste_triale)
      && active_account 
  }

  private isRedirectRequired(triale: boolean,
    reste_triale: boolean, type_abn: string, url: string): boolean {
    if (!reste_triale && !triale) {
      return true
    } else if (type_abn == "premuim") {
      return false
    } else if (type_abn == "facturation_only") {
      if (url.indexOf("carte-fidelite") == -1 && url.indexOf("sales-point") == -1
        && url.indexOf("transport") == -1) {
        return true
      }
    } else if (type_abn == "facturation_point_vente") {
      if (url.indexOf("carte-fidelite") == -1 && url.indexOf("transport") == -1) {
        return true
      }
    } else if (type_abn == "livraison") {
      if(url.indexOf("carte-fidelite") == -1 && url.indexOf("sales-point") == -1){
        return true;
      }
    } else if (type_abn == "carte_fidelite") {
      if(url.indexOf("customer") != -1 || url.indexOf("carte-fidelite") != -1){
        return true
      }
    }
    // return false;
  }

  private isAuthorizedRoute(url: string, type_abn: string): boolean {
    // return (
    //   url.indexOf("/sales/invoice") !== -1 ||
    //   url.indexOf("/sales/quotation") !== -1 ||
    //   url.indexOf("crates") !== -1 ||
    //   url.indexOf("/caisse") !== -1
    // );
    if (type_abn == 'premuim') {
      return true
    }
    if (type_abn == 'facturation_only') {
      return (url.indexOf("carte-fidelite") == -1 && url.indexOf("sales-point") == -1
        && url.indexOf("transport") == -1)
    }
    if (type_abn == 'facturation_point_vente') {
      return (url.indexOf("carte-fidelite") == -1 && url.indexOf("transport") == -1
      )
    }
    if (type_abn == 'livraison') {
      return (url.indexOf("carte-fidelite") == -1 && url.indexOf("sales-point") == -1
      )
    }
    if (type_abn == 'carte_fidelite') {
      return (url.indexOf("customer") != -1 || url.indexOf("carte-fidelite") != -1)
    }
    return false
  }

  private handleRedirect(url: string,reste_triale : boolean, trial : boolean,
    abonne : boolean): void {
    if(!reste_triale && !trial && !abonne){
      this.router.navigateByUrl('/setting/company');
    }else if(abonne){
      this.router.navigate(['/dashboard']);
    }
  }
}
