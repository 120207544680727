import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-cashier',
  templateUrl: './edit-cashier.component.html',
  styleUrls: ['./edit-cashier.component.css']
})
export class EditCashierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
