import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { PriceType } from 'src/app/setting/type/type';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Category } from '../../category/category';
import { CategoryService } from '../../category/category.service';
import { Detail } from '../../detail/detail';
import { DetailService } from '../../detail/detail.service';
import { Mark } from '../../mark/mark';
import { MarkService } from '../../mark/mark.service';
import { Tax } from '../../tax/tax';
import { TaxService } from '../../tax/tax.service';
import { Unity } from '../../unity/unity';
import { UnityService } from '../../unity/unity.service';
import { Detailproduct, Product, ProductPicture, Productprice } from '../product';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {
  showAlert: boolean;
  model: Product = new Product();
  modelProductprice: Productprice = new Productprice();
  productPriceArray: ProductPicture[] = [];
  modelDetailProduct: Detailproduct = new Detailproduct()
  productDetailArray: Detailproduct[] = [];
  modelProductPicture: ProductPicture = new ProductPicture()

  productId: number;
  filter: FilterDto = new FilterDto()
  pictures: ProductPicture[];
  loading: boolean = false;
  saisie: string;

  categories: Category[];
  marks: Mark[];
  unities: Unity[];
  taxs: Tax[];
  priceTypes: PriceType[];
  details: Detail[];

  pictureArray: ProductPicture[] = [];
  allpicture: ProductPicture = new ProductPicture();
  pictureString: ProductPicture = new ProductPicture();
  filename: string;
  pictureIndex: number = -1;
  desableDefCheck: boolean;
  isPictureChecked: boolean;
  pictureDetails: boolean;
  untouched: boolean = true;
  filesize: number;
  indexProductPrice: number = -1;
  indexProductDetail: number = -1;
  modEditProductDetail: boolean;
  modEditProductPrice: boolean;
  tailleInvalid: boolean;
  editCateory: boolean = false;
  category: Category;
  initialStep: string = "step4"
  id_entreprise: number
  showAddMarque: boolean = false
  showAddTaxe: boolean = false
  showAddCategory: boolean = false
  showAddUnite: boolean = false
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private activatedRoute: ActivatedRoute, private productService: ProductService, private settingService: SettingService, private categoryService: CategoryService, private markService: MarkService, private unityService: UnityService,
    private taxService: TaxService, private detailService: DetailService) {
  }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.activatedRoute.params.subscribe((params: Params) => {
      this.productId = +params['id'];
    });
    this.getProduct();
  }
  AddTaxePopUp() {
    this.showAddTaxe = true;
  }
  AddCategoriePopUp() {
    this.showAddCategory = true;
  }
  AddUnityPopUp() {
    this.showAddUnite = true;
  }
  AddMarquePopUp() {
    this.showAddMarque = true;
  }
  AddUnity() {
    this.getUnities(this.filter);
  }
  AddMarque() {
    this.getMarks(this.filter);
  }
  AddCategory() {
    this.getCategories(this.filter);
  }
  AddTaxe() {
    this.getTaxs(this.filter);
  }
  CloseAction() {
    this.showAddMarque = false;
    this.showAddCategory = false;
    this.showAddUnite = false;
    this.showAddTaxe = false;
  }

  getProduct() {
    let filter: FilterDto = new FilterDto()
    filter.relations = ["markId", "taxId", "unityId", "categoryId", "categoryId.details", "productPrices", "productPrices.priceTypeId", "productDetails", "productDetails.detailId"];
    filter.where = { "id": this.productId, "entrepriseId": this.id_entreprise };
    //filter.where = { "entrepriseId": this.id_entreprise }
    this.productService.getProducts(filter).subscribe(
      product => {
        this.model = product[0][0];
        this.productPriceArray = this.model.productPrices;
        this.productDetailArray = this.model.productDetails;
        this.category = product[0][0].categoryId
        this.productService.getPictures({ relations: ["detailId"], where: { productId: this.productId, "entrepriseId": this.id_entreprise } }).subscribe(
          pictures => {
            this.pictures = pictures[0];
            this.pictureArray = this.pictures
            const index = this.pictureArray.findIndex(picture => picture.defaults === true);
            if (index > 0) {
              let picture: ProductPicture = new ProductPicture()
              picture = this.pictureArray[index]
              this.pictureArray.splice(index, 1)
              this.pictureArray.unshift(picture)
            }
          },
          err => console.error('Observer got an error: ', err),
        );
      },
      err => console.error('Observer got an error: ', err),
    );
    this.showAlert = false;
  }
  clrInitialStep(initialStep: string) {
    this.initialStep = initialStep
  }

  onSearchChange(args: string, table: string) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      if (table == "tax") {
        this.filter.where = { percentage: { type: "equal", value: +args }, active: true }
      } else {
        if (table == "detail") {
          this.filter.where = { type: { type: "like", value: this.saisie }, active: true }
        } else {
          this.filter.where = { name: { type: "like", value: this.saisie }, active: true }
        }
      }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true }
    }
    if (table == "category") {
      this.getCategories(this.filter)
    } else {
      if (table == "mark") {
        this.getMarks(this.filter)
      } else {
        if (table == "unity") {
          this.getUnities(this.filter)
        } else {
          if (table == "tax") {
            this.getTaxs(this.filter)
          } else {
            if (table == "priceType") {
              this.getPriceTypes(this.filter)

            } else {
              if (table == "detail") {
                this.getDetails(this.filter)
              }
            }
          }
        }
      }
    }
  }
  itemChanged(item: any, tableItem: string) {
    if (tableItem == "category") {
      this.model.categoryId = item
      if (this.category.id == item.id) {
        this.productDetailArray = this.model.productDetails;
        this.pictureArray = this.pictures
      }

    } else {
      if (tableItem == "mark") {
        this.model.markId = item
      } else {
        if (tableItem == "unity") {
          this.model.unityId = item
        } else {
          if (tableItem == "tax") {
            this.model.taxId = item
          } else {
            if (tableItem == "priceType") {
              this.modelProductprice.priceTypeId = item
            } else {
              if (tableItem == "detail") {
                this.modelDetailProduct.detailId = item
              }
            }
          }
        }
      }
    }
  }

  getCategories(filter: FilterDto) {
    filter.relations = ["details"]
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.categoryService.getCategories(filter).subscribe(
      data => {
        this.categories = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getMarks(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.markService.getMarks(filter).subscribe(
      data => {
        this.marks = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getUnities(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.unityService.getUnities(filter).subscribe(
      data => {
        this.unities = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getTaxs(filter: FilterDto) {
    filter.where = { "active": true }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getPriceTypes(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.settingService.getPricesTypes(filter).subscribe(
      data => {
        this.priceTypes = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getDetails(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    let idDetails = []
    this.model.categoryId.details.forEach(element => {
      idDetails.push(element.id)
    })
    filter.where = Object.assign(filter.where, { id: { type: "in", value: idDetails } })
    this.detailService.getDetails(filter).subscribe(
      data => {
        this.details = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  ajoutProductprice() {
    if (this.indexProductPrice >= 0) {
      this.productPriceArray[this.indexProductPrice] = this.modelProductprice
      this.modEditProductPrice = false

    } else {
      this.productPriceArray.push(this.modelProductprice)
    }
    this.indexProductPrice = -1
    this.modelProductprice = new Productprice()
  }

  editProductPrice(item: Productprice, index: number) {
    this.modelProductprice = item;
    if (item.id) {
      this.indexProductPrice = index
      this.modEditProductPrice = true
    } else {
      this.productPriceArray.splice(index, 1);
    }
  }

  deleteProductPrice(item: Productprice, index: number) {
    this.productPriceArray.splice(index, 1);
    if (item.id) {
      this.productService.deleteProductPrice(item.id).subscribe(
        data => {
        },
        err => console.error('Observer got an error: ' + err),
      )
    }
  }

  change(table: string) {
    this.onSearchChange(undefined, table)
  }

  ajoutDetailProduct() {
    if (this.indexProductDetail >= 0) {
      this.modelDetailProduct.entrepriseId = this.id_entreprise
      this.productDetailArray[this.indexProductDetail] = this.modelDetailProduct
      this.modEditProductDetail = false
    } else {
      this.modelDetailProduct.entrepriseId = this.id_entreprise
      this.productDetailArray.push(this.modelDetailProduct)
    }
    this.indexProductDetail = -1
    this.modelDetailProduct = new Detailproduct()
  }

  editProductDetail(item: Detailproduct, index: number) {
    this.modelDetailProduct = item;
    if (item.id) {
      this.modEditProductDetail = true
      this.indexProductDetail = index
    } else {
      this.productDetailArray.splice(index, 1);
    }
  }

  deleteProductDetail(item: Detailproduct, index: number) {
    if (index) {
      this.productDetailArray.splice(index, 1);
    }
    if (item.id) {
      this.productService.deleteProductDetail(item.id).subscribe(
        data => {
        },
        err => console.error('Observer got an error: ' + err),
      )
    }
  }

  picked(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.filename = file.name;
      this.filesize = file.size;
      if (this.filesize > 100000) { //size of file 
        this.tailleInvalid = true;
        return
      }
      this.tailleInvalid = false;

      this.handleInputChange(file);
    }
    else {
      alert("No file selected");
    }
  }

  handleInputChange(files) {
    var file = files;
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    let allpicture: ProductPicture = new ProductPicture()
    allpicture.url = await e.target.result;
    allpicture.defaults = false
    this.pictureArray.push(allpicture)
    const found = this.pictureArray.filter(item => item.defaults === true);
    if (found.length == 0) {
      this.pictureArray[0].defaults = true
    }
    this.desableDefCheck = false
  }

  async deletePicAction(item) {
    var pos = this.pictureArray.indexOf(item);
    this.pictureArray.splice(pos, 1);
    if (item.defaults == true) {
      if (this.pictureArray.length > 0) {
        this.pictureArray[0].defaults = true
      }
    }
    if (item.id) {
      this.productService.deleteProductPicture(item.id).subscribe(
        data => {

        },
        err => console.error('Observer got an error: ' + err),
      )
    }
  }

  pictureDetailAction(item: ProductPicture, index: number) {
    if (this.untouched) {
      this.pictureString = item
      this.pictureDetails = true
      this.pictureIndex = index
      if (this.pictureArray.length == 1) {
        this.desableDefCheck = true
      }
      if (this.pictureString.defaults == true) {
        this.isPictureChecked = true
      } else {
        this.isPictureChecked = false
      }
    }
  }

  submitPictureDetails() {
    this.pictureDetails = false
    if (this.pictureArray[this.pictureIndex].defaults == true && this.pictureIndex != 0) {
      this.pictureArray[0].defaults = false
      let item = this.pictureArray[this.pictureIndex]
      this.pictureArray.splice(this.pictureIndex, 1)
      this.pictureArray.unshift(item)
    }

  }

  submit() {
    if (this.model.categoryId.id != this.category.id) {
      let idsDetails: number[] = []
      let idsPictures: number[] = []
      this.model.productDetails?.forEach(element => {
        element.entrepriseId = this.id_entreprise;
        idsDetails.push(element.id)
      });
      this.productService.deleteProductDetails(idsDetails).subscribe(
        data => {
        },
        err => console.error('Observer got an error: ' + err),
      )

      this.pictures?.forEach(element => {
        element.entrepriseId = this.id_entreprise
        idsPictures.push(element.id)
      });
      this.productService.deleteProductPictures(idsPictures).subscribe(
        data => {
        },
        err => console.error('Observer got an error: ' + err),
      )
    }
    this.model.productPrices = this.productPriceArray
    this.model.productDetails = this.productDetailArray
    this.model.updatebyAdmin = this.isAdmin
    this.productService.editProductById(this.productId, this.model)
      .subscribe(
        product => {
          this.pictureArray.forEach(async picture => {
            picture.productId = product.id
            picture.entrepriseId = this.id_entreprise
            if (picture.id) {
              await this.productService.editProductPicture(picture.id, picture)
                .subscribe(
                  picture => {
                    this.showAlert = true;
                  })
            } else {
              this.productService.addPicture(picture)
                .subscribe(
                  picture => {
                    this.showAlert = true;
                  })
            }
          })
          this.showAlert = true;
        })
  }

  editCategory() {
    this.editCateory = true;
  }
  cancelEditCategory() {
    this.editCateory = false;
  }
  activeEditCatgory() {
    this.model.categoryId = null;
    this.pictureArray = []
    this.productDetailArray = []
    this.editCateory = false
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}