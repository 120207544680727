import { Component, Input } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken } from 'src/app/user/user.service';
import { PartsService } from '../../parts/parts.service';

@Component({
  selector: 'app-description-list-filter',
  templateUrl: './description-list-filter.component.html',
  styleUrls: ['./description-list-filter.component.css']
})
export class DescriptionListFilterComponent implements ClrDatagridFilterInterface<{ key: string, value: string }> {

  @Input() public desCostType: boolean
  @Input() public descPriceType: boolean
  @Input() public descProvider: boolean
  @Input() public descCustomer: boolean
  public changes = new Subject<any>();
  descritionArray = []
  loading: boolean;
  saisie: string;
  filter: FilterDto = new FilterDto();
  id_entreprise: number;

  constructor(private settingService: SettingService, private partsService: PartsService) { }

  onSearchChange(args?: string) {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.select = ["id", "description"];
      this.filter.where = { description: { type: "like", value: this.saisie }, active: true }
      if (this.descCustomer) { this.getDescCustomer(this.filter) }
      if (this.descProvider) { this.getDescProvider(this.filter) }
      if (this.desCostType) { this.getcostsTypes(this.filter) }
      if (this.descPriceType) { this.getPricesTypes(this.filter) }

    }
    else {
      this.filter.where = { active: true }
      this.filter.take = 15;
      if (this.descCustomer) { this.getDescCustomer(this.filter) }
      if (this.descProvider) { this.getDescProvider(this.filter) }
      if (this.desCostType) { this.getcostsTypes(this.filter) }
      if (this.descPriceType) { this.getPricesTypes(this.filter) }
    }

  }

   
  getDescCustomer(filter: FilterDto<any>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.partsService.getCustomers(filter).subscribe(
      data => {
        data[0].forEach(element => {
          if (element.description) {
            this.descritionArray.push(element)
          }
        });
        this.changes.next(true);
        this.loading = false
      }
    )
  }

  getcostsTypes(filter: FilterDto<any>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getCostTypes(filter).subscribe(
      data => {
        data[0].forEach(element => {
          if (element.description) {
            this.descritionArray.push(element)
          }
        });
        this.changes.next(true);
        this.loading = false
      }
    )
  }

  getPricesTypes(filter: FilterDto<any>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.settingService.getPricesTypes(filter).subscribe(
      data => {
        data[0].forEach(element => {
          if (element.description) {
            this.descritionArray.push(element)
          }
        });
        this.changes.next(true);
        this.loading = false
      }
    )
  }

  getDescProvider(filter: FilterDto<any>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.partsService.getProviders(filter).subscribe(
      data => {
        data[0].forEach(element => {
          if (element.description) {
            this.descritionArray.push(element)
          }
        });
        this.changes.next(true);
        this.loading = false
      }
    )
  }

  accepts(item): boolean {
    return true;
  }

  isActive(): boolean {
    return this.descritionArray != null && this.descritionArray.length > 0;

  }

}
