import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/catalog/product/product';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from 'src/app/parts/parts';
import { Operation } from 'src/app/stock/stock';
import { Entreprise } from 'src/app/user/entreprise';
import { Contact, User } from 'src/app/user/user';
import { environment } from 'src/environments/environment';
import { Invoice, InvoiceDetail } from './invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  getInvoicesDetails(filter: FilterDto<InvoiceDetail>): Observable<[InvoiceDetail[], number]> {
    return this.http.get(environment.api + '/invoicesDetails?filter=' + JSON.stringify(filter)) as Observable<[InvoiceDetail[], number]>;
  }
  getInvoices(filter: FilterDto<Invoice>): Observable<[Invoice[], number]> {
    return this.http.get(environment.api + '/invoices?filter=' + JSON.stringify(filter)) as Observable<[Invoice[], number]>;
  }

  getCustomers(filter: FilterDto<Customer>): Observable<[Customer[], number]> {
    return this.http.get(environment.api + '/customers?filter=' + JSON.stringify(filter)) as Observable<[Customer[], number]>;
  }

  getProducts(filter: FilterDto<Product>): Observable<[Product[], number]> {
    return this.http.get(environment.api + '/products?filter=' + JSON.stringify(filter)) as Observable<[Product[], number]>;
  }

  getUser(filter: FilterDto<any>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>;
  }

  getEntrepriseData(filter: FilterDto<any>): Observable<[Entreprise[], number]> {
    return this.http.get(environment.api + '/entreprises?filter=' + JSON.stringify(filter)) as Observable<[Entreprise[], number]>;
  }

  deleteInvoice(ID: number) {
    return this.http.delete(environment.api + '/invoice/' + JSON.stringify(ID));
  }

  addOperation(operation: Operation): Observable<Operation> {
    return this.http.post<Operation>(environment.api + '/operation', operation)
  }

  deleteInvoiceDetail(ID: number) {
    return this.http.delete(environment.api + '/invoicedetail/' + JSON.stringify(ID));
  }

  postInvoice(invoice: Invoice): Observable<Invoice> {
    return this.http.post<Invoice>(environment.api + '/invoice', invoice)
  }

  postInvoiceDetail(invoiceDetail: InvoiceDetail): Observable<InvoiceDetail> {
    return this.http.post<InvoiceDetail>(environment.api + '/invoicedetail', invoiceDetail)
  }

  patchInvoice(ID: number, invoice: Invoice): Observable<Invoice> {
    return this.http.patch<Invoice>(environment.api + '/invoice/' + JSON.stringify(ID), invoice);
  }

  // sendMail(mail){
  //   return this.http.post( "https://iotech-mailer.ioc.tn/api/mails", mail)
  // }
  sendMail(mail) {
    return this.http.post(environment.api + '/sendInvoice', mail)
  }

  patchContactClient(id: number, contact: Contact): Observable<Contact> {
    return this.http.patch<Contact>(environment.api + '/contact/' + JSON.stringify(id), contact);
  }
}
