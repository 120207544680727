import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { environment } from 'src/environments/environment';
import { Mark } from './mark';

@Injectable({
  providedIn: 'root'
})
export class MarkService {

  constructor(private http: HttpClient) { }

  getMarks(filter: FilterDto<Mark>): Observable<[Mark[],number]> {
    
    return this.http.get(environment.api + '/marks?filter=' + JSON.stringify(filter))as Observable<[Mark[],number]>;
  }

  getMarkById(ID: number): Observable<any> {
    return this.http.get(environment.api + '/mark/' + JSON.stringify(ID)) as Observable<Mark[]>;
  }

  editMark(ID: number, mark: Mark): Observable<any> {
    return this.http.patch<Mark>(environment.api + '/mark/' + JSON.stringify(ID), mark);
  }

  addMark(mark: Mark): Observable<Mark> {
    return this.http.post<Mark>(environment.api + '/mark', mark);
  }

  deleteMark(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/mark/' + JSON.stringify(ID)) as Observable<Mark[]>;
  }
}