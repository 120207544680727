<div class="clr-row">
  <div class="clr-col-md-6 clr-col-12">
    <form clrForm [formGroup]="productForm">
      <div class="alert alert-danger" *ngIf="showAlert">
        <div class="alert-items">
          <div class="alert-item static" role="alert">
            <div class="alert-icon-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-exclamation-circle" viewBox="0 0 16 16" >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
              </svg>
            </div>
            <span class="alert-text">
              {{msgAlert}}
            </span>
          </div>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="alertStockSecurity">
        <div class="alert-items">
          <div class="alert-item static" role="alert">
            <div class="alert-icon-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
              </svg>
            </div>
            <span class="alert-text">
              {{StockMsg}}
            </span>
          </div>
        </div>
      </div>
      <clr-alert [clrAlertType]="'success'" *ngIf="succesEditOrderDetail" [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text">
            {{msgAlert}} </span>
        </clr-alert-item>
      </clr-alert>

      <clr-alert [clrAlertType]="'danger'" *ngIf="echecEditOrderDetail" [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text">
            {{msgAlert}} </span>
        </clr-alert-item>
      </clr-alert>
      <clr-combobox-container>
        <label>{{ 'pack.produit' | translate }} *</label>
        <clr-combobox [(ngModel)]="productName" name="name" required [clrLoading]="loading"
          formControlName="productName" (clrInputChange)="onSearchChange($event)" placeholder="Nom de produit">
          <clr-options *ngIf="products.length">
            <clr-option *ngFor="let item of products;" clrValue={{item.name}}
              (click)="productChanged(item,item.taxId.percentage)">
              {{item?.name}}
            </clr-option>
          </clr-options>
        </clr-combobox>
        <clr-control-error>{{ 'pack.champobligatoire' | translate }}</clr-control-error>
      </clr-combobox-container>
      <clr-control-helper>
        <button class="btn btn-link btn-sm" (click)="showAddProduct = true">{{ 'pack.ajouterproduit' | translate
          }}</button>
      </clr-control-helper>
      <clr-input-container>
        <label>{{ 'pack.quantite' | translate }}</label>
        <input clrInput type="number" [(ngModel)]="quantity" name="quantity" (input)="onChange('quantity')"
          [disabled]="!productName" formControlName="quantity" />
      </clr-input-container>
    </form>
    <br>
  </div>
  <br>
  <div class="clr-col-8">
    <button class="btn btn-outline btn-sm" [disabled]="!productName || showAlert || !showButtonTow"
      (click)="ajoutProduit()">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg>{{ 'pack.valider' | translate }}
    </button>
  </div>
</div>
<clr-datagrid class="overflow-x" (clrDgRefresh)="refresh()">
  <clr-dg-column>
    {{ 'pack.produit' | translate }}
  </clr-dg-column>
  <clr-dg-column>
    {{ 'pack.quantite' | translate }}
  </clr-dg-column>
  <clr-dg-row *ngFor="let item of listeProducts.totalProducts;let i=index">
    <clr-dg-action-overflow>
      <button class="action-item" (click)="EditProduct(item,i)">{{
        'pack.modification' | translate }}</button>
      <button class="action-item" (click)="DeleteProduct(i)">{{
        'pack.suppression' | translate }}</button>
    </clr-dg-action-overflow>
    <clr-dg-cell>{{item?.productName}}</clr-dg-cell>
    <clr-dg-cell>{{item?.quantity}}</clr-dg-cell>
  </clr-dg-row>
</clr-datagrid>
<div class="clr-row">
  <div *ngIf="notify">
    <div class="modal">
      <div class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" type="button">
              <clr-icon aria-hidden="true" shape="close" (click)="cancel()"></clr-icon>
            </button>
            <h3 class="modal-title">{{ 'pack.notification' | translate }}</h3>
          </div>
          <div class="modal-body">
            <p>{{ 'pack.notif' | translate }}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-outline" type="button" (click)="cancel()">{{ 'pack.annuler' | translate }}</button>
            <button class="btn btn-primary" type="button" (click)="active()">{{ 'pack.suivre' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" aria-hidden="true"></div>
  </div>
  <app-modal-add-product (saved)="refreshProducts()" (closed)="showAddProduct = false"
    *ngIf="showAddProduct"></app-modal-add-product>