import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AbnGuard } from '../auth/abn.guard';
import { AuthGuard } from '../auth/auth.guard';
import { RoleGuard } from '../auth/role.guard';
import { LoginComponent } from './login/login.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AddUserComponent } from './user-management/add-user/add-user.component';
import { DeleteUserComponent } from './user-management/delete-user/delete-user.component';
import { DetailUserComponent } from './user-management/detail-user/detail-user.component';
import { EditUserComponent } from './user-management/edit-user/edit-user.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AutorisationComponent } from './autorisation/autorisation.component';
import { AddAutorisationComponent } from './autorisation/add-autorisation/add-autorisation.component';
import { AutorisationDetailComponent } from './autorisation/autorisation-detail/autorisation-detail.component';
import { NumberMaxGuard } from '../auth/number-max.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  // { path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'sign-in', component: SignInComponent },
  { path: 'users', component: UserManagementComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'users/edit-user/:id', component: EditUserComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'users/add-user', component: AddUserComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard, NumberMaxGuard] },
  { path: 'users/delete-user', component: DeleteUserComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'users/detail-user/:id', component: DetailUserComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'autorisations', component: AutorisationComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'autorisations/add/:id', component: AddAutorisationComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'autorisations/edit/:id', component: AddAutorisationComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'autorisations/detail/:id', component: AutorisationDetailComponent, data: { roles: ['admin'], abonnements: ["trial", "facturation_only","carte_fidelite", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
