<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          {{ 'article.dt' | translate }}{{ 'article.Marque.Mrq' | translate }}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="close()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <div class="clr-row">
          <div class="clr-col-3">
            <span><b> {{ 'commun.id' | translate }} </b></span><br>
            <span><b> {{ 'login.VotreNom' | translate }} </b></span><br>
            <span><b> {{ 'Points.desc' | translate }} </b></span><br>
            <span><b> {{ 'commun.cp' | translate }}</b></span><br>
            <span *ngIf="mark.updatedAt"><b> {{ 'commun.mjp' | translate }} </b></span><br>
          </div>
          <div class="clr-col-9">
            <span>{{mark.real_id}}</span><br>
            <span>{{mark?.name}}</span><br>
            <span *ngIf="mark.description">{{mark.description}}</span>
            <span class="label label-danger" *ngIf="!mark.description"> {{ 'commun.nd' | translate }}
            </span><br>
            <span>{{user?.name}}</span> à <b>{{mark.createdAt | date:'dd/MM/yyyy HH:mm'}}</b><br>
            <span *ngIf="mark.updatedAt">{{user2?.name}} à </span> <b>{{mark.updatedAt | date:'dd/MM/yyyy à
              HH:mm'}}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>