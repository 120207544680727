<div id="myModal" class="modal fade show" style="display: block;" aria-modal="true">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div>

                </div>
                <h4 class="modal-title w-100">Success</h4>
            </div>
            <div class="modal-body">
                <p class="text-center">{{msg}}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-block" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop fade show"></div>