import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Unity } from '../unity';
import { UnityService } from '../unity.service';

@Component({
  selector: 'app-unity-delete',
  templateUrl: './unity-delete.component.html',
  styleUrls: ['./unity-delete.component.css']
})
export class UnityDeleteComponent {
  @Input() unity: Unity;
  @Output() closed = new EventEmitter<boolean>();
  
  constructor(protected unityService:UnityService) { }
  
  close() {
    this.closed.emit(true);
  }
  
  save() {
    this.unityService.deleteUnity(this.unity.id)
    .subscribe(
      data => {
        this.closed.emit(true);
      })
  }
}
