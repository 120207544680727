import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from 'src/app/stock/stock';
import { StockService } from 'src/app/stock/stock.service';
import { getValueFromToken } from '../../user.service';

@Component({
  selector: 'app-add-modal-store',
  templateUrl: './add-modal-store.component.html',
  styleUrls: ['./add-modal-store.component.css']
})
export class AddModalStoreComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>()
  id_entreprise: number
  real_id: number

  constructor(private stockService: StockService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.stockService.getStore({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }
   
  CloseAction() {
    this.closed.emit(true);
  }
  submit(form: Store) {
    form.entrepriseId = this.id_entreprise
    form.real_id = this.real_id
    this.stockService.addStore(form).subscribe(success => {
      this.CloseAction();
      this.saved.emit(true);
    })
  }
}
