import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-quantity-modal',
  templateUrl: './quantity-modal.component.html',
  styleUrls: ['./quantity-modal.component.css']
})
export class QuantityModalComponent implements OnInit {

  constructor() { }
  @Output() result = new EventEmitter<any>()
  @Output() closed = new EventEmitter<boolean>()
  input: string
  ngOnInit(): void {
    this.input = ''
  }
  closeAction() {
    this.closed.emit(true)
  }
  pressNum(num: string) {
    if (num == "0") {
      if (this.input == "") {
        return;
      }
      const PrevKey = this.input[this.input.length - 1];
      if (PrevKey === '/' || PrevKey === '*' || PrevKey === '-' || PrevKey === '+') {
        return;
      }
    }

    this.input = this.input + num
    var nb = 0;
    for (let i = 0; i < this.input.length; i++) {
      if (this.input[i] == '.') {
        nb++;
      }
    }

    this.calcAnswer();
  }

  Valider() {
    const res = parseFloat(this.input)
    this.result.emit(res);
  }

  getLastOperand() {
    let pos: number;
    pos = this.input.toString().lastIndexOf("+")
    if (this.input.toString().lastIndexOf("-") > pos) pos = this.input.lastIndexOf("-")
    if (this.input.toString().lastIndexOf("*") > pos) pos = this.input.lastIndexOf("*")
    if (this.input.toString().lastIndexOf("/") > pos) pos = this.input.lastIndexOf("/")
    return this.input.substr(pos + 1)
  }


  pressOperator(op: string) {
    //Do not allow operators more than once
    const lastKey = this.input[this.input.length - 1];
    if (lastKey === '/' || lastKey === '*' || lastKey === '-' || lastKey === '+') {
      return;
    }
    this.input = this.input + op
    this.calcAnswer();
  }


  clear() {
    if (this.input != "") {
      this.input = this.input.substr(0, this.input.length - 1)
    }
  }

  allClear() {
    this.input = '';
  }

  calcAnswer() {
    //this.result = parseInt(this.input) * this.productSelected.montant_TTC
  }



}
