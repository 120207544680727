import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutorisationService } from '../autorisation.service';
import { SettingService } from 'src/app/setting/setting.service';
import { User } from '../../user';

@Component({
  selector: 'app-autorisation-delete',
  templateUrl: './autorisation-delete.component.html',
  styleUrls: ['./autorisation-delete.component.css']
})
export class AutorisationDeleteComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>()
  @Input() user: any
  alertError: boolean = false
  constructor(
    private autorisationService: AutorisationService,
    private settingService : SettingService
  ) { }

  ngOnInit(): void {
  }

  closeAction() {
    this.closed.emit(true)
  }
  deleteAutorisation() {
    let userModel = new User()
    userModel.autorisationId = null
    this.settingService.editUser(this.user.id,userModel).subscribe(
      data => {
        this.autorisationService.deleteAutorisation(this.user.autorisationId.id).subscribe(
          data => {
            this.saved.emit(true)
          }, er => {
            this.alertError = true
          }
        )
      }
    )
  }

}
