<section>
    <h5 dir="rtl"> {{ 'article.deta.det' | translate }} </h5>
    <button *ngIf="userRole != 'writer' && userRole != 'carrier'" type="button" class="btn  btn-success btn-sm" style="font-size: x-small;  
text-align:center" routerLink="/catalog/detail/add">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-plus">
            <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>{{ 'commun.crn' | translate }}
    </button>

    <div>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th style="text-align: center;">{{ 'commun.id' | translate }}</th>
                    <th style="text-align: center;">{{ 'article.deta.nom' | translate }}</th>
                    <th style="text-align: center;">{{ 'commun.type' | translate }}</th>
                    <th style="text-align: center;">{{ 'commun.dtc' | translate }}</th>
                    <th style="text-align: center;"></th>
                    <th style="text-align: center;"></th>
                    <th style="text-align: center;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of details ">
                    <td scope="row">{{item.real_id}}</td>
                    <td>
                        <span *ngIf="item.name">{{item.name}}</span>
                        <span *ngIf="!item.name" class="label label-danger"> {{ 'commun.nd' | translate }} !</span>
                    </td>
                    <td>
                        <span *ngIf="item.type" class="label label-info">{{item.type}}</span>
                        <span *ngIf="!item.type" class="label label-danger"> {{ 'commun.nd' | translate }} </span>
                    </td>
                    <td>
                        <span *ngIf="item.createdAt">{{item.createdAt | date:'dd/MM/yyyy à HH:mm'}}</span>
                        <span *ngIf="!item.createdAt" class="label label-danger"> {{ 'commun.nd' | translate }} </span>
                    </td>
                    <td>
                        <button style="border:none" type="button" (click)="detail(item)"
                            class="btn btn-sm btn-outline-info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                class="bi bi-info-circle">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path
                                    d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </button>
                    </td>
                    <td>
                        <button *ngIf="userRole != 'writer' && userRole != 'carrier'"
                            [routerLink]="['/catalog/detail/edit', item.id]" style="border:none" type="button"
                            class="btn btn-sm btn-outline-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                class="bi bi-pencil-square">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </button>
                    </td>
                    <td>
                        <button *ngIf="userRole != 'writer' && userRole != 'carrier'" style=" border:none"
                            (click)="delete(item)" type="button" class="btn btn-sm btn-outline-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                class="bi bi-trash3">
                                <path
                                    d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-detail-detail *ngIf="showDetail" (closed)="close()" [detail]="selectedDetail"></app-detail-detail>
    <app-detail-delete *ngIf="showAlert" (closed)="close()" [detail]="selectedDetail"></app-detail-delete>
</section>