import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { Transaction } from '../finance/finance';
import { Retenue } from '../sales/order/order';
import { PictureAccount, User, PostUser, News } from '../user/user';
import { Abonnement, Configuration, Entreprise, Timbre } from './setting';
import { OperationType, TransactionType, PriceType, CostsType } from './type/type';


@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http: HttpClient) { }



  addOperationType(model: OperationType): Observable<OperationType> {
    return this.http.post<OperationType>(environment.api + '/operation-type', model);
  }
  getTransactions(filter: FilterDto<Transaction>): Observable<[Transaction[], number]> {
    return this.http.get(environment.api + '/transactions?filter=' + JSON.stringify(filter)) as Observable<[Transaction[], number]>;
  }
  addAbonnement(abonnement: Abonnement): Observable<Abonnement> {
    return this.http.post<Abonnement>(environment.api + '/abonnement/abonnement', abonnement);
  }
  editAbonnement(ID: number, abonnement: Abonnement): Observable<Abonnement> {
    return this.http.patch<Abonnement>(environment.api + '/abonnement/abonnement/' + JSON.stringify(ID), abonnement);
  }

  deleteOperation(id: number): Observable<OperationType> {
    return this.http.delete(environment.api + '/operation-type/' + JSON.stringify(id));
  }

  getOperationTypes(search: FilterDto<OperationType>): Observable<[OperationType[], number]> {
    return this.http.get(environment.api + '/operation-types?filter=' + JSON.stringify(search)) as Observable<[OperationType[], number]>;
  }


  getOperationType(ID: number): Observable<OperationType> {
    return this.http.get(environment.api + '/operation-type/' + JSON.stringify(ID)) as Observable<OperationType>;
  }
  editOperationType(ID: number, operationObject: OperationType): Observable<OperationType> {
    return this.http.patch<OperationType>(environment.api + '/operation-type/' + JSON.stringify(ID), operationObject);
  }


  addTransactionType(transaction: TransactionType): Observable<TransactionType> {
    return this.http.post<TransactionType>(environment.api + '/transaction-type', transaction)
  }

  getTransactionTypes(search: FilterDto<TransactionType>): Observable<[TransactionType[], number]> {
    return this.http.get(environment.api + '/transaction-types?filter=' + JSON.stringify(search)) as Observable<[TransactionType[], number]>;
  }

  deleteTransaction(ID: number): Observable<TransactionType> {
    return this.http.delete(environment.api + '/transaction-type/' + JSON.stringify(ID));
  }
  getTransactionType(ID: number): Observable<TransactionType> {
    return this.http.get(environment.api + '/transaction-type/' + JSON.stringify(ID)) as Observable<TransactionType>;
  }
  editTransactionType(ID: number, transactionObject: TransactionType): Observable<TransactionType> {
    return this.http.patch<TransactionType>(environment.api + '/transaction-type/' + JSON.stringify(ID), transactionObject);
  }




  getPricesTypes(search: FilterDto<PriceType>): Observable<PriceType> {
    return this.http.get(environment.api + '/price-types?filter=' + JSON.stringify(search)) as Observable<PriceType>;
  }

  getPricesType(ID: number): Observable<PriceType> {
    return this.http.get(environment.api + '/price-type/' + JSON.stringify(ID)) as Observable<PriceType>;
  }

  addPriceType(priceType: PriceType): Observable<PriceType> {
    return this.http.post<PriceType>(environment.api + '/price-type', priceType)
  }

  editPriceType(ID: number, priceObject: PriceType): Observable<PriceType> {
    return this.http.patch<PriceType>(environment.api + '/price-type/' + JSON.stringify(ID), priceObject);
  }

  deletePriceType(ID: number): Observable<PriceType> {
    return this.http.delete(environment.api + '/price-type/' + JSON.stringify(ID));
  }




  getCostTypes(search: FilterDto<CostsType>): Observable<CostsType> {
    return this.http.get(environment.api + '/costs-types?filter=' + JSON.stringify(search)) as Observable<CostsType>;
  }

  addCostType(CostsType: CostsType): Observable<CostsType> {
    return this.http.post<CostsType>(environment.api + '/cost-type', CostsType)
  }

  getCostType(ID: number): Observable<CostsType> {
    return this.http.get(environment.api + '/cost-type/' + JSON.stringify(ID)) as Observable<CostsType>;
  }

  editCostType(ID: number, costObject: CostsType): Observable<CostsType> {
    return this.http.patch<CostsType>(environment.api + '/cost-type/' + JSON.stringify(ID), costObject);
  }

  deleteCostType(ID: number): Observable<CostsType> {
    return this.http.delete(environment.api + '/cost-type/' + JSON.stringify(ID));
  }




  getConfiguration(search: FilterDto<CostsType>): Observable<Configuration> {
    return this.http.get(environment.api + '/configurations' + '?filter=' + JSON.stringify(search));
  }

  getAllConfigurations(search: FilterDto<CostsType>): Observable<[Configuration[], number]> {
    return this.http.get(environment.api + '/configurations' + '?filter=' + JSON.stringify(search)) as Observable<[Configuration[], number]>;
  }
  getRetenues(filter: FilterDto<Retenue>): Observable<[Retenue[], number]> {
    return this.http.get(environment.api + "/retenue?filter=" + JSON.stringify(filter)) as Observable<[Retenue[], number]>
  }
  getUserInfo(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>
  }

  PatchConfigurationlogo(ID: number, pictureAccount: PictureAccount): Observable<Configuration> {
    return this.http.patch<Configuration>(environment.api + '/configuration-logo/' + JSON.stringify(ID), pictureAccount);
  }



  PatchConfiguration(ID: number, Configuration: Configuration): Observable<Configuration> {
    return this.http.patch<Configuration>(environment.api + '/configuration/' + JSON.stringify(ID), Configuration);
  }

  PatchConfigurationDiscount(ID: number, configuration: Configuration): Observable<Configuration> {
    return this.http.patch<Configuration>(environment.api + '/configuration/' + JSON.stringify(ID), configuration);
  }

  addConfigurationEntreprise(Configuration: Configuration): Observable<Configuration> {
    return this.http.post<Configuration>(environment.api + '/configuration', Configuration);
  }








  getUser(ID: number): Observable<User> {
    return this.http.get(environment.api + '/user/' + JSON.stringify(ID)) as Observable<User>;
  }

  getEntreprisedata(ID: number): Observable<Entreprise> {
    return this.http.get(environment.api + "/entreprise/" + JSON.stringify(ID)) as Observable<Entreprise>;
  }
  getAllEntreprise(filter: FilterDto<Entreprise>): Observable<[Entreprise[], number]> {
    return this.http.get(environment.api + "/entreprises?filter=" + JSON.stringify(filter)) as Observable<[Entreprise[], number]>
  }
  // addUsers(user:User): Observable<User> {
  //   return this.http.post<User>(environment.api+'/user',user);
  // }
  addEntrepriseAccount(entrepreise: Entreprise): Observable<Entreprise> {
    return this.http.post<Entreprise>(environment.api + '/entreprise', entrepreise);
  }
  addUser(user: PostUser): Observable<PostUser> {
    return this.http.post<PostUser>(environment.api + '/user', user);
  }

  deleteUser(ID: number): Observable<User> {
    return this.http.delete(environment.api + '/user/' + JSON.stringify(ID));
  }


  editUser(ID: number, userObject: User): Observable<User> {
    return this.http.patch<User>(environment.api + '/user/' + JSON.stringify(ID), userObject);
  }
  editEntreprise(ID: number, Entreprise: Entreprise): Observable<Entreprise> {
    return this.http.patch<Entreprise>(environment.api + '/entreprise/' + JSON.stringify(ID), Entreprise);
  }

  getTimbres(filter: FilterDto): Observable<[Timbre[], number]> {
    return this.http.get(environment.api + "/timbres?filter=" + JSON.stringify(filter)) as Observable<[Timbre[], number]>;
  }

  getNews(filter: FilterDto): Observable<[News[], number]> {
    return this.http.get(environment.api + "/news?filter=" + JSON.stringify(filter)) as Observable<[News[], number]>
  }

}

