import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AbnGuard } from '../auth/abn.guard';
import { AuthGuard } from '../auth/auth.guard';
import { CategoryAddComponent } from './category/category-add/category-add.component';
import { CategoryDetailComponent } from './category/category-detail/category-detail.component';
import { CategoryEditComponent } from './category/category-edit/category-edit.component';
import { CategoryComponent } from './category/category.component';
import { DetailAddComponent } from './detail/detail-add/detail-add.component';
import { DetailDetailComponent } from './detail/detail-detail/detail-detail.component';
import { DetailEditComponent } from './detail/detail-edit/detail-edit.component';
import { DetailComponent } from './detail/detail.component';
import { MarkAddComponent } from './mark/mark-add/mark-add.component';
import { MarkDetailComponent } from './mark/mark-detail/mark-detail.component';
import { MarkEditComponent } from './mark/mark-edit/mark-edit.component';
import { MarkComponent } from './mark/mark.component';
import { PrintProductListComponent } from './product/print-product-list/print-product-list.component';
import { ProductAddComponent } from './product/product-add/product-add.component';
import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { ProductEditComponent } from './product/product-edit/product-edit.component';
import { ProductComponent } from './product/product.component';
import { TaxAddComponent } from './tax/tax-add/tax-add.component';
import { TaxDetailComponent } from './tax/tax-detail/tax-detail.component';
import { TaxEditComponent } from './tax/tax-edit/tax-edit.component';
import { TaxComponent } from './tax/tax.component';
import { UnityAddComponent } from './unity/unity-add/unity-add.component';
import { UnityDetailComponent } from './unity/unity-detail/unity-detail.component';
import { UnityEditComponent } from './unity/unity-edit/unity-edit.component';
import { UnityComponent } from './unity/unity.component';
import { PackAddComponent } from '../pack/pack-add/pack-add.component';
import { PackEditComponent } from '../pack/pack-edit/pack-edit.component';
import { PackComponent } from '../pack/pack.component';
import { AccessGuard } from '../auth/access.guard';
import { ServiceComponent } from './service/service.component';
import { CategoryServiceComponent } from './category-service/category-service.component';
import { CategoryServiceAddComponent } from './category-service/category-service-add/category-service-add.component';
import { ServiceAddComponent } from './service/service-add/service-add.component';
import { CategoryServiceDetailComponent } from './category-service/category-service-detail/category-service-detail.component';
import { CategoryServiceEditComponent } from './category-service/category-service-edit/category-service-edit.component';
import { ServiceEditComponent } from './service/service-edit/service-edit.component';

const routes: Routes = [
  { path: 'mark', component: MarkComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'mark/add', component: MarkAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  { path: 'mark/detail/:id', component: MarkDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard] },
  { path: 'mark/edit/:id', component: MarkEditComponent, data: {  abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  { path: 'product', component: ProductComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'product/add', component: ProductAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  { path: 'product/detail/:id', component: ProductDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'product/edit/:id', component: ProductEditComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  // { path: 'tax', component: TaxComponent, canActivate: [AuthGuard] },
  // { path: 'tax/add', component: TaxAddComponent, data: { roles: ["admin", "responsable"] }, canActivate: [AuthGuard, RoleGuard] },
  // { path: 'tax/detail/:id', component: TaxDetailComponent, canActivate: [AuthGuard] },
  // { path: 'tax/edit/:id', component: TaxEditComponent, data: { roles: ["admin", "responsable"] }, canActivate: [AuthGuard, RoleGuard] },
  { path: 'unity', component: UnityComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'unity/add', component: UnityAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  { path: 'unity/detail/:id', component: UnityDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'unity/edit/:id', component: UnityEditComponent, data: {abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  // { path: 'detail', component: DetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard] },
  // { path: 'detail/add', component: DetailAddComponent, data: { roles: ["admin", "responsable"], abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  // { path: 'detail/detail/:id', component: DetailDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, AbnGuard] },
  // { path: 'detail/edit/:id', component: DetailEditComponent, data: { roles: ["admin", "responsable"], abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison"] }, canActivate: [AuthGuard, RoleGuard, AbnGuard] },
  { path: 'category', component: CategoryComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'category/add', component: CategoryAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  { path: 'category/detail/:id', component: CategoryDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'category/edit/:id', component: CategoryEditComponent, data: { roles: ["admin", "responsable"], abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  {
    path: 'product/printList',
    component: PrintProductListComponent, data: {abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard]
  },
  { path: 'pack', data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, component: PackComponent, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'pack/pack-add', component: PackAddComponent, data: {abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  { path: 'pack/edit/:id', component: PackEditComponent, data: {abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },

  { path: 'service', component: ServiceComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'category-service', component: CategoryServiceComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'category-service/add', component: CategoryServiceAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'category-service/detail/:id', component: CategoryServiceDetailComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'category-service/edit/:id', component: CategoryServiceEditComponent, data: { roles: ["admin", "responsable"], abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
  { path: 'service/add', component: ServiceAddComponent, data: { abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard, AbnGuard,AccessGuard] },
  { path: 'service/edit/:id', component: ServiceEditComponent, data: { roles: ["admin", "responsable"], abonnements: ["trial", "facturation_only", "facturation_point_vente", "premuim", "livraison","carte_fidelite"] }, canActivate: [AuthGuard,AbnGuard,AccessGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogRoutingModule { }
