<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> Contactez-nous </h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <form (ngSubmit)="submit(contactForm.value)" #contactForm="ngForm">
      <div class="modal-body">
            <div class="form-group row">
                <label for="" class="col-md-3 form-control-label">Adresse e-mail</label>
                <div class="col-md-6">
                    <input class="form-control" ngModel type="email" name="email" placeholder="votre@email.com">
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-md-3 form-control-label">Message</label>
                <div class="col-md-9">
                    <textarea class="form-control" ngModel name="content" placeholder="Comment pouvons nous vous aider ?" cols="30" rows="3"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" type="submit" >Envoyer</button>
        </div>
    </form>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>