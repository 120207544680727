

export class Service {
  constructor(
    public id?: number,
    public name?: string,
    public price?: number,
    public description?: string,
    public entrepriseId?: number,
    public real_id?: number,
    public categoryserviceId?: number,
    public taxId?: number,
    public createdBy?: number,
    public createdAt?: string,
    public updatedBy?: number,
    public updatedAt?: string,
    public active?: boolean,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean
  ) { }
}