import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { categoryService } from '../../category-service/categoryService';
import { categoryserviceService } from '../../category-service/categoryService.service';
import { Tax } from '../../tax/tax';
import { TaxService } from '../../tax/tax.service';
import { Service } from '../service';
import { serviceService } from '../service.service';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.css']
})
export class ServiceEditComponent implements OnInit {
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  id: number;
  service: Service;
  showAlert: boolean;
  showMsg: boolean;
  serviceId: number;
  filter: FilterDto = new FilterDto()
  loading: boolean = false;
  saisie: string;
  categories: categoryService[];
  taxs: Tax[];
  
  constructor(private serviceService: serviceService, private activatedRoute: ActivatedRoute,
    private categoryserviceService: categoryserviceService, private taxService: TaxService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.activatedRoute.paramMap.subscribe(params => {
      this.serviceId = +params.get('id');
    });
    this.getService()
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getService() {
    let filter: FilterDto = new FilterDto()
    filter.relations = ["taxId","categoryserviceId"];
    filter.where = { "id": this.serviceId, "entrepriseId": this.id_entreprise };
    this.serviceService.getServices(filter)
      .subscribe(
        data => {
          this.service = data[0][0]
        }
      )
  }

  onSearchChange(args: string, table: string) {
    this.filter = new FilterDto()
    this.loading = true
    if (args) {
      this.saisie = "*" + args + "*"
      this.filter.take = 10;
      if (table == "tax") {
        this.filter.where = { percentage: { type: "equal", value: +args }, active: true }
      } else {
        if (table == "detail") {
          this.filter.where = { type: { type: "like", value: this.saisie }, active: true }
        } else {
          this.filter.where = { name: { type: "like", value: this.saisie }, active: true }
        }
      }
    }
    else {
      this.filter.take = 15;
      this.filter.where = { active: true }
    }
    if (table == "category") {
      this.getCategories(this.filter)
    } else {
        if (table == "tax") {
          this.getTaxs(this.filter)
        }
    }
  }

  itemChanged(item: any, tableItem: string) {
    if (tableItem == "category") {
      this.service.categoryserviceId = item
    } else {
        if (tableItem == "tax") {
          this.service.taxId = item
        }
    }
  }

  getCategories(filter: FilterDto) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.categoryserviceService.getCategoriesService(filter).subscribe(
      data => {
        this.categories = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  getTaxs(filter: FilterDto) {
    filter.where = { "active": true }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }

  submit(service: Service) {
    this.service.updatebyAdmin = this.isAdmin
    this.serviceService.editService(this.serviceId, this.service)
      .subscribe(
        data => {
          this.showMsg = true;
        })
  }
}
