import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Tax } from 'src/app/catalog/tax/tax';
import { TaxService } from 'src/app/catalog/tax/tax.service';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { detailpack, pack } from '../pack';
import { PackService } from '../pack.service';

@Component({
  selector: 'app-pack-edit',
  templateUrl: './pack-edit.component.html',
  styleUrls: ['./pack-edit.component.css']
})
export class PackEditComponent implements OnInit {

  constructor(private taxService: TaxService, private activatedRoute: ActivatedRoute, private packService: PackService) { }
  showAlert: boolean = false
  showAlertError: boolean = false
  errorMsg: string = '';
  modelpack: pack = new pack()
  listeproducts: any[] = [];
  initialStep: string = 'step1'
  Id_pack: number
  liste_details: detailpack[] = []
  filter: FilterDto = new FilterDto()
  loading: boolean = false
  taxs: Tax[] = []
  selectedTax: string
  showAddTaxe: boolean = false
  taxSelected: Tax
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin";
    this.activatedRoute.params.subscribe((p: Params) => {
      this.Id_pack = +p["id"];
      this.getpackInfo();
    })
  }
  AddTaxePopUp() {
    this.showAddTaxe = true
  }
  CloseAction() {
    this.showAddTaxe = false
  }
  AddTaxe() {
    this.CloseAction();
    this.onSearchChange();
  }
  getpackInfo() {
    let filter = new FilterDto<pack>()
    filter.where = { "id": this.Id_pack, "entrepriseId": this.id_entreprise };
    filter.relations = ["details", "details.productid", "taxId"]
    this.packService.getAllPacks(filter).subscribe(
      data => {
        this.modelpack = data[0][0];
        this.selectedTax = this.modelpack?.taxId?.name;
        this.taxSelected = this.modelpack?.taxId
      }
    )
  }
  clrInitialStep(initialStep: string) {
    this.initialStep = initialStep
  }
  packProductsEvent($event) {
    this.listeproducts = []
    let prods = $event.totalProducts;
    prods.forEach(el => {
      this.listeproducts.push(el)
    })
  }
  getTaxs(filter: FilterDto) {
    filter.where = { "active": true }
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.loading = false
      },
      err => console.error('Observer got an error: ' + err),
    );
  }
  onSearchChange(search?: string) {
    this.filter = new FilterDto()
    if (search) {
      this.filter.where = { percentage: { type: "equal", value: +search }, "entrepriseId": this.id_entreprise, active: true }
    } else {
      this.filter.take = 15;
      this.filter.where = { active: true }
    }
    this.getTaxs(this.filter)
  }
  itemChanged(item: any) {
    this.taxSelected = item
  }
  submit() {
    this.liste_details = []
    this.listeproducts.forEach(el => {
      let detail_pack = new detailpack()
      detail_pack.productid = el.productId.id;
      detail_pack.updatedAt = new Date()
      detail_pack.qte_produit = el.quantity
      detail_pack.updatebyAdmin = this.isAdmin
      this.liste_details.push(detail_pack)
    })
    this.modelpack.taxId = this.taxSelected.id
    this.modelpack.updatebyAdmin = this.isAdmin
    this.packService.updatepack(this.modelpack.id, this.modelpack).subscribe(
      data => {
        this.liste_details.forEach(el => {
          let index = this.modelpack.details.filter(x => x.productid.id == el.productid)
          if (index.length == 0) {
            el.packId = data.id
            this.packService.addDetailPack(el).subscribe(
              d => {
                // console.log("detail added")
              }
            )
          } else {
            el.packId = data.id
            this.packService.updatePackDetail(index[0].id, el).subscribe(
              x => {
                // console.log("detail updated")
              }
            )
          }
        })
        this.modelpack.details.forEach(el => {
          let index = this.liste_details.findIndex(x => x.productid == el.productid.id)
          if (index == -1) {
            this.packService.deletepackDetail(el.id).subscribe(
              data => {
                // console.log("detail deleted")
              }
            )
          }
        })
      }
    )
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
