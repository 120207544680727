import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ClrDatagridStateInterface } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { Product } from 'src/app/catalog/product/product';
import { ProductService } from 'src/app/catalog/product/product.service';
import { FilterDto } from 'src/app/filter.dto';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { Order, OrderDetail } from 'src/app/sales/order/order';
import { OrderService } from 'src/app/sales/order/order.service';
import { ListeProducts, ProductSelected } from 'src/app/sales/shared-sales/shared-sales';
import { SettingService } from 'src/app/setting/setting.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { detailpack, pack } from '../pack';
import { PackService } from '../pack.service';

@Component({
  selector: 'app-pack-edit-product',
  templateUrl: './pack-edit-product.component.html',
  styleUrls: ['./pack-edit-product.component.css']
})
export class PackEditProductComponent implements OnInit {
  @Output() savedEvent = new EventEmitter<boolean>();
  @Output() deleteEvent = new EventEmitter<boolean>();
  @Output() totalProduitsEvent = new EventEmitter<any>();
  @Input() orderId: number;
  @Input() packId: number
  showAddProduct = false;
  modelProduct: Product = new Product();
  productSelected: ProductSelected = new ProductSelected();
  listeProducts: ListeProducts = new ListeProducts([]);
  filter: FilterDto<Product> = new FilterDto<Product>()
  order: Order = new Order();
  orderDetail: ProductSelected = new ProductSelected();
  products: Product[] = [];
  tmpProductList: Product[] = []
  idOrderDetail: number
  productName: string;
  quantity: number = 1;
  remise: number = 0
  saisie: string;
  loading: boolean;
  quantityReserved: number;
  showCardTowOrder: boolean = false;
  showButtonTow: boolean = true;
  showAlert: boolean = false
  msgAlert: string;
  notify: boolean;
  editAndDeleteProduct: boolean = true;
  succesEditOrderDetail: boolean;
  echecEditOrderDetail: boolean;
  index: number;
  globaleDiscount: boolean;
  remiseGlobale: number = 0;
  remisePartielle: number = 0;
  promoConfig: boolean;
  promo: number = 0
  initialeProductPrice: number
  productForm: any;
  id_entreprise: number
  devise: string;
  quantities: number[] = []
  tmpListQuantites: number[] = []
  stock_security: number
  alertStockSecurity: boolean = false
  StockMsg: string
  userRole: string
  isAdmin: boolean = false
  store_id: number
  edit_product: boolean = false

  constructor(private orderService: OrderService, private productService: ProductService,
    private configService: ConfigService, private formBuilder: UntypedFormBuilder
    , private packService: PackService, private salesPointService: SalesPointService,
    private settingService: SettingService) {
    this.productForm = this.formBuilder.group({
      productName: ['', [Validators.required]],
      quantity: [''],
      discount: [''],
      Promo: ['']
    });
    // window.addEventListener('CommandeToSharedSales', (evt: any) => {
    //   if (evt.detail == true) {
    //     this.notify = true
    //   }
    // });
    // window.addEventListener('creerNouveau', (evt: any) => {
    //   if (evt.detail == true) {
    //     this.listeProducts = new ListeProducts([]);
    //   }
    // });
  }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.getStoreId();
    this.configService.getEntrepriseInfo({
      where: { "id": this.id_entreprise }, relations: ["deviseId"]
    }).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code
      }
    )
    this.getConfiguration()
    if (this.packId != null) {
      this.getPackDetail();
    }
  }
  refreshProducts() {
    this.showAddProduct = false
    this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.filter.relations = ["taxId"];
    this.getproductsInfo(this.store_id, this.filter);
  }
  getStoreId() {
    if (this.isAdmin) {
      let filter = new FilterDto()
      filter.where = { "entrepriseId": this.id_entreprise, "name": "point de vente principale" }
      filter.relations = ["storeId"]
      this.salesPointService.getPoints(filter).subscribe(
        data => {
          if (data[1] > 0) {
            this.store_id = data[0][0].storeId.id
            this.filter = new FilterDto()
            this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
            this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
            this.filter.relations = ["taxId"];
            this.getproductsInfo(this.store_id, this.filter);
          }
        }
      )
    } else {
      let id_user = parseInt(getValueFromToken("id"))
      let filter = new FilterDto()
      filter.where = { "id": id_user, "active": true, "entrepriseId": this.id_entreprise }
      if (this.userRole == "writer") {
        filter.relations = ["cratesId","cratesId.pointid", "cratesId.pointid.storeId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            if (data[1] > 0) {
              this.store_id = data[0][0].cratesId.pointid.storeId.id
              this.filter = new FilterDto()
              this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
              this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
              this.filter.relations = ["taxId"];
              this.getproductsInfo(this.store_id, this.filter);
            }
          }
        )
      } else if (this.userRole == "responsable" || this.userRole == "carrier") {
        filter.relations = ["pointId", "pointId.storeId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            if (data[1] > 0) {
              this.store_id = data[0][0].pointId.storeId.id
              this.filter = new FilterDto()
              this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
              this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
              this.getproductsInfo(this.store_id, this.filter);
            }
          }
        )
      }
    }
  }
  getPackDetail() {
    let filter = new FilterDto<pack>()
    filter.where = { "id": this.packId }
    filter.relations = ["details", "details.productid", "details.productid.taxId"]
    this.packService.getAllPacks(filter).subscribe(
      data => {
        data[0].forEach(el => {
          el.details.forEach((element: detailpack) => {
            let productSelected = new ProductSelected()
            productSelected.id = element.productid.id
            productSelected.quantity = element.qte_produit
            productSelected.productId = element.productid
            productSelected.productName = element.productid.name
            productSelected.productPrice = element.productid.price
            productSelected.tax = element.productid.taxId.percentage
            productSelected.taxId = element.productid.taxId
            this.listeProducts.totalProducts.push(productSelected)
          })
        })
      }
    )
  }
  getConfiguration() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    this.configService.getConfiguration(filter).subscribe(
      data => {
        if (data.stock_security) {
          this.stock_security = data[0].stock_security
        } else {
          this.stock_security = 1;
        }
      }
    )
  }
  CloseAction() {
    this.showAddProduct = false;
  }
  AddProduct() {
    this.getproductsInfo(this.store_id, this.filter);
    this.CloseAction();
  }
  AddProductPopUp() {
    this.showAddProduct = true;
  }
  onSearchChange(args: string) {
    if (args == undefined || args.length == 0) {
      this.products = this.tmpProductList
      this.quantities = this.tmpListQuantites
    } else {
      this.products = []
      this.quantities = []
      for (let i = 0; i < this.tmpProductList.length; i++) {
        if (this.tmpProductList[i].name.indexOf(args) != -1) {
          this.products.push(this.tmpProductList[i])
          this.quantities.push(this.tmpListQuantites[i])
        }
      }
    }
  }
  getproductsInfo(store_id: number, filter: FilterDto<Product>) {
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    filter.relations = ["taxId"]
    this.productService.getInfoProducts(store_id, filter).subscribe(
      data => {
        data[0].forEach(el => {
          let index = this.products.findIndex(x => x.id == el.product.id)
          if (index == -1) {
            this.products.push(el.product);
            this.quantities.push(el.quantity)
          }
        })
        this.tmpListQuantites = this.quantities
        this.tmpProductList = this.products
      }
    )
  }
  addproductToListe(product: ProductSelected) {
    let index = this.listeProducts.totalProducts.filter(x => x.id == product.id)
    // if (index.length == 0) {
    this.listeProducts.totalProducts.push(product)
    this.calculeMontant()
    this.calculeMontantTotal();
    //}
  }
  productChanged(product: Product, tax: number) {
    this.initialeProductPrice = product.price
    this.productSelected = new ProductSelected();
    this.modelProduct = product;
    this.productSelected.productId = product;
    this.productSelected.id = product.id
    this.productSelected.productName = product.name;
    this.productSelected.productPrice = product.price;
    this.productSelected.tax = tax;
    this.productSelected.quantity = this.quantity;
    this.productSelected.remise = this.remise;
    this.productSelected.gratuite = this.promo;
    this.productSelected.real_id = product.real_id
    this.calculeMontant()
    this.showCardTowOrder = true;
    this.showAlert = false;
  }

  onChange(name: string) {
    if (this.quantity > 0) {
      this.showAlert = false;
      //==> code commented by Dali 
      // to avoid verif of the stock available
      if (name == "quantity") {
        let index = this.products.findIndex(x => x.id == this.productSelected.productId.id)
        let qte = this.quantities[index];
        // if (qte <= 0 || qte == undefined) {
        //   this.showAlert = true
        //   this.msgAlert = "Ce produit n'est pas disponible dans votre stock "
        //   this.showButtonTow = false
        // }
        // if (qte - this.stock_security <= 0) {
        //   this.alertStockSecurity = true
        //   this.StockMsg = "Ce produit a atteint le stock de sécurité " +
        //     "il ne reste que " + (qte - this.quantity) + "dans votre stock"
        //   this.showButtonTow = true
        // } else {
        //   this.alertStockSecurity = false;
        // }
        // if (this.quantity > qte) {
        //   this.showAlert = true
        //   this.msgAlert = "Epuise de stock, la quantité entré n'est pas disponible ";
        //   this.showButtonTow = false
        // } else
        //   if (this.quantity >= qte - this.stock_security) {
        //     this.alertStockSecurity = true
        //     this.StockMsg = "Pour cette quantité le produit entre dans le stock de sécurité"
        //     this.showButtonTow = true
        //   } else {
        //     this.alertStockSecurity = false
        //     this.showButtonTow = true
        //   }
        // if (this.quantity < qte) {
        this.productSelected.quantity = +this.quantity;
        this.showAlert = false;
        this.calculeMontant()
        this.showButtonTow = true;
        // }
        //==> code commented by Dali 
        // to avoid verif of the stock available
      }
    }
    else {
      if (this.quantity < 0) {
        this.showButtonTow = false;
        this.msgAlert = "veuillez saisir une valeur positive"
        this.showAlert = true;
      }
      else {
        if (name == "quantity") {
          this.msgAlert = "veuillez saisir une valeur superieur a zéro"
          this.showButtonTow = false;
          this.showAlert = true;
        }
      }
    }
  }

  calculeMontant() {
    this.productSelected.montant_HT = (this.productSelected.quantity * this.productSelected.productPrice)
    this.productSelected.montant_TTC = ((this.productSelected.montant_HT - this.productSelected.montant_HT * this.productSelected.remise / 100) + (this.productSelected.montant_HT - this.productSelected.montant_HT * this.productSelected.remise / 100) * (this.productSelected.tax / 100))
    this.productSelected.montant_U_TTC = ((this.productSelected.productPrice - this.productSelected.productPrice * this.productSelected.remise / 100) + ((this.productSelected.productPrice - this.productSelected.productPrice * this.productSelected.remise / 100) * this.productSelected.tax / 100))
    this.remisePartielle = this.productSelected.quantity * this.productSelected.productPrice * this.productSelected.remise / 100
  }
  calculeMontantTotal() {
    this.listeProducts.montantTotal_HT = this.listeProducts.totalProducts.reduce((acc, product) => acc + product.montant_HT, 0);

    this.listeProducts.remiseTotal = this.listeProducts.totalProducts.reduce((acc, product) => acc + (product.montant_HT * (product.remise / 100)), 0);

    this.listeProducts.montantTotal_TVA = this.listeProducts.totalProducts.reduce((acc, product) => acc + (product.montant_HT - (product.montant_HT * this.remise) / 100) * (product.tax / 100), 0);


    if (this.listeProducts.remiseGlobale > 0) {
      this.remiseGlobale = this.listeProducts.montantTotal_HT * this.listeProducts.remiseGlobale / 100
    } else {
      this.listeProducts.remiseGlobale = 0
      this.remiseGlobale = 0
    }

    this.listeProducts.montantTotal_TTC = this.listeProducts.totalProducts.reduce((acc, product) => acc + ((product.montant_HT - product.montant_HT * product.remise / 100 - product.montant_HT * this.listeProducts.remiseGlobale / 100) + (product.montant_HT - product.montant_HT * product.remise / 100 - product.montant_HT * this.listeProducts.remiseGlobale / 100) * (product.tax / 100)), 0);

    this.listeProducts.montantTotal_TTC = +this.listeProducts.montantTotal_TTC.toFixed(2)
  }



  refresh(state?: ClrDatagridStateInterface) {
    this.listeProducts = new ListeProducts()
    this.listeProducts.totalProducts = []
    if (this.orderId) {
      let searchOrdre: FilterDto<Order> = new FilterDto<Order>()
      searchOrdre.relations = ["customerId", "orderDetails", "orderDetails.productId", "orderDetails.productId.taxId", "operations", "operations.operationDetails", "operations.operationDetails.productId"];
      searchOrdre.select = ["id", "totalPrice", "globalDiscount", "createdBy", "createdAt"];
      searchOrdre.where = { "id": this.orderId, "active": true, "entrepriseId": this.id_entreprise };
      this.orderService.getOrders(searchOrdre).subscribe(
        orders => {
          this.order = orders[0][0];
          this.order.orderDetails = this.order?.orderDetails?.filter(orderDetail =>
            orderDetail.active == true
            && orderDetail.entrepriseId == this.id_entreprise
          )
          this.order.operations = this.order?.operations?.filter(operation => operation.active == true && operation.entrepriseId == this.id_entreprise)
          this.order.operations?.forEach(element => {
            element.operationDetails = element.operationDetails?.filter(operationDetail => operationDetail.active == true && operationDetail.entrepriseId == this.id_entreprise)
          })
          this.order.orderDetails?.forEach(element => {
            if (element.active == true) {
              this.orderDetail = new ProductSelected()
              this.orderDetail.id = element.id;
              this.orderDetail.productId = element.productId;
              this.orderDetail.productName = element.productId.name;
              this.orderDetail.quantity = element.quantity - element.gratuite;
              this.orderDetail.gratuite = element.gratuite;
              this.orderDetail.productPrice = element.price;
              this.orderDetail.tax = element.tax;
              this.orderDetail.remise = element.discount;
              this.orderDetail.montant_HT = this.orderDetail.quantity * this.orderDetail.productPrice;
              this.orderDetail.montant_TTC = element.amount;
              this.listeProducts.totalProducts?.push(this.orderDetail);
            }
          });
          this.listeProducts.remiseGlobale = this.order.globalDiscount
          this.totalProduitsEvent.emit(this.order);
          this.calculeMontantTotal();
        },
        err => console.error('Observer got an error: ', err),
        () => this.loading = false
      );
    }
  }
  EditProduct(item: ProductSelected, i: number) {
    this.index = i
    if (this.orderId) {
      this.idOrderDetail = item.id;
    }
    let product: Product = new Product()
    this.productName = item.productName;
    this.quantity = +item.quantity;
    this.remise = item.remise;
    this.promo = +item.gratuite
    this.showCardTowOrder = true;
    product.id = item.productId.id
    product.name = item.productName
    product.price = item.productPrice
    this.edit_product = true
    this.productChanged(product, item.productId.taxId);
    if (!item.productId.id) {
      this.listeProducts.totalProducts.splice(i, 1);
      this.calculeMontantTotal();
    }
  }

  async ajoutProduit() {
    if (this.edit_product == false) {
      let index = this.listeProducts.totalProducts.filter(
        x => x.productId.id == this.productSelected.id
      )
      if (index.length == 0) {
        this.productSelected.quantity = this.quantity
        this.listeProducts.totalProducts.push(this.productSelected)
      } else {
        this.msgAlert = "produit existe déjà!"
        this.showAlert = true;
      }
      this.totalProduitsEvent.emit(this.listeProducts)
      this.productSelected = new ProductSelected();
      this.productName = ""
      this.quantity = 1
    } else {
      let index = this.listeProducts?.totalProducts?.findIndex(x => x.productId.id == this.productSelected.id)
      if (index != -1) {
        this.listeProducts.totalProducts[index].quantity = this.quantity
        this.totalProduitsEvent.emit(this.listeProducts)
        this.productName = ""
        this.quantity = 1
      }
    }
  }

  DeleteProduct(i: number) {
    this.listeProducts.totalProducts.splice(i, 1);
    this.calculeMontantTotal();
    this.totalProduitsEvent.emit(this.listeProducts);
  }

  enregistrer() {
    if (this.orderId) {
      this.orderService.patchOrder(this.orderId, { totalPrice: this.listeProducts.montantTotal_TTC, globalDiscount: this.listeProducts.remiseGlobale }).subscribe(
        order => {
          this.order.totalPrice = order.totalPrice
          this.order.globalDiscount = order.globalDiscount
          this.totalProduitsEvent.emit(this.order);
        },
        err => console.error('Observer got an error: ', err),
      );
    } else {
      this.totalProduitsEvent.emit(this.listeProducts);
    }
  }

  changePrice(prix: number) {
    this.productSelected.productPrice = prix
    this.calculeMontant()
  }

  cancel() {
    this.notify = false;
    this.editAndDeleteProduct = false
  }
  active() {
    var event = new CustomEvent('SharedSalesToCommande', { 'detail': true });
    window.dispatchEvent(event);
    this.notify = false
    this.editAndDeleteProduct = true
  }
  async calculQuantityReserved(item: OrderDetail) {
    this.quantityReserved = 0
    let searchOrdre: FilterDto<Order> = new FilterDto<Order>()
    searchOrdre.relations = ["operations", "operations.operationDetails", "operations.operationDetails.productId"];
    searchOrdre.where = { id: this.orderId, active: true, "entrepriseId": this.id_entreprise };
    let orders: [Order[], number] = await this.orderService.getOrders(searchOrdre).toPromise()
    let operations = orders[0][0].operations.filter(operation => operation.active == true);
    operations.forEach(async element => {
      element.operationDetails = element.operationDetails?.filter(operationDetail => operationDetail.active == true && operationDetail.productId["id"] === item.productId.id)
      this.quantityReserved = this.quantityReserved + element.operationDetails.reduce((acc, operationDetail) => acc + operationDetail.quantity, 0);
    })
    return this.quantityReserved
  }

}
