import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { categoryService } from './categoryService';


@Injectable({
    providedIn: 'root'
})
export class categoryserviceService {
    constructor (private http: HttpClient) {}

    getCategoriesService(filter: FilterDto<categoryService>): Observable<[categoryService[], number]> {
        return this.http.get(environment.api + '/categoryService?filter=' + JSON.stringify(filter)) as Observable<[categoryService[], number]>;
    }

    getCategoryServiceById(id:number){
        return this.http.get(environment.api + '/categoryService/' +JSON.stringify(id));
    }

    editCategoryService(id:number, category:categoryService){
        return this.http.patch<categoryService>(environment.api + '/categoryService/' + JSON.stringify(id), category)
    }

    addCategoryService(categoryService: categoryService): Observable<categoryService> {
        return this.http.post(environment.api + '/categoryService', categoryService);
    }

    deleteCategoryService(id:number): Observable<categoryService[]>{
        return this.http.delete(environment.api + '/categoryService/' + JSON.stringify(id)) as Observable<categoryService[]>;
    }
}