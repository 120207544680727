import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { User } from 'src/app/user/user';
import { getValueFromToken } from 'src/app/user/user.service';
import { Product, ProductPicture } from '../product';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() idProduit: number;
  showAlert: boolean;
  action: string;
  filter: FilterDto<Product> = new FilterDto<Product>()
  product: Product = new Product();
  pictures: ProductPicture[];
  searchUser: FilterDto<User> = new FilterDto<User>()
  user: any
  id_entreprise: number

  constructor(private productService: ProductService, private quotationService: QuotationService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getProduct();
  }
  getProduct() {
    this.filter.relations = ["markId", "taxId", "unityId", "categoryId", "productPrices", "productPrices.priceTypeId", "productDetails", "productDetails.detailId"];
    this.filter.where = { "id": this.idProduit };
    this.productService.getProducts(this.filter).subscribe(
      product => {
        this.product = product[0][0];
        this.productService.getPictures({ where: { productId: this.idProduit } }).subscribe(
          pictures => {
            this.pictures = pictures[0];
            if (!this.product.byAdmin) {
              this.searchUser.select = ["name"];
              this.searchUser.where = { "id": this.product.createdBy };
              this.quotationService.getUser(this.searchUser).subscribe(
                user => {
                  this.user = user[0][0];
                })
            } else {
              let filter = new FilterDto()
              filter.where = { "id": this.id_entreprise }
              filter.select = ["name"]
              this.quotationService.getEntreprise(filter).subscribe(
                data => {
                  this.user = data[0][0]
                }
              )
            }
          },
          err => console.error('Observer got an error: ', err),
        );
      },
      err => console.error('Observer got an error: ', err),
    );
    this.showAlert = false;
  }
  close() {
    this.closed.emit(true);
  }
}