import { Component, Input, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { Customer } from 'src/app/parts/parts';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { PartsService } from '../../../parts.service';

@Component({
  selector: 'app-customer-infos',
  templateUrl: './customer-infos.component.html',
  styleUrls: ['./customer-infos.component.css']
})
export class CustomerInfosComponent implements OnInit {
  @Input() customerId: any
  customerObject: Customer = new Customer();
  userAdd: any
  userEdit: any
  id_entreprise: number
  constructor(private partsService: PartsService, private settingService: SettingService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    let filter: FilterDto = new FilterDto();
    filter.relations = ['contacts', 'adress'];
    filter.where = { "id": this.customerId.id };
    this.partsService.getCustomers(filter).subscribe(
      loaded => {
        this.customerObject = loaded[0][0];
        if (this.customerObject.createdBy) {
          if (!this.customerObject.byAdmin) {
            this.settingService.getUser(this.customerObject.createdBy).subscribe(
              data => {
                this.userAdd = data
              }
            )
          } else {
            let filter = new FilterDto()
            filter.where = { "id": this.id_entreprise }
            this.settingService.getAllEntreprise(filter).subscribe(
              data => {
                this.userAdd = data[0][0]
              }
            )
          }
        }
        if (this.customerObject.updatedBy) {
          if (!this.customerObject.updatebyAdmin) {
            this.settingService.getUser(this.customerObject.updatedBy).subscribe(
              data => {
                this.userEdit = data
              }
            )
          } else {
            let filter = new FilterDto()
            filter.where = { "id": this.id_entreprise }
            this.settingService.getAllEntreprise(filter).subscribe(
              data => {
                this.userEdit = data[0][0]
              }
            )
          }
        }
      },
      (err) => console.error('Observer got an error: ', err)
    );
  }

}
