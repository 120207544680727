<div class="login-wrapper">
  <form class="login">
    <section class="title">
      <h5 class="hint">
        {{ 'commun.mvp' | translate }}<b class="text-primary">&nbsp;&nbsp;Inveep</b>
      </h5>
    </section>
    <div class="login-group">
      <br>
      <form>
        <cds-alert-group type="banner" status="success" *ngIf="alertSuccess && !alertError">
          <cds-alert closable>
            {{ 'commun.vts' | translate }}
          </cds-alert>
        </cds-alert-group>
        <cds-alert-group type="banner" status="danger" *ngIf="alertError && !alertSuccess">
          <cds-alert closable>
            {{ 'commun.chmop' | translate }}
          </cds-alert>
        </cds-alert-group>
        <br>
        <clr-password-container>
          <label class="clr-sr-only"> {{ 'login.MDP' | translate }}</label>
          <input type="password" name="password" clrPassword placeholder="Votre mot de passe"
            [(ngModel)]="entreprise.password" required />
        </clr-password-container>
        <clr-password-container>
          <label class="clr-sr-only"> {{ 'commun.rpp' | translate }}</label>
          <input type="password" name="repassword" clrPassword placeholder="Retaper votre mot de passe"
            [(ngModel)]="entreprise.repassword" required />
        </clr-password-container>
        <h6
          *ngIf="entreprise.password != entreprise.repassword && entreprise.repassword!=undefined && !alertSuccess && !alertError "
          class="hint text-error text-left">
          {{ 'commun.ldi' | translate }}
        </h6>
        <h6
          *ngIf="entreprise.password == entreprise.repassword && entreprise.repassword!=undefined && !alertSuccess && !alertError "
          class="hint text-success text-left">
          {{ 'commun.lmid' | translate }}
        </h6>
      </form>
      <button type="submit" class="btn btn-primary" (click)="changePwd()" *ngIf="!alertSuccess"> {{ 'commun.mod' |
        translate }}</button>
      <button type="submit" class="btn btn-primary" routerLink="/user/login" *ngIf="alertSuccess"> {{ 'login.tt' |
        translate }}
      </button>
      <p routerLink="/user/login" class="text-primary text-center cursor-pointer" *ngIf=" !alertSuccess">
        {{ 'login.tt' | translate }}
      </p>
    </div>
  </form>
</div>