<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{title}}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <form clrForm #search_prod="ngForm">
          <clr-input-container>
            <label>Code barre</label>
            <input clrInput type="text" name="bar_code" [(ngModel)]="search_prod.bar_code" required>
            <clr-control-error>Ce champs est obligatoire</clr-control-error>
          </clr-input-container>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="closeAction()">Annuler</button>
        <button class="btn btn-primary" [disabled]="!search_prod.bar_code" type="button"
          (click)="SearchProd(search_prod.bar_code)">Rechercher</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
