<div class="modal">
  <div class="modal-dialog" role="dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> {{ 'commun.jt' | translate }} {{ 'article.Mcatg.catg' | translate }}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CancelAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <form clrForm (ngSubmit)="submit(categoryForm.value,categoryForm)" #categoryForm="ngForm">
          <clr-input-container>
            <label> {{ 'article.deta.nom' | translate }}* </label>
            <input clrInput type="text" name="name" ngModel required />
            <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
          </clr-input-container>
          <!-- <clr-combobox-container>
            <label> {{ 'commun.type' | translate }}</label>
            <clr-combobox ngModel name="details" clrMulti="true" required [clrLoading]="loading"
              (clrInputChange)="fetchDetails($event)" (clrOpenChange)="$event ? fetchDetails() : null">
              <ng-container *clrOptionSelected="let selected">
                <clr-icon shape="details"></clr-icon>
                {{selected?.name}}
              </ng-container>
              <clr-options>
                <clr-option *clrOptionItems="let detail of details; field:'name'" [clrValue]="detail">
                  <clr-icon shape="details"></clr-icon>
                  {{detail.name}}
                </clr-option>
              </clr-options>
            </clr-combobox>
            <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
          </clr-combobox-container> -->
          <!-- <clr-control-helper>
                        <button class="btn btn-link btn-sm" (click)="AddTypePopUp()">
                            Ajouter Type
                        </button>
                    </clr-control-helper> -->
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="CancelAction()"> {{ 'commun.nn' | translate
          }}</button>
        <button class="btn btn-primary" [disabled]="categoryForm.value.name == ''" type="button"
          (click)="submit(categoryForm.value,categoryForm)">{{ 'commun.jt' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>