import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Configuration } from 'src/app/setting/setting';
import { deleteCookie, getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { CoreService } from '../core.service';
import jwt_decode from "jwt-decode";
import { User } from 'src/app/user/user';
import { TranslateService } from '@ngx-translate/core';
import { SettingService } from 'src/app/setting/setting.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  //name: string;
  picture: string;
  nameComplet: string = "";
  nameCourt: string;
  pictureConfig: string;
  filter: FilterDto<Configuration> = new FilterDto<Configuration>()
  filter1: FilterDto<User> = new FilterDto<User>()
  id: number;
  globaleDiscount: boolean;
  lgclick: boolean;
  promo: boolean;
  email: string;
  sendMail: boolean;
  mailbox: string;
  userRole: string;
  matricule: number;
  tel: number;
  adresseville: string;
  open: boolean;
  public y: string;
  currentLang: string;
  id_entreprise: number
  isAdmin: boolean = false
  current_user: number
  email_user: string
  userId: number
  arabicLanguage: boolean = false
  openMenuLang: boolean = false
  @Output() openSideBarArabic = new EventEmitter<boolean>()
  name:string
  familyName:string
  showProfile: boolean = false


  constructor(private settingService: SettingService, public translate: TranslateService, private router: Router, private serviceCore: CoreService) {
    window.addEventListener('refreshHeader', (evt: any) => {
      if (evt.detail == true) {
        this.getUser()
      }
    });
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.translate.use(this.currentLang);
    this.getUser()
  }

  ngOnInit() {
    this.userRole = jwt_decode(tokenGetter())["role"];
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.current_user = parseInt(getValueFromToken("id"))
    this.isAdmin = this.userRole === "admin"
    this.getUser();
    this.getConfiguration()
    this.id = +getValueFromToken('id')
    //this.name = this.getCookie('nameUser');
    this.EventLang();
    this.setSelectedLang();
  }

  navigateToProfile() {
    this.router.navigate(["/setting/profile"])
  }

  clicklang(lang: any) {
    this.reloadData()
    this.translate.use(lang);
    localStorage.setItem('currentLang', lang)
    if (lang == "ar") {
      localStorage.setItem("y", "rtl");
    } else {
      localStorage.setItem('y', 'ltr');
    }
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.enleverSelectedLanguage()
    this.setSelectedLang()
  }
  setMenuLang() {
    this.openMenuLang = !this.openMenuLang;
  }
  enleverSelectedLanguage() {
    for (
      let index = 0;
      index < document.getElementsByClassName("language-icon").length;
      index++
    ) {
      const element = document.getElementsByClassName("language-icon")[index];
      element?.classList.remove("selected");
    }
  }
  setSelectedLang() {
    for (
      let index = 0;
      index < document.getElementsByClassName("language-icon").length;
      index++
    ) {
      const element = document.getElementsByClassName("language-icon")[index];
      if (element.id === this.currentLang) {
        element?.classList.add("selected");
      }
    }
  }
  reloadData() {
    window.location.reload();
  }

  ngOnChanges() {
    this.getUser();
  }

  getUser() {
    this.filter1 = new FilterDto();
    this.userRole = jwt_decode(tokenGetter())["role"];
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.current_user = parseInt(getValueFromToken("id"))
    this.isAdmin = this.userRole === "admin"
    this.filter1.select = ["picture", "name"]
    if (this.isAdmin == false) {
      this.filter1.where = { "id": this.current_user }
      this.serviceCore.getUser(this.filter1).subscribe(
        data => {
          if (data[0][0]?.picture != null) {
            this.picture = data[0][0]?.picture;
          } else {
            this.picture = "assets/profile.png";
          }
          
          this.nameComplet = data[0][0].name 
          this.name = this.nameComplet.split(' ')[0]
          this.familyName = this.nameComplet.split(' ')[1]       
          // document.cookie = "pictureUser=" + (this.picture);
          //document.cookie = "nameUser=" + (this.name);
        }
      )
    } else if (this.isAdmin) {
      let filter_entreprise = new FilterDto();
      filter_entreprise.select = ["logo", "name"];
      filter_entreprise.where = { "id": this.id_entreprise }
      this.serviceCore.getEntrepriseData(filter_entreprise).subscribe(
        data => {
          if (data[0][0]?.logo != null) {
            this.picture = data[0][0]?.logo;
          } else {
            this.picture = "assets/profile.png";
          }
          this.nameComplet = data[0][0].name
          this.name = this.nameComplet.split(' ')[0]
          this.familyName = this.nameComplet.split(' ')[1]  
          // this.name = data[0][0]?.name;
          // document.cookie = "pictureUser=" + this.picture;
          // document.cookie = "nameUser=" + this.name
        }
      )
    }
  }


  getConfiguration() {
    this.filter.select = ["nameComplet", "nameCourt", "picture", "globaleDiscount", "promo", "email", "mailbox", "sendMail", "matricule", "tel", "adresseVille"]
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.serviceCore.getconfiguration(this.filter).subscribe(
      data => {
        //this.nameComplet = data[0][0]?.nameComplet;
        this.nameCourt = data[0][0]?.nameCourt;
        this.pictureConfig = data[0][0]?.picture;
        this.globaleDiscount = data[0][0]?.globaleDiscount;
        this.promo = data[0][0]?.promo;
        this.email = data[0][0]?.email
        this.mailbox = data[0][0]?.mailbox
        this.sendMail = data[0][0]?.sendMail
        this.matricule = data[0][0]?.matricule
        this.tel = data[0][0]?.tel
        this.adresseville = data[0][0]?.adresseVille
        //document.cookie = "nameComplet=" + (this.nameComplet);
        // document.cookie = "nameCourt=" + (this.nameCourt);
        // document.cookie = "pictureConfig=" + (this.pictureConfig);
        // document.cookie = "globaleDiscount=" + (this.globaleDiscount);
        // document.cookie = "promo=" + (this.promo);
        // document.cookie = "email=" + (this.email)
        // document.cookie = "mailbox=" + (this.mailbox)
        // document.cookie = "sendMail=" + (this.sendMail)
        // document.cookie = "matricule=" + (this.matricule)
        // document.cookie = "tel=" + (this.tel)
        // document.cookie = "adresseville=" + (this.adresseville)
      }
    )
  }

  logout() {
    localStorage.removeItem("authorisation")
    this.router.navigate(['/user/login']);
    deleteCookie("token")
  }

  async onSearchChange(args: string) {
    if (window.location.pathname != '/search') {
      await this.router.navigate(['/search']);
    }
    this.serviceCore.sendSearchStream(args)
    var event = new CustomEvent('findAction', { 'detail': args });
    window.dispatchEvent(event);
  }
  btnToggle() {
    this.open = true
  }
  EventLang() {
    let lang = localStorage.getItem('currentLang')
    if (lang == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  changeEventLang(event: any) {
    this.translate.use(event.target.value);
    if (event.target.value == 'ar') {
      this.arabicLanguage = true
    } else {
      this.arabicLanguage = false
    }
  }
  OpenSidebarArabic() {
    this.openSideBarArabic.emit(true);
  }
  showMenuProfile(){
    this.showProfile = !this.showProfile
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    const profileBlock = document.getElementById('profile');
    const langBlock = document.getElementById('menuLang')
    const clickedElement = event.target as HTMLElement;
    let found = profileBlock.contains(clickedElement)
    let found1 = langBlock.contains(clickedElement)
    if (!found) {
      this.showProfile=false
    }
    if (!found1) {
      this.openMenuLang=false
    }
  }
}
