import { Component, OnInit } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { imagesEventObject } from 'src/app/setting/setting';
import { SettingService } from 'src/app/setting/setting.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  imageObject: imagesEventObject[] = []
  constructor(private settingService: SettingService) { }

  ngOnInit(): void {
    this.getPicturesNews();
  }

  getPicturesNews() {
    let filter = new FilterDto()
    filter.where = { "active": true }
    filter.relations = ["pictures"]
    this.settingService.getNews(filter).subscribe(
      data => {
        this.imageObject = []
        data[0].forEach(element => {
          element.pictures.forEach(el => {
            let model = new imagesEventObject()
            model.image = el.url;
            model.thumbImage = el.url;
            model.title = element.name
            this.imageObject.push(model)
          })
        })
      }
    )
  }

}
