import { Transaction } from "../finance/finance";
import { Address } from "../parts/parts";
import { Contact, Devise } from "../user/user";

export class Configuration {
    constructor(
        public id?: number,
        public identity?: string,
        public DBname?: string,
        public name?: string,
        public firstName?: string,
        public lastName?: string,
        public cloudName?: string,
        public apiKey?: string,
        public apiSecret?: string,
        public nom_Domaine?: string,
        public tel?: number,
        public email?: string,
        public mailbox?: string,
        public password?: string,
        public nameComplet?: string,
        public nameCourt?: string,
        public matricule?: string,
        public adresseNumero?: string,
        public adresseRoute?: string,
        public adresseCode?: string,
        public adresseVille?: string,
        public adresseGouvernorat?: string,
        public picture?: string,
        public createdAt?: string,
        public updatedAt?: string,
        public active?: boolean,
        public globaleDiscount?: boolean,
        public promo?: boolean,
        public sendMail?: boolean,
        public entrepriseId?: number,
        public updatedBy?: number,
        public createdBy?: number,
        public real_id?: number,
        public stock_security?: number,
        public timbre_fiscale?: boolean,
        public retenue_source?: boolean,
        public retenue_source_second?: boolean,
        public price_calc?: boolean,
        public dueDateNotif?: number,
        public show_customer_crates?: boolean,
        public qte_offre_carte_fid?: number,
        public qte_seuil_carte_fid?: number
    ) { }
}

export class imagesEventObject {
    constructor(
        public image?: string,
        public thumbImage?: string,
        public title?: string
    ) { }
}

export class Timbre {
    constructor(
        public id?: number,
        public name?: string,
        public value?: number,
    ) { }
}
export class Abonnement {
    constructor(
        public id?: number,
        public dateExp?: string,
        public mensuel?: boolean,
        public nbMensuel?: number,
        public nbInvoice?: number,
        public nbQuotation?: number,
        public nbCaisse?: number,
        public nbSalesPoint ?: number,
        public nbUser?: number,
        public annuel?: boolean,
        public premuim?: boolean,
        public facturation_point_vente?: boolean,
        public facturation_only?: boolean,
        public carte_fidelite?: boolean,
        public livraison?: boolean,
        public transactions?: Transaction[],
        public userId?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public active?: boolean,
        public entrepriseId?: number
    ) { }
}
export class Entreprise {
    constructor(
        public id?: number,
        public email?: string,
        public name?: string,
        public password?: string,
        public resToken?: string,
        public role?: string,
        public logo?: string,
        public active?: boolean,
        public contactId?: Contact,
        public adressId?: Address,
        public createdAt?: string,
        public updatedAt?: string,
        public saltRounds?: string,
        public abonnementId?: Abonnement,
        public deviseId?: Devise,
        public mf?: string,
        public tel?: string,
        public activationId?: string,
        public confirmedEmail?: boolean
    ) { }
}