import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Contact, User } from '../../user';
import { SettingService } from '../../../setting/setting.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { FilterDto } from 'src/app/filter.dto';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from '../../user.service';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.css']
})
export class DetailUserComponent implements OnInit {

  user = new User()
  showAlert: boolean;
  userId: number;
  currentUser: number
  disable: boolean;
  userAdd: any
  userEdit: any
  id_entreprise: number
  isAdmin: boolean = false;
  userObject: User = new User();
  contact: Contact = new Contact();
  stopLoading: boolean;
  type_abn: string = ''
  constructor(private route: ActivatedRoute, private settingService: SettingService, private configService: ConfigService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.currentUser = parseInt(getValueFromToken("id"))
    // if (this.currentUser == this.id_entreprise) {
    //   this.isAdmin = true;
    // } else {
    //   this.settingService.getUser(this.currentUser).subscribe(data => {
    //     if (data.role == 'admin') {
    //       this.isAdmin = true;
    //     }
    //   })
    // }
    //this.currentUser = +this.configService.getCookie('iocId')
    //this.isAdmin = this.id_entreprise == this.currentUser
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = +params.get('id')
      this.getUser(this.userId)
    })
    // this.settingService.getUser(this.userId).subscribe(
    //   data => {
    //     this.user = data;
    //     if (this.user.createdBy && this.user.createdBy != this.id_entreprise) {
    //       this.settingService.getUser(this.user.createdBy).subscribe(
    //         data => {
    //           this.userAdd = data
    //           if (this.user.updatedBy && this.user.updatedBy != this.id_entreprise) {
    //             this.settingService.getUser(this.user.updatedBy).subscribe(
    //               data => {
    //                 this.userEdit = data
    //               }
    //             )
    //           }
    //           else if (this.user.updatedBy && this.user.updatedBy == this.id_entreprise) {
    //             this.settingService.getEntreprisedata(this.user.updatedBy).subscribe(
    //               data => {
    //                 this.userEdit = data
    //               }
    //             )
    //           }

    //         }
    //       )
    //     }
    //     else if (this.user.createdBy && this.user.createdBy == this.id_entreprise) {
    //       this.settingService.getEntreprisedata(this.user.createdBy).subscribe(
    //         data => {
    //           this.userAdd = data
    //           if (this.user?.updatedBy && this.user.updatedBy != this.id_entreprise) {
    //             this.settingService.getUser(this.user.updatedBy).subscribe(
    //               data => {
    //                 this.userEdit = data
    //               }
    //             )
    //           }
    //           else if (this.user?.updatedBy && this.user.updatedBy == this.id_entreprise) {
    //             this.settingService.getEntreprisedata(this.user.updatedBy).subscribe(
    //               data => {
    //                 this.userEdit = data
    //               }
    //             )
    //           }

    //         }
    //       )
    //     }

    //   },
    //   err => {
    //     console.error('Observer got an error: ' + err)
    //   }
    // );

  }

  deleteAction() {
    this.showAlert = true;
  }
  closeAction() {
    this.showAlert = false;
  }
   

  getUser(id: number) {
    let filter = new FilterDto<User>()
    filter.where = { "id": id,"entrepriseId":this.id_entreprise }
    filter.relations = ["cratesId","cratesId.pointid","pointId", "contactId"]
    this.settingService.getUserInfo(filter).subscribe(
      data => {
        this.user = data[0][0]
        this.contact.firstName = this.userObject.name
        this.contact.email = this.userObject.email
        this.contact.tel = this.userObject.contactId?.tel
        this.contact.fax = this.userObject.contactId?.fax
        this.contact.id = this.userObject.contactId?.id
        this.stopLoading = true
        if(this.user.createdBy){
          this.settingService.getUser(this.user.createdBy).subscribe(
            data =>{
              this.userAdd = data 
            }
          )
        }
        if(this.user.updatedBy){
          this.settingService.getUser(this.user.updatedBy).subscribe(
            data =>{
              this.userEdit = data 
            }
          )
        }
      },
      err => {
        console.error('Observer got an error: ' + err)
      },
    )
  }
}
