import { Component, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';

@Component({
  selector: 'app-add-cashier',
  templateUrl: './add-cashier.component.html',
  styleUrls: ['./add-cashier.component.css']
})
export class AddCashierComponent implements OnInit {

  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false
  constructor() { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
  }
   

}
