import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Mark } from '../mark';
import { MarkService } from '../mark.service';

@Component({
  selector: 'app-mark-add',
  templateUrl: './mark-add.component.html',
  styleUrls: ['./mark-add.component.css']
})
export class MarkAddComponent implements OnInit {
  showAlert: boolean;
  creerNouveau: boolean = false;
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false

  constructor(private markService: MarkService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.markService.getMarks({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }

  submit(mark: Mark, form: NgForm) {
    mark.real_id = this.real_id;
    mark.entrepriseId = this.id_entreprise
    mark.byAdmin = this.isAdmin
    this.markService.addMark(mark)
      .subscribe(
        data => {
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.resetForm(form);
          } else {
            this.creerNouveau = true;
          }
        })
  }

  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false;
  }

 
}