import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommandeProduct, CommandeDetail } from '../../Commande';
import { serviceService } from 'src/app/catalog/service/service.service';
import { ProductService } from 'src/app/catalog/product/product.service';
import { FilterDto } from 'src/app/filter.dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-commande-product',
  templateUrl: './commande-product.component.html',
  styleUrls: ['./commande-product.component.css']
})
export class CommandeProductComponent implements OnInit {
  @Output() totalElement = new EventEmitter<CommandeProduct[]>()
  @Input() listProduct: CommandeDetail[]
  @Input() saved : boolean = false 

  commandProduct: CommandeProduct[] = []
  tmpCommandProduct: CommandeProduct[] = []
  listCommandProduct: CommandeProduct[] = []
  id_entreprise: number
  productCommandeForm: FormGroup
  productName: string
  elementSelected: CommandeProduct = new CommandeProduct()
  loading: boolean = false
  editElement: boolean = false
  editElementIndex: number = -1
  alertError: boolean = false
  alertMsg: string = ""

  constructor(private _serviceService: serviceService,
    private _productService: ProductService,
    private _fb: FormBuilder) {
    this.productCommandeForm = this._fb.group({ 
      productName: [''],
      quantity: [''],
    })
  }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getServices()
    this.getProducts()
    if (this.listProduct?.length > 0) {
      this.listProduct.forEach(el => {
        let model = new CommandeProduct()
        model.entrepriseId = el.entrepriseId
        model.id = el.id
        model.name = el.productId ? el.productId.name : el.serviceId.name
        model.productId = el.productId ? el.productId : null
        model.serviceId = el.serviceId ? el.serviceId : null
        model.quantity = el.quantity
        this.listCommandProduct.push(model)
      })
    }
  }

  DeleteProduct(item : CommandeProduct) {
    let index = this.listCommandProduct.findIndex(x=>x==item)
    if(index != -1){
      this.listCommandProduct.splice(index,1)
    }
  }

  EditProduct(item: CommandeProduct) {
    this.editElementIndex = this.listCommandProduct.findIndex(x => x == item)
    if (this.editElementIndex != -1) {
      this.elementSelected = this.listCommandProduct[this.editElementIndex]
      this.productName = this.elementSelected.name
      this.editElement = true
    }
  }

  addProduct() {
    if (!this.editElement) {
      this.listCommandProduct.push(this.elementSelected)
    } else {
      this.listCommandProduct[this.editElementIndex].quantity = this.elementSelected.quantity
      this.editElement = false
    }
    this.productName = ""
    this.elementSelected = new CommandeProduct()
  }

  productChanged(item: CommandeProduct) {
    let indexElement = -1
    if (item.productId) {
      indexElement = this.listCommandProduct.findIndex(x => x.productId == item.productId)
    } else {
      indexElement = this.listCommandProduct.findIndex(x => x.serviceId == item.serviceId)
    }
    if (indexElement == -1) {
      this.elementSelected.entrepriseId = this.id_entreprise
      this.elementSelected.name = item.name
      if (item.productId) {
        this.elementSelected.productId = item.productId
      }
      if (item.serviceId) {
        this.elementSelected.serviceId = item.serviceId
      }
    } else {
      this.alertError = true;
      this.alertMsg = "Produit déja existant";
      this.productName = ""
      this.elementSelected = new CommandeProduct()
    }
  }

  submit() {
    this.totalElement.emit(this.listCommandProduct)
  }

  refresh() { }

  getServices() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this._serviceService.getServices(filter).subscribe(
      data => {
        data[0].forEach(el => {
          let commandProduct = new CommandeProduct();
          commandProduct.serviceId = el.id
          commandProduct.name = el.name
          this.commandProduct.push(commandProduct);
          this.tmpCommandProduct.push(commandProduct);
        })
      }
    )
  }

  getProducts() {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this._productService.getProducts(filter).subscribe(
      data => {
        data[0].forEach(el => {
          let commandProduct = new CommandeProduct();
          commandProduct.productId = el.id
          commandProduct.name = el.name
          this.commandProduct.push(commandProduct);
          this.tmpCommandProduct.push(commandProduct);
        })
      }
    )
  }

  onSearchChangeProduct(args?: string) {
    if (args == undefined || args?.length == 0) {
      this.commandProduct = this.tmpCommandProduct
    } else {
      this.commandProduct = []
      this.tmpCommandProduct.forEach(el => {
        if (el?.name.toLowerCase().indexOf(args.toLowerCase()) != -1) {
          this.commandProduct.push(el)
        }
      })
    }
  }
}
