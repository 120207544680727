import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { Position } from 'src/app/sales/shared-sales/shared-sales';
import { Configuration, Entreprise } from 'src/app/setting/setting';
import { tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private router: Router, private http: HttpClient) { }

  // getCookie(cookieName: string) {
  //   var cookieValue: string
  //   var ca = document.cookie.split(';');
  //   if (cookieName != undefined) {
  //     if (ca.length > 0) {
  //       ca.forEach(element => {
  //         if (element.includes(cookieName + "=")) {
  //           cookieValue = element.replace(cookieName + "=", "")
  //           return cookieValue
  //         }
  //       });
  //     }
  //   }
  //   return cookieValue
  // }

  getToken() {
    return getValueFromToken('token')
  }

  getRole() {
    return getValueFromToken("role")
  }

  getProfile() {
     var id = +getValueFromToken('id')
     var filter = { where: { "id": id }, select: ["picture", "name", "lastName", "email"] }
     return this.http.get(this.getApiUrl() + '/profileUser?filter=' + JSON.stringify(filter))
  }

  getUrl() {
    return this.router.url;
  }

  getApiUrl() {
    return 'http://127.0.0.1:3000'
  }

  getCurrentUser() {
    // var id = +this.getCookie('iocId')
    // return this.http.get(this.getApiUrl() + '/user/' + JSON.stringify(id))
  }


  addConfiguration(config: Configuration): Observable<Configuration> {
    return this.http.post<Configuration>(environment.api + '/configuration', config);
  }

  getConfiguration(filter: FilterDto): Observable<Configuration> {
    return this.http.get<Configuration>(environment.api + '/configurations?filter=' + JSON.stringify(filter))
  }

  getEntrepriseInfo(filter: FilterDto<Entreprise>): Observable<[Entreprise[], number]> {
    return this.http.get(environment.api + '/entreprises?filter=' + JSON.stringify(filter)) as Observable<[Entreprise[], number]>
  }

  addPosition(model: Position): Observable<Position> {
    return this.http.post<Position>(environment.api + "/position", model);
  }

  getPosition(filter: FilterDto): Observable<[Position[], number]> {
    return this.http.get(environment.api + "/position?filter=" + JSON.stringify(filter)) as Observable<[Position[], number]>
  }

}



