import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { environment } from 'src/environments/environment';
import { Detail } from './detail';

@Injectable({
  providedIn: 'root'
})
export class DetailService {
  constructor(private http: HttpClient) { }

  getDetails(filter: FilterDto<Detail>): Observable<[Detail[],number]> {
    
    return this.http.get(environment.api + '/details?filter=' + JSON.stringify(filter))as Observable<[Detail[],number]>;
  }

  getDetailById(ID: number): Observable<any> {
    return this.http.get(environment.api + '/detail/' + JSON.stringify(ID)) as Observable<Detail[]>;
  }

  editDetail(ID: number, detail: Detail): Observable<any> {
    return this.http.patch<Detail>(environment.api + '/detail/' + JSON.stringify(ID), detail);
  }

  addDetail(detail: Detail): Observable<Detail> {
    return this.http.post<Detail>(environment.api + '/detail', detail);
  }

  deleteDetail(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/detail/' + JSON.stringify(ID)) as Observable<Detail[]>;
  }
}
