import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Truck } from 'src/app/transport/truck/truck';
import { TruckService } from 'src/app/transport/truck/truck.service';
import { getValueFromToken } from '../../user.service';

@Component({
  selector: 'app-add-modal-vehicule',
  templateUrl: './add-modal-vehicule.component.html',
  styleUrls: ['./add-modal-vehicule.component.css']
})
export class AddModalVehiculeComponent implements OnInit {
  @Output() saved = new EventEmitter<boolean>()
  @Output() closed = new EventEmitter<boolean>()

  truckObject: Truck = new Truck()
  id_entreprise: number
  constructor(private truckService: TruckService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
  }
  submit() {
    this.truckObject.entrepriseId = this.id_entreprise
    this.truckService.addTruck(this.truckObject).subscribe(
      () => {
        this.saved.emit(true)
      }
    )
  }

  CloseAction() {
    this.closed.emit(true)
  }
}
