import { Component, HostListener, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Mark } from './mark';
import { MarkService } from './mark.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';

@Component({
  selector: 'app-mark',
  templateUrl: './mark.component.html',
  styleUrls: ['./mark.component.css']
})
export class MarkComponent implements OnInit {

  marks: Mark[] = [];
  tmpList: Mark[] = []
  showMark: Mark[] = []
  selectedMark: Mark
  showAlert: boolean;
  loading: boolean;
  filter: FilterDto;
  ascSort: ClrDatagridSortOrder;
  whereFindOperator: WhereFindOperator<Mark> = new WhereFindOperator<Mark>()
  showDetail: boolean;
  id_entreprise: number
  userRole: string
  total: number
  nbr_page: number
  currentPage: number = 1
  pageSize: number = 5
  userAutorisation : Autorisation = new Autorisation()
  userId : number
  isAdmin : boolean = false 
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(
    private markService: MarkService, private filterService: FilterService,
      private userService : UserService) {
    this.userRole = jwtDecode(tokenGetter())["role"]
  }

  ngOnInit() {
    // this.ascSort = ClrDatagridSortOrder.ASC;
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userId = parseInt(jwtDecode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getUserAutorisation();
    }
    this.filter = new FilterDto()
    this.filter.where = { "entrepriseId": this.id_entreprise, "active": true }
    this.filter.take = this.pageSize
    this.getMarks(this.filter);
  }

  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  } 

  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;
    this.filter = this.filterService.bind(state);
    this.whereFindOperator.key = this.filterService.dateSearch(state)
    if (this.whereFindOperator.key) {
      this.filter.where = Object.assign(this.filter.where, this.whereFindOperator.key)
    }
    this.filter.where = Object.assign(this.filter.where.valueOf(), { active: true })
    this.filter.where = { "entrepriseId": this.id_entreprise }
    this.getMarks(this.filter);
  }

  getMarks(filter: FilterDto<Mark>) {
    filter.take = this.pageSize
    let insertIndex = filter.skip ? filter.skip : 0
    if (!this.marks[insertIndex]){
      this.markService.getMarks(filter).subscribe(
        data => {
          for (let i = 0; i < data[0].length; i++) {
            this.marks[insertIndex + i] = data[0][i];
          }
          this.tmpList = this.marks
          this.showMark = this.marks.slice(insertIndex, insertIndex + this.pageSize)
          this.total = data[1];
          if (this.total > 0) {
            this.nbr_page = Math.ceil(this.total / this.pageSize)
          } else {
            this.nbr_page = 1
          }
        },
        err => console.error('Observer got an error: ' + err),
        () => this.loading = false
      );
    } else {
      this.showMark = this.marks.slice(insertIndex, insertIndex + this.pageSize)
    }
  }

  getPaginatedData(currentPage) {
    this.filter.skip = (currentPage - 1) * this.pageSize
    this.getMarks(this.filter)
    // if (this.marks.length < this.total) {
    //   this.filter.skip = currentPage * this.pageSize
    //   this.filter.take = this.pageSize
    //   this.getMarks(this.filter)
    // }
  }

  delete(mark: Mark) {
    this.selectedMark = mark
    this.showAlert = true;
  }
  detail(mark: Mark) {
    this.selectedMark = mark
    this.showDetail = true;
  }
  close() {
    this.getMarks(this.filter);
    this.showAlert = false;
    this.showDetail = false;
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
      
    }
  }
}