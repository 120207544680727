import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { User } from '../user/user';
import { Banniere } from './shop';


@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(private http: HttpClient) { }
  
  postBanniere(banniere: Banniere): Observable<Banniere> {
    return this.http.post<Banniere>(environment.api + '/shop/banniere', banniere)
  }

  getBannieres(filter: FilterDto<Banniere>):  Observable<Banniere> {
    return this.http.get(environment.api + '/shop/banniere?filter=' + JSON.stringify(filter)) as Observable<Banniere>;
  }
  getUser(filter: FilterDto<User>): Observable<Banniere> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as  Observable<Banniere>;
  }
  patchBanniere(ID: number, banniere: Banniere): Observable<Banniere> {
    return this.http.patch<Banniere>(environment.api + '/shop/banniere/' + JSON.stringify(ID), banniere);
  }
  deleteBanniere(ID: number): Observable<any> {
    return this.http.delete(environment.api + '/shop/banniere/' + JSON.stringify(ID)) as  Observable<Banniere>;
  }
  
}