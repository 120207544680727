import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Category } from '../category';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-category-delete',
  templateUrl: './category-delete.component.html',
  styleUrls: ['./category-delete.component.css']
})
export class CategoryDeleteComponent {
  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() category: Category;
  constructor(private categoryService: CategoryService) { }

  closeAction() {
    this.closed.emit(true);
  }
  valideAction() {
    this.categoryService.deleteCategory(this.category.id).subscribe(
      res => {
        this.saved.emit(true);
      }
    );
  }
}


