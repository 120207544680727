import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Detail } from '../detail';
import { DetailService } from '../detail.service';

@Component({
  selector: 'app-detail-edit',
  templateUrl: './detail-edit.component.html',
  styleUrls: ['./detail-edit.component.css']
})
export class DetailEditComponent implements OnInit { 
  showAlert: boolean;
  showMsg: boolean;
  detail: Detail;
  id: number;

  constructor(private detailService: DetailService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.detailService.getDetailById(this.id)
        .subscribe(
          data => {
            this.detail = data
          })
    });
  }

  delete() {
    this.showAlert = true;
  }

  close() {
    this.showAlert = false;
  }

  submit(detail: Detail) {
    this.detailService.editDetail(this.id, detail)
      .subscribe(
        data => {
          this.showAlert = true;
        })
  }

}
