import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { User } from 'src/app/user/user';
import { getValueFromToken } from 'src/app/user/user.service';
import { Unity } from '../unity';
import { UnityService } from '../unity.service';

@Component({
  selector: 'app-unity-detail',
  templateUrl: './unity-detail.component.html',
  styleUrls: ['./unity-detail.component.css']
})
export class UnityDetailComponent implements OnInit {
  @Input() unity: Unity;
  @Output() closed = new EventEmitter<boolean>();
  showAlert: boolean;
  searchUser: FilterDto<User> = new FilterDto<User>()
  user: any
  id_entreprise: number
  user2: any
  constructor(private unityService: UnityService, private quotationService: QuotationService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.unityService.getUnityById(this.unity.id)
      .subscribe(
        data => {
          this.unity = data
          if (!this.unity.byAdmin) {
            this.searchUser.select = ["name"];
            this.searchUser.where = { "id": this.unity.createdBy };
            this.quotationService.getUser(this.searchUser).subscribe(
              user => {
                this.user = user[0][0];
              },
              err => console.error('Observer got an error: ', err),
            );
          } else {
            let filter = new FilterDto()
            filter.where = { "id": this.id_entreprise }
            filter.select = ["name"]
            this.quotationService.getEntreprise(filter).subscribe(
              data => {
                this.user = data[0][0]
              }
            )
          }
          if (this.unity.updatedAt) {
            if (!this.unity.updatebyAdmin) {
              this.searchUser.select = ["name"];
              this.searchUser.where = { "id": this.unity.updatedBy };
              this.quotationService.getUser(this.searchUser).subscribe(
                user => {
                  this.user2 = user[0][0];
                },
                err => console.error('Observer got an error: ', err),
              );
            } else {
              let filter = new FilterDto()
              filter.where = { "id": this.id_entreprise }
              filter.select = ["name"]
              this.quotationService.getEntreprise(filter).subscribe(
                data => {
                  this.user2 = data[0][0]
                }
              )
            }
          }
        })
  }
  close() {
    this.closed.emit(true);
  }
}