<section>
    <h3> {{ 'Relation.Caissières.mcs' | translate }}</h3>
    <clr-datagrid [clrDgRowSelection]="true" [clrDgLoading]="loading" (clrDgRefresh)="refresh($event)">
        <clr-dg-action-bar>
            <div class="btn-group large-screen">
                <button type="button" class="btn btn-sm btn-success" routerLink="/parts/provider/add">
                    <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-plus" viewBox="-1 -4 16 16">
                        <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    {{ 'commun.crn' | translate }}
                </button>
            </div>
        </clr-dg-action-bar>
        <clr-dg-column [clrDgField]="'id'" style="background-color: #d0edb8 !important;" [clrDgSortOrder]="descSort">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                {{ 'commun.id' | translate }}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'name'" style="background-color: #d0edb8 !important;">
            {{ 'Relation.Caissières.ncs' | translate }}
            <!-- <clr-dg-filter [clrDgFilter]="provider" (click)="openProvFilter()">
                <app-combobox-list-filter #provider [providerFilter]="providerFilter">
                </app-combobox-list-filter>
            </clr-dg-filter> -->
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'createdAt'" style="background-color: #d0edb8 !important;">
            {{ 'commun.dtc' | translate }}
            <clr-dg-filter [clrDgFilter]="dateProvider">
                <app-date-filter #dateProvider></app-date-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-placeholder>
            {{ 'commun.err' | translate }} </clr-dg-placeholder>
        <clr-dg-row *ngFor="let item of cashierArray">
            <clr-dg-action-overflow>
                <button class="action-item" (click)="detailAction(item)"> {{ 'article.dt' | translate }}</button>
                <button class="action-item" routerLink="/parts/provider/edit/{{item.id}}"> {{ 'commun.mod' | translate
                    }}</button>
                <button class="action-item" (click)="deleteAction(item)"> {{ 'commun.sp' | translate }}</button>
            </clr-dg-action-overflow>
            <clr-dg-cell class="cursor-pointer">{{item.real_id}}</clr-dg-cell>
            <clr-dg-cell class="cursor-pointer">{{item.name}}</clr-dg-cell>

            <clr-dg-cell class="cursor-pointer" *ngIf="item.updatedAt">{{item.updatedAt | date:'dd/MM/yyyy à HH:mm'}}
            </clr-dg-cell>
            <clr-dg-cell class="cursor-pointer" *ngIf="!item.updatedAt">{{item.createdAt | date:'dd/MM/yyyy à HH:mm'}}
            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-column-toggle>
                <clr-dg-column-toggle-title> {{ 'Transactions.Chèque.v' | translate }}</clr-dg-column-toggle-title>
                <clr-dg-column-toggle-button> {{ 'Transactions.Chèque.s' | translate }}</clr-dg-column-toggle-button>
            </clr-dg-column-toggle>
            <clr-dg-pagination #pagination [clrDgTotalItems]="count">
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                de {{count}}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</section>
<!-- <app-provider-detail *ngIf="showDetail" (closed)="closeAction()" [providerId]="providerId" (saved)="refresh($event)">
</app-provider-detail> -->
<!-- <app-provider-delete *ngIf="showAlert" (closed)="closeAction()" [providerId]="providerId" (saved)="refresh($event)">
</app-provider-delete> -->
