<section>
    <h3 dir='{{y}}'> {{ 'article.taxe.Mtax' | translate }}</h3>
    <clr-datagrid (clrDgRefresh)="refresh($event)">
        <clr-dg-action-bar>
            <div class="btn-group large-screen" *ngIf="userRole != 'writer' && userRole !='carrier'">
                <button type="button" class="btn btn-sm btn-success" routerLink="/catalog/tax/add">
                    <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-plus" viewBox="-1 -4 16 16">
                        <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg> {{ 'commun.crn' | translate }}
                </button>
            </div>
        </clr-dg-action-bar>
        <clr-dg-column [clrDgField]='"id"' style="background-color: #d0edb8  !important;" [clrDgSortOrder]="ascSort">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                {{ 'commun.id' | translate }}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'name'" style="background-color: #d0edb8  !important;">
            {{ 'article.taxe.Taxe' | translate }}
            <clr-dg-filter [clrDgFilter]="nameTaxe">
                <app-combobox-filter #nameTaxe [properties]="'name'" [table]="'tax'"></app-combobox-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'percentage'" style="background-color: #d0edb8  !important;">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                {{ 'article.taxe.Pourc' | translate }}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'createdAt'" style="background-color: #d0edb8  !important;">
            {{ 'commun.Date' | translate }}
            <clr-dg-filter [clrDgFilter]="groupFilter">
                <app-date-filter #groupFilter></app-date-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-placeholder>{{ 'commun.err' | translate }} </clr-dg-placeholder>
        <clr-dg-row *ngFor="let item of taxs" [clrDgItem]="item" class="cursor-pointer">
            <clr-dg-action-overflow>
                <button class="action-item" (click)="detail(item)">{{ 'article.dt' | translate }}</button>
                <button class="action-item" *ngIf="userRole != 'writer' && userRole != 'carrier'"
                    [routerLink]="['/catalog/tax/edit', item.id]">{{ 'commun.mod' | translate
                    }}</button>
                <button class="action-item" *ngIf="userRole != 'writer' && userRole != 'carrier'"
                    (click)="delete(item)">{{ 'commun.sp' |
                    translate }}</button>
            </clr-dg-action-overflow>
            <clr-dg-cell class="cursor-pointer">{{item.real_id}}
            </clr-dg-cell>
            <clr-dg-cell class="cursor-pointer">
                <span *ngIf="item.name">{{item.name}}</span>
                <span *ngIf="!item.name" class="label label-danger">{{ 'commun.nd' | translate }}</span>
            </clr-dg-cell>
            <clr-dg-cell class="cursor-pointer">
                <span *ngIf="item.percentage">{{item.percentage}} %</span>
                <span *ngIf="!item.percentage" class="label label-danger">{{ 'commun.nd' | translate }}</span>
            </clr-dg-cell>
            <clr-dg-cell class="cursor-pointer">
                <span *ngIf="item.createdAt">{{item.createdAt | date:'dd/MM/yyyy à HH:mm'}}</span>
                <span *ngIf="!item.createdAt" class="label label-danger">{{ 'commun.nd' | translate }}</span>
            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgTotalItems]="count" [clrDgPageSize]="10">
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                de {{count}}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</section>
<app-tax-detail *ngIf="showDetail" (closed)="close()" [tax]="selectedTax"></app-tax-detail>
<app-tax-delete *ngIf="showAlert" (closed)="close()" [tax]="selectedTax"></app-tax-delete>