import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FilterDto } from "../filter.dto";
import { detailpack, pack } from "./pack";
@Injectable({
  providedIn: 'root'
})
export class PackService {
  constructor(private http: HttpClient) { }

  getAllPacks(filter: FilterDto<pack>): Observable<[pack[], number]> {
    return this.http.get(environment.api + "/packs?filter=" + JSON.stringify(filter)) as Observable<[pack[], number]>;
  }

  getPackById(id: number): Observable<pack> {
    return this.http.get(environment.api + "/pack/" + JSON.stringify(id)) as Observable<pack>
  }

  addPack(model: pack): Observable<pack> {
    return this.http.post(environment.api + "/pack", model);
  }

  getAllDetailPack(filter: FilterDto<detailpack>): Observable<[detailpack[], number]> {
    return this.http.get(environment.api + "/detailpacks?filter=" + JSON.stringify(filter)) as Observable<[detailpack[], number]>
  }
  addDetailPack(model: detailpack): Observable<detailpack> {
    return this.http.post(environment.api + "/packdetail", model)
  }
  deletepack(id: number) {
    return this.http.delete(environment.api + "/pack/" + JSON.stringify(id))
  }
  updatepack(id: number, model: pack): Observable<pack> {
    return this.http.patch(environment.api + "/pack/" + JSON.stringify(id), model)
  }
  updatePackDetail(id: number, model: detailpack) {
    return this.http.patch(environment.api + "/detailpack/" + JSON.stringify(id), model)
  }
  deletepackDetail(id: number) {
    return this.http.delete(environment.api + "/packdetail/" + JSON.stringify(id))
  }

}
