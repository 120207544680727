<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"> {{ 'article.dt' | translate }} {{ 'article.Prod.Pro' | translate }}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="close()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <clr-tabs>
          <clr-tab>
            <button clrTabLink> {{ 'article.dt' | translate }} </button>
            <clr-tab-content>
              <br>
              <div class="clr-row">
                <div class="clr-col-3">
                  <span><b> {{ 'article.deta.nom' | translate }}</b></span><br>
                  <span><b> {{ 'article.Prod.Réference' | translate }}</b></span><br>
                  <span><b> {{ 'article.Prod.codeBarre' | translate }}</b></span><br>
                  <span><b> {{ 'article.Mcatg.catg' | translate }}</b></span><br>
                  <span><b> {{ 'article.Marque.Mrq' | translate }}</b></span><br>
                  <span><b> {{ 'article.unites.unite' | translate }}</b></span><br>
                  <span><b>{{ 'article.Prod.stocksecur' | translate }}</b></span><br>
                  <span><b> {{ 'Points.desc' | translate }}</b></span><br>
                  <span><b> {{ 'commun.cp' | translate }}</b></span><br>
                </div>
                <div class="clr-col">
                  <span *ngIf="product.name">{{product?.name}}</span>
                  <span *ngIf="!product.name" class="label label-danger">{{ 'commun.nd' | translate }}</span><br>

                  <span *ngIf="product.reference">{{product?.reference}}</span>
                  <span *ngIf="!product.reference" class="label label-danger">{{ 'commun.nd' | translate }}</span><br>
                  <span *ngIf="product.bar_code">{{product?.bar_code}}</span>
                  <span *ngIf="!product.bar_code" class="label label-danger">{{ 'commun.nd' | translate }}</span><br>
                  <span *ngIf="product?.categoryId?.name">{{product?.categoryId?.name}}</span>
                  <span *ngIf="!product?.categoryId?.name" class="label label-danger">{{ 'commun.nd' | translate
                    }}</span><br>
                  <span *ngIf="product?.markId?.name">{{product?.markId?.name}}</span>
                  <span *ngIf="!product?.markId?.name" class="label label-danger">{{ 'commun.nd' | translate
                    }}</span><br>
                  <span *ngIf="product?.unityId?.name">{{product?.unityId?.name}}</span>
                  <span *ngIf="!product?.unityId?.name" class="label label-danger">{{ 'commun.nd' | translate
                    }}</span><br>
                  <span *ngIf="product?.stock_security">{{product?.stock_security}}</span>
                  <span *ngIf="!product?.stock_security" class="label label-danger">{{ 'commun.nd' | translate
                    }}</span><br>
                  <span *ngIf="product.description">{{product?.description}}</span>
                  <span class="label label-danger" *ngIf="!product.description">{{ 'commun.nd' | translate }}</span><br>
                  <span>{{user?.name}}</span> à <b>{{product.createdAt | date:'dd/MM/yyyy à HH:mm'}}</b><br>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink id="link1"> {{ 'commun.r' | translate }}</button>
            <clr-tab-content>
              <br>
              <div class="clr-row">
                <div class="clr-col-2">
                  <span><b> {{ 'article.taxe.Taxe' | translate }}</b></span>
                </div>
                <div class="clr-col-10">
                  <span *ngIf="product?.taxId?.percentage">{{product?.taxId?.percentage}} %</span>
                  <span *ngIf="!product?.taxId?.percentage" class="label label-danger"> {{ 'commun.nd' | translate }}
                  </span>
                </div>
              </div>
              <br>
              <b> {{ 'commun.up' | translate }} </b>
              <div *ngIf="product?.productPrices?.length == 0">
                <div class="alert alert-info" role="alert">
                  <div class="alert-items">
                    <div class="alert-item static">
                      <span class="alert-text"> {{ 'commun.lpv' | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <table class="table table-Width" *ngIf="product?.productPrices?.length > 0">
                <thead>
                  <tr>
                    <th> {{ 'commun.type' | translate }} </th>
                    <th> {{ 'commun.r' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of product?.productPrices">
                    <td>
                      <span *ngIf="item.priceTypeId.name">{{item?.priceTypeId?.name}}</span>
                      <span *ngIf="!item.priceTypeId.name" class="label label-danger"> {{ 'commun.nd' | translate }}
                      </span>
                    </td>
                    <td>
                      <span *ngIf="item.price">{{item?.price}} DT</span>
                      <span *ngIf="!item.price" class="label label-danger">{{ 'commun.nd' | translate }} </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink> {{ 'commun.ig' | translate }}</button>
            <clr-tab-content>
              <br>
              <div *ngIf="pictures && pictures.length == 0">
                <div class="alert alert-info" role="alert">
                  <div class="alert-items">
                    <div class="alert-item static">
                      <span class="alert-text">{{ 'commun.lpv' | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clr-row" *ngIf="pictures && pictures.length > 0">
                <div class="clr-col-12 clr-col-md-6  clr-col-lg-6" *ngFor="let item of pictures">
                  <div class="card-img img">
                    <img [src]="item.url" />
                  </div>
                  <div class="card-block">
                    <span class="label label-success default-label" *ngIf="item.defaults == true"> {{ 'commun.prc' |
                      translate }}</span>
                  </div>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
        </clr-tabs>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>