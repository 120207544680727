import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { environment } from 'src/environments/environment';
import { Category } from './category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService  {

  constructor(private http: HttpClient) { }

  getCategories(filter: FilterDto<Category>): Observable<[Category[],number]> {
    
    return this.http.get(environment.api + '/categories?filter=' + JSON.stringify(filter))as Observable<[Category[],number]>;
  }

  getCategoryById(id: number){
    return this.http.get(environment.api + '/category/' + JSON.stringify(id));
  }

  editCategory(id: number, category: Category){
    return this.http.patch<Category>(environment.api + '/category/' + JSON.stringify(id), category);
  }

  addCategory(category: Category): Observable<Category> {
    return this.http.post<Category>(environment.api + '/category', category);
  }

  deleteCategory(id: number): Observable<Category[]> {
    return this.http.delete(environment.api + '/category/' + JSON.stringify(id)) as Observable<Category[]>;
  }
}
