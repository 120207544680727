import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Order } from 'src/app/sales/order/order';
import { OrderService } from 'src/app/sales/order/order.service';
import { Entreprise } from 'src/app/user/entreprise';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-print-ticket-sales',
  templateUrl: './print-ticket-sales.component.html',
  styleUrls: ['./print-ticket-sales.component.css']
})
export class PrintTicketSalesComponent implements OnInit {
  @Input() Order: any
  @Input() EntrepriseObject: any
  @Input() address: any
  @Input() vendeur: any
  id_entreprise: number
  orderId: Order
  devise: string
  url: string
  data: string
  filter: FilterDto = new FilterDto()
  user_created: any

  constructor(private userService: UserService, private router: Router,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
  }
}
