export class Truck {
  constructor(
    public id?: number,
    public mark?: string,
    public model?: string,
    public tonnage?: number,
    public updatedAt?: string,
    public createdAt?: string,
    public updatedBy?: number,
    public createdBy?: number,
    public active?: boolean,
    public entrepriseId?: number,
    public real_id?: number,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean
  ) { }

}