import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { User } from 'src/app/user/user';
import { getValueFromToken, UserService } from 'src/app/user/user.service';
import { Category } from '../category';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.css']
})
export class CategoryDetailComponent implements OnInit {
  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() category: Category;
  showAlert: boolean;
  categoryId: number;
  search: FilterDto<Category> = new FilterDto<Category>()
  searchUser: FilterDto<User> = new FilterDto<User>()
  user: any
  userId: number;
  id_entreprise: number
  constructor(private categoryService: CategoryService, private userService: UserService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getcategory();
  }

  getcategory() {
    this.search.relations = ["details"];
    this.search.where = { "id": this.category.id };
    this.categoryService.getCategories(this.search).subscribe(
      data => {
        this.category = data[0][0];
        if (!this.category.byAdmin) {
          this.category.details = this.category?.details?.filter(item => item.active == true)
          this.searchUser = { select: ["name"], where: { "id": this.category.createdBy } };
          this.userService.getUser(this.searchUser).subscribe(
            data => {
              this.user = data[0][0];
            }
          );
        } else {
          let filter = new FilterDto()
          filter.select = ["name"]
          filter.where = { "id": this.id_entreprise }
          this.userService.getAllEntreprises(filter).subscribe(
            data => {
              this.user = data[0][0]
            }
          )
        }
      },
      err => console.error('Observer got an error: ', err),
    );
    this.showAlert = false;
  }

  closeAction() {
    this.closed.emit(true);
  }
}