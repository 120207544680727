import { Crates } from "../crates/crates";
import { Transaction } from "../finance/finance";
import { Address } from "../parts/parts";
import { Invoice } from "../sales/invoice/invoice";
import { Store, Buying, Order } from "../stock/stock";
import { Contact, User } from "../user/user";

export class Points {
  constructor(
    public id?: number,
    public name?: string,
    public tel?: number,
    public description?: string,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public contacts?: Contact[],
    public adress?: Address[],
    public storeId?: number & Store,
    public entrepriseId?: number,
    public real_id?: number,
    public crates?: Crates[],
    public userId?: User[],
    public transactions?: Transaction[],
    public buying?: Buying[],
    public invoices?: Invoice[],
    public orders?: Order[],
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean
  ) { }
}

export class chiffreAffaireCaissier {
  constructor(
    public userId?: User,
    public amount?: number,
    public count?: number
  ) { }
}
