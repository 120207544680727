import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { Points } from './points';

@Injectable({
  providedIn: 'root'
})
export class SalesPointService {

  constructor(private http: HttpClient) { }
  getPoints(filter: FilterDto<Points>): Observable<[Points[], number]> {
    return this.http.get(environment.api + '/sales-points?filter=' + JSON.stringify(filter)) as Observable<[Points[], number]>;
  }
  async getPointNumber(filter: FilterDto<Points>): Promise<any> {
    const url = `${environment.api}/sales-points?filter=${JSON.stringify(filter)}`;
    try {
      const response = await this.http.get(url).toPromise();
      return response;
    } catch (error) {
      // Handle errors here or rethrow the error
      throw error;
    }
  }
  addPoints(points: Points): Observable<Points> {
    return this.http.post<Points>(environment.api + '/sales-points', points);
  }
  getPointById(id: number): Observable<any> {
    return this.http.get(environment.api + '/sales-points/' + JSON.stringify(id)) as Observable<Points[]>;
  }
  deletePoints(ID: number) {
    return this.http.delete(environment.api + '/sales-points/' + JSON.stringify(ID));
  }
  editPoint(ID: number, point: Points): Observable<any> {
    return this.http.patch<Points>(environment.api + '/sales-points/' + JSON.stringify(ID), point);
  }

}