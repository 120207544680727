import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from './login/login';
import { HttpClient } from '@angular/common/http'
import { FilterDto } from '../filter.dto';
import { User, PostUser, Contact } from './user';
import { Address } from '../parts/parts';
import { Entreprise } from './entreprise';
import jwtDecode from 'jwt-decode';

export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function getValueFromToken(value : string){
  const token = tokenGetter()
  if(token.length >0){
    return jwtDecode(token)[value]
  }
}

export function decodeToken () : any{
  const token = tokenGetter()
  if(token.length > 0){
    return jwtDecode(token)
  }
}


// export function getCookie(cookieName: string) {
//   var cookieValue: string
//   var ca = document.cookie.split(';');
//   if (cookieName != undefined) {
//     if (ca.length > 0) {
//       ca.forEach(element => {
//         if (element.includes(cookieName + "=")) {
//           cookieValue = element.replace(cookieName + "=", "")
//           return cookieValue
//         }
//       });
//     }
//   }
//   return cookieValue
// }
 
// export function tokenGetter() {
//   var name = "token=";
//   var decodedCookie = decodeURIComponent(document.cookie);
//   var ca = decodedCookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) == ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

export function tokenGetter() {
  const name = "token=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim(); // Use trim() to remove leading/trailing spaces

    if (c.indexOf(name) === 0) {
      const token = c.substring(name.length, c.length);
      if (token) {
        return token; // Return the token if it's not empty
      }
    }
  }
  
  return ""; // Return an empty string if the token is not found in the cookie
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  login(user: Login): Observable<any> {
    return this.http.post<Login>(environment.api + '/auth/login', user)
  }



  // getCookie(cname: string) {
  //   var name = cname + "=";
  //   var decodedCookie = decodeURIComponent(document.cookie);
  //   var ca = decodedCookie.split(';');
  //   for (var i = 0; i < ca.length; i++) {
  //     var c = ca[i];
  //     while (c.charAt(0) == ' ') {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) == 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // }

  getUser(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>;
  }

  async getUserNumber(filter: FilterDto<User>): Promise<any> {
    const url = `${environment.api}/users?filter=${JSON.stringify(filter)}`;
    try {
      const response = await this.http.get(url).toPromise();
      return response;
    } catch (error) {
      // Handle errors here or rethrow the error
      throw error;
    }
  }

  getUserAutorisation(userId : number):Observable<[User[],number]>{
    let filter = new FilterDto()
    filter.where = {"id":userId}
    filter.relations = ["autorisationId"]
    return this.http.get(environment.api+"/users?filter="+JSON.stringify(filter)) as Observable<[User[],number]>
  }

  isAdmin() : boolean{
    return getValueFromToken("role")=="admin"
  }

  userAuth(){
    let userId = parseInt(getValueFromToken("id"))
    return this.getUserAutorisation(userId)
  }

  VerifResetPaswword(id: number, user: PostUser): Observable<any> {
    return this.http.post(environment.api + '/user-reset-pwd/' + JSON.stringify(id), user) as Observable<any>;
  }

  addContact(contact: Contact): Observable<Contact> {
    return this.http.post(environment.api + '/contact', contact) as Observable<Contact>;
  }
  addAdress(adress: Address): Observable<Address> {
    return this.http.post(environment.api + '/adress', adress) as Observable<Address>;
  }

  // 
  getAllUsers(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>;
  }
  editUserById(ID: number, userObject: User): Observable<User> {
    return this.http.patch<User>(environment.api + '/user-shop/' + JSON.stringify(ID), userObject);
  }
  getAllEntreprises(filter: FilterDto<Entreprise>): Observable<Entreprise> {
    return this.http.get(environment.api + '/entreprises?filter=' + JSON.stringify(filter)) as Observable<Entreprise>
  }
  updateEntrepriseAccount(id: number, entreprise: Entreprise): Observable<Entreprise> {
    return this.http.patch<Entreprise>(environment.api + '/entreprise/' + JSON.stringify(id), entreprise);
  }

  resetEntreprisePasswordWithToken(request : any): Observable<Entreprise> {
    return this.http.post<any>(environment.api + '/auth/reset-password-resToken', request);
  }

  getResetToken(req : any) : Observable<any>{
    return this.http.post<any>(environment.api + '/auth/get-reset-token',req)
  }

  getEntrepriseByResToken(request : any): Observable<any> {
    return this.http.post(environment.api + '/auth/get-entreprise-by-token' , request) 
  }
}
