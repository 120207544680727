import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { Customer, Providers } from 'src/app/parts/parts';
import { User } from 'src/app/user/user';
import { getValueFromToken } from 'src/app/user/user.service';
import { FinanceService } from '../../finance/finance.service';

@Component({
  selector: 'app-name-filter',
  templateUrl: './name-filter.component.html',
  styleUrls: ['./name-filter.component.css']
})
export class NameFilterComponent implements ClrDatagridFilterInterface<{ key: string, value: string }>, OnInit {
  @Input() cratesFilter: boolean
  @Input() chequeFilter: boolean
  @Input() entityName: string
  saisie: string;
  type: string
  loading: boolean;
  where = {}
  idArray = []
  nameArray = [];
  choosed: any
  filter: FilterDto<any> = new FilterDto()
  id_entreprise: number
  public static instanceof(obj: any) {
    return obj.hasOwnProperty('partsName') && obj.hasOwnProperty('items');
  }
   
  public changes = new Subject<any>();
  constructor(private financeService: FinanceService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.where = {}
    this.entityName = "entityName"
  }

  onSearchChange(args?: any) {
    if (args) {
      var item = args.split('_')
      var name = item[0]
      var role = item[1]
      var customerType = item[2]
      var id = item[3]
      this.choosed = name
      if (role) {
        this.type = 'user',
        this.filter.where = { userId: id }
        this.where = this.filter.where
      }
      if (customerType) {
        this.type = 'customer',
        this.filter.where = { customerId: id }
        this.where = this.filter.where

      }
      if (item.length > 1 && !role && !customerType) {
        this.type = 'provider',
        this.filter.where = { providerId: id }
        this.where = this.filter.where

      }
      this.saisie = name
      this.loadAllData(this.saisie, this.type)
      this.loading = true
    }
    else {
      this.type = 'all'
      this.saisie = 'anyOne'
      this.loadAllData(this.saisie, this.type)
    }
  }

  loadAllData(saisie: string, type: string) {
    this.financeService.getPersonelList(saisie, type).subscribe(
      data => {
        if (this.cratesFilter || this.chequeFilter) {
          this.nameArray = data.filter(item => item["customerType"])
          this.loading = false
          this.changes.next(true);
        } else {
          this.nameArray = data
          this.idArray = []
          this.nameArray.forEach(element => {
            this.idArray.push(element.id)
          });
          this.loading = false
          this.changes.next(true);
        }

      }
    )
  }

  accepts(item): boolean {
    return true;
  }

  isActive(): boolean {
    return this.nameArray != null && this.nameArray.length > 0
  }

}
