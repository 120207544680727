import { Product } from "src/app/catalog/product/product";
import { Tax } from "src/app/catalog/tax/tax";
import { Order } from "src/app/filter.dto";
import { detailpack, pack } from "src/app/pack/pack";
import { User } from "src/app/user/user";

export class ProductSelected {
  constructor(
    public id?: number,
    public productId?: Product,
    public productName?: string,
    public productPrice?: number,
    public quantity?: number,
    public remise?: number,
    public gratuite?: number,
    public tax?: number,
    public taxId?: number & Tax,
    public montant_U_TTC?: number,
    public montant_HT?: number,
    public montant_TTC?: number,
    public qtEnStock?: number,
    public real_id?: number
  ) { }
}
export class ElementSelected {
  constructor(
    public id?: number,
    public productId?: Product,
    public packId?: pack,
    public elementName?: string,
    public elementPrice?: number,
    public quantity?: number,
    public remise: number=0,
    public remiseDisplay:string="%",
    public gratuite?: number,
    public tax?: number,
    public taxId?: number & Tax,
    public montant_U_TTC?: number,
    public montant_HT?: number,
    public montant_TTC?: number,
    public qtEnStock?: number,
    public real_id?: number
  ) { }
}
export class PackSelected {
  public id?: number;
  public packId?: pack;
  public packName: string;
  public packPrice?: number;
  public quantity?: number;
  public remise?: number;
  public gratuite?: number;
  public tax?: number;
  public taxId?: number & Tax;
  public montant_U_TTC?: number;
  public montant_HT?: number;
  public montant_TTC?: number;
  public real_id?: number
  public details?: detailpack[]
}
export class ListePacks {
  constructor(
    public totalPacks?: PackSelected[],
    public montantTotal_HT?: number,
    public montantTotal_TVA?: number,
    public remiseTotal?: number,
    public remiseGlobale?: number,
    public montantTotal_TTC?: number,
  ) { }

}
export class ListeElement {
  constructor(
    public totalElements?: ElementSelected[],
    public montantTotal_HT?: number,
    public montantTotal_TVA?: number,
    public remiseTotal?: number,
    public remiseGlobale?: number,
    public montantTotal_TTC?: number,
  ) { }
}
export class ListeProducts {
  constructor(
    public totalProducts?: ProductSelected[],
    public montantTotal_HT?: number,
    public montantTotal_TVA?: number,
    public remiseTotal?: number,
    public remiseGlobale?: number,
    public montantTotal_TTC?: number,
  ) { }
}

export class ProductSelectedInvoice {
  constructor(
    public id?: number,
    public productId?: Product,
    public quantity?: number,
    public montant_U_TTC?: number,
    public montant_U_HT?: number,
    public totalTTC?: number,
  ) { }
}
export class PackSelectedInvoice {
  constructor(
    public id?: number,
    public packId?: pack,
    public quantity?: number,
    public montant_U_TTC?: number,
    public montant_U_HT?: number,
    public totalTTC?: number,
  ) { }
}

export class Position {
  constructor(
    public id?: number,
    public long?: number,
    public isDeleted?: boolean,
    public lat?: number,
    public type?: string,
    public userId?: User | number,
    public orderId?: Order | number,
    public entrepriseId?: number,
    public createdAt?: Date,
    public updatedAt?: Date
  ) { }
}