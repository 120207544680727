import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { InformationComponent } from './information/information.component';
import { RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { CatalogModule } from '../catalog/catalog.module';
import { PartsModule } from '../parts/parts.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ImpexpoAlertComponent } from './side-nav/impexpo-alert/impexpo-alert.component';
import { SideNavRightComponent } from './side-nav-right/side-nav-right.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ContactezNousComponent } from './contactus/contactez-nous/contactez-nous.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [HeaderComponent, SideNavComponent,
    InformationComponent, SearchComponent, ImpexpoAlertComponent,
    SideNavRightComponent,
    ContactusComponent,
    ContactezNousComponent,],
  imports: [
    CommonModule,
    ClarityModule,
    RouterModule,
    CatalogModule,
    PartsModule,
    TranslateModule,
    FormsModule 
  ],
  exports: [
    HeaderComponent,
    SideNavComponent,
    SideNavRightComponent,
    ContactusComponent
  ]
})
export class CoreModule { }
