import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Unity } from '../unity';
import { UnityService } from '../unity.service';

@Component({
  selector: 'app-unity-add',
  templateUrl: './unity-add.component.html',
  styleUrls: ['./unity-add.component.css']
})
export class UnityAddComponent implements OnInit {
  showAlert: boolean;
  creerNouveau: boolean = false;
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false

  constructor(private unityService: UnityService) { }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.unityService.getUnities({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }

  submit(unity: Unity, form: NgForm) {
    unity.real_id = this.real_id;
    unity.entrepriseId = this.id_entreprise
    unity.byAdmin = this.isAdmin
    this.unityService.addUnity(unity)
      .subscribe(
        data => {
          this.showAlert = true;
          if (this.creerNouveau == true) {
            this.resetForm(form);
          } else {
            this.creerNouveau = true;
          }
        })
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false;
  }

}