import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Commande, EtatCommande } from '../Commande';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommandeService {

  constructor(private http : HttpClient) { }

  getAllCommande(filter : FilterDto):Observable<[Commande[],number]>{
    return this.http.get(environment.api+"/commandes?filter="+JSON.stringify(filter)) as Observable<[Commande[],number]> 
  }

  getEtatsCommande(filter: FilterDto): Observable<[EtatCommande[], number]> {
    return this.http.get(environment.api + "/etatCommande?filter=" + JSON.stringify(filter)) as Observable<[EtatCommande[], number]>
  }
  
  getCommandeById(id: number) {
    return this.http.get(environment.api + '/commande/' + JSON.stringify(id))
  }

  addCommande(commande:Commande): Observable<Commande>{
    return this.http.post(environment.api + '/commande', commande)
  }

  editCommande( id:number, commande:Commande){
    return this.http.patch<Commande>(environment.api + '/commande/' + JSON.stringify(id), commande)
  }

  deleteCommande(id:number): Observable<Commande[]> {
    return this.http.delete(environment.api + '/commande/' + JSON.stringify(id)) as Observable<Commande[]>
  }
}
