<section>
  <div class="wrapper">
    <div class="demo-wrapper">
    <span>Comment ajouter un service</span>
    <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
      <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
      <span class="demo-span" id="demo-span">Demo</span>
    </button>
  </div>
  <h3> {{ 'commun.jt' | translate }} {{ 'article.Prod.Ser' | translate }}</h3>
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'commun.jd' | translate }}
        </span>
      </div>
    </div>
  </div>
  <br>
  <div class="alert alert-danger" role="alert" *ngIf="tailleInvalid">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'commun.fv' | translate }}
        </span>
      </div>
    </div>
  </div>
  <br>
  <form clrForm>
    <div class="wrapper1-client">
              <clr-input-container class="clrInput">
                <label> {{ 'login.VotreNom' | translate }} *</label>
                <input placeholder="{{ 'login.VotreNom' | translate }}" clrInput [(ngModel)]="model.name" name="name"
                  required/>
                <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
              </clr-input-container>
              <clr-input-container class="clrInput">
                <label>{{ 'pack.PrixHT' | translate }} *</label>
                <input type="number" min="0" placeholder="{{ 'pack.PrixHT' | translate }}" [(ngModel)]="model.price"
                  name="price" clrInput required/>
                  <clr-control-error> {{ 'reg.ob' | translate }}</clr-control-error>
              </clr-input-container>
              <clr-input-container class="clrInput">
                <label>{{ 'Points.desc' | translate }}</label>
                <input placeholder="{{ 'Points.desc' | translate }}" [(ngModel)]="model.description" name="description" clrInput />
              </clr-input-container>
              <div class="cmb-wrapper">
              <clr-combobox-container class="clrInput">
                <label> {{ 'article.Mcatg.catg' | translate }} *</label>
                <clr-combobox name="categoryserviceId" required [clrLoading]="loading"
                  (clrInputChange)="onSearchChange($event,'category')" placeholder="Select category">
                  <clr-options *ngIf="categories">
                    <clr-option *ngFor="let item of categories;" clrValue={{item.name}}
                      (click)="itemChanged(item.id,'category')">{{item?.name}}
                    </clr-option>
                  </clr-options>
                  <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
                </clr-combobox>
              </clr-combobox-container>
              <clr-control-helper>
                <button class="btn btn-link btn-sm" (click)="AddCategoriePopUp()" style="margin-left: 92px;">
                  {{ 'commun.jt' | translate }} {{ 'article.Mcatg.catg' | translate }}
                </button>
              </clr-control-helper>
              </div>
              <div class="cmb-wrapper">
              <clr-combobox-container class="clrInput">
                <label> {{ 'article.taxe.Taxe' | translate }} % *</label>
                <clr-combobox name="taxId" required [clrLoading]="loading"
                  (clrInputChange)="onSearchChange($event,'tax')" [ngModel]="selectedTax" placeholder="Select taxs">
                  <clr-options *ngIf="taxs">
                    <clr-option *ngFor="let item of taxs;" clrValue={{item.percentage}}
                      (click)="itemChanged(item.id,'tax')"> {{item?.name}} ({{item?.percentage}} %)
                    </clr-option>
                  </clr-options>
                  <clr-control-error> {{ 'reg.ob' | translate }} </clr-control-error>
                </clr-combobox>
              </clr-combobox-container>
              </div>
    </div>    
  </form>
  <div class="champOb">
    <span><strong>* : {{ 'reg.ob' | translate }}</strong></span>
  </div>
  
  <div class="btn-group btn-outline-primary">
    <button class="btn" routerLink="/catalog/service">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> {{ 'login.tt' | translate }}
    </button>
    <button class="btn" (click)="submit()" *ngIf="!creerNouveau"
      [disabled]="!model.categoryserviceId || !model.name || !model.taxId || !model.price">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> {{ 'reg.e' | translate }}
    </button>
    <button class="btn" (click)="annuler()" *ngIf="creerNouveau">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> {{ 'commun.crn' | translate }}
    </button>
  </div>
  </div>
</section>
<app-catalog-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-catalog-demo> 
<app-add-category-service-modal *ngIf="showAddCategory" (closed)="CloseAction()" (saved)="AddCategory($event)">
</app-add-category-service-modal>