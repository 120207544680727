<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<app-quantity-modal *ngIf="showQteModal" (result)="getQteReturned($event)"
  (closed)="CloseAction()"></app-quantity-modal>
<app-alert-modal *ngIf="showAlert" [showQuestion]="showQuestion" (closed)="CloseAction()" [msg]="msgAlert"
  (searchProd)="searchProdModal()" (searchCarte)="searchCarteModal()"></app-alert-modal>
<app-search-product-modal *ngIf="showSearch" (closed)="CloseAction()"></app-search-product-modal>
<app-enter-code-product *ngIf="showEnterCode" [showCarteBarcode]="showCarteBarcode" (notFound)="NotFoundCode()"
  (carteFidelite)="selectedCarteFidelite($event)" (product)="get_product_selected($event)" (closed)="CloseAction()">
</app-enter-code-product>
<app-felicitations-modal (confirmation)="confirmed()" *ngIf="showFelicitationModal" [customerName]="customer.name"
  [messages]="felicitationMessages"></app-felicitations-modal>
<section>
  <div class="caisse">
    <div class="caisse-header">
      <div class="header-vendeur">
        <span>{{ 'Caisses.ven' | translate }} : </span>
        <span style="margin-left: 10px;"> {{currentUser?.name}}</span>
      </div>
      <div class="header-options">
        <button class="btn-header one" (click)="navigateToHome()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512">
            <path
              d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
          </svg>
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house"
            viewBox="0 0 16 16">
            <path
              d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
          </svg> -->
          <span>Acceuil</span>
        </button>
        <button class="btn-header two" (click)="OpenMySales()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512">
            <path
              d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-list"
            viewBox="0 0 16 16">
            <path viewBox="0 0 18 18"
            d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
            <path viewBox="0 0 18 18"
            d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
          </svg> -->
          <span>Mes ventes</span>
        </button>
        <button class="btn-header four" (click)="OpenMyOrders()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512">
            <path
              d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
          <span>Mes commandes</span>
        </button>
        <button class="btn-header three" (click)="logout()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16">
            <path fill="#ffffff"
              d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
          </svg>
          <span>Déconnexion</span>
        </button>
      </div>
    </div>
    <div class="caisse-content">
      <div class="content-products">
        <table class="table-products table">
          <thead>
            <tr>
              <th class="table-color"></th>
              <th class="table-color" scope="col"><b>Désignation</b></th>
              <th class="table-color" scope="col"><b>P.UNIT HT</b></th>
              <th class="table-color" scope="col"><b>Qté</b></th>
              <th class="table-color" scope="col"><b>Remise(%)</b></th>
              <th class="table-color" scope="col"><b>Tax(%)</b></th>
              <th class="table-color" scope="col"><b>Prix TTC</b></th>
            </tr>
          </thead>
          <tbody>
            <tr id="tr_product" *ngFor="let item of liste_elements.totalElements" (click)="selectTableRow(item)">
              <td class="table-color">
                <button (click)="editQte(item)" style="font-size: x-small;  
                  text-align:center; border:none" type="button" class="btn btn-sm btn-outline-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                    class="bi bi-pencil-square">
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                </button>
              </td>
              <td *ngIf="item.productId">{{item.productId.name}}</td>
              <td *ngIf="item.packId">{{item.packId.nom}}</td>
              <td>{{item.elementPrice}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.remiseDisplay}}</td>
              <td>{{item.taxId.percentage}}</td>
              <td>{{item.montant_TTC}}</td>
            </tr>
          </tbody>
        </table>
        <div class="search-product">
          <input type="text" [(ngModel)]="search_prod" (input)="searchProdChanged()" class="input-product"
            placeholder="Rechercher" />
          <button class="button-product">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
              viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
        </div>
        <div class="product-favoris">
          <div class="title-favoris">
            <!-- <img src="../../assets/favoris_image.png" alt=""> -->
            <br>
            <span>Categories</span>
          </div>
          <button (click)="makeScrollLeft('fav-products')" class="btn-outline-secondary btn-scroll scroll-left"
            *ngIf="categories.length>0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-caret-left-fill" viewBox="0 0 16 16">
              <path
                d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
            </svg>
          </button>
          <div class="products-favoris" id="fav-products">
            <div class="prod" *ngFor="let item of categories">
              <button *ngIf="item?.real_id" (click)="setCategory(item)">
                <span>{{item?.name}}</span>
                <img *ngIf="item.productId?.pictures.length>0" src="{{item.productId?.pictures[0]?.url}}" alt="">
                <br *ngIf="item.productId?.pictures.length>0"> {{item.productId?.name}}</button>
              <button *ngIf="item?.packId" (click)="selectFavorite(item)">{{item.packId?.nom}}</button>
            </div>
          </div>
          <button (click)="makeScroll('fav-products')" class="btn-outline-secondary btn-scroll"
            *ngIf="categories.length>0">
            <svg xmlns="http://www.w3.org/2000/svg" style="height: 50px;" width="16" height="16" fill="currentColor"
              class="bi bi-caret-right-fill" viewBox="0 0 16 16">
              <path
                d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
          </button>
        </div>
        <div class="product-autre">
          <div class="title-autre">
            <span>
              Produits
            </span>
          </div>
          <button (click)="makeScrollLeft('other-products')" class="btn-outline-secondary btn-scroll scroll-left"
            *ngIf="allProduct.length>0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-caret-left-fill" viewBox="0 0 16 16">
              <path
                d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
            </svg>
          </button>
          <div class="products-autre" id="other-products" *ngIf="showProducts">
            <div class="prod" *ngFor="let item of allProduct">
              <button *ngIf="item?.productId" (click)="selectedOther(item)" class="prod-autre">
                <img *ngIf="item.productId?.pictures.length>0" src="{{item.productId?.pictures[0]?.url}}" alt="">
                <br>{{item?.productId?.name}}
              </button>
              <button *ngIf="item?.packId" (click)="selectedOther(item)">{{item.packId?.nom}}</button>
            </div>
          </div>
          <button (click)="makeScroll('other-products')" class="btn-outline-secondary btn-scroll"
            *ngIf="allProduct.length>0">
            <svg xmlns="http://www.w3.org/2000/svg" style="height: 50px;" width="16" height="16" fill="currentColor"
              class="bi bi-caret-right-fill" viewBox="0 0 16 16">
              <path
                d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="content-sum">
        <div class="netPayer">
          <span class="net-payer">Net à Payer</span>
          <span class="net-sum">{{result || 0}} {{devise}}</span>
        </div>
        <div class="net-remise">
          <div style="display: flex; align-items: center;">
            <label id="label-client">Client</label>
            <p-dropdown class="dropdown-client" [options]="customers" [filter]="true" filterBy="name" [showClear]="true"
              [(ngModel)]="customer" optionLabel="name"  placeholder="Selectionner Client">
              <ng-template pTemplate="selectedItem" *ngIf="customer">
                <div class="flex align-items-center gap-2">
                  <div>{{ customer.name }}</div>
                </div>
              </ng-template>
              <ng-template let-customer pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ customer.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <!-- <clr-combobox style="flex-grow: 1;" [(ngModel)]="customer.name" name="name" (clrInputChange)="onSearchChange($event)">
              <clr-options *ngIf="customers">
                <clr-option *ngFor="let item of customers;" clrValue="{{item.name}}" (click)="clientChanged(item)">
                  <clr-icon shape="user"></clr-icon>{{item?.name}}
                </clr-option>
              </clr-options>
            </clr-combobox> -->
          </div>
          <table class="table_remise table">
            <thead>
              <tr>
                <th scope="col">Remise</th>
                <th scope="col">Prix</th>
              </tr>
            </thead>
            <tbody>
              <tr id="tr1" *ngIf="elementSelected?.productId">
                <td id="td1">
                  <input type="text" (input)="onRemiseChange()" name="remise" id="remise"
                    [(ngModel)]="elementSelected.remiseDisplay">
                </td>
                <td id="td2" *ngIf="!elementSelected?.productId?.price">
                  <label id="td2">0</label>
                </td>
                <td id="td2" *ngIf="elementSelected?.productId?.price">
                  <label id="td2">{{elementSelected?.productId?.price}}</label>
                </td>
              </tr>
              <tr id="tr1" *ngIf="elementSelected?.packId">
                <td id="td1">
                  <input type="text" (input)="onRemiseChange()" name="remise" id="remise"
                    [(ngModel)]="elementSelected.remiseDisplay">
                </td>
                <td id="td2" *ngIf="!elementSelected?.packId?.price">
                  <label id="td2">0</label>
                </td>
                <td id="td2" *ngIf="elementSelected?.packId?.price">
                  <label id="td2">{{elementSelected?.packId?.price}}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sum-amount">
          <div class="sum-amount-header">Montant</div>
          <div class="sum-amount-content">
            <button class="btn_grey btn mb-2 btn-outline-secondary" type="button" (click)="CalculReste('5')">
              5 DT
            </button>
            <button class="btn_grey btn mb-2 btn-outline-secondary" (click)="CalculReste('10')" type="button">
              10 Dt
            </button>
            <button class="btn_grey btn mb-2 btn-outline-secondary" type="button" (click)="CalculReste('20')">
              20 DT
            </button>
            <button class="btn_grey btn mb-2 btn-outline-secondary" (click)="CalculReste('50')" type="button">
              50 DT
            </button>
          </div>
          <div class="amount">
            <input type="number" [(ngModel)]="amount_entered" (ngModelChange)="CalculReste(amount_entered)"
              placeholder="Montant" class="input-amount">
          </div>
        </div>
        <div class="reste">
          <span class="reste-tag">RESTE :</span>
          <span *ngIf="!reste || reste === 0"></span>
          <span class="reste-value positive" *ngIf="reste>0">{{reste}}</span>
          <span class="reste-value negative" *ngIf="reste<0">{{reste}}</span>
        </div>
        <div class="sum-options">
          <div class="sum-options-header">Options</div>
          <div class="sum-options-content">
            <button id="btn_grey1" class="btn ml-1 mr-1 mb-2 btn-outline-primary" (click)="EnterCode()" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upc"
                viewBox="0 0 16 16">
                <path
                  d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
              </svg>
              &nbsp;Entrer<br> code
            </button>
            <button id="btn_grey1" (click)="addQuotation()" class="btn ml-1 mr-1 mb-2 btn-outline-secondary"
              type="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">
                <path
                  d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z" />
              </svg>&nbsp;Devis
            </button>
            <button id="btn_grey1" (click)="OpenProductList()" class="btn ml-1 mr-1 mb-2 btn-outline-secondary"
              type="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>&nbsp;Produit
            </button>
            <button id="btn_grey1" class="btn ml-1 mr-1 mb-2 btn-outline-danger" type="button" (click)="DeleteLine()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-trash3-fill" viewBox="0 0 16 16">
                <path
                  d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
              </svg>Effacer
              <br>
              ligne
            </button>
            <button [disabled]="!id_order_to_print" id="btn_print" class="btn ml-1 mr-1 mb-2 btn-outline-primary"
              printSectionId="printable-section" ngxPrint type="button" printTitle="Impression Ticket"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer"
                viewBox="0 0 16 16">
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
              </svg>&nbsp;Imprimer<br>
              ticket</button>
            <!-- <button id="btn_grey1" class="btn ml-1 mr-1 mb-2 btn-outline-success" (click)="ValidateLine()" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check"
                viewBox="0 0 16 16">
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
              </svg>&nbsp;Valider
            </button> -->
            <button id="btn_grey1" class="btn ml-1 mr-1 mb-2 btn-outline-info" (click)="searchProduct()"
              type="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-search" viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>&nbsp;Recherche<br> article </button>
            <button id="btn_grey1" class="btn ml-1 mr-1 mb-2 btn-outline-secondary" (click)="OpenInvoices()"
              type="button">Factures
            </button>
            <button id="btn_submit" [disabled]="liste_elements.totalElements.length==0"
              class="btn ml-1 mr-1 mb-2 btn-outline-success" type="button" (click)="submitOrder()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>&nbsp;Terminer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div id="printable-section" style="display: none;">
  <app-print-ticket-sales [vendeur]="currentUser" [address]="address" [Order]="id_order_to_print"
    [EntrepriseObject]="entrepriseObject">
  </app-print-ticket-sales>
</div>