import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto, Order } from '../filter.dto';
import { Customer, Providers, PostBank, HistoriqueObject, Companys, Cashiers } from './parts';
import { Contact } from '../user/user';
import { Transaction } from '../finance/finance';


@Injectable({
  providedIn: 'root'
})
export class PartsService {

  constructor(private http: HttpClient) { }

  getTransactions(filter: FilterDto<Transaction>): Observable<Transaction> {
    return this.http.get(environment.api + '/transactions?filter=' + JSON.stringify(filter)) as Observable<Transaction>;
  }
  geCustomertOrders(filter: FilterDto<Order>): Observable<any> {
    return this.http.get(environment.api + '/customer-orders?filter=' + JSON.stringify(filter)) as Observable<any>;
  }

  getCustomers(filter: FilterDto<Customer>): Observable<Customer> {
    return this.http.get(environment.api + '/customers?filter=' + JSON.stringify(filter)) as Observable<Customer>;
  }

  getCustomerInfo(filter: FilterDto<Customer>): Observable<[Customer[], number]> {
    return this.http.get(environment.api + '/customers?filter=' + JSON.stringify(filter)) as Observable<[Customer[], number]>
  }

  getCustomer(id: number): Observable<Customer> {
    return this.http.get(environment.api + '/customer/' + JSON.stringify(id)) as Observable<Customer>;
  }

  addCustomer(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(environment.api + '/customer', customer);
  }

  editCustomer(ID: number, customer: Customer): Observable<Customer> {
    return this.http.patch<Customer>(environment.api + '/customer/' + JSON.stringify(ID), customer);
  }

  deleteCustomer(ID: number): Observable<Customer> {
    return this.http.delete(environment.api + '/customer/' + JSON.stringify(ID));
  }

  getCustomerDetails(id: number, year: number): Observable<any> {
    return this.http.get(environment.api + '/customer-Detail/' + JSON.stringify(id) + '/' + JSON.stringify(year)) as Observable<any>;
  }

  getCustomerChart(id: number, year: number): Observable<any> {
    return this.http.get(environment.api + '/customer-chart/' + JSON.stringify(id) + '/' + JSON.stringify(year)) as Observable<any>;
  }

  getCustomerHistory(searchObject: HistoriqueObject): Observable<any> {
    return this.http.post(environment.api + '/customer-history', searchObject) as Observable<any>;
  }




  getContacts(filter: FilterDto<Contact>): Observable<[Contact[], number]> {
    return this.http.get(environment.api + '/contacts?filter=' + JSON.stringify(filter)) as Observable<[Contact[], number]>;
  }




  getProviders(filter: FilterDto<Providers>): Observable<Providers> {
    return this.http.get(environment.api + '/providers?filter=' + JSON.stringify(filter)) as Observable<Providers>;
  }

  getProvider(id: number): Observable<Providers> {
    return this.http.get(environment.api + '/provider/' + JSON.stringify(id)) as Observable<Providers>;
  }

  addProvider(provider: Providers): Observable<Providers> {
    return this.http.post<Providers>(environment.api + '/provider', provider);
  }

  editProvider(ID: number, provider: Providers): Observable<Providers> {
    return this.http.patch<Providers>(environment.api + '/provider/' + JSON.stringify(ID), provider);
  }

  deleteProvider(ID: number): Observable<Providers> {
    return this.http.delete(environment.api + '/provider/' + JSON.stringify(ID));
  }


  getCompanys(filter: FilterDto<Companys>): Observable<Companys> {
    return this.http.get(environment.api + '/companys?filter=' + JSON.stringify(filter)) as Observable<Companys>;
  }
  addCompany(company: Companys): Observable<Companys> {
    return this.http.post<Companys>(environment.api + '/companys', company);
  }
  deleteCompany(ID: number): Observable<Companys> {
    return this.http.delete(environment.api + '/companys/' + JSON.stringify(ID));
  }
  editCompany(ID: number, company: Companys): Observable<Companys> {
    return this.http.patch<Companys>(environment.api + '/companys/' + JSON.stringify(ID), company);
  }


  getBanks(filter: FilterDto<PostBank>): Observable<PostBank> {
    return this.http.get(environment.api + '/banks?filter=' + JSON.stringify(filter)) as Observable<PostBank>;
  }

  getBank(id: number): Observable<PostBank> {
    return this.http.get(environment.api + '/bank/' + JSON.stringify(id)) as Observable<PostBank>;
  }

  addBank(bank: PostBank): Observable<PostBank> {
    return this.http.post<PostBank>(environment.api + '/bank', bank);
  }

  editBank(ID: number, bank: PostBank): Observable<PostBank> {
    return this.http.patch<PostBank>(environment.api + '/bank/' + JSON.stringify(ID), bank);
  }

  deleteBank(ID: number): Observable<PostBank> {
    return this.http.delete(environment.api + '/bank/' + JSON.stringify(ID));
  }



  getCashiers(filter: FilterDto<Cashiers>): Observable<Cashiers> {
    return this.http.get(environment.api + '/cashiers?filter=' + JSON.stringify(filter)) as Observable<Cashiers>;
  }



}