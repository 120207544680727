import { detailpack, pack } from "src/app/pack/pack";
import { PriceType } from "src/app/setting/type/type";
import { Category } from "../category/category";
import { Detail } from "../detail/detail";
import { Mark } from "../mark/mark";
import { Tax } from "../tax/tax";
import { Unity } from "../unity/unity";

export class Product {
  constructor(
    public id?: number,
    public reference?: string,
    public name?: string,
    public description?: string,
    public price?: number,
    public priceBuy?: number,
    public priceType?: number,
    public alertStockMin?: number,
    public forBuying?: boolean,
    public markId?: number & Mark,
    public taxId?: Tax & number,
    public unityId?: number & Unity,
    public categoryId?: number & Category,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public productPrices?: Productprice[],
    public productDetails?: Detailproduct[],
    public entrepriseId?: number,
    public real_id?: number,
    public bar_code?: string,
    public favorite?: boolean,
    public detailpackid?: number | detailpack | null,
    public byAdmin?: boolean,
    public updatebyAdmin?: boolean,
    public stock_security?: number | null,
    public priceBuyProduct?: number | null,
    public remise_product?: number | null,
    public percentage?: number | null
  ) { }
}
export class ProductCaisse {
  constructor(
    public id?: number,
    public productId?: Product,
    public packId?: pack
  ) { }
}
export class Detailproduct {
  constructor(
    public id?: number,
    public valueNumber?: number,
    public valueColor?: string,
    public valueString?: string,
    public updatedAt?: string,
    public createdAt?: string,
    public updatedBy?: number,
    public createdBy?: number,
    public active?: boolean,
    public detailId?: number & Detail,
    public productId?: number & Product,
    public entrepriseId?: number,
  ) { }
}
export class Productprice {
  constructor(
    public id?: number,
    public price?: number,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public productId?: number,
    public priceTypeId?: number & PriceType,
    public entrepriseId?: number
  ) { }

}

export class QuantityProduct {

  product: Product & number;
  quantity: number

}
export class SupplySalesPointModel{
  product: Product & number;
  quantity: number
  selected  : boolean | null
  quantity_entered : number
}

export class ProductPicture {
  constructor(
    public id?: number,
    public url?: string,
    public defaults?: boolean,
    public cloudinaryId?: string,
    public createdAt?: string,
    public updatedAt?: string,
    public createdBy?: number,
    public updatedBy?: number,
    public active?: boolean,
    public productId?: number & Product,
    public detailId?: number & Detail,
    public entrepriseId?: number
  ) { }
}



