<div *ngIf="showSuccess">
    <div class="container">
        <div class="header-msg">
            <b>Compte Activé</b>
        </div>
        <div class="success-message">
            <p>Votre compte a été activé avec succès!</p>
        </div>
    </div>
</div>

<div *ngIf="showAlert">
    <div class="container">
        <div class="header-msg">
            Compte déjà activé
        </div>
        <div class="success-message">
            <p>Votre compte est déjà activé </p>
        </div>
    </div>
</div>