<div class="header mb-3" style="overflow: auto" id="head">
  <h3> <b>   {{ 'commun.lp' | translate }} </b></h3>
</div>
<b><p id="head_list"> {{ 'commun.lp' | translate }} </p></b>
<br />
<div class="list_prod mt-2 mb-2">
  <table class="table">
    <thead>
      <th>  {{ 'commun.rfp' | translate }} </th>
      <th>  {{ 'Stock.Mouvements.NomPro' | translate }} </th>
      <th>  {{ 'commun.pht' | translate }} </th>
      <th>  {{ 'commun.qdis' | translate }}</th>
    </thead>
    <tbody>
      <tr
        class="bordered"
        *ngFor="let item of products; let i = index"
      >
        <td>{{ item.product.reference }}</td>
        <td>{{ item.product.name }}</td>
        <td>{{ item.product.price }}</td>
        <td>{{ item.quantity }}</td>
      </tr>
    </tbody>
  </table>
</div>
<button
  type="button"
  class="btn btn-primary dontPrint"
  id="printBTN"
  (click)="PrintList()"
>
  Imprimer
</button>
