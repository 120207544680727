import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Product } from 'src/app/buying/buying';
import { CarteFideliteService } from 'src/app/carte-fidelite/services/carte-fidelite.service';
import { QuantityProduct } from 'src/app/catalog/product/product';
import { ProductService } from 'src/app/catalog/product/product.service';
import { FilterDto } from 'src/app/filter.dto';
import { pack } from 'src/app/pack/pack';
import { PackService } from 'src/app/pack/pack.service';
import { SettingService } from 'src/app/setting/setting.service';
import { getValueFromToken } from 'src/app/user/user.service';

@Component({
  selector: 'app-enter-code-product',
  templateUrl: './enter-code-product.component.html',
  styleUrls: ['./enter-code-product.component.css']
})
export class EnterCodeProductComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>()
  @Output() element = new EventEmitter<any>()
  @Output() notFound = new EventEmitter<boolean>()
  @Output() carteFidelite = new EventEmitter<any>()
  @Input() showCarteBarcode: boolean = false
  search_prod: UntypedFormGroup
  id_entreprise: number
  productId: Product
  packId: pack
  notfound_prod: boolean = false
  notfound_pack: boolean = false
  store_id: number
  title : string = ""

  constructor(private fb: FormBuilder, private productService: ProductService,
    private packService: PackService, private settingService: SettingService,
    private _carteFideliteService: CarteFideliteService
  ) {
    this.search_prod = this.fb.group({
      bar_code: ['', [
        Validators.required
      ]]
    })
  }

  ngOnInit(): void {
    this.title = this.showCarteBarcode == true ? "Code barre carte" : "Code barre article"
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.getUserInfo();
  }
  getUserInfo() {
    let id_user = parseInt(getValueFromToken("id"))
    let filter = new FilterDto()
    filter.where = { "id": id_user, "entrepriseId": this.id_entreprise }
    filter.relations = ["cratesId", "cratesId.pointid", "cratesId.pointid.storeId"]
    this.settingService.getUserInfo(filter).subscribe(
      data => {
        if (data[0][0] != undefined) {
          this.store_id = data[0][0].cratesId.pointid.storeId.id
        }
      }
    )
  }
  getProduct(id_search: any) {
    let filter: FilterDto = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    filter.relations = ["taxId"]
    this.productService.getInfoProducts(this.store_id, filter).subscribe(
      data => {
        let index = data[0].findIndex(x => x.product.bar_code == id_search);
        if (index > -1) {
          this.productId = data[0][index].product
          this.element.emit(this.productId)
        } else {
          this.notfound_prod = true
        }
      }
    )
  }
  getPacks(id_search: any) {
    let filter = new FilterDto()
    filter.where = { "entrepriseId": this.id_entreprise }
    filter.relations = ["taxId", "details", "details.productid"]
    this.packService.getAllPacks(filter).subscribe(
      data => {
        let index = data[0].findIndex(x => x.bar_code == id_search)
        if (index > -1) {
          this.packId = data[0][index]
          this.element.emit(this.packId)
        } else {
          this.notfound_pack = true
        }
      }
    )
  }

  SearchProd(item) {
    if (!this.showCarteBarcode) {
      this.getProduct(item);
      if (this.notfound_prod) {
        this.getPacks(item)
      }
      setTimeout(x => {
        if (this.notfound_pack && this.notfound_prod) {
          this.notFound.emit(true)
        }
      }, 2000)
    } else {
      let filter = new FilterDto()
      filter.where = {
        "entrepriseId": this.id_entreprise, "active": true,
        "bar_code": item
      }
      filter.relations = ["customerId", "carteFideliteDetails",
      "carteFideliteDetails.productId",
       "carteFideliteDetails.packId"]
      this._carteFideliteService.getAllCartes(filter)
        .subscribe(
          data => {
            this.carteFidelite.emit(data[0][0])
          }
        )
    }
  }

  closeAction() {
    this.closed.emit(true)
  }
}
