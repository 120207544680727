import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tax } from '../tax';
import { TaxService } from '../tax.service';

@Component({
  selector: 'app-tax-delete',
  templateUrl: './tax-delete.component.html',
  styleUrls: ['./tax-delete.component.css']
})
export class TaxDeleteComponent {
  @Input() tax: Tax;
  @Output() closed = new EventEmitter<boolean>();

  constructor(protected taxService: TaxService) { }

  close() {
    this.closed.emit(true);
  }

  save() {
    this.taxService.deleteTax(this.tax.id)
      .subscribe(
        data => {
          this.closed.emit(true);
        })
  }
}