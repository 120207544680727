<!-- CLARITY ICONS STYLE -->
<!-- <link rel="stylesheet" href="https://unpkg.com/clarity-icons@0.10.28/clarity-icons.min.css"> -->

<!--CLARITY ICONS DEPENDENCY: CUSTOM ELEMENTS POLYFILL-->
<!-- <script src="https://unpkg.com/@webcomponents/custom-elements/custom-elements.min.js"></script> -->

<!--CLARITY ICONS API & ALL ICON SETS-->
<!-- <script src="https://unpkg.com/clarity-icons/clarity-icons.min.js"></script> -->

<clr-main-container class="main-container">
  <!-- <div *ngIf="(loadService.isLoading | async) && !showMenu " id="preloaderMain">

  </div> -->
  <div *ngIf="(loadService.isLoading | async) && !showMenu " class="loading-container">
    <div class="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <!--CLARITY ICONS STYLE-->
  
  <!-- <link rel="stylesheet" href="https://unpkg.com/clarity-icons@0.10.28/clarity-icons.min.css"> -->

  <!--CLARITY ICONS DEPENDENCY: CUSTOM ELEMENTS POLYFILL-->

  <!-- <script src="https://unpkg.com/@webcomponents/custom-elements/custom-elements.min.js"></script> -->

  <!--CLARITY ICONS API & ALL ICON SETS-->
  <!-- <script src="https://unpkg.com/clarity-icons/clarity-icons.min.js"></script> -->
  
  
  <app-header *ngIf="showMenu" (openSideBarArabic)="OpenSidebar()"></app-header>
  <div class="content-container">
    <!-- <div class="content"> -->
    
    <div class="content-area espace" [ngClass]="{'showcaisse': showCaisse}">

      <router-outlet>
        <!-- <div *ngIf="(loadService.isLoading | async) && showMenu " id="preloader">

        </div> -->
        <div *ngIf="(loadService.isLoading | async) && showMenu " class="loading-container">
          <div class="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </router-outlet>
    </div>
    <clr-vertical-nav class="navTaille" [clrVerticalNavCollapsed]="clrVerticalNavCollapsed" [clr-nav-level]="1"
      *ngIf="showMenu && !arabicLanguage">
      <app-side-nav *ngIf="showMenu && !arabicLanguage"></app-side-nav>

    </clr-vertical-nav>
    <!-- <clr-vertical-nav class="navTaille" [clrVerticalNavCollapsed]="clrVerticalNavCollapsed" [clr-nav-level]="2"
      *ngIf="showMenu"> -->
    <!-- <div *ngIf="lg">  -->
    <app-side-nav-right *ngIf="showMenu && arabicLanguage" [openSidebarArabic]="openSidebarArabic"></app-side-nav-right>
    <!-- </div> -->

    <!-- </clr-vertical-nav> -->
    <!-- </div> -->
    <app-contactus *ngIf="showContactUs"></app-contactus>
  </div>
  
</clr-main-container>
