import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-felicitations-modal',
  templateUrl: './felicitations-modal.component.html',
  styleUrls: ['./felicitations-modal.component.css']
})
export class FelicitationsModalComponent implements OnInit {

  @Input() messages : string[]
  @Input() customerName : string
  @Output() confirmation = new EventEmitter<boolean>()
  
  constructor() { }

  ngOnInit(): void {
  }

  confirmAction(){
    this.confirmation.emit(true)
  }

}
