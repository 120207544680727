import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { OrderDetail } from 'src/app/sales/order/order';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-delete',
  templateUrl: './product-delete.component.html',
  styleUrls: ['./product-delete.component.css']
})
export class ProductDeleteComponent {
  @Output() saved = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() idProduit: number;
  total: number;
  orderDetail: OrderDetail[] = [];
  real_id: number

  constructor(private productService: ProductService, private proService: ProductService) { }
  ngOnInit() {
    this.getOrders();
    this.productService.getProductById(this.idProduit).subscribe(
      data => {
        this.real_id = data[0].real_id;
      }
    )
  }
  getOrders() {
    let filter: FilterDto<OrderDetail> = new FilterDto<OrderDetail>()
    filter.relations = ["orderId", "productId"];
    filter.where = { productId: { id: this.idProduit } };
    this.proService.getOrderDetail(filter).subscribe(
      data => {
        this.orderDetail = data[0].filter((item => item.orderId.isCompleted == true))
      },
      err => console.error('Observer got an error: ', err)
    );
  }

  save() {
    this.productService.deleteProduct(this.idProduit).subscribe(
      res => {
        this.saved.emit(true);
      }
    );
  }
  close() {
    this.closed.emit(true);
  }

}