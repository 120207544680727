import { Component, HostListener, OnInit } from '@angular/core';
import { Address, Providers } from '../../parts';
import { Contact } from '../../../user/user';
import { PartsService } from '../../parts.service';
import { StockService } from 'src/app/stock/stock.service';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';

@Component({
  selector: 'app-provider-add',
  templateUrl: './provider-add.component.html',
  styleUrls: ['./provider-add.component.css']
})
export class ProviderAddComponent implements OnInit {

  providerObject = new Providers()
  contact = new Contact()
  adresse = new Address()
  adressArray = []
  contactArray = []
  allContact = [];
  allAdress = [];
  showAlert: boolean;
  showDetail: boolean;
  showDetailAdr: boolean;
  usedMail: boolean;
  initaStep: string;
  addNew: any;
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false
  providerObjectMF = false
  providerObjectDe = true
  contactTel = false
  contactMail = true
  AdressStreet: boolean = false
  AdressPostal: boolean = true
  AdressCity: boolean = false
  AdressGouvernorat: boolean = true
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private partsService: PartsService, private stockService: StockService) { }

  ngOnInit() {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.partsService.getContacts({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allContact = data[0]
      }
    )
    this.partsService.getProviders({ where: { "entrepriseId": this.id_entreprise } }).subscribe(
      data => {
        this.real_id = data[1] + 1;
      }
    )
  }
  keyPressNumbers(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  loadAdresse() {
    this.stockService.getAdress({
      where: { "entrepriseId": this.id_entreprise }
    }).subscribe(
      data => {
        this.allAdress = data[0]
      }
    )
  }

  addContact() {
    const existsInBd = this.allContact.filter(element =>
      element.email === this.contact.email
      && element.email != undefined
      && element.entrepriseId == this.id_entreprise
    )
    const existsInTab = this.contactArray.filter(element => element.email === this.contact.email && element.email != undefined)
    if (existsInBd.length > 0 || existsInTab.length > 0) {
      this.usedMail = true
    }
    else {
      this.contact.firstName = this.providerObject.name
      this.contact.entrepriseId = this.id_entreprise
      this.contactArray.push(this.contact)
      this.onkeyTel({target:{value:""}})
      this.contact = new Contact()
      this.providerObject.contacts = this.contactArray
      this.providerObject.tel = this.contactArray[0].tel
      this.showDetail = true;
      this.usedMail = false;
    }
  }
  addAdresse() {
    this.adresse.entrepriseId = this.id_entreprise
    this.adressArray.push(this.adresse)
    this.adresse = new Address()
    this.providerObject.adress = this.adressArray
    this.showDetailAdr = true;
  }

  deleteContact(item) {
    this.contactArray.splice(this.contactArray.indexOf(item), 1);
    this.showDetail = false;
  }

  deleteAdresse(item) {
    this.adressArray.splice(this.adressArray.indexOf(item), 1);
    this.showDetailAdr = false;
  }
  submit() {
    if (!this.addNew) {
      this.providerObject.byAdmin = this.isAdmin
      this.providerObject.entrepriseId = this.id_entreprise
      this.providerObject.real_id = this.real_id
      this.partsService.addProvider(this.providerObject).subscribe(
        data => {
          this.showAlert = true;
          this.addNew = true;
        }
      )
    }
  }

  resetForm() {
    this.addNew = false;
    this.allContact = []
    this.allAdress = []
    this.adressArray = []
    this.contactArray = []
    this.providerObject.contacts = []
    this.providerObject.adress = []
    this.providerObject = new Providers();
    this.showAlert = false
    this.showDetail = false
    this.showDetailAdr = false
    this.usedMail = false
    this.initaStep = 'step1'
  }
  onKeyMF(event: any){
    const regex= /^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/
    if (regex.test(event.target.value)){
      this.providerObjectMF=true
    } else {
      this.providerObjectMF=false
    }
  }
  onkeyAdress(event: any){
    const regex= /^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/
    if (regex.test(event.target.value)){
      this.providerObjectDe=true
    } else {
      this.providerObjectDe=false
    }
    if (event.target.value==""){
      this.providerObjectDe=true
    }
  }
  onkeyTel(event: any){
    const regex1 = /^[+]?[0-3]{2}?[0-9]{8}$/;
    const regex2 = /^[0-9]{8}$/;
    const regex = new RegExp(`(${regex1.source})|(${regex2.source})`)
    if (regex.test(event.target.value)){
      this.contactTel=true
    } else {
      this.contactTel=false
    } 
  }
  onkeyMail(event: any){
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (regex.test(event.target.value)){
      this.contactMail=true
    } else {
      this.contactMail=false
    }
    if (event.target.value==""){
      this.contactMail=true
    }
  }
  onkeyStreet(event:any){
    const regex = /^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/;
    if (regex.test(event.target.value)){
      this.AdressStreet=true
    } else {
      this.AdressStreet=false
    }
  }
  onkeyCodePostal(event:any){
    const regex = /^(?!-?0+$|-$)\d*$/;
    if (regex.test(event.target.value)){
      this.AdressPostal=true
    } else {
      this.AdressPostal=false
    }
  }
  onkeyCity(event:any){
    const regex = /^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/;
    if (regex.test(event.target.value)){
      this.AdressCity=true
    } else {
      this.AdressCity=false
    }
  }
  onkeyGovernorat(event:any){
    const regex = /^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/;
    if (regex.test(event.target.value)){
      this.AdressGouvernorat=true
    } else {
      this.AdressGouvernorat=false
    }
    if (event.target.value==""){
      this.AdressGouvernorat=true
    }
  }
  keyPressNom(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressMF(event) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressNum(event:any) {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
}
