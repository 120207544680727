import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { tokenGetter } from 'src/app/user/user.service';
import { Mark } from '../mark';
import { MarkService } from '../mark.service';

@Component({
  selector: 'app-mark-edit',
  templateUrl: './mark-edit.component.html',
  styleUrls: ['./mark-edit.component.css']
})
export class MarkEditComponent implements OnInit {
  showAlert: boolean;
  showMsg: boolean;
  mark: Mark;
  id: number;
  userRole: string
  isAdmin: boolean = false

  constructor(private markService: MarkService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.markService.getMarkById(this.id)
        .subscribe(
          data => {
            this.mark = data
          })
    });
  }

  delete() {
    this.showAlert = true;
  }

  close() {
    this.showAlert = false;
  }

  submit(mark: Mark) {
    mark.updatebyAdmin = this.isAdmin
    this.markService.editMark(this.id, mark)
      .subscribe(
        data => {
          this.showAlert = true;
        })
  }
}