<div class="clr-row">
    <div class="clr-col-md-4 clr-col-12">
        <div class="card">
            <div class="card-header">
                {{ 'sales.Chaffaire' | translate }}
            </div><br>
            <div class="card-title center">
                <b>{{card.totaleCmd | currency:devise:'symbol':'1.2-3':'fr'}}</b>
            </div><br>
        </div>
    </div>

    <div class="clr-col-md-4 clr-col-12">
        <div class="card">
            <div class="card-header">
                {{ 'sales.Mntpay' | translate }}
            </div><br>
            <div class="card-title center">
                <b>{{card.totPaye | currency:devise:'symbol':'1.2-3':'fr'}}</b>
            </div><br>
        </div>
    </div>

    <div class="clr-col-md-4 clr-col-12">
        <div class="card">
            <div class="card-header">
                {{ 'sales.Restpay' | translate }}
            </div><br>
            <div class="card-title center">
                <b>{{card.totNonPaye | currency:devise:'symbol':'1.2-3':'fr'}}</b>
            </div><br>
        </div>
    </div>

</div>
<h4>{{ 'sales.LstCmde' | translate }}</h4>
<clr-datagrid [clrDgRowSelection]="true" [clrDgLoading]="loading" (clrDgRefresh)="refresh($event)">
    <clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="descSort">
        <ng-container *clrDgHideableColumn="{hidden: false}">
            {{ 'commun.id' | translate }}
        </ng-container>
    </clr-dg-column>

    <clr-dg-column [clrDgField]="'totalPrice'">
        <ng-container *clrDgHideableColumn="{hidden: false}">
            {{ 'Transactions.Transaction.mnt' | translate }}
        </ng-container>
    </clr-dg-column>

    <clr-dg-column>
        {{ 'sales.Mntpay' | translate }}
    </clr-dg-column>

    <clr-dg-column>
        {{ 'sales.Restpay' | translate }}
    </clr-dg-column>

    <clr-dg-column [clrDgField]="'createdBy'">
        {{ 'sharedSales.crpar' | translate }}
        <clr-dg-filter [clrDgFilter]="cmdUserFilter">
            <app-combobox-list-filter #cmdUserFilter [cmdUserFilter]="true"></app-combobox-list-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgField]="'isCompleted'">
        {{ 'commun.etat' | translate }}
        <clr-dg-filter [clrDgFilter]="etat">
            <app-checkbox-list-filter #etat [filterParamName]="'isCompleted'" [items]="etatObject" [filterEtat]="true">
            </app-checkbox-list-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgField]="'createdAt'">
        {{ 'commun.dtc' | translate }}
        <clr-dg-filter [clrDgFilter]="dateProvider">
            <app-date-filter #dateProvider></app-date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-placeholder> Nous n'avons trouvé aucune commande ! </clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of orders">
        <clr-dg-action-overflow>
            <button class="action-item" routerLink="/sales/order/detail/{{item.id}}">Détails</button>
        </clr-dg-action-overflow>
        <clr-dg-cell class="cursor-pointer" routerLink="/sales/order/detail/{{item.id}}">{{item.real_id}}</clr-dg-cell>
        <clr-dg-cell *ngIf="item.totalPrice">
            {{item.totalPrice |
            currency:devise:'symbol':'1.2-3':'fr'}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!item.totalPrice">
            <span class="label label-warning">non disponible</span>
        </clr-dg-cell>
        <clr-dg-cell>{{item.paye |
            currency:devise:'symbol':'1.2-3':'fr'}}</clr-dg-cell>
        <clr-dg-cell><span class="label label-danger">{{item.reste |
                currency:devise:'symbol':'1.2-3':'fr'}}</span></clr-dg-cell>
        <clr-dg-cell *ngIf="item.userName">
            {{item.userName}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!item.userName">
            <span class="label label-warning">non disponible</span>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="item.isCompleted">
            <span class="label label-success">validé</span>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="!item.isCompleted">
            <span class="label label-danger">Non validé</span>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="item.updatedAt">
            {{item.updatedAt | date:'dd/MM/yyyy à HH:mm'}}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="!item.updatedAt">
            {{item.createdAt | date:'dd/MM/yyyy à HH:mm'}}
        </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
        <clr-dg-column-toggle>
            <clr-dg-column-toggle-title>Visualisation de colonnes</clr-dg-column-toggle-title>
            <clr-dg-column-toggle-button>Tous selectionner</clr-dg-column-toggle-button>
        </clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgTotalItems]="count">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            de {{count}}
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>