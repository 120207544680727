import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { FilterDto } from 'src/app/filter.dto';
import { OrderCard } from 'src/app/parts/parts';
import { PartsService } from 'src/app/parts/parts.service';
import { Order } from 'src/app/sales/order/order';
import { ConfigService } from 'src/app/shared/config/config.service';
import { FilterService } from 'src/app/shared/filter.service';
import { getValueFromToken } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';

@Component({
  selector: 'app-customer-commandes',
  templateUrl: './customer-commandes.component.html',
  styleUrls: ['./customer-commandes.component.css']
})
export class CustomerCommandesComponent implements OnInit {
  @Input() customerId: number
  filter: FilterDto<Order> = new FilterDto<Order>()
  whereFindOperator = new WhereFindOperator()
  card: OrderCard = new OrderCard()
  orders: any[] = [];
  count: number
  etatObject = [{ "name": "validé", "isCompleted": true }, { "name": "non validé", "isCompleted": false }]
  descSort: ClrDatagridSortOrder;
  loading: boolean
  id_entreprise: number
  devise: string
  constructor(private configService: ConfigService, private filterService: FilterService, private partsService: PartsService) {
    this.descSort = ClrDatagridSortOrder.DESC;
  }
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.configService.getEntrepriseInfo({
      where: { "id": this.id_entreprise }, relations: ["deviseId"]
    }).subscribe(
      data => {
        this.devise = data[0][0].deviseId.code
      }
    )
  }
   
  refresh(state: ClrDatagridStateInterface) {
    if (state) {
      this.filter = this.filterService.bind(state);
    }
    this.whereFindOperator.key = this.filterService.dateSearch(state)
    if (!this.whereFindOperator.key) { this.whereFindOperator.key = undefined }
    let data = { active: true, customerId: this.customerId }
    if (!state?.filters) {
      this.filter.where = data
    }
    else {
      this.filter.where = Object.assign(this.filter.where.valueOf(), data)
      if (this.whereFindOperator.key) {
        this.filter.where = Object.assign(this.filter.where.valueOf(), this.whereFindOperator.key)
      }
    }
    this.filter.order = { "id": "DESC" }
    this.partsService.geCustomertOrders(this.filter).subscribe(
      orders => {
        this.orders = orders[0]
        this.count = orders[1]
        this.getcommande();
      },
      err => console.error('Observer got an error: ', err)
    );
  }

  getcommande() {
    this.card.totaleCmd = this.orders.reduce((acc, item) => acc + item.totalPrice, 0)
    this.card.totPaye = this.orders.reduce((acc, item) => acc + item.paye, 0)
    this.card.totNonPaye = this.orders.reduce((acc, item) => acc + item.reste, 0)
  }
}
