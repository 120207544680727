<h3> Modifier Commande</h3>
<div *ngIf="showAlertSuccess" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
          viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg>
      </div>
      <span class="alert-text">
        {{msgAlert}}
      </span>
    </div>
  </div>
  <button type="button" class="close" (click)="showAlertSuccess=false" aria-label="Close">
    <clr-icon aria-hidden="true" shape="close"></clr-icon>
  </button>
</div>
<div *ngIf="showAlertError" class="alert alert-danger" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-exclamation-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
        </svg>
      </div>
      <span class="alert-text">
        {{msgAlert}}
      </span>
    </div>
  </div>
  <button type="button" class="close" (click)="showAlertError=false" aria-label="Close">
    <clr-icon aria-hidden="true" shape="close"></clr-icon>
  </button>
</div>
    <div class="clr-row">
          <div class="clr-col-sm-10">
            <form clrForm>
              <clr-combobox-container>
                <label>{{ 'sales.nomclient' | translate}} *</label>
                <clr-combobox [(ngModel)]="client.name" name="name" [clrLoading]="loading"
                  (clrInputChange)="onSearchChange($event)">
                  <clr-options *ngIf="clients">
                    <clr-option *ngFor="let item of clients;" clrValue={{item.name}} (click)="clientChanged(item)">
                      <clr-icon shape="user"></clr-icon>{{item?.name}}
                    </clr-option>
                  </clr-options> 
                </clr-combobox>
              </clr-combobox-container>
              <clr-combobox-container>
                <label>Etat de commande *</label>
                <clr-combobox [(ngModel)]="etatCommande.name" name="nameEtat" [clrLoading]="loading"
                  (clrInputChange)="onSearchEtatChange($event)">
                  <clr-options *ngIf="etatCommandes">
                    <clr-option *ngFor="let item of etatCommandes;" clrValue={{item.name}} (click)="etatChanged(item)">
                      {{item?.name}}
                    </clr-option>
                  </clr-options>
                </clr-combobox>
              </clr-combobox-container>
              <clr-date-container>
                <label>Date limite</label>
                <input type="date" [(clrDate)]="commandModel.dateLimite" clrDate name="date_debut"  min={{today_date}}>
              </clr-date-container>
            </form>
          </div>
    </div>
    <div *ngIf="commandModel?.id != undefined">
      <app-commande-product [saved]=saved [listProduct]="commandModel.commandeDetails" (totalElement)="submitDetail($event)"></app-commande-product>
    </div>
