import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { FeedBack } from '../feedBack';
import { CoreService } from '../../core.service';
import { environment } from 'src/environments/environment';
import { FilterDto } from 'src/app/filter.dto';

@Component({
  selector: 'app-contactez-nous',
  templateUrl: './contactez-nous.component.html',
  styleUrls: ['./contactez-nous.component.css']
})
export class ContactezNousComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  id_entreprise: number
  real_id: number
  userRole: string
  isAdmin: boolean = false
  userName : string
  mail: { from: any; to: string; subject: string; html: string };
  infoLogin : any 
  constructor(private coreService : CoreService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.infoLogin = jwtDecode(tokenGetter())["username"]
    this.userName = this.infoLogin["name"]
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getEntrepriseInfo()
    }
  }
  getEntrepriseInfo(){
    let filter = new FilterDto()
    filter.where={"id":this.id_entreprise}
    this.coreService.getEntrepriseData(filter).subscribe(
      data => {
        this.userName +=  ` (utilisateur de ${data[0][0]?.name} )`
      }
    )
  }
  submit(contact: FeedBack) {
     contact.entrepriseId = this.id_entreprise
     contact.byAdmin = this.isAdmin
     contact.createdAt = new Date()
     this.coreService.addFeedback(contact).subscribe(
      data => {
          let content = `
          <!DOCTYPE html>
          <html>
          <head>
            <style>
              body {
                font-family: Arial, sans-serif;
                background-color: #f6f6f6;
                margin: 0;
                padding: 0;
              }
          
              .container {
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
                background-color: #fff;
              }
          
              h1 {
                color: #333;
              }
          
              p {
                margin-bottom: 20px;
              }
          
              .button {
                display: inline-block;
                padding: 10px 20px;
                background-color: #32a852;
                color: #32a852;
                text-decoration: none;
                border-radius: 3px;
              }
            </style>
          </head>
          <body>
            <div class="container">
              <h1>Nouveau message de feedback</h1>
              <p>Cher administrateur,</p>
              <p>Un nouveau message de feedback de la part de ${this.userName} a été ajouté sur votre plateforme.</p>
              <p>Veuillez vous connecter à votre compte administrateur pour consulter le message et y répondre si nécessaire.</p>
              <p>Merci,</p>
              <p>L'équipe de votre plateforme</p>
              <br>
              <p><a class="button" href="${environment.frontAdmin}">Se connecter</a></p>
            </div>
          </body>
          </html>       
          `
          this.mail={
            from:environment.emailSociete,
            to : environment.adminMail,
            subject:"Message de feedback recu",
            html:content
          }
          this.coreService.sendMail(this.mail).subscribe(
            data => {
              alert("message envoyé avec succes ")
              this.closeAction();
            }
          )
      }
     )
  }
  closeAction() {
    this.closed.emit(true);
  }
}
