import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { Configuration } from '../setting/setting';
import { Entreprise } from '../user/entreprise';
import { User } from '../user/user';
import { FeedBack } from './contactus/feedBack';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  searchString: string;
  constructor(private http: HttpClient) { }

  getEntrepriseData(filter: FilterDto<any>): Observable<[Entreprise, number]> {
    return this.http.get(environment.api + '/entreprises?filter=' + JSON.stringify(filter)) as Observable<[Entreprise, number]>;
  }

  getUser(filter: FilterDto<User>): Observable<User> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<User>;
  }

  getconfiguration(filter: FilterDto<Configuration>): Observable<Configuration> {
    return this.http.get(environment.api + '/configurations?filter=' + JSON.stringify(filter)) as Observable<Configuration>;
  }

  sendSearchStream(data: string) {
    this.searchString = data;
  }

  getSearchStream() {
    return this.searchString;
  }

  exportData(id: number) {
    return this.http.get(environment.api + '/export/' + JSON.stringify(id))
  }

  addFeedback(model : FeedBack): Observable<FeedBack>{
    return this.http.post<FeedBack>(environment.api+"/feedback",model);
  }

  sendMail(mail) {
    return this.http.post(environment.api + '/send', mail)
  }

}
