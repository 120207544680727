import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { CarteFidelite } from '../models/CarteFidelite';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarteFideliteService {

  constructor(private http : HttpClient) { }

  getAllCartes(filter : FilterDto) : Observable<[CarteFidelite[],number]>{
    return this.http.get(environment.api+"/carte-fidelites?filter="+JSON.stringify(filter)) as Observable<[CarteFidelite[],number]>
  }

  getById(id : number) : Observable<CarteFidelite | null>{
    return this.http.get<CarteFidelite>(environment.api+"/carte-fidelite/"+JSON.stringify(id))
  }

  addCarteFidelite(carteFidelite : CarteFidelite){
    return this.http.post<CarteFidelite>(environment.api+"/carte-fidelite",carteFidelite)
  }
  updateCarteFidelite(id : number,carteFidelite : CarteFidelite){
    return this.http.patch<CarteFidelite>(environment.api+"/carte-fidelite/"+JSON.stringify(id),carteFidelite)
  }

  deleteCarteFidelite(id : number){
    return this.http.delete<CarteFidelite>(environment.api+"/carte-fidelite/"+JSON.stringify(id))
  }
}
