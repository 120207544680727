import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridStateInterface } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { SalesPointService } from 'src/app/sales-point/sales-point.service';
import { SettingService } from 'src/app/setting/setting.service';
import { FilterService } from 'src/app/shared/filter.service';
import { getValueFromToken, tokenGetter } from 'src/app/user/user.service';
import { Product, QuantityProduct } from '../product';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-print-product-list',
  templateUrl: './print-product-list.component.html',
  styleUrls: ['./print-product-list.component.css'],
})
export class PrintProductListComponent implements OnInit {
  filter: FilterDto<Product> = new FilterDto<Product>();
  constructor(private productService: ProductService, private salesPointService: SalesPointService,
    private settingService: SettingService) { }

  products!: QuantityProduct[];
  count: number;
  id_entreprise: number
  userRole: string
  isAdmin: boolean = false
  store_id: number
  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.isAdmin = this.userRole == "admin"
    this.products = [];
    this.count = 0;
    this.getStoreId()
  }
  getStoreId() {
    if (this.isAdmin) {
      let filter = new FilterDto()
      filter.where = { "entrepriseId": this.id_entreprise, "name": "point de vente principale" }
      filter.relations = ["storeId"]
      this.salesPointService.getPoints(filter).subscribe(
        data => {
          if (data[1] > 0) {
            this.store_id = data[0][0].storeId.id
            this.filter = new FilterDto()
            this.count = 0;
            this.filter.select = ['id', 'reference', 'name', 'forBuying', 'price'];
            this.filter.take = Infinity;
            this.filter.where = { "entrepriseId": this.id_entreprise }
            this.getProducts(this.store_id, this.filter);
          }
        }
      )
    } else {
      const id_user = parseInt(getValueFromToken("id"))
      let filter = new FilterDto()
      filter.where = { "id": id_user, "entrepriseId": this.id_entreprise }
      if (this.userRole == "writer") {
        filter.relations = ["cratesId","cratesId.pointid", "cratesId.pointid.storeId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            if (data[1] > 0) {
              this.store_id = data[0][0].cratesId.pointid.storeId.id
              this.filter = new FilterDto()
              this.count = 0;
              this.filter.select = ['id', 'reference', 'name', 'forBuying', 'price'];
              this.filter.take = Infinity;
              this.filter.where = { "entrepriseId": this.id_entreprise }
              this.getProducts(this.store_id, this.filter);
            }
          }
        )
      } else if (this.userRole == "responsable") {
        filter.relations = ["pointId", "pointId.storeId"]
        this.settingService.getUserInfo(filter).subscribe(
          data => {
            if (data[1] > 0) {
              this.store_id = data[0][0].pointId.storeId.id
              this.filter = new FilterDto()
              this.filter.select = ["id", "reference", "name", "forBuying", "price", "real_id", "favorite"]
              this.filter.where = { "entrepriseId": this.id_entreprise }
              this.getProducts(this.store_id, this.filter);
            }
          }
        )
      }
    }
  }
  getProducts(store_id: number, filter: FilterDto<Product>) {
    this.productService.getInfoProducts(store_id, filter).subscribe(
      (data) => {
        this.products = data[0];
        this.count = data[1];
      },
      (err) => console.error('Observer got an error: ', err)
    );
  }
   

  PrintList() {
    window.print();
  }
}
